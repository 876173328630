import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';

const PREFIX = 'NavigationRegistration';

const classes = {
  titleText: `${PREFIX}-titleText`,
  registerButton: `${PREFIX}-registerButton`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.titleText}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.registerButton}`]: {
    padding: '8px 42px',
    backgroundColor: colors.orange,
    borderColor: colors.orange,
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
    },
  }
}));

type Props = {
  navigateToRoute?: (route: string) => void;
};

const NavigationRegistration = ({ navigateToRoute = () => ({}) }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledGrid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h6" className={classes.titleText}>
          {t('NOT_REGISTERED_FOR_ONLINE_ACCESS')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6" component="span">
          {t('CREATE_YOUR_ONLINE_ACCOUNT')}
        </Typography>
      </Grid>
      <Grid item>
        <PGEButton
          variant="outlined"
          size="large"
          className={classes.registerButton}
          onClick={() => navigateToRoute(ROUTES.REGISTRATION)}
        >
          {t('REGISTER')}
        </PGEButton>
      </Grid>
    </StyledGrid>
  );
};

export default NavigationRegistration;
