import React, { FC } from 'react';
import { SearchProvider } from '@elastic/react-search-ui';
import searchConfig from '../config/searchConfig';
import { startPostMessageService } from '../lib/postMessageService';
import { postGTMAttributes } from '../lib/gtmService';
import { NotificationsContextProvider } from '../providers/NotificationsProvider';
import TransitionProvider from '../providers/TransitionProvider';
import PromotionProvider from '../providers/PromotionProvider';
import {
  Box,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
import theme from '../themes/theme';
import TranslationLoader from '../components/translation-loader/TranslationLoader';
import ErrorBoundary from '../components/error-boundary/ErrorBoundary';
import Backdrop from '../components/backdrop';
import BroadcastCommunication from '../components/BroadcastCommunication';
import GlobalInterceptModal from '../components/intercept-modal';
import { Navigation } from '../components/navigation/Navigation';
import PgeFooter from '../components/navigation/pge-footer';
import Notifications from '../components/notifications';
import SessionTimeout from '../components/SessionTimeout';
import PathProvider from '../providers/PathProvider';
import ROUTES from '../routes';
import { AccountsContextProvider } from '../providers/AccountsProvider';
import { EVChargerFormStateProvider } from '../providers/EVChargerFormStateProvider';
import AccountDisconnectedBanner from '../components/account-disconnected-banner/AccountDisconnectedBanner';
import Helmet from 'react-helmet';
import { globalHistory } from '@gatsbyjs/reach-router';

startPostMessageService();

globalHistory.listen(({ location }) => {
  postGTMAttributes(location.pathname);
});

interface Props {
  readonly element: any;
  readonly props: Readonly<{
    pageContext: Record<string, any>;
    pageResources: Record<string, any>;
    location: Record<string, any>;
  }>;
}

const wrapPageElement: FC<Props> = ({
  element,
  props: { pageContext, pageResources, location },
}) => {
  const isBrowser = !(typeof window === 'undefined');
  const pathname = location.pathname.replace(/\/$/, '');

  const isOutagesMobileApp =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES_MOBILE_APP;

  const isPGEServiceAreaMap = pathname === ROUTES.PGE_SERVICE_AREA_MAP;

  const RenderPageComponents: React.FC = () => (
    <Box
      id={'full-height-shim'}
      display={'flex'}
      flexDirection={'column'}
      minHeight="100vh"
    >
      {!isOutagesMobileApp && !isPGEServiceAreaMap && (
        <>
          <Box id="global-messages-container">
            <BroadcastCommunication />
            <GlobalInterceptModal />
          </Box>
          <Box id="pge-header">
            <Navigation />
          </Box>
        </>
      )}
      <Box id="global-notifications-banner">
        <Notifications />
        <AccountDisconnectedBanner />
      </Box>
      <Box flexGrow={1}>{element}</Box>
      <Backdrop />
      <Box flexShrink={0}>{!isPGEServiceAreaMap && <PgeFooter />}</Box>
      <Helmet>
        <meta name="google" content="notranslate" />
        <script>{`
        ;(function(d, s, c) {
          var j = d.createElement(s),
          t = d.getElementsByTagName(s)[0]
          j.src = '/static/scripts/elastic-apm-rum.umd.min.js'
          j.onload = function() {elasticApm.init(c)}
          t.parentNode.insertBefore(j, t)
          })(document, 'script', {
            serviceName: 'cs_client_${process.env.GATSBY_ACTIVE_ENV ||
              'default'}',
            environment:'${process.env.GATSBY_ACTIVE_ENV || 'default'}',
            serverUrl: '${process.env.APM_URL}',
            active: ${Boolean(process.env.APM_URL) &&
              process.env.APM_ACTIVE &&
              (process.env.GATSBY_ACTIVE_ENV === 'development' ||
                process.env.GATSBY_ACTIVE_ENV === 'test' ||
                process.env.GATSBY_ACTIVE_ENV === 'production' ||
                process.env.GATSBY_ACTIVE_ENV === 'staging')}
          })
        `}</script>
        <script src="https://nebula-cdn.kampyle.com/wu/646316/onsite/embed.js" />
      </Helmet>
    </Box>
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <TransitionProvider>
          <NotificationsContextProvider>
            <AccountsContextProvider>
              <PromotionProvider context={{ ...pageContext, ...pageResources }}>
                <EVChargerFormStateProvider>
                  <PathProvider context={pageContext.slugPathMap}>
                    <CssBaseline />
                    <TranslationLoader />
                    <ErrorBoundary>
                      <SessionTimeout />
                      {/* <StormInfoMessage path={pageResources?.page?.path} /> */}
                      {isBrowser && (
                        <SearchProvider config={searchConfig}>
                          <RenderPageComponents />
                        </SearchProvider>
                      )}
                      {!isBrowser && <RenderPageComponents />}
                    </ErrorBoundary>
                  </PathProvider>
                </EVChargerFormStateProvider>
              </PromotionProvider>
            </AccountsContextProvider>
          </NotificationsContextProvider>
        </TransitionProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default wrapPageElement;
