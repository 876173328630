import React from 'react';
import {Database} from 'firebase/database'
import {database} from '../firebase';

const firebaseContext = React.createContext<{ database:  Database | null}>({ database: null  });
const { Provider } = firebaseContext;

const FirebaseProvider = (props: any) => {  
  return <Provider value={{ database }}>{props.children}</Provider>;
};

export { FirebaseProvider, firebaseContext };




// import React, { useEffect, useState } from 'react';
// import { getFirebase } from '../firebase';

// const firebaseContext = React.createContext({ database: null });
// const { Provider } = firebaseContext;

// const FirebaseProvider = (props: any) => {
//   const [database, setDatabase] = useState(null);

//   useEffect(() => {
//     // const lazyFirebase = import('firebase/compat/index');
//     const lazyApp = import('firebase/compat/app');
//     const lazyDatabase = import('firebase/compat/database');

//     // Lazy load the firebase instance since we need to use the client library rather than the server side library

//     // Promise.all([lazyApp, lazyDatabase]).then(([firebase]) => {
//     // eslint-disable-next-line @typescript-eslint/no-floating-promises
//     Promise.all([lazyApp, lazyDatabase]).then(([firebase]) => {
//       const firebaseDatabase = getFirebase(firebase).database();
//       setDatabase(firebaseDatabase);
//     });
//   }, []);

//   return <Provider value={{ database }}>{props.children}</Provider>;
// };

// export { FirebaseProvider, firebaseContext };
