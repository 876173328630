import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  content?: JSX.Element;
  statusAlert?: any;
  onOverlay?: any;
  show: boolean;
  entryName?: any;
}

const DialogContent = MuiDialogContent;

const Overlay: React.FC<IProps> = props => {
  const { content, statusAlert, show, entryName } = props;
  const [open, setOpen] = React.useState(show);
  useEffect(() => {
    setOpen(show);
  }, [show]);
  const handleClose = () => {
    setOpen(false);
    props.onOverlay(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      maxWidth="xl"
      onClose={handleClose}
      aria-label={entryName}
      open={open}
      fullScreen={fullScreen}
      css={{
        width: '100%',
        maxWidth: 1920,
      }}
      disableScrollLock
    >
      <IconButton
        aria-label="close"
        css={{
          alignSelf: 'flex-end',
          color: theme.palette.grey[500],
        }}
        onClick={handleClose}
        size="large">
        <CloseIcon />
      </IconButton>
      <DialogContent
        css={{
          margin: 0,
          padding: theme.spacing(2),

          '& figure': {
            margin: 0,
          },
        }}>
        {statusAlert}
        {content}
      </DialogContent>
    </Dialog>
  );
};

export default Overlay;
