import React, { FunctionComponent } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import { getLanguage } from '../providers/LanguageProvider';
import { replaceVariables } from '../util/string-utils';

// necessary because this is not .tsx
// see: https://github.com/remarkjs/react-markdown/issues/579
const ReactMarkdownWrapper = (ReactMarkdown as unknown) as FunctionComponent<
  Options
>;

export interface UseTranslationHook {
  t: (key: string, variables?: Variables) => string;
  richT: (key: string, variables?: Variables) => React.ReactNode;
  inlineRichT: (key: string, variables?: Variables) => React.ReactNode;
}

export interface TranslationsKeyValues {
  [key: string]: string;
}

export interface Variables {
  [key: string]: string | undefined;
}

export interface Translations<Values = TranslationsKeyValues> {
  [locale: string]: Values;
}

export type UnpackContentfulNodes<N> = N extends ReadonlyArray<infer E> ? E : N;

interface ContentfulLocaleLike {
  node_locale?: string;
}

// Utility function to transform Contentful nodes into [locale][key] shape.
export const getTranslationsByLocale = <T>(
  nodes: ReadonlyArray<T & ContentfulLocaleLike>,
) => {
  const translations: Translations<T> = {};
  for (const node of nodes) {
    const [loc] = node['node_locale']?.split('-') || ['undefined'];
    translations[loc] = node as T;
  }
  return translations;
};

// Util for transforming contentful translation groups
export const getTranslationsFromContentfulGroups = (
  nodes: Queries.ContentfulTranslationGroup[],
) => {
  const translations: Translations = {};
  for (const node of nodes) {
    const [loc] = node.node_locale?.split('-') || ['undefined'];
    translations[loc] = {};
    for (const field of node.translations || []) {
      translations[loc][field?.slug || 'SLUG_UNDEFINED'] =
        field?.text?.text || '';
    }
  }
  return translations;
};

export const getTranslationsFromFieldsQuery = (
  nodes: Queries.ContentfulTranslationField[],
): Translations => {
  const translations: Translations = {};
  for (const node of nodes) {
    const [loc] = node.node_locale?.split('-') || ['undefined'];
    if (!translations[loc]) {
      translations[loc] = {};
    }
    translations[loc][node?.slug || 'SLUG_UNDEFINED'] = node?.text?.text || '';
  }
  return translations;
};

// This is very much re-asssigned/mutated. See below.

// eslint-disable-next-line prefer-const
let translationsCache: Translations = {};

export const useTranslation = (
  newTranslations?: Translations | any,
): UseTranslationHook => {
  if (newTranslations) {
    Object.keys(newTranslations).map((key: string) => {
      translationsCache[key] = {
        ...translationsCache[key],
        ...newTranslations[key],
      };
    });
  }

  const t = (key: string, variables?: Variables) => {
    const language = getLanguage();
    if (
      translationsCache &&
      translationsCache[language] &&
      translationsCache[language][key]
    ) {
      const baseText = translationsCache[language][key];
      return variables ? replaceVariables(baseText, variables) : baseText;
    }
    // finally, if translation isnt found in lookups, warn, return key
    if (process.env.NODE_ENV === 'development' && translationsCache) {
      // this is super noisy  - shouldnt be anymore
      // console.log(`Missing translation key ${key} for locale ${locale}`)
    }
    return key;
  };

  const richT = (key: string, variables?: Variables) => {
    return React.createElement(ReactMarkdownWrapper, null, t(key, variables));
  };

  // A quick & dirty implmentation to suppress the default surrounding <p>.
  // It's probably something we can use for the default implentation since we'll always (almost?) be wrapping the output in an element.
  // Currently, surrounding the richT output in a default Typography control causes a 'no <p> as a child of <p>' erorr. This can be
  // gotten around by setting the container attribute of the Typography control to 'span' or 'div', but it's irritating...
  const inlineRichT = (key: string, variables?: Variables) => {
    return React.createElement(
      ReactMarkdownWrapper,
      {
        children: '',
        disallowedElements: ['paragraph'], unwrapDisallowed: true
      },
      t(key, variables),
    );
  };
  return { t, richT, inlineRichT };
};
