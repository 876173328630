import { gql } from '@apollo/client';

export const verifyRoutingNumber = gql`
  query verifyRoutingNumber($payload: VerifyRoutingRequest!) {
    verifyRoutingNumber(payload: $payload) {
      valid
    }
  }
`;

export const makePayment = gql`
  mutation makePayment($payload: PaymentInputList!) {
    makePayment(payload: $payload) {
      isPartialSucceed
      paymentConfirmation {
        accountNumber
        confirmationId
        isSuccess
        errorReason
        errorMessage
      }
    }
  }
`;
