import { FC } from 'react';
import SvgMap from '../../svg-map';
import { useSliderContext } from './GallerySlider';
import { NavBtnProps as Props } from './types.d';
import { useTheme } from '@mui/material';
import { Animate, Action, Size } from '../../../constants';


const NavBtn: FC<Props> = ({ use, children }: Props) => {
  if (!children) {
    return null;
  }

  const type = use.toUpperCase() as Action['Animate'];
  const { dispatch } = useSliderContext();
  const theme = useTheme();

  return (
    <button
      className={'graphical'}
      css={{
        boxSizing: 'content-box',
        width: Size.TOUCH,
        height: Size.TOUCH,
        padding: 0,
        position: 'absolute',
        top: '50%',
        zIndex: 3,
        outline: 0,
        color: theme.palette.grey[100],
        border: 0,
        background: 'rgba(7,26,43,.4)',
        transform: 'translateY(-50%) scale(1)',
        cursor: 'pointer',

        '&:first-of-type': {
          left: 0,
          borderRadius: '0 5px 5px 0',
          transformOrigin: '0 50%',
        },

        '&:last-of-type': {
          right: 0,
          borderRadius: '5px 0 0 5px',
          transformOrigin: '100% 50%',
        },

        '& svg': {
          width: '100%',
          height: '100%',
          filter: 'drop-shadow(0 3px 6px rgba(0,0,0,.4))',
        },

        '&:focus': {
          background: 'rgba(7,26,43,1)',
        },

        '&:active': {
          transform: 'translateY(-50%) scale(.94)',
        },

        [theme.breakpoints.up('sm')]: {
          width: Size.TOUCH + 10,
          padding: theme.spacing(2, 0),

          '&:hover': {
            background: 'rgba(7,26,43,1)',
          },
        },
      }}
      type="button"
      onClick={() => dispatch({ type: Animate[type] })}
    >
      <SvgMap use={use} />
      <span>{children}</span>
    </button>
  );
};

export default NavBtn;
