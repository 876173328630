import { DEV, PROD, TEST, STAGE } from './envConstants';
import {resolveApigeeUrl, resolvePgePlusUrl} from './resolveApigeeUrl';
import resolveEnv from './resolveEnv';

const env = resolveEnv();

const apigeePath = process.env.GATSBY_REACT_APP_APIGEE_PATH || 'pge-graphql';
const apigeePgePlusPath = process.env.REACT_APP_APIGEE_PGE_PLUS_PATH || 'pge-graphql';

export default {
  env,
  googleTagManager: {
    oPowerWidgetCode:
      (env === PROD && 'GTM-N6K36L') ||
      (env === DEV && 'GTM-NJ9GLQ5') ||
      (env === TEST && 'GTM-K7JK6LL') ||
      (env === STAGE && 'GTM-N6K36L') ||
      'GTM-NJ9GLQ5',
  },
  storageKey: 'p2s5u8x/A?D(G+KbPeShVmYq3t6w9y$B',
  graphql: {
    baseUrl: `${resolveApigeeUrl(env)}/${apigeePath}`,
    /**
     * For the time being, graphql requests for pge+ are routed to a different server behind apigee.
     * This config allows developers to override the pge+ endpoint for local development.
     */
    baseUrlPgePlus: `${resolvePgePlusUrl(env)}/${apigeePgePlusPath}`,
  },
  apigee: {
    baseUrl: resolveApigeeUrl(env),
    auth: {
      clientId:
        (env === PROD && 'rHuS10KrfsLwFAr2sZ7MHh7oHELGx6YK') ||
        (env === STAGE && 'rHuS10KrfsLwFAr2sZ7MHh7oHELGx6YK') ||
        'hAAyoQ0i6rgQKSoLhLsbWT9yyrKWdMyi',
    },
    widgetAuth: {
      clientId:
        (env === PROD && 'VrrKnd0tw2O4zIM6vqHLYn0PxM3ZW2hY') ||
        (env === STAGE && 'VrrKnd0tw2O4zIM6vqHLYn0PxM3ZW2hY') ||
        'uuIVwZihg6ura1fyE5uitOiWGSKu2Zwo',
    },
  },
  firebase: {
    key:
      (env === PROD && 'AIzaSyDGQGl4SfFoD_KJTo87PboxfNmq89pifqU') ||
      (env === DEV && 'AIzaSyDY4YkNlTokuhcoaGkgYOT2QBgLtHwINt4') ||
      (env === TEST && 'AIzaSyAbzW74uB0gENCQ4uC6k48cBQqI1S8k3GI') ||
      (env === STAGE && 'AIzaSyDGQGl4SfFoD_KJTo87PboxfNmq89pifqU') ||
      'AIzaSyDY4YkNlTokuhcoaGkgYOT2QBgLtHwINt4',
    databaseURL:
      (env === PROD && 'https://gcp-csweb-prod-09ae.firebaseio.com') ||
      (env === DEV && 'https://gcp-csweb-dev-d78c.firebaseio.com') ||
      (env === TEST && 'https://gcp-csweb-test-4917.firebaseio.com') ||
      (env === STAGE && 'https://gcp-csweb-prod-09ae.firebaseio.com') ||
      'https://gcp-csweb-dev-d78c.firebaseio.com',
    reCaptchaSiteKey:
      (env === PROD && '6LciutMlAAAAAI862MawHfQcSdm3n428n-uSIAOG') ||
      (env === DEV && '6LfJWrklAAAAAFmYX88eIDVHgDPzCAT4-L_ioqN6') ||
      (env === TEST && '6LfCWdYlAAAAALsVXtI_-cnZJ5LOaT8rNvBhJFs6') ||
      (env === STAGE && '6LciutMlAAAAAI862MawHfQcSdm3n428n-uSIAOG') ||
      '6LfJWrklAAAAAFmYX88eIDVHgDPzCAT4-L_ioqN6',
    projectId:
      (env === PROD && 'gcp-csweb-prod-09ae') ||
      (env === DEV && 'gcp-csweb-dev-d78c') ||
      (env === TEST && 'gcp-csweb-test-4917') ||
      (env === STAGE && 'gcp-csweb-prod-09ae') ||
      'gcp-csweb-dev-d78c',
    appId:
      (env === PROD && '1:137274392672:web:c340778b45c0489b8d0305') ||
      (env === DEV && '1:640205426174:web:0e5891d8115c07276804af') ||
      (env === TEST && '1:857245679539:web:92ff4ada80185f5b8615c6') ||
      (env === STAGE && '1:137274392672:web:c340778b45c0489b8d0305') ||
      '1:640205426174:web:0e5891d8115c07276804af',
  },
  legacySiteURL:
    (env === PROD && 'https://cs.portlandgeneral.com') ||
    (env === DEV && 'https://cswebdev1.portlandgeneral.com') ||
    (env === TEST && 'https://cswebtestci.portlandgeneral.com') ||
    (env === STAGE && 'https://cs.portlandgeneral.com') ||
    'https://cswebdev1.portlandgeneral.com',

  bizWebLegacyURL:
    (env === PROD && 'https://biz.portlandgeneral.com') ||
    (env === DEV && 'https://devbiz.portlandgeneral.com') ||
    (env === TEST && 'https://testbiz.portlandgeneral.com') ||
    (env === STAGE && 'https://biz.portlandgeneral.com') ||
    'https://devbiz.portlandgeneral.com',

  bizApiLegacyURL:
    (env === PROD && 'https://bizapi.portlandgeneral.com') ||
    (env === DEV && 'https://devbizapi.portlandgeneral.com') ||
    (env === TEST && 'https://testbizapi.portlandgeneral.com') ||
    (env === STAGE && 'https://bizapi.portlandgeneral.com') ||
    'https://devbizapi.portlandgeneral.com',

  csApiLegacyURL:
    (env === PROD && 'https://csapi.portlandgeneral.com') ||
    (env === DEV && 'https://devcsapi1.portlandgeneral.com') ||
    (env === TEST && 'https://testcsapi1.portlandgeneral.com') ||
    (env === STAGE && 'https://csapi.portlandgeneral.com') ||
    'https://devcsapi1.portlandgeneral.com',

  salseforceEVFormURL:
    (env === PROD &&
      'https://portlandgeneral.force.com/EVEnroll/s/evenrollmentdemo') ||
    (env === STAGE &&
      'https://portlandgeneral.force.com/EVEnroll/s/evenrollmentdemo') ||
    (env === TEST && 'https://pgn4--tn.sandbox.my.site.com/EVEnroll/s/') ||
    (env === DEV &&
      'https://ev-portlandgeneral.cs41.force.com/EV1/s/evenrollmentdemo') ||
    'https://ev-portlandgeneral.cs41.force.com/EV1/s/evenrollmentdemo',
};
