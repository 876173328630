import ExternalLink, { LinkProps } from '@mui/material/Link';
import { useTheme } from '@mui/material';
import { Link as InternalLink } from 'gatsby';
import React from 'react';

export interface TextLinkProps extends LinkProps {
  to?: string;
  external?: boolean;
  state?: Record<string, any>;
  plain?: boolean;
  textColor?: string;
  tooltip?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void)
    | undefined;
  download?: string;
  tabIndex?: number;
  dataAttributes?: Record<string, string>;
  className?: string;
  accountTextLink?: boolean;
}

const TextLink: React.FC<TextLinkProps> = ({
  tooltip,
  textColor = '#fff',
  plain,
  to,
  onClick,
  dataAttributes,
  external,
  tabIndex,
  accountTextLink,
  className,
  target,
  download,
  style,
  children,
  state,
}) => {

  const theme = useTheme();

  const css = tooltip ? {
    cursor: 'pointer',
    textDecoration: 'none',
    borderBottom: 'dashed',
    borderWidth: '1px !important',
    '&:hover': {
      borderBottom: `dashed ${theme.palette.secondary.main}`,
    },
    '&:active': {
      color: theme.palette.primary.dark,
      borderBottom: `dashed ${theme.palette.primary.dark}`,
    },
  }
    : plain ? {
      fontSize: theme.typography.h5.fontSize,
      textDecoration: 'none',
      borderBottom: 'none',
      color: theme.palette.primary.main,

      '&.MuiLink-root': {
        fontSize: theme.typography.h4.fontSize,
      },
    }
    : {
      textDecoration: 'underline',
      borderBottom: 'none',
      // '&:hover': {
      //   borderBottom: `solid ${theme.palette.secondary.main}`,
      // },
      '&:active': {
        color: theme.palette.primary.dark,
        borderBottom: `solid ${theme.palette.primary.dark}`,
      },
    }

  const onClickActual = (to || onClick)
    ? onClick
    : (e: React.MouseEvent) => e.preventDefault();

  if (external) {
    return (
      <ExternalLink
        tabIndex={tabIndex}
        className={accountTextLink ? className : ''}
        css={css}
        target={target || '_blank'}
        href={to || '#'}
        onClick={onClickActual}
        download={download}
        component={'a'}
        style={style}
      >
        {children}
      </ExternalLink>
    );
  }

  return (
    <InternalLink
      tabIndex={tabIndex}
      to={(to as string) || '#'}
      css={css}
      onClick={onClickActual}
      style={style}
      state={state}
      {...dataAttributes ?? {}}
    >
      {children}
    </InternalLink>
  );
};


export default TextLink;
