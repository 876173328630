import { ComponentProps, FC, Fragment, useState } from 'react';
import { styled, Theme } from '@mui/material/styles';
import colors from '../../themes/main-colors';
import { navigate } from 'gatsby';

import Overlay from '../pge-overlay';
import {
  BodyComponent,
  StatusAlertComponent,
} from '../../util/contentful-render-utils';
import { Button } from '@mui/material';

const PREFIX = 'PGEButtons';

const classes = {
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  emphasis: `${PREFIX}-emphasis`,
  backCancel: `${PREFIX}-backCancel`,
  Outline: `${PREFIX}-Outline`,
  btnIconWhite: `${PREFIX}-btnIconWhite`,
  btnIconOther: `${PREFIX}-btnIconOther`,
  mobileDesign: `${PREFIX}-mobileDesign`
};

const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.primary}`]: {
    color: colors.white,
    backgroundColor: colors.sparkBlue,
    borderRadius: theme.spacing(0.625),
    fontSize: theme.spacing(2.25),
    height: theme.spacing(6),
    padding: theme.spacing(2.5),
    lineHeight: theme.typography.pxToRem(18),
    '&:hover': {
      color: colors.lightGray2,
      backgroundColor: colors.sparkBlue,
      boxShadow: '0px 5px 5px #00000033',
      '& .MuiButton-startIcon': {
        color: colors.lightGray2,
      },
    },
    '&:disabled': {
      color: colors.lightGray2,
      backgroundColor: '#cacbcd',
      '& .MuiButton-startIcon': {
        color: colors.lightGray2,
      },
    },
    [theme.breakpoints.down('md')]: {
      color: colors.white,
      backgroundColor: colors.sparkBlue,
      borderRadius: theme.spacing(0.625),
      fontSize: theme.spacing(2.25),
      height: theme.spacing(6),
      padding: theme.spacing(2.5),
      width: theme.spacing(30.25),
      border: 'none',
    },
  },

  [`& .${classes.secondary}`]: {
    color: colors.sparkBlue,
    backgroundColor: colors.white,
    borderRadius: theme.spacing(0.625),
    fontSize: theme.spacing(2.25),
    border: '2px solid #0371b4',
    height: theme.spacing(6),
    padding: theme.spacing(2.5),
    lineHeight: theme.typography.pxToRem(18),
    '&:hover': {
      backgroundColor: colors.white,
      boxShadow: '0px 5px 5px #00000033',
    },
    '&:disabled': {
      color: colors.lightGray2,
      backgroundColor: '#cacbcd',
      border: 'none',
      '& .MuiButton-startIcon': {
        color: colors.lightGray2,
      },
    },
    [theme.breakpoints.down('md')]: {
      color: colors.white,
      backgroundColor: colors.sparkBlue,
      borderRadius: theme.spacing(0.625),
      fontSize: theme.spacing(2.25),
      height: theme.spacing(6),
      padding: theme.spacing(2.5),
      width: theme.spacing(30.25),
      border: 'none',
    },
  },

  [`& .${classes.emphasis}`]: {
    color: colors.lightGray2,
    backgroundColor: '#EB7100',
    borderRadius: theme.spacing(0.625),
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    height: theme.spacing(6),
    padding: theme.spacing(2.5),
    lineHeight: theme.typography.pxToRem(18),
    '&:hover': {
      color: colors.lightGray2,
      backgroundColor: '#EB7100',
      boxShadow: '0px 5px 5px #00000033',
    },
    '&:disabled': {
      color: colors.lightGray2,
      backgroundColor: '#cacbcd',
      '& .MuiButton-startIcon': {
        color: colors.lightGray2,
      },
    },
    [theme.breakpoints.down('md')]: {
      color: colors.white,
      backgroundColor: colors.sparkBlue,
      borderRadius: theme.spacing(0.625),
      fontSize: theme.spacing(2.25),
      height: theme.spacing(6),
      padding: theme.spacing(2.5),
      width: theme.spacing(30.25),
      border: 'none',
    },
  },

  [`& .${classes.backCancel}`]: {
    color: colors.white,
    backgroundColor: colors.darkGray2,
    borderRadius: theme.spacing(0.625),
    fontSize: theme.spacing(2.25),
    height: theme.spacing(6),
    padding: theme.spacing(2.5),
    lineHeight: theme.typography.pxToRem(18),
    '&:hover': {
      backgroundColor: colors.darkGray2,
      boxShadow: '0px 5px 5px #00000033',
    },
    '&:disabled': {
      color: colors.lightGray2,
      backgroundColor: '#cacbcd',
      '& .MuiButton-startIcon': {
        color: colors.lightGray2,
      },
    },
    [theme.breakpoints.down('md')]: {
      color: colors.white,
      backgroundColor: colors.sparkBlue,
      borderRadius: theme.spacing(0.625),
      fontSize: theme.spacing(2.25),
      height: theme.spacing(6),
      padding: theme.spacing(2.5),
      width: theme.spacing(30.25),
      border: 'none',
    },
  },

  [`& .${classes.Outline}`]: {
    color: colors.white,
    backgroundColor: 'inherit',
    borderRadius: theme.spacing(0.625),
    fontSize: theme.spacing(2.25),
    border: '2px solid #FFFFFF',
    padding: theme.spacing(1.92, 3.1),
    lineHeight: theme.typography.pxToRem(18),
    '&:hover, &:focus ': {
      backgroundColor: colors.white,
      color: colors.noirBlur,
      textShadow: '#84898f 0 1px 2px',
    },
    '&:disabled': {
      color: '#97c6e5',
      backgroundColor: '#e6f5ff',
      border: 'none',
      '& .MuiButton-startIcon': {
        color: '#97c6e5',
      },
    },
    [theme.breakpoints.down('md')]: {
      color: colors.white,
      borderRadius: theme.spacing(0.625),
      fontSize: theme.spacing(2.25),
    },
  },

  [`& .${classes.btnIconWhite}`]: {
    '& .MuiButton-startIcon': {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0.625),
      color: colors.white,
    },
  },

  [`& .${classes.btnIconOther}`]: {
    '& .MuiButton-startIcon': {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0.625),
      color: colors.sparkBlue,
      [theme.breakpoints.down('md')]: {
        color: colors.white,
      },
    },
  },

  [`& .${classes.mobileDesign}`]: {
    '& > a.MuiButtonBase-root': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  }
}));

export interface IPGEButtonProps extends ComponentProps<any> {
  buttonMode: string;
  text?: string;
  icon?: JSX.Element | null;
  onClick?: any;
  buttonLink?: any;
  target?: string | null;
  disableRippleEffect?: boolean;
}

const getButtonModeClass = (buttonMode: string) => {
  let rootClass = '';

  switch (buttonMode) {
    case 'Primary':
      rootClass = classes.primary;
      break;
    case 'Secondary':
      rootClass = classes.secondary;
      break;
    case 'Emphasis':
      rootClass = classes.emphasis;
      break;
    case 'Back/Cancel':
      rootClass = classes.backCancel;
      break;
    case 'Outline':
      rootClass = classes.Outline;
      break;
    default:
      rootClass = '';
      break;
  }

  if (
    buttonMode === 'Primary' ||
    buttonMode === 'Emphasis' ||
    buttonMode === 'Outline' ||
    buttonMode === 'Back/Cancel'
  ) {
    rootClass = `${rootClass} ${classes.btnIconWhite}`;
  }
  if (buttonMode === 'Secondary') {
    rootClass = `${rootClass} ${classes.btnIconOther}`;
  }

  return {
    rootClass: rootClass,
  };
};

const PGEButtons: FC<IPGEButtonProps> = ({
  buttonMode = '',
  text,
  icon,
  onClick,
  buttonLink,
  target,
  disableRippleEffect,
  ...buttonProps
}) => {
  let component;
  const { rootClass } = getButtonModeClass(buttonMode);
  const [open, setOpen] = useState(false);

  if (buttonLink) {
    const { content, entryName, statusAlert } = buttonLink;

    const setOverlay = (status: any): any => {
      setOpen(status);
    };
    const statusComponent: any = statusAlert ? (
      <StatusAlertComponent statusAlertData={statusAlert} />
    ) : null;
    const contentBody = content ? (
      <BodyComponent bodyData={content} />
    ) : undefined;

    component = (
      <Overlay
        onOverlay={(status: any) => setOverlay(status)}
        content={contentBody ?? undefined}
        show={open}
        entryName={entryName}
        statusAlert={statusComponent}
      />
    );
  }

  return (
    <Root className={text ? `button ${classes.mobileDesign}` : ''}>
      {buttonLink
        ? text && (
            <Fragment>
              <Button
                size={'large'}
                className={rootClass}
                startIcon={icon ? icon : null}
                onClick={(e: any) => setOpen(true)}
                disableRipple={disableRippleEffect ? true : false}
              >
                {text}
              </Button>
              {component}
            </Fragment>
          )
        : text &&
          (target ? (
            <Button
              size={'large'}
              className={rootClass}
              startIcon={icon ? icon : null}
              href={onClick}
              target={target}
              rel={target === '_blank' ? 'noreferrer noopener' : undefined}
              disableRipple={disableRippleEffect ? true : false}
            >
              {text}
            </Button>
          ) : (
            <Button
              size={'large'}
              className={rootClass}
              startIcon={icon ? icon : null}
              href={onClick}
              onClick={async (e: any) => {
                e.preventDefault();
                await navigate(onClick);
              }}
              disableRipple={disableRippleEffect ? true : false}
            >
              {text}
            </Button>
          ))}
    </Root>
  );
};
export default PGEButtons;
