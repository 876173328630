import { navigate } from 'gatsby';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  gql,
  InMemoryCache,
} from '@apollo/client';
import fetch from 'isomorphic-fetch';
import ROUTES from '../routes';
import { getFromStorage } from '../util/storage-utils';
import authService from './authService';
import { local } from './storage';
import config from '../config';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

export function resolveId(obj: any) {
  if (!obj) {
    return null;
  }
  switch (obj.__typename) {
    case 'AccountCustomer':
      return obj.uid;
    case 'AccountDetail':
      return obj.accountNumber;
    case 'Group':
      return obj.groupId;
    default:
      return null;
  }
}

export const cache = new InMemoryCache({
  dataIdFromObject: obj => {
    const id = resolveId(obj);
    if (id !== null) {
      return `${obj.__typename}:${id}`;
    }
    return defaultDataIdFromObject(obj);
  },
  typePolicies: {
    Query: {
      fields: {
        getAccountDetails: {
          read(_, { args, cache: cacheRef, toReference }) {
            return args?.params?.accountNumberList?.map((item: any) =>
              toReference({
                __typename: 'AccountDetail',
                accountNumber: item?.accountNumber,
              }),
            );
          },
        },
      },
    },
  },
});

export const SELECTED_GROUP_ID = 'selectedGroupId';
export const SELECTED_ACCOUNT_PARAMS = 'selectedAccountParams-v2';

function getDefaultCacheData() {
  const selectedGroupId = getFromStorage(SELECTED_GROUP_ID);
  const stringifiedSelectedAccountParams = getFromStorage(
    SELECTED_ACCOUNT_PARAMS,
  );
  const selectedAccountParams = stringifiedSelectedAccountParams
    ? JSON.parse(stringifiedSelectedAccountParams)
    : null;

  return {
    selectedGroupId,
    selectedAccountParams,
  };
}

const httpLinkPge = createHttpLink({
  uri: config.graphql.baseUrl,
  fetch: fetch,
});

const httpLinkPgePlus = createHttpLink({
    uri: config.graphql.baseUrl,
    fetch,
    headers: {
        'x-api-version': 'pgeplus'
    }
})

const httpLink = ApolloLink.split(
    (operation) => !!operation.getContext().pgePlus,
    httpLinkPgePlus,
    httpLinkPge
)

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  const context = operation.getContext();
  const status = context?.response?.status;

  if (status === 403) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      authService.signOut();
      local.clear();
      await navigate(ROUTES.SIGN_IN);
    })();
  }
});
const authLink = setContext((_, { headers }) => {
  let token;
  try {
    const tokens = authService.tokensStorage.get();
     // TODO: I believe we should use the id token here instead to use with Apigee vs. direclty against GQL.
    // tokens.idToken?.token
    token = tokens.accessToken?.token;
  } catch (error) {
    console.warn(error);
  }
  
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
export const apolloClient = new ApolloClient({
  cache,
  uri: config.graphql.baseUrl,
  resolvers: {},
  link: ApolloLink.from([errorLink, authLink, httpLink]),
});
function writeDefaultCache() {
  apolloClient.writeQuery({
    query: gql`
      query getSelectedAccountParams {
        selectedAccountParams @client {
          accountNumber
          encryptedPersonId
          encryptedAccountNumber
          selectedGroupId
          automaticSelection
        }
      }
    `,
    data: getDefaultCacheData(),
  });
}

writeDefaultCache();

apolloClient.onResetStore(async () => {
  writeDefaultCache();
});

export default apolloClient;
