import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Typography,
} from '@mui/material';
import { useTranslation } from '../../hooks/useTranslation';

const SessionTimeoutDialog = ({
  open,
  onAccept,
  onDecline,
}: {
  open: boolean;
  onAccept: () => void;
  onDecline: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      data-testid="session-timeout-dialog"
      disableScrollLock
    >
      <DialogTitle>
        <Typography variant="h2">
          {t('SESSION_EXPIRING_WARNING_TITLE')}
        </Typography>
      </DialogTitle>
      <DialogContent>{t('SESSION_EXPIRING_WARNING_MESSAGE')}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => onDecline()}
          data-testid="session-timeout-decline"
        >
          {t('NO')}
        </Button>
        <Button
          variant="contained"
          onClick={() => onAccept()}
          color="primary"
          autoFocus
          data-testid="session-timeout-accept"
        >
          {t('YES')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SessionTimeoutDialog;
