import React, { FC, useMemo } from 'react';
import { getLayout } from './PromoGroup.utils';
import { Container, Grid, useTheme } from '@mui/material';
import colors from '../../themes/main-colors';
import PromoItem from '../promo-item';


// eslint-disable-next-line no-redeclare
type PromoItem = {
  layoutType?: string;
  mediaURL?: string;
  mediaType?: string;
  header?: string;
  description?: React.ReactElement;
  readonly imageLink?: Queries.ModulePromoItemFragment['imageLink'];
};

interface PromoGroupProps {
  promoItems: PromoItem[];
  leftNav?: boolean;
  header?: string;
  desc?: React.ReactElement;
  bgColor?: any;
}

const PromoGroup: FC<PromoGroupProps> = ({
  promoItems,
  leftNav,
  header,
  desc,
  bgColor,
}) => {

  const perColumn = leftNav ? 2 : 3;
  const promoArrayList: any = useMemo(
    () => getLayout(promoItems, perColumn),
    [promoItems, perColumn],
  );

  const generateColumn = (item: any, index: number, columnWidth: number) => {
    let mdValue: any = 6;
    if (leftNav) {
      mdValue = columnWidth === 1 ? 12 : 6;
    } else {
      if (columnWidth === 2) {
        mdValue = 6;
      } else {
        mdValue = columnWidth === 1 ? 12 : 4;
      }
    }

    return (
      <Grid item xs={12} md={mdValue} key={index}>
        <PromoItem
          layoutType={mdValue === 12 ? 'horizontal' : 'vertical'}
          mediaURL={item?.mediaURL}
          mediaType={item?.mediaType}
          header={item?.header}
          description={item?.description}
          link={item?.link}
          imageLink={item?.imageLink}
          imagePosition={item?.imagePosition}
          vimeoDownloadUrl={item?.vimeoDownloadUrl}
        />
      </Grid>
    );
  };

  const generateRow = (rowArray: any, length: number) => {
    const rowObject = rowArray.map((rowData: any, index: number) => {
      return generateColumn(rowData, index, length);
    });
    return rowObject;
  };

  const theme = useTheme()

  return (
    <div css={{
      backgroundColor: bgColor,
      padding: theme.spacing(5),
      borderRadius: 5,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2.5, 0),
      },
      '& .MuiGrid-spacing-xs-3': {
        width: '100%',
        margin: 0,
        padding: theme.spacing(0),
        '& .MuiGrid-grid-md-6': {
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1.25, 0),
          },
        },
        '& .MuiGrid-grid-md-6:first-child': {
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 2.5, 2.5, 0),
          },
        },
        '& .MuiGrid-grid-md-6:last-child': {
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 0, 2.5, 2.5),
          },
        },
        '& .MuiGrid-grid-md-12': {
          padding: theme.spacing(0),
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1.25, 0),
          },
        },
        '& .MuiGrid-grid-md-4:first-child': {
          padding: theme.spacing(1.25, 1.25, 1.25, 0),
          [theme.breakpoints.down('md')]: {
            padding: `${theme.typography.pxToRem(12)} 0`,
          },
        },
        '& .MuiGrid-grid-md-4': {
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            padding: `${theme.typography.pxToRem(12)} 0`,
          },
        },
        '& .MuiGrid-grid-md-4:last-child': {
          padding: theme.spacing(1.25, 0, 1.25, 1.25),
          [theme.breakpoints.down('md')]: {
            padding: `${theme.typography.pxToRem(12)} 0`,
          },
        },
      },
    }}>
      <Container>
        {header && <div css={{
          marginBottom: theme.spacing(3),
          fontSize: theme.spacing(4.5),
          fontWeight: 400,
          color: colors.noirBlur,
          lineHeight: theme.typography.pxToRem(40),
          [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1.5),
            fontSize: theme.spacing(3),
            lineHeight: theme.typography.pxToRem(28),
          },
        }}>{header}</div>}
        {desc && <div css={{
          fontSize: theme.spacing(2.5),
          color: colors.noirBlur,
          margin: theme.spacing(2.5, 0),
          [theme.breakpoints.down('md')]: {
            margin: 0,
            fontSize: theme.spacing(2),
          },
        }}>{desc}</div>}
        <Grid container spacing={3} justifyContent="flex-start">
          {promoArrayList.map((rowArray: any) => {
            return generateRow(rowArray, rowArray.length);
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default PromoGroup;
