import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import RichText from '../../rich-text';

const PREFIX = 'InterceptModal';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  closeButton: `${PREFIX}-closeButton`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.root}`]: {
    margin: 0,
    padding: theme.spacing(2),
    '& h2': {
      paddingRight: theme.spacing(4),
    },
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
    '& .RichText .Column': {
      padding: 0,
      '& .col1': {
        textAlign: 'right',
      },
    },
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

interface Props {
  modal: Queries.interceptModalFragment | Queries.ContentfulInterceptModal;
  onCloseCallback?: () => void;
}

const InterceptModal = (props: Props) => {

  const {
    modal: { modalTitle, modalBody },
    onCloseCallback,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(true);
  const dialogContentRef: React.MutableRefObject<
    HTMLElement | undefined | null
  > = React.useRef();

  const handleClose = () => {
    setOpen(false);
    onCloseCallback && onCloseCallback();
  };

  const attachCloseHandler = (node: HTMLElement) => {
    const buttons = node.querySelectorAll('button, a');
    buttons?.forEach(button => {
      button.addEventListener('click', () => {
        handleClose();
      });
    });
  };

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby={modalTitle || undefined}
      open={open}
      maxWidth={'sm'}
      fullScreen={fullScreen}
      disableScrollLock
    >
      <DialogTitle className={classes.root}>
        <Typography aria-label="title" variant="h2">{modalTitle}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        className={classes.content}
        ref={(node: HTMLElement) => {
          dialogContentRef.current = node;
          if (node) {
            attachCloseHandler(node);
          }
        }}
      >
        <RichText className={'RichText'}>
          {modalBody}
        </RichText>
      </DialogContent>
    </StyledDialog>
  );
};

export default InterceptModal;
