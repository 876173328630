import React, { useRef } from 'react';
//import Helmet from 'react-helmet';
import { FacebookProvider, Page } from 'react-facebook';
import { useComponentSize } from '../../../util/style-utils';
import TwitterFeed from '../../twitter-embed';
//import InstagramFeed from '../../instagram-embed';
import socialFeedConfig from '../../../config/socialFeedConfig';
import { ColorName } from '../../../constants';
import { useTheme } from '@mui/material';


const SocialFeedType = {
  Twitter: 'Twitter',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
} as const;

interface SocialFeedBase {
  anchorId?: string | undefined;
  mention?: string | null | undefined;
  hashtag?: string | null | undefined;
  entryName?: string;
  feedType?: typeof SocialFeedType[keyof typeof SocialFeedType];
  limit?: number;
  backgroundColor?: string | null | undefined;
  isFromColumn?: boolean;
}

const SocialFeed: React.FC<SocialFeedBase> = ({
  anchorId,
  hashtag,
  entryName,
  mention,
  feedType,
  limit,
  backgroundColor,
  isFromColumn = false,
}) => {
  const theme = useTheme()
  const { spacing, typography, breakpoints } = theme;

  const $div = useRef<HTMLDivElement>(null);
  const { width } = useComponentSize($div);
  let calculatedHorizontalMargin = 0;

  let _color = 'None';
  if (!isFromColumn) {
    _color = backgroundColor || 'None';
  }

  if (width > 550) {
    calculatedHorizontalMargin = Math.trunc((width - 550) / 2);
  }

  let setScroll = false;

  let component;
  switch (feedType) {
    case SocialFeedType.Twitter: {
      setScroll = true;
      component = (
        <TwitterFeed userMentions={mention} hashtag={hashtag} limit={limit} />
      );
      break;
    }
    case SocialFeedType.Facebook: {
      component = (
        <FacebookProvider appId={socialFeedConfig.FacebookAppId}>
          <Page
            href="https://www.facebook.com/PortlandGeneralElectric/"
            tabs="timeline"
            width={width - 8}
            style={{ maxWidth: '550px' }}
          />
        </FacebookProvider>
      );
      break;
    }
    case SocialFeedType.Instagram: {
      setScroll = true;
      //component = <InstagramFeed limit={limit} />;
      component = <></>;
      break;
    }
    default: {
      component = <></>;
    }
  }

  return (
    <>
      {/* <Helmet>
        <script async defer src="https://www.instagram.com/embed.js" />
      </Helmet> */}
      <aside
        id={isFromColumn ? undefined : anchorId}
        aria-label={entryName || undefined}
        className="SocialFeed"
      >
        <div
          ref={$div}
          css={{
            contain: 'content',
            padding: spacing(4, 0),
            overflow: 'hidden',

            backgroundColor: _color === 'Blue' ? ColorName['Blue']
              : _color === 'Grey' ? ColorName['Grey']
              : _color === 'None' ? 'transparent'
              : 'transparent',

            '& h1, & > p': {
              boxSizing: 'content-box',
              maxWidth: 800,
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: spacing(0, 2),

              [breakpoints.up('sm')]: {
                padding: spacing(0, 1.25),
              },
            },

            '& h1': {
              marginTop: spacing(2.5),
              marginBottom: spacing(2),
              [breakpoints.up('sm')]: {
                marginTop: spacing(4),
                marginBottom: spacing(2),
              },
            },

            '& > p': {
              marginTop: spacing(3),

              [breakpoints.up('sm')]: {
                marginTop: spacing(4.5),
              },
            },

            '& h1 + p': {
              marginTop: spacing(2),
            },

            '& .graphical span': {
              width: 1,
              height: 1,
              position: 'absolute',
              overflow: 'hidden',
            },
          }}
        >
          <div
            css={setScroll ? {
              padding: spacing(1),
              height: typography.pxToRem(500),
              overflowY: 'auto',
            } : {}}
            style={{
              margin: `0 ${calculatedHorizontalMargin}px`,
            }}
          >
            {component}
          </div>
        </div>
      </aside>
    </>
  );
}

export default SocialFeed;
