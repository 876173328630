// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { firebaseContext } from './FirebaseProvider';
import { Scalars } from '../pge-types';
import { onValue, ref } from 'firebase/database';

interface ContactDataIntercept {
  isEnabled?: boolean;
  startDate?: Scalars['LocalDate'];
  endDate?: Scalars['LocalDate'];
}

interface FeatureFlags {
  isPlannedDownTime: boolean;
  startStopEnabled: boolean;
  moveEnabled: boolean;
  loading: boolean;
  isTPAEnabled: boolean;
  isOutageByAddressEnabled: boolean;
  isClosedAccountsEnabled?: boolean;
  isPowerPortfolioEnabled?: boolean;
  isClosedAccountsEnabled?: boolean;
  contactDataIntercept?: ContactDataIntercept;
  isEVProgramEnabled: boolean;
  isEnergyTrackerMigration: boolean;
  isPGEPlusEVEnabled: boolean;
}

const initialState: FeatureFlags = {
  // runtime: firebase flags
  isPlannedDownTime: false,
  startStopEnabled: false,
  moveEnabled: false,
  loading: true,
  isTPAEnabled: false,
  isOutageByAddressEnabled: false,
  isClosedAccountsEnabled: false,
  isPowerPortfolioEnabled: false,
  isClosedAccountsEnabled: false,
  isEVProgramEnabled: false,
  isEnergyTrackerMigration: false,
  isPGEPlusEVEnabled: false,
};

const featureFlagsContext = React.createContext<FeatureFlags>(initialState);
const { Provider } = featureFlagsContext;

const FeatureFlagsProvider = (props: any) => {
  const { database } = useContext(firebaseContext);
  const [value, setValue] = useState(initialState);

  useEffect(() => {
    /* Important: Using firebase real-time database listner.
     * onValue() is called every time data is changed on featureFlags database.
     */
    const featureFlagRef = ref(database, '/featureFlags');
    onValue(featureFlagRef, (snapshot) => {
      if (snapshot.exists()) {
        setValue({
          ...value,
          isPlannedDownTime: !!snapshot.val()?.isPlannedDownTime,
          startStopEnabled: !!snapshot.val()?.startStopEnabled,
          moveEnabled: !!snapshot.val()?.moveEnabled,
          isTPAEnabled: !!snapshot.val()?.isTPAEnabled,
          isOutageByAddressEnabled: !!snapshot.val()?.isOutageByAddressEnabled,
          isPowerPortfolioEnabled: !!snapshot.val()?.isPowerPortfolioEnabled,
          isClosedAccountsEnabled: !!snapshot.val()?.isClosedAccountsEnabled,
          contactDataIntercept: snapshot.val()?.contactDataIntercept,
          isEVProgramEnabled: !!snapshot.val()?.isEVProgramEnabled,
          isEnergyTrackerMigration: !!snapshot.val()?.isEnergyTrackerMigration,
          isPGEPlusEVEnabled: !!snapshot.val()?.isPGEPlusEVEnabled,
          loading: false,
        });
      }
    });
  }, [database]);

  return <Provider value={value}>{props.children}</Provider>;
};

export { FeatureFlagsProvider, featureFlagsContext, ContactDataIntercept };
