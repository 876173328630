import React, { FC, TouchEvent } from 'react';
import { SlideProps as Props } from './types.d';
import { useSliderContext } from './GallerySlider';
import { useTheme } from '@mui/material';
import { Animate, Shadow } from '../../../constants';



/**
 * Helper Funcs
 */

const createHelpers = (dispatch: any) => {
  let initPos = 0;
  let distance = 0;

  return {
    touchResponse(event: TouchEvent<HTMLElement>): void {
      event.preventDefault();

      const { currentTarget } = event;
      const threshold = (currentTarget.offsetWidth || 100) / 5;

      if (event.type === 'touchstart') {
        initPos = event.touches[0].clientX;
      }

      if (['touchend', 'touchcancel'].includes(event.type)) {
        currentTarget.style.transition = 'transform 0.6s, opacity 0.4s';

        // PREV
        if (event.type === 'touchend' && distance < -threshold) {
          dispatch({ type: Animate.PREV });

          // NEXT
        } else if (event.type === 'touchend' && distance > threshold) {
          dispatch({ type: Animate.NEXT });

          // CANCEL
        } else {
          currentTarget.style.transform = 'translateX(0)';
        }
      }
    },
    touchTrace(event: TouchEvent<HTMLElement>): void {
      event.preventDefault();

      const { currentTarget } = event;

      distance = initPos - event.touches[0].clientX;
      currentTarget.style.transition = '0s';
      currentTarget.style.transform = `translateX(${-distance}px)`;
    },
  };
};

const Slide: FC<Props> = ({ image, imageAltText }) => {
  const { url } = image?.file || {};
  const { dispatch } = useSliderContext();
  const { touchResponse, touchTrace } = createHelpers(dispatch);
  const theme = useTheme();

  return (
    <div
      className={'GallerySlide'}
      css={{
        contain: 'strict',
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        opacity: 0,
        boxShadow: Shadow.ONE,
        transform: 'translateX(-100%)',
        backfaceVisibility: 'hidden',

        [theme.breakpoints.up('sm')]: {
          borderRadius: 5,
        },

        '& picture': {
          pointerEvents: 'none',
        },

        '& img': {
          height: '100%',
        },

        '& picture:first-of-type img': {
          width: '100%',
          opacity: 0.75,
          objectFit: 'cover',
          filter: 'blur(12px)',
          transform: 'scale(1.1)',
        },

        '& picture:last-of-type img': {
          width: 'auto',
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
        },
      }}
      onTouchStart={touchResponse}
      onTouchEnd={touchResponse}
      onTouchCancel={touchResponse}
      onTouchMove={touchTrace}
    >
      {url && (
        <>
          <picture className="blur-bkg">
            <img src={`${url}?w=50`} alt="" width="50" height="500" />
          </picture>
          <picture>
            <img
              src={url}
              alt={imageAltText?.imageAltText || ''}
              width="700"
              height="500"
            />
          </picture>
        </>
      )}
    </div>
  );
};

export default Slide;
