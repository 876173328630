import React, { FunctionComponent } from 'react';
import { styled , Theme } from '@mui/material/styles';

const PREFIX = 'Map';

const classes = {
  embedMedia: `${PREFIX}-embedMedia`
};

const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.embedMedia}`]: {
    padding: theme.spacing(2.5, 0),
    '& > iframe': {
      maxHeight: 576,
      width: '100%',
      borderRadius: 5,
      height: 418,
      [theme.breakpoints.down('md')]: {
        height: 275,
      },
    },
  }
}));

export interface mapProps {
  src?: string;
}

const Map: FunctionComponent<mapProps> = props => {
  const { src } = props;


  return (
    <Root className={classes.embedMedia}>
      <iframe
        src={src}
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen={false}
        aria-hidden={false}
        tabIndex={0}
      ></iframe>
    </Root>
  );
};

export default Map;
