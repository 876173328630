import React from 'react';
import { Typography, useTheme } from '@mui/material';
import RichText from '../../rich-text';
import ListItem from './ListItem';
import useAttrs from '../../../hooks/useAttrs';
import { StaticListProps as Props, ListOmits } from './types.d';
import { Theme } from '@mui/material/styles';
import { ColorName, Shadow } from '../../../constants';

interface StaticListProps extends Props {
  staticListHeader?: any
  staticListIntroCopy?:any
  staticListImage?:any
  addItems?:any
}

const StaticList: React.FC<StaticListProps> = ({
  staticListHeader,
  staticListIntroCopy,
  staticListImage,
  addItems,
  colorBackground = 'None',
  imagePosition,
  ...props
}) => {
  // Vars
  const { ...attrs } = useAttrs<Props>(props, ListOmits);
  const theme = useTheme()
  const { palette } = theme;
  const { white } = palette.common;
  const text = palette.text.primary;
  const darkGrey = palette.grey[700];

  return (
    <article
      css={{
        boxSizing: 'border-box',
        contain: 'content',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        borderRadius: '5px',
        color: text,
        boxShadow: Shadow.ONE,

        ...(colorBackground === 'Grey' ? {
          backgroundColor: ColorName['Grey'],
        } : colorBackground === 'Blue' ? {
          backgroundColor: ColorName['Blue'],
        } : {
          backgroundColor: ColorName['None'],
        })
      }}
      aria-label={staticListHeader || undefined}
      {...attrs}
    >
      {staticListImage?.file?.url && (
        <picture css={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          overflow: 'hidden',
          borderRadius: '5px 5px 0 0',
          height: '275px',

          '&::before': {
            display: 'block',
            width: '100%',
            paddingTop: '22%',
            content: '""',
          },

          '& img': {
            width: '100%',
            position: 'absolute',
            height: '275px',
            objectFit: 'cover',
            objectPosition: imagePosition,
          },
        }}>
          <img src={staticListImage.file.url} alt="" />
        </picture>
      )}
      <div css={{
        padding: theme.spacing(2),
        counterReset: 'list-item 1',

        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(6),
        },

        '& header': {
          counterIncrement: 'list-item -1',
        },
      }}>
        {staticListHeader && (
          <header>
            <Typography variant="h1">{staticListHeader}</Typography>
            <RichText>
              {staticListIntroCopy}
            </RichText>
          </header>
        )}
        {addItems?.map((item: any, index: any) => (
          <ListItem
            pos={index + 1}
            length={addItems.length}
            key={`${item?.contentful_id}${index}`}
            {...item}
          />
        ))}
      </div>
    </article>
  );
}

export default StaticList;
