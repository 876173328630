import React, {
  Context,
  createContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { globalHistory } from '@gatsbyjs/reach-router';

interface NotificationsContext {
  isOpen?: boolean;
  severity?: string;
  variant?: string;
  message?: React.ReactNode;
  title?: string;
}
const defaultState = {
  isOpen: false,
  severity: 'info',
  variant: 'filled',
  message: '',
};

// eslint-disable-next-line no-redeclare
const NotificationsContext: Context<any> = createContext(defaultState);

function getTimestamp() {
  return Date.now().valueOf();
}

// Close after 2 minutes
const TIMEOUT = 120000;
// Prevent history from closing when the last open was within this time
const CLOSE_ON_HISTORY_THRESHOLD = 500;

const NotificationsContextProvider = (props: any) => {
  const [state, setState] = useState(defaultState);
  const lastOpen = useRef<number | null>(null);
  const value = {
    state,
    setState: (context: any) => {
      if (context.isOpen) {
        lastOpen.current = getTimestamp();
        setState({
          variant: 'filled',
          ...context,
        });
      } else {
        lastOpen.current = null;
        setState(st => ({ ...st, variant: 'filled', ...context }));
      }
    },
  };

  useMemo(() => {
    globalHistory.listen(() => {
      if (
        lastOpen.current !== null &&
        getTimestamp() - lastOpen.current > CLOSE_ON_HISTORY_THRESHOLD
      ) {
        lastOpen.current = null;
        setState(defaultState);
      }
    });
  }, []);

  const timer = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (state.isOpen) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => setState(defaultState), TIMEOUT);
    }
  }, [state.message, state.isOpen]);

  return (
    <NotificationsContext.Provider value={value}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

const NotificationsContextConsumer = NotificationsContext.Consumer;

export {
  NotificationsContext,
  NotificationsContextProvider,
  NotificationsContextConsumer,
};
