import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import colors from '../../themes/main-colors';
import PgeVideoPlayer from '../pge-video-player';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import Container from '@mui/material/Container';

const PREFIX = 'SplitHeader';

const classes = {
  root: `${PREFIX}-root`,
  fixWidthContainer: `${PREFIX}-fixWidthContainer`,
  mediaDiv: `${PREFIX}-mediaDiv`,
  image: `${PREFIX}-image`,
  detail: `${PREFIX}-detail`,
  largeHeadline: `${PREFIX}-largeHeadline`,
  paragraphHeadline: `${PREFIX}-paragraphHeadline`,
  description: `${PREFIX}-description`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: colors.lightBlue1,
    display: 'flex',
    alignItems: 'center',
    minHeight: 320,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
      '& .MuiContainer-root': {
        padding: 0,
      },
    },
  },

  [`& .${classes.fixWidthContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },

  [`& .${classes.mediaDiv}`]: {
    float: 'right',
    padding: theme.spacing(0, 0, 0, 7.5),
    lineHeight: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2.5),
      padding: 0,
    },
    '& > iframe': {
      width: 462,
      height: 240,
      borderRadius: 5,
      [theme.breakpoints.down('md')]: {
        height: 175,
        width: '100%',
      },
    },
  },

  [`& .${classes.detail}`]: {
    flex: 'auto',
    padding: theme.spacing(5, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5),
    },
  },

  [`& .${classes.largeHeadline}`]: {
    fontSize: theme.spacing(4.5),
    fontWeight: 100,
    lineHeight: theme.typography.pxToRem(40),
    color: colors.noirBlur,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(3),
      marginBottom: theme.spacing(1.25),
      lineHeight: theme.typography.pxToRem(28),
    },
  },

  [`& .${classes.paragraphHeadline}`]: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2.5),
    lineHeight: theme.typography.pxToRem(27),
    color: colors.noirBlur,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },

  [`& .${classes.description}`]: {
    fontSize: theme.spacing(2),
    lineHeight: theme.typography.pxToRem(20),
    color: colors.noirBlur,
    margin: theme.spacing(0),
  }
}));

interface SplitHeaderProps {
  image?: string;
  video?: string;
  largeHeadline?: string;
  paragraphHeadline?: string;
  description?: any;
  button?: IPGEButtonProps | null;
  readonly imagePosition?: Ctfl.Field['Position'];
}

const SplitHeader: React.FC<SplitHeaderProps> = props => {

  const theme = useTheme();
  const {
    image = '',
    video = '',
    largeHeadline = '',
    paragraphHeadline = '',
    description = '',
    button,
  } = {
    ...props,
  };

  return (
    (<Root>
      <div className={classes.root}>
        <Container>
          <div className={classes.fixWidthContainer}>
            <div className={(image || video) && classes.mediaDiv}>
              {image && (
                <img
                  css={{
                    width: 462,
                    borderRadius: theme.spacing(0.625),
                    height: 240,
                    objectFit: 'cover',
                    objectPosition: props.imagePosition,
                    [theme.breakpoints.down('md')]: {
                      height: 175,
                      width: '100%',
                    },
                  }}
                  src={image}
                  height="240"
                  width="500"
                />
              )}
              {video && <PgeVideoPlayer video={{ url: `${video}` }} />}
            </div>
            <div className={classes.detail}>
              {largeHeadline && (
                <h1 className={classes.largeHeadline}>{largeHeadline}</h1>
              )}
              {paragraphHeadline && (
                <h3 className={classes.paragraphHeadline}>
                  {paragraphHeadline}
                </h3>
              )}
              {description && (
                <h3 className={classes.description}>{description}</h3>
              )}
              {button && <PgeButton {...button} />}
            </div>
          </div>
        </Container>
      </div>
    </Root>)
  );
};
export default SplitHeader;
