import React from 'react';
import { styled , Theme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import MUIModal, { ModalProps } from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import theme from '../../themes/theme';


const PREFIX = 'Modal';

const classes = {
  modal: `${PREFIX}-modal`,
  card: `${PREFIX}-card`,
  closeIcon: `${PREFIX}-closeIcon`,
  titleArea: `${PREFIX}-titleArea`,
  emptyTitleArea: `${PREFIX}-emptyTitleArea`,
  content: `${PREFIX}-content`
};

const StyledStyledEngineProvider = styled(StyledEngineProvider)(() => ({
  [`& .${classes.modal}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.card}`]: {
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },

  [`& .${classes.closeIcon}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
  },

  [`& .${classes.titleArea}`]: {
    paddingRight: () => theme.spacing(3),
  },

  [`& .${classes.emptyTitleArea}`]: {
    paddingTop: 27,
  },

  [`& .${classes.content}`]: (props: { cardContentPadding: string | undefined }) => ({
    padding: props.cardContentPadding
      ? props.cardContentPadding
      : theme.spacing(3),
  })
}));

type Props = {
  className?: string;
  onClose?: () => void;
  open: boolean;
  children?: React.ReactNode;
  title?: React.ReactNode;
  showCloseIcon?: boolean;
  cardContentPadding?: string;
} & Omit<ModalProps, 'children' | 'title'>;

export default function Modal({
  title,
  children,
  className,
  onClose,
  open,
  cardContentPadding,
  showCloseIcon = true,
  ...rest
}: Props) {
  const titleElement =
    title === undefined || React.isValidElement(title) ? (
      title
    ) : (
      <Typography variant={'body1'}>{title}</Typography>
    );


  const hasTitle = titleElement !== undefined;

  return (
    <StyledStyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MUIModal
          open={open}
          className={clsx(classes.modal, className)}
          onClose={onClose}
          {...rest}
        >
          <Card className={classes.card}>
            {showCloseIcon && (
              <IconButton
                className={classes.closeIcon}
                onClick={onClose}
                data-testid="close-btn"
                size="large">
                <Close />
              </IconButton>
            )}
            <CardContent className={classes.content}>
              <div
                className={hasTitle ? classes.titleArea : classes.emptyTitleArea}
              >
                {titleElement}
              </div>
              {children}
            </CardContent>
          </Card>
        </MUIModal>
      </ThemeProvider>
    </StyledStyledEngineProvider>
  );
}
