import React, { FunctionComponent, useMemo } from 'react';
import { styled , Theme } from '@mui/material/styles';
import colors from '../../themes/main-colors';
import Container from '@mui/material/Container';

const PREFIX = 'PgeContact';

const classes = {
  root: `${PREFIX}-root`,
  contactCopy: `${PREFIX}-contactCopy`,
  contactName: `${PREFIX}-contactName`,
  info: `${PREFIX}-info`
};

const StyledContainer = styled(Container)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    padding: 0,
    '& > p': {
      margin: 0,
    },
  },

  [`& .${classes.contactCopy}`]: {
    color: colors.noirBlur,
    fontStyle: 'italic',
  },

  [`& .${classes.contactName}`]: {
    color: colors.noirBlur,
    fontWeight: 'bold',
  },

  [`& .${classes.info}`]: {
    color: colors.sparkBlue,
    fontWeight: 'bold',
    '& > a': {
      textDecoration: 'none',
    },
  }
}));

interface contactProps {
  contactName?: string;
  emailAddress?: React.ReactNode;
  phoneNumber?: React.ReactNode;
  contactCopy?: React.ReactNode;
}

const PgeContact: React.FC<contactProps> = (props) => {

  const { contactName, phoneNumber, emailAddress, contactCopy } = props;

  return (
    <StyledContainer className={classes.root}>
      {contactCopy && <div className={classes.contactCopy}>{contactCopy}</div>}
      {contactName && <p className={classes.contactName}>{contactName}</p>}
      {phoneNumber && <p className={classes.info}>{phoneNumber}</p>}
      {emailAddress && <p className={classes.info}>{emailAddress}</p>}
    </StyledContainer>
  );
};

export default PgeContact
