import CryptoJS from 'crypto-js';
import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import config from '../config';

const cookiesEnabled =
  typeof window !== 'undefined' && window.navigator.cookieEnabled;

// TODO: Remove old key eventually
export const generateOldKey = function() {
  const phrase =
    typeof window !== 'undefined'
      ? window.navigator.appName +
        window.navigator.appCodeName +
        window.navigator.product +
        window.navigator.productSub +
        window.navigator.appVersion +
        window.navigator.language +
        window.navigator.platform
      : '';

  return phrase.replace(/\s/, '');
};

const SECRET_KEY = config.storageKey;
const OLD_SECRET_KEY = generateOldKey();

function decryptWithKey(data: string, key: string) {
  try {
    const decryptedData = CryptoJS.AES.decrypt(data, key);
    return decryptedData.toString(CryptoJS.enc.Utf8);
  } catch (_) {
    return null;
  }
}

const EncryptLib = {
  encrypt: function encrypt(data: string) {
    const encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY);
    return encryptedData.toString();
  },
  decrypt: function decrypt(data: string) {
    const result = decryptWithKey(data, SECRET_KEY);
    if (result) {
      return result;
    }
    return decryptWithKey(data, OLD_SECRET_KEY);
  },
};

export function getFromStorage(key: string): any {
  if (typeof window !== 'undefined' && cookiesEnabled) {
    if (!window.localStorage[key]) {
      return null;
    }
    return EncryptLib.decrypt(window.localStorage[key]) || null;
  }
}

export const setInStorage = (key: string, value: any) => {
  if (typeof window !== 'undefined' && cookiesEnabled) {
    const encrypted = EncryptLib.encrypt(value);
    return window.localStorage.setItem(key, encrypted);
  }
};

export const removeFromStorage = (key: string) => {
  if (typeof window !== 'undefined' && cookiesEnabled) {
    return window.localStorage.removeItem(key);
  }
};

export function getFromSessionStorage(key: string): any {
  if (typeof window !== 'undefined' && cookiesEnabled) {
    if (!window.sessionStorage[key]) {
      return null;
    }
    return EncryptLib.decrypt(window.sessionStorage[key]) || null;
  }
}

export const setInSessionStorage = (key: string, value: any) => {
  if (typeof window !== 'undefined' && cookiesEnabled) {
    const encrypted = EncryptLib.encrypt(value);
    return window.sessionStorage.setItem(key, encrypted);
  }
};

export const removeFromSessionStorage = (key: string) => {
  if (typeof window !== 'undefined' && cookiesEnabled) {
    return window.sessionStorage.removeItem(key);
  }
};

export const getMessageAcceptObject = {
  key: 'IE_MESSAGE_ACCEPT',
  hours: 720,
};

export const getStormInfoMessageAcceptObject = {
  key: 'STORM_MESSAGE_ACCEPT',
  hours: 24,
};

export const clearSessionAndLocalStorage = () => {
  if (typeof window !== 'undefined' && cookiesEnabled) {
    // Preserve selected local storage entries.
    // TODO: Make this more robust and testable.
    Object.keys(localStorage).forEach(function(key) {
      if (
        ![
          'userEmail',
          'SITE_INTERCEPT_REVISION',
          'outageAddressLookUpResult',
          'globalInterceptModalDismissed',
          'isOpowerMigrationAlertClosed',
          getMessageAcceptObject.key,
        ].includes(key)
      ) {
        window.localStorage.removeItem(key);
      }
    });

    window.sessionStorage.clear();
  }
};

export function useStoredState<T>(
  key: string,
  defaultValue: T | (() => T),
): [T, Dispatch<SetStateAction<T>>] {
  const fromStorage = getFromStorage(key);
  const [value, setter] = useState<T>(
    fromStorage ? JSON.parse(fromStorage) : defaultValue,
  );

  useEffect(() => {
    if (value === null) {
      removeFromStorage(key);
    } else {
      setInStorage(key, JSON.stringify(value));
    }
  }, [value]);

  return [value, setter];
}
