import React from 'react';
import { styled , Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import SlugLink from '../slug-link/SlugLink';

const PREFIX = 'RelatedList';

const classes = {
  root: `${PREFIX}-root`,
  iconSize: `${PREFIX}-iconSize`
};

const Root = styled('ul')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    marginTop: 0,
    marginBottom: 0,
    '& > li': {
      padding: '4px 0',
      fontSize: theme.typography.pxToRem(20),
      '& > svg': {
        fontSize: theme.typography.pxToRem(8),
        color: '#0371b4',
      },
      '& > a': {
        textDecoration: 'none',
        '& > img': {
          verticalAlign: 'middle',
        },
      },
    },
    '& .MuiTypography-subtitle1': {
      lineHeight: 1.4,
    },
  },

  [`& .${classes.iconSize}`]: {
    width: theme.spacing(1.75),
    [theme.breakpoints.down('md')]: {
      width: '14px!important',
    },
  }
}));

interface IIcon {
  src: string;
  altText: string;
}

interface IList {
  title: string | JSX.Element;
  subTitle?: string;
  link?: string;
  icon?: IIcon;
  pageType?: string;
}
interface IProps {
  list: Array<IList>;
  className?: string;
  componentType?: string;
}

function isValidUrl(string: any) {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
}

const RelatedList = (props: IProps): JSX.Element => {

  const { list, componentType } = props;

  const listItems = list?.map(
    (item: IList, index: number): JSX.Element => {
      if (item?.icon) {
        return (
          <li key={index} data-testid={index}>
            <a href={item?.link ? item?.link : '#'} target="_blank">
              {item?.title}
              {item?.icon && (
                <>
                  {' '}
                  <img
                    src={item?.icon?.src}
                    className={classes.iconSize}
                    alt={item?.icon?.altText}
                  />
                </>
              )}
            </a>
            {item?.subTitle && componentType && (
              <Typography variant="subtitle1">{item?.subTitle}</Typography>
            )}
          </li>
        );
      } else {
        return (
          <li key={index} data-testid={index}>
            {item?.pageType === 'ContentfulPageOverlay' ? (
              <>{item?.title}</>
            ) : (
              <>
                <SlugLink slug={item?.link} linkText={item?.title} />
                {item?.subTitle && componentType && (
                  <Typography variant="subtitle1">{item?.subTitle}</Typography>
                )}
              </>
            )}
          </li>
        );
      }
    },
  );

  return <Root className={classes.root}>{listItems}</Root>;
};

export default RelatedList;
