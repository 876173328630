import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

// TODO: Remove from application
export const isMobile = () => {
  const theme = useTheme();
  
  /**
   * To perform the server-side hydration, the hook needs to render twice. A first time with false.
   * This causes the problem of desktop menu being displayed for a split second on mobile devices.
   * 
   * https://mui.com/material-ui/react-use-media-query/#client-side-only-rendering
   */  
  return useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
};

// this should be used over `isMobile` due to bad naming.
// `isMobile` is a hook, but does not start with the word "use"
export const useIsMobile = () => {
  return isMobile();
};

export const useComponentSize = (
  myRef: React.MutableRefObject<null | HTMLElement>,
) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (myRef.current) {
        setWidth(myRef.current.offsetWidth);
        setHeight(myRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef.current]);

  return { width, height };
};
