import React from 'react';
import { navigate } from 'gatsby';
import ROUTES from '../../routes';
interface Props {
  children?: React.ReactNode;
}
class ErrorBoundary extends React.Component<Props> {
  state = {
    hasError: false,
  };

  constructor(props: {}) {
    super(props);
  }

  static getDerivedStateFromError(error: object) {
    return { hasError: true };
  }

  async componentDidCatch(error: object, errorInfo: object) {
    await navigate(ROUTES.ERROR);
    this.setState({
      hasError: false,
    });
  }

  render() {
    if (this.state.hasError) {
      return false;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
