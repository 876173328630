import React, { ReactElement } from 'react';
import { styled , Theme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';

const PREFIX = 'DocumentList';

const classes = {
  documentListHeader: `${PREFIX}-documentListHeader`,
  documentListDesc: `${PREFIX}-documentListDesc`,
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  iconButton: `${PREFIX}-iconButton`,
  listRoot: `${PREFIX}-listRoot`,
  listRow: `${PREFIX}-listRow`,
  itemText: `${PREFIX}-itemText`,
  pdfIconStyle: `${PREFIX}-pdfIconStyle`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.documentListHeader}`]: {
    fontSize: theme.spacing(4.5),
    color: colors.noirBlur,
    lineHeight: theme.typography.pxToRem(40),
    paddingBottom: theme.spacing(2.5),
    fontWeight: 100,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(3),
      lineHeight: theme.typography.pxToRem(28),
    },
  },

  [`& .${classes.documentListDesc}`]: {
    fontSize: theme.spacing(2.5),
    color: colors.noirBlur,
    paddingBottom: theme.spacing(2.5),
    lineHeight: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2),
      lineHeight: theme.typography.pxToRem(20),
    },
  },

  [`& .${classes.root}`]: {
    padding: theme.spacing(0.25, 0.5),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #707070',
    borderRadius: theme.spacing(0.625),
  },

  [`& .${classes.input}`]: {
    marginLeft: theme.spacing(1),
    flex: 1,
    opacity: 1,
    fontSize: theme.spacing(2.5),
    color: colors.noirBlur,
  },

  [`& .${classes.iconButton}`]: {
    padding: theme.spacing(1.25),
  },

  [`& .${classes.listRoot}`]: {
    marginTop: theme.spacing(2.5),
  },

  [`& .${classes.listRow}`]: {
    display: 'table',
    paddingBottom: theme.spacing(1.875),
  },

  [`& .${classes.itemText}`]: {
    textDecoration: 'none',
    fontSize: theme.spacing(2.5),
    paddingRight: theme.spacing(0.625),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2),
    },
  },

  [`& .${classes.pdfIconStyle}`]: {
    width: theme.typography.pxToRem(16),
  }
}));

interface IitemProps {
  title: string;
  url: string;
  src?: string;
  target?: string;
}

interface IDocumentList {
  lists: IitemProps[];
  header: string | ReactElement | null;
  intro: string | ReactElement | null;
}

const DocumentList: React.FC<IDocumentList> = (props) => {

  const { t } = useTranslation();
  const { lists = [], header = '', intro = '' } = { ...props };
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<IitemProps[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    const results = lists.filter(
      (listItem: IitemProps) =>
        listItem['title'] &&
        listItem['title'].toLowerCase().includes(searchTerm),
    );
    setSearchResults(results);
  }, [searchTerm]);

  return (
    <Root>
      {props.header && (
        <div className={classes.documentListHeader}>{props.header}</div>
      )}
      {props.intro && (
        <div className={classes.documentListDesc}>{props.intro}</div>
      )}
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Filter Documents"
          value={searchTerm}
          onChange={handleChange}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          size="large">
          <SearchIcon />
        </IconButton>
      </Paper>
      <div className={classes.listRoot}>
        {searchResults.length > 0 &&
          searchResults.map((item: IitemProps, index: number) => (
            <li className={classes.listRow} key={index}>
              <a
                className={classes.itemText}
                href={item.url}
                target={item.target}
              >
                {item.title}
                {item?.src && (
                  <>
                    {' '}
                    <img
                      src={item?.src}
                      alt={item?.title}
                      className={classes.pdfIconStyle}
                    />
                  </>
                )}
              </a>
            </li>
          ))}
      </div>
    </Root>
  );
};
export default DocumentList;
