import React, { FunctionComponent, useState } from 'react';
import { styled , Theme } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Remove from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MobileNavigationControls from './MobileNavigationControls';
import colors from '../../themes/main-colors';

const PREFIX = 'SubLinkGroup';

const classes = {
  nested: `${PREFIX}-nested`,
  linksList: `${PREFIX}-linksList`,
  listLevel1: `${PREFIX}-listLevel1`,
  listLevel2: `${PREFIX}-listLevel2`,
  listItem: `${PREFIX}-listItem`,
  listItemBack: `${PREFIX}-listItemBack`,
  listLevelLink: `${PREFIX}-listLevelLink`,
  listLevelLinkMenu: `${PREFIX}-listLevelLinkMenu`,
  fourthLevelDrawer: `${PREFIX}-fourthLevelDrawer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(4),
  },

  [`& .${classes.linksList}`]: {
    background: '#0671b4',
    width: '100%',
    padding: 0,
    margin: 0,
    flexGrow: 3,
  },

  [`& .${classes.listLevel1}`]: {
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: theme.spacing(2),
    fontWeight: 400,
  },

  [`& .${classes.listLevel2}`]: {
    backgroundColor: '#1c93dc',
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(2),
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '14px',
    letterSpacing: 1,
    borderBottom: '1px solid #36a0e0',
  },

  [`& .${classes.listItem}`]: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #ffffff33',
    fontSize: '16px',
  },

  [`& .${classes.listItemBack}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderBottom: '1px solid #ffffff33',
    fontSize: '16px',
  },

  [`& .${classes.listLevelLink}`]: {
    display: 'block',
    textDecoration: 'none',
    color: '#fff',
    backgroundColor: '#0388d8',
    padding: theme.spacing(2),
    fontWeight: 400,
    fontSize: '14px',
    borderBottom: '1px solid #36a0e0',
  },

  [`& .${classes.listLevelLinkMenu}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
    color: '#fff',
    backgroundColor: '#0388d8',
    padding: theme.spacing(2),
    fontWeight: 400,
    fontSize: '14px',
    borderBottom: '1px solid #36a0e0',
  },

  [`& .${classes.fourthLevelDrawer}`]: {
    background: colors.navBarBackground,
    color: colors.white,
  }
}));

interface SubLinkGroupProps {
  level: number;
  section: any;
  navigateToRoute: (route: string) => (e: any) => Promise<void>;
  parentLevels: string[] | null;
  toggleMenu: () => void;
}

type LinkGroup = {
  label?: string;
  title?: string;
  links: [];
};

const SubLinkGroup: FunctionComponent<SubLinkGroupProps> = ({
  section,
  level,
  navigateToRoute,
  parentLevels,
  toggleMenu,
}) => {

  const [sectionExpanded, toggleSection] = useState<boolean>(false);
  const [fourthLevelDrawerOpen, setFourthLevelDrawerOpen] = useState<boolean>(
    false,
  );
  const [fifthLevelDrawerOpen, setFifthLevelDrawerOpen] = useState<boolean>(
    false,
  );

  const onClickHeading = (): void => {
    toggleSection(state => !state);
  };

  if (level === 1) {
    return (
      (<Root>
        <ListItem
          button
          onClick={onClickHeading}
          classes={{ root: classes.listItem }}
        >
          <span className={classes.listLevel1}>
            {section.label}
            {section?.groups?.length > 0 ? (
              sectionExpanded ? (
                <Remove />
              ) : (
                <Add />
              )
            ) : null}
          </span>
        </ListItem>
        <Collapse in={sectionExpanded}>
          {section?.groups?.length
            ? section.groups.map((group: LinkGroup) => (
                <SubLinkGroup
                  key={`${group.label}-level${level}`}
                  level={level + 1}
                  section={group}
                  navigateToRoute={navigateToRoute}
                  parentLevels={(parentLevels || []).concat(section.label)}
                  toggleMenu={toggleMenu}
                />
              ))
            : null}
        </Collapse>
      </Root>)
    );
  }

  if (level === 2) {
    return (
      <>
        {section.link ? (
          <a
            className={classes.listLevel2}
            href="#"
            onClick={navigateToRoute(section.link)}
          >
            {section.label}
          </a>
        ) : (
          <span className={classes.listLevel2}>{section.label}</span>
        )}
        {section?.links?.map((link: LinkGroup) => (
          <SubLinkGroup
            key={`${link.title || link.label || 'level-2-link'}-level3`}
            level={level + 1}
            section={link}
            navigateToRoute={navigateToRoute}
            parentLevels={(parentLevels || []).concat(section.label)}
            toggleMenu={toggleMenu}
          />
        ))}
      </>
    );
  }

  if (level === 3) {
    if (section.link) {
      return (
        <a
          href="#"
          onClick={(e: any) => {
            e.preventDefault();
            navigateToRoute(section.link);
          }}
          className={classes.listLevelLink}
        >
          {section.label}
        </a>
      );
    }

    if (section.slug) {
      return (
        <a href={section.slug} className={classes.listLevelLink}>
          {section.label}
        </a>
      );
    }

    return (
      <>
        {(section?.links?.length || 0) === 0 ? (
          section.title ? (
            <a
              href="#"
              className={classes.listLevelLink}
              onClick={navigateToRoute(section.url)}
            >
              {section.title}
            </a>
          ) : null
        ) : (
          <>
            <SubLinkGroup
              key={`${section.title}-level3`}
              level={level + 1}
              section={section}
              navigateToRoute={navigateToRoute}
              parentLevels={(parentLevels || []).concat(section.title)}
              toggleMenu={toggleMenu}
            />
          </>
        )}
      </>
    );
  }

  if (level === 4) {
    return (
      <>
        <a
          href="#"
          className={classes.listLevelLinkMenu}
          onClick={(e: any) => {
            e.preventDefault();
            setFourthLevelDrawerOpen(true);
          }}
        >
          {section.title}
          <ChevronRightIcon />
        </a>
        <Drawer
          open={fourthLevelDrawerOpen}
          anchor="left"
          classes={{ paper: classes.fourthLevelDrawer }}
          SlideProps={{
            direction: 'left',
            timeout: {
              enter: 10,
              exit: 10,
            },
          }}
          ModalProps={{
            BackdropProps: {
              invisible: true,
            },
          }}
        >
          <MobileNavigationControls
            toggleMenu={() => {
              setFourthLevelDrawerOpen(false);
              toggleMenu();
            }}
            navigateToRoute={navigateToRoute}
          />
          <Container className={classes.linksList}>
            <ListItem
              classes={{ root: classes.listItemBack }}
              onClick={() => setFourthLevelDrawerOpen(false)}
            >
              <ArrowBackIcon />
              <div className={classes.listLevel1}>
                {parentLevels?.[0] || ''}
              </div>
            </ListItem>
            <div className={classes.listLevel2}>{parentLevels?.[2] || ''}</div>
            {(section?.links || []).map((link: any) =>
              link?.links?.length > 0 ? (
                <SubLinkGroup
                  key={`${link.title}-level5`}
                  section={link}
                  navigateToRoute={navigateToRoute}
                  toggleMenu={toggleMenu}
                  level={level + 1}
                  parentLevels={(parentLevels || []).concat(link.title)}
                />
              ) : (
                <a
                  href="#"
                  className={classes.listLevelLink}
                  onClick={navigateToRoute(link.url)}
                >
                  {link.title}
                </a>
              ),
            )}
          </Container>
        </Drawer>
      </>
    );
  }

  if (level === 5) {
    return (
      <>
        <a
          href="#"
          className={classes.listLevelLinkMenu}
          onClick={(e: any) => {
            e.preventDefault();
            setFifthLevelDrawerOpen(true);
          }}
        >
          {section.title}
          <ChevronRightIcon />
        </a>
        <Drawer
          open={fifthLevelDrawerOpen}
          anchor="left"
          classes={{ paper: classes.fourthLevelDrawer }}
          SlideProps={{
            direction: 'left',
            timeout: {
              enter: 10,
              exit: 10,
            },
          }}
          ModalProps={{
            BackdropProps: {
              invisible: true,
            },
          }}
        >
          <MobileNavigationControls
            toggleMenu={() => {
              setFifthLevelDrawerOpen(false);
              toggleMenu();
            }}
            navigateToRoute={navigateToRoute}
          />
          <Container className={classes.linksList}>
            <ListItem
              classes={{ root: classes.listItemBack }}
              onClick={() => setFifthLevelDrawerOpen(false)}
            >
              <ArrowBackIcon />
              <div className={classes.listLevel1}>
                {parentLevels?.[2] || ''}
              </div>
            </ListItem>
            <div className={classes.listLevel2}>{parentLevels?.[3] || ''}</div>
            {(section?.links || []).map((link: any) => (
              <a
                href="#"
                className={classes.listLevelLink}
                onClick={navigateToRoute(link.url)}
              >
                {link.title}
              </a>
            ))}
          </Container>
        </Drawer>
      </>
    );
  }

  return null;
};

export default SubLinkGroup;
