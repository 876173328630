import React from 'react';
import { styled , Theme } from '@mui/material/styles';
import colors from '../../themes/main-colors';
import Container from '@mui/material/Container';

const PREFIX = 'Body';

const classes = {
  content: `${PREFIX}-content`
};

const StyledContainer = styled(Container)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.content}`]: {
    color: 'inherit',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    padding: 0,
    '& a:link': {
      textDecoration: 'none',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
    '& a:visited': {
      color: '#4c98ce',
    },
    '& .button a:hover':{
      textDecoration: 'none',
    },
    '& .button a > span':{
      color: 'inherit',
    },
    '& img':{
      maxWidth: '100%',
    },
    '& li > p': {
      margin: '5px 0px',
    },
    '& ul': {
      fontSize: theme.spacing(2.5),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(2),
      },
    },
    '& ol': {
      fontSize: theme.spacing(2.25),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(1.75),
      },
    },
    [theme.breakpoints.down('md')]: {
      '& img': {
        width: '100%',
      },
    },
  }
}));

interface IProps {
  bodyCopy: any;
}

const Body = (props: IProps): any => {

  const { bodyCopy } = props;

  return <StyledContainer className={classes.content}>{bodyCopy}</StyledContainer>;
};

export default Body;
