// These styles were ported over from previous vendors work and need to be validated
export default {
  styleOverrides: {
    lineHorizontal: {
      borderTopWidth: 1.5,
    },
    alternativeLabel: {
      top: 18,
    },
  }
};
