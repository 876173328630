import { Env, PROD, DEV, TEST, LOCAL, STAGE } from './envConstants';
/**
 * resolves environment based on current hostname
 * @return {string} LOCAL|DEV|TEST|PROD|STAGE
 */
export default function resolveEnv(): Env {
  //Avoid Gatsby trying to run this at SSR
  if (typeof window !== 'undefined') {
    if (
      window.location.host === 'new.portlandgeneral.com' ||
      window.location.host === 'portlandgeneral.com'
    ) {
      return PROD;
    }

    if (window.location.host === 'pge-client-pre-wphy5ip64q-uc.a.run.app') {
      return STAGE;
    }

    if (/test/.test(window.location.host)) {
      return TEST;
    }

    if (/dev/.test(window.location.host)) {
      return DEV;
    }

    if (window.location.hostname === 'localhost') {
      return DEV; // For Cypress testing on CI/CD
    }
  }
  return DEV;
}
