import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import colors from '../../themes/main-colors';

export type PGEButtonVariant =
  | 'contained'
  | 'outlined'
  | 'underlined'
  | 'tooltip';
export type PGEButtonColor = 'primary' | 'secondary';

export interface PGEButtonProps extends Omit<ButtonProps, 'variant' | 'color'> {
  variant?: PGEButtonVariant;
  color?: PGEButtonColor;
}

const PGEButton = forwardRef<HTMLButtonElement, PGEButtonProps>(({
  children,
  variant,
  color,
  className,
  ...props
}, ref) => {
    const isUnderlined = variant === 'underlined'
      || variant === 'tooltip';
    const btnVariant = variant === 'outlined' ? 'outlined'
      : !variant && color === 'secondary' ? 'outlined'
      : isUnderlined ? undefined
      : 'contained';

    const theme = useTheme();

    const getBorderColor = (col?: PGEButtonColor) => col === 'secondary'
      ? theme.palette.primary.main
      : '#fff';

    return (
      <Button
        variant={btnVariant}
        color={'primary'}
        disableRipple={true}
        disableFocusRipple={true}
        className={className}
        focusVisibleClassName={'.MuiButton-containedPrimary-Focused'}
        css={{
          ...(variant === 'underlined' && color === 'secondary' ? {
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: 'transparent',

              ...((!props.disabled && color === 'secondary') ? {
                '& #line': {
                  backgroundColor: colors.shoreGreen,
                }
              } : {})
            },
            '&:active': {
              backgroundColor: 'transparent',
              color: '#20415B',

              ...((!props.disabled && color === 'secondary') ? {
                '& #line': {
                  backgroundColor: '#20415B',
                }
              } : {})
            },
          } : variant === 'underlined' ? {
              color: 'white',
              '&:hover': {
                backgroundColor: 'transparent',

                ...((!props.disabled && color !== 'secondary') ? {
                  '& #line': {
                    backgroundColor: colors.buttonHoverPrimary,
                    color: 'red',
                  }
                } : {})
              },
              '&:active': {
                backgroundColor: 'transparent',
                color: colors.focusedVisible,

                ...((!props.disabled && color !== 'secondary') ? {
                  '& #line': {
                    backgroundColor: colors.focusedVisible,
                    color: 'red',
                  }
                } : {})
              },
            } : variant === 'outlined' ? {
              color: getBorderColor(color),
              borderColor: getBorderColor(color),
              '&:hover': {
                borderColor: getBorderColor(color),
                backgroundColor:
                  color === 'secondary'
                    ? colors.buttonHoverSecondary
                    : colors.buttonHoverPrimary + 'B2',
              },
              '&:active': {
                borderColor: getBorderColor(color),
                backgroundColor:
                  color === 'secondary'
                    ? colors.buttonActiveSecondary
                    : colors.focusedVisible + 'B2',
              },
            } : {
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: colors.buttonHoverPrimary,
              },
              '&:active': {
                backgroundColor: colors.focusedVisible,
                boxShadow: 'none',
              },
            }
          )
        }}
        {...props}
        ref={ref}
      >
        {!isUnderlined ? (children) : (
          <div css={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            {children}
            <div
              id="line"
              css={{
                marginTop: '-2px',
                height: '2px',
                width: '100%',
                transition: 'backgroundColor 250ms',

                ...(props && props.disabled ? {
                  backgroundColor: 'rgba(0, 0, 0, 0.26)',
                }
                : color === 'secondary' ? {
                  backgroundColor: theme.palette.primary.main,
                }
                : {
                  backgroundColor: 'white',
                }),
              }}
            />
          </div>
        )}
      </Button>
    );
  },
);

export default PGEButton;
