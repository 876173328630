import React, { FC, createContext, useContext, useState } from 'react';
import { ContactInfo, RebateEligibility, SelectCharger, Installation, InstallationLocation, UploadPhotos } from '../components/pge-plus-form/evcharger.types';
import { AccountDetail } from '../pge-types';

interface Props {
  children: React.ReactNode;
}

export type EVChargerFormState = {
    account: AccountDetail | null,
    contactInfo: ContactInfo,
    rebateEligibility: RebateEligibility,
    selectCharger: SelectCharger,
    installation: Installation,
    installationLocation: InstallationLocation,
    uploadPhotos: UploadPhotos
}

export type EVChargerContextState = {
    loading: boolean;
    formState: EVChargerFormState,
    init: () => void;
    resetForm: () => void; 
    updateFormState: <T extends EVChargerFormState, K extends keyof T>(key: K, data: T[K]) => boolean;
};

const inititalValues: EVChargerContextState = {
    loading: false,
    formState: {
        account: null,
        contactInfo: {},
        rebateEligibility: {},
        selectCharger: {},
        installation: {
            dayOfTheWeek: [],
            urgency: '',
            timeOfTheWeek: []
        },
        installationLocation: {},
        uploadPhotos: {}
    },
    init: () => false,
    updateFormState: () => false,
    resetForm: () => false,
};
const EVChargerFormStateContext = createContext<EVChargerContextState>(inititalValues);

const EVChargerFormStateProvider: FC<Props> = ({ children }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [formState, setFormState] = useState<EVChargerFormState>(inititalValues.formState);

    const updateFormState = <T extends EVChargerFormState, K extends keyof T>(
        key: K, 
        data: T[K]) => {
        setFormState(e => ({ ...e, [key]: data }));
        return true;
    }

    const init = () => {
        //TODO: Check from API to look if user has pending form on page load
        //setLoading(true);
    }

    const resetForm = () => {
        setFormState(inititalValues.formState);
    }

    return (
        <EVChargerFormStateContext.Provider
            value={{
                loading,
                formState,
                init,
                updateFormState,
                resetForm
            }}
        >
            {children}
        </EVChargerFormStateContext.Provider>
    );
};
const useEVChargerFormState = () => ({ ...useContext(EVChargerFormStateContext) });

export { EVChargerFormStateProvider, useEVChargerFormState };
