import React, { useState, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Alert, Theme, IconButton, Collapse } from '@mui/material';
import { AlertProps } from '@mui/lab';
import { Close } from '@mui/icons-material';

import colors from '../../themes/main-colors';

const PREFIX = 'BroadcastCommunicationBanner';

const classes = {
  root: `${PREFIX}-root`,
  action: `${PREFIX}-action`,
  bodyContainer: `${PREFIX}-bodyContainer`
};

const StyledCollapse = styled(Collapse)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0),
      borderRadius: 0,
    },
  },

  [`& .${classes.action}`]: {
    alignItems: 'start',
  },

  [`& .${classes.bodyContainer}`]: {
    '& :first-child': {
      marginTop: 0,
    },
    '& .RichText p': {
      fontSize: theme.spacing(1.75),
    },
    '& .RichText a, & .RichText button': {
      color: colors.white,
    },
  }
}));

const BroadcastCommunicationBanner = ({
  severity = 'info',
  messageBody,
  ...props
}: AlertProps & { messageBody?: ReactNode }) => {

  const { bodyContainer, ...rest } = classes;
  const [open, setOpen] = useState(true);
  return (
    <StyledCollapse in={open}>
      <Alert
        data-testid="broadcast-message-alert"
        classes={rest}
        severity={severity}
        variant="filled"
        color="info"
        {...props}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            data-testid="broadcast-message-close-action"
            className="close-icon"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        }
      >
        {/* <AlertTitle data-testid="broadcast-message-title">
          {messageTitle}
        </AlertTitle> */}
        <div className={bodyContainer}>{messageBody}</div>
      </Alert>
    </StyledCollapse>
  );
};

export default BroadcastCommunicationBanner;
