import { Env, PROD, DEV, TEST, LOCAL, STAGE } from './envConstants';

export function resolveApigeeUrl(targetEnv: Env):string {
  switch (targetEnv) {
    case LOCAL:
      // Allow local overrides using REACT_APP_APIGEE_URL
      // should we put this back to sandbox at some point?
      return (
        `${process.env.REACT_APP_APIGEE_URL ?? 'https://pge-nonprod-test.apigee.net'}`
      );
    case PROD:
      return 'https://api.portlandgeneral.com';
    case STAGE:
      return 'https://pge-prod-staging.apigee.net';
    case TEST:
      return 'https://pge-nonprod-test.apigee.net';
    case DEV:
    default:
      return 'https://pge-nonprod-sandbox.apigee.net';
  }
}

/**
 * Currently, pge+ is supported by a different backend server.
 */
export function resolvePgePlusUrl(targetEnv: Env):string {
    switch (targetEnv) {
      case PROD:
        return 'https://api.portlandgeneral.com';
      case STAGE:
        return 'https://pge-prod-staging.apigee.net';
      case TEST:
        return 'https://pge-nonprod-test.apigee.net';
      case LOCAL:
      case DEV:
      default:
        return process.env.REACT_APP_PGEPLUS_URL || 'https://pge-nonprod-sandbox.apigee.net';
    }
  }
