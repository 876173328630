import { useApolloClient } from '@apollo/client';
import { navigate } from 'gatsby';
import { NavigateOptions } from '@gatsbyjs/reach-router';
import { useEffect, useState } from 'react';
import authService from '../lib/authService';
import ROUTES from '../routes';
import { clearSessionAndLocalStorage } from '../util/storage-utils';

function useAuth() {
  const [authState, setState] = useState(authService.getState());
  const apollo = useApolloClient();
  useEffect(() => {
    return authService.subscribe(setState);
  }, []);

  const signInWithCustomToken = authService.signInWithCustomToken.bind(
    authService,
  );

  const stopAllQueries = () => apollo.stop();

  const handleSignOut = async () => {
    // Queries need to be stopped before the store is cleared, if a query is in-flight,
    // an error will be returned and we will respond with an error page
    stopAllQueries();
    await apollo.clearStore();
    clearSessionAndLocalStorage();
    authService.signOut();
  };

  const signOut = async (
    redirectPath?: string,
    navigateOptions?: NavigateOptions<any>,
  ) => {
    await navigate(redirectPath || ROUTES.SIGN_IN, navigateOptions);
    await handleSignOut();
  };

  const signOutWithoutRedirect = async () => {
    await handleSignOut();
  };
  const signInWithPassword = authService.signInWithPassword.bind(authService);
  const refreshTokens = authService.refreshTokens.bind(authService);
  const signInWithMultiFactorAuth = authService.signInWithMultiFactorAuth.bind(
    authService,
  );
  const startTokenRefresher = authService.startTokenRefresher.bind(authService);
  const stopTokenRefresher = authService.stopTokenRefresher.bind(authService);

  return {
    signInWithPassword,
    signInWithCustomToken,
    signInWithMultiFactorAuth,
    signOut,
    signOutWithoutRedirect,
    refreshTokens,
    startTokenRefresher,
    stopTokenRefresher,
    ...authState,
  };
}

export default useAuth;
