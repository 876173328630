const colors = {
  sparkBlue: '#0371b4',
  shoreGreen: '#1BA488',
  riverBlue: '#C6E4E5',
  noirBlur: '#20415B',
  forestGreen: '#A1BD55',
  sunsetOrange: '#F3AD3C',
  roseRed: '#EF5743',
  orange: '#F06D06',
  cloudGrey: '#AEB2B5',
  mountainBrown: '#977C63',
  darkGray1: '#3d3d3d',
  darkGray2: '#686c71',
  darkGray3: '#84898F',
  lightGray1: '#d7d7d7',
  lightGray2: '#f8f8f8',
  siteBackground: '#f7f8f7',
  navBarBackground: '#20415B',
  navBarHoverBackground: '#00929B',
  navBarSelectLine: '#00929B',
  mobileNavQuickLinks: '#015e98',
  buttonHoverPrimary: '#0074DB',
  buttonHoverSecondary: '#006FB70d', // F2
  buttonActiveSecondary: '#006FB726', // F2
  focusedVisible: '#005084',
  megaMenuBackLight: '#0075BB',
  megaMenuBackDark: '#00487D',
  megaMenuBackground: '#203240',
  white: '#fff',
  black: '#000',
  darkGreen: '#315045',
  darkRed: '#772B21',
  darkOrange: '#79561E',
  darkBlue: '#01385A',
  lightBlue: '#E1F8FA',
  lightBlue1: '#E7F9FB',
  boxShadow: '#000029',
  charcoal: '#707070',
  boxShadowLight: '#8C8C8C4D',
  oliveDrab: '#176796',
  aliceBlue: '#F4FBFF',
  prussianBlue: '#003554',
  navyBlue: '#006DBA',
  raven: '#696C71',
  whiteSmoke: '#F4F4F4',
  blackRussian: '#00000D',
  errorRed: '#D82912',
  gray97: '#F7F7F7',
  gray93: '#EDEDED',
  darkBlue2: '#274159',
  darkerRed: '#C73B25',
  lightSkyBlue: '#d6e8f3',
  rhinoBlack: '#28425a',
  boxShadowColor: '#00000029',
  modalBackgroundColor: '#E6F5FF',
  disableColor: '#E0E0E0',
  quickSilverGrey: '#A6A6A6',
  modalBlue: '#E6F5FF',
  brightBlue: '#0371B4',
  aluminiumColor: '#868B90',
  midGrey: '#686C71',
  noirBlurLight: '#20415BDE',
  fordGray: '#979797',
  gray100: '#edf2f7',
  gray300: '#e2e8f0',
  gray600: '#718096',
  gray800: '#2d3748',
  indigo500: '#667eea',
  gray82: '#D1D1D1',
  lightGray3: '#efefef',
  lightGray4: '#7F7F7F',
  lightGray5: '#757575',
  scrollbarBackground: '#CFDCE5',
  backgroundLightBlue: '#F4F9FC',
  tooltipBoxShadow: '#00002930',
  boxShadowBlack: '#00000033',
};
export default colors
