import React from 'react';
import { styled , Theme } from '@mui/material/styles';
import colors from '../../themes/main-colors';
import BroadcastCommunicationBanner from '../BroadcastCommunication/BroadcastCommunicationBanner';
import Container from '@mui/material/Container';

const PREFIX = 'AlertNotifications';

const classes = {
  outlinedWarning: `${PREFIX}-outlinedWarning`
};

const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.outlinedWarning}`]: {
    '& .MuiCollapse-root': {
      background: colors.lightGray2,
      boxShadow: '0px 3px 6px #00002930',
      borderRadius: 6,
      borderWidth: theme.spacing(0.125),
      borderStyle: 'solid',
      borderColor: colors.white,
      width: '100%',
    },
    '& .MuiAlert-root': {
      padding: 0,
      margin: theme.spacing(2.5, 3.75, 1.25, 3.75),
    },
    '& .MuiAlert-icon': {
      color: colors.darkGray2,
      padding: theme.spacing(0, 0),
      fontSize: theme.spacing(2.5),
      opacity: 0.5,
    },
    '& .MuiAlert-outlinedWarning': {
      border: 'none',
      paddingTop: theme.spacing(1.25),
      background: 'inherit',
      '& .MuiSvgIcon-root': {
        fill: 'currentColor',
      },
    },
    '& .MuiAlert-outlinedInfo': {
      border: 'none',
    },
    '& .MuiIconButton-sizeSmall': {
      padding: theme.spacing(0, 0.375),
    },
    '& .MuiAlert-message': {
      padding: theme.spacing(0, 0),
      '& .MuiAlertTitle-root': {
        color: colors.darkGray2,
        fontSize: theme.spacing(2.25),
        fontWeight: 400,
        lineHeight: theme.typography.pxToRem(18),
        marginTop: 0,
      },
      '& p': {
        fontSize: theme.spacing(2.25),
        color: colors.darkGray2,
        lineHeight: theme.typography.pxToRem(18),
      },
    },
    '& .MuiAlert-action': {
      alignItems: 'flex-start',
      color: colors.darkGray2,
      opacity: 0.87,
    },
    '& .MuiCollapse-entered': {
      margin: theme.spacing(2.5, 0),
    },
  }
}));

interface IProps {
  message: any;
  title?: string;
  severity?: 'warning' | 'information' | 'none';
}
const AlertNotifications: React.FC<IProps> = (props) => {
  const iconType = props?.severity?.includes('info') ? 'info' : props?.severity;

  return (
    <Root>
      <BroadcastCommunicationBanner
        severity={iconType as any}
        messageBody={props.message}
        variant="outlined"
      />
    </Root>
  );
};
export default AlertNotifications
