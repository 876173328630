/**
 * TODO:
 * Refactor a better Overlay.
 */

import { FC, Dispatch, SetStateAction, ReactElement } from 'react';
import { useTheme, Dialog, DialogContent, IconButton, useMediaQuery } from '@mui/material';
import { Close } from '@mui/icons-material';
import RichText from '../rich-text';
import { StatusAlertComponent } from '../../util/contentful-render-utils';

interface Base extends Queries.OverlayLinkFragment {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<Base['open']>>;
  readonly html?: ReactElement;
}

export type Props = Partial<
  Omit<Base, '__typename' | 'slug' | 'contentful_id'>
>;

const Content = DialogContent;

const Overlay: FC<Props> = ({
  content,
  statusAlert,
  open,
  entryName,
  html,
  setOpen,
}) => {
  if (!setOpen) {
    return null;
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const closeDialog = () => setOpen(false);

  return (
    <Dialog
      maxWidth="xl"
      onClose={closeDialog}
      aria-label={entryName || undefined}
      open={!!open}
      fullScreen={fullScreen}
      css={{ width: '100%' }}
      disableScrollLock
    >
      <IconButton
        aria-label="close"
        css={{
          alignSelf: 'flex-end',
          color: theme.palette.grey[500],
        }}
        onClick={closeDialog}
        size="large">
        <Close />
      </IconButton>
      <Content>
        <StatusAlertComponent statusAlertData={statusAlert} />
        {html || (
          <RichText>
            {content}
          </RichText>
        )}
      </Content>
    </Dialog>
  );
};

export default Overlay;
