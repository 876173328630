import React, { useRef } from 'react';
import Map from '../embed-map/Map';
import PgeVideoPlayer from '../pge-video-player/PgeVideoPlayer';
import RichText from '../rich-text';
import { MediaWrapperProps as Props } from './types.d';
import { useComponentSize } from '../../util/style-utils';
import Button from '@mui/material/Button';
import { GetApp } from '@mui/icons-material';
import { useTranslation } from '../../hooks/useTranslation';
import { useTheme } from '@mui/material';


const MediaWrapper: React.FC<Props> = ({
  embedMediaHeader,
  // embedMediaAltText,
  embedMediaCaption,
  url,
  googleMapUrl,
  esri,
  vimeoEmbedUrl,
  vimeoDownloadUrl,
}) => {
  const theme = useTheme()
  const { palette, typography, breakpoints, spacing } = theme;
  const text = palette.text.primary;

  const videoDivRef = useRef<HTMLDivElement>(null);
  const { width } = useComponentSize(videoDivRef);
  const { t } = useTranslation();

  return (
    <figure css={{
      margin: 0,
      textAlign: 'center',

      '& figcaption': {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
      },
    }} ref={videoDivRef}>
      {embedMediaHeader && (
        <div css={{
          color: text,
          fontSize: spacing(4.5),
          lineHeight: typography.pxToRem(40),
          fontWeight: 100,
          [breakpoints.down('sm')]: {
            fontSize: spacing(3),
            lineHeight: typography.pxToRem(28),
          },
        }}>{embedMediaHeader}</div>
      )}
      {googleMapUrl && <Map src={googleMapUrl} />}
      {esri && <Map src={esri} />}
      {url && (
        <div>
          <PgeVideoPlayer
            video={{
              url: url,
              width: `${width}px`,
              height: `${(width / 16) * 9}px`,
            }}
          />
        </div>
      )}
      {vimeoEmbedUrl && (
        <PgeVideoPlayer
          video={{
            url: vimeoEmbedUrl,
            width: `${width}px`,
            height: `${(width / 16) * 9}px`,
          }}
        />
      )}
      {vimeoEmbedUrl && vimeoDownloadUrl && (
        <Button
          component={'a'}
          color="primary"
          size="large"
          href={vimeoDownloadUrl}
          target="_blank"
          rel="noopener noreferrer"
          css={{
            minWidth: '9ch',
            padding: spacing(1, 3),
            boxSizing: 'border-box',
            fontSize: spacing(2),
            borderRight: '1px solid currentColor',
            borderRadius: 0,

            '&:last-child': {
              borderRight: 'none',
            },

            [breakpoints.down('sm')]: {
              padding: spacing(1, 0),

              '& .MuiButton-label': {
                flexDirection: 'column-reverse',
              },

              '& .MuiButton-endIcon': {
                marginLeft: spacing(0),
              },
            },
          }}
          endIcon={<GetApp />}
        >
          {t('DOWNLOAD')}
        </Button>
      )}
      <RichText wrap="figcaption">{embedMediaCaption}</RichText>
    </figure>
  );
}

export default MediaWrapper;
