import { Typography, useTheme } from '@mui/material';
import RichText from '../../rich-text';
import PgeButton from '../../pge-button/_PgeButton';
import useAttrs from '../../../hooks/useAttrs';
import { ListItemProps as Props, ItemOmits } from './types.d';
import { Shadow } from '../../../constants';

function ListItem({
  tabTitle,
  titleLink,
  tabTitleIcon,
  tabBody,
  length,
  pos,
  ...props
}: Props): ElementNode {
  const img = tabTitleIcon?.file?.url;
  const withImage = img ? 'withImage' : '';

  const { className = '', ...attrs } = useAttrs<Props>(props, ItemOmits);
  const theme = useTheme();
  const { palette } = theme;
  const { white } = palette.common;
  const text = palette.text.primary;
  const darkGrey = palette.grey[700];

  return (
    <section
      className={`ListItem ${className}`}
      css={{
        boxSizing: 'border-box',
        contain: 'content',
        display: 'flex',
        padding: theme.spacing(4, 2),
        marginTop: theme.spacing(3),
        overflow: 'hidden',
        backgroundColor: white,
        boxShadow: Shadow.ONE,

        '&::before': {
          flex: withImage ? '0 1px' : 'auto',
          maxWidth: '40px',
          counterIncrement: 'list-item',
          content: 'counter(list-item)',
          font: `700 ${theme.spacing(6)} / 1 ${theme.typography.fontFamily}`,
          color: darkGrey,
          textAlign: 'center',
          overflow: 'hidden',
          [theme.breakpoints.up('md')]: {
            maxWidth: '80px',
            fontSize: theme.spacing(9),
          },
        },
      }}
      aria-label={`Part ${pos} of ${length}`}
      {...attrs}
    >
      {img && (
        <picture css={{
          display: 'flex',
          flex: 'auto',
          maxWidth: '40px',
          justifyContent: 'center',
          alignItems: 'start',
          overflow: 'hidden',

          [theme.breakpoints.up('md')]: {
            maxWidth: '80px',
          },

          '& img': {
            width: '100%',
          },
        }}>
          <img src={img} alt="" height="auto" width="80" />
        </picture>
      )}
      <div css={{
        flex: 1,
        padding: theme.spacing(0, 0.5, 0, 2),
        overflow: 'hidden',

        '& h2': {
          fontSize: '1.4rem',
          fontWeight: 700,

          [theme.breakpoints.up('md')]: {
            fontSize: '1.8rem',
          },
        },
      }}>
        {tabTitle && (
          <Typography variant="h2">
            
            <PgeButton link={titleLink} theme="Text" display="Inline">
              {tabTitle}
            </PgeButton>
          </Typography>
        )}
        <RichText css={{
          '& hr': {
            backgroundColor: darkGrey,
          },
        }}>{tabBody}</RichText>
      </div>
    </section>
  );
}

export default ListItem;
