import { DocumentNode, OperationVariables, useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import ROUTES from '../routes';

const defaultOnError = () => {
  return navigate(ROUTES.ERROR);
};

export default function usePgeQuery<TData = any>(
  query: DocumentNode,
  options?: OperationVariables,
) {
  const { errorPolicy } = options?.errorPolicy
    ? options.errorPolicy
    : { errorPolicy: 'ignore' };
  const ignoreErrors = errorPolicy === 'all' || errorPolicy === 'ignore';
  const onError =
    options?.onError || (ignoreErrors ? undefined : defaultOnError);
  return useQuery<TData>(query, {
    ...options,
    onError,
  });
}
