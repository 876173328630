import React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import colors from '../../themes/main-colors';
import MobileNavigationControls from './MobileNavigationControls';
import SubLinkGroup from './SubLinkGroup';

const PREFIX = 'MobileNavigationMenu';

const classes = {
  paper: `${PREFIX}-paper`,
  linksList: `${PREFIX}-linksList`
};

const StyledDrawer = styled(Drawer)(() =>
  ({
    [`& .${classes.paper}`]: {
      background: colors.navBarBackground,
      color: colors.white,
    },

    [`& .${classes.linksList}`]: {
      background: '#0671b4',
      width: '100%',
      padding: 0,
      margin: 0,
      flexGrow: 3,
      position: 'relative',
    }
  }));

type MobileNavigationMenuProps = {
  menuOpen: boolean;
  toggleMenu: () => void;
  navigateToRoute: (route: string) => (e: any) => Promise<any>;
  navigation: any;
};

const MobileNavigationMenu = ({
  menuOpen,
  toggleMenu,
  navigateToRoute,
  navigation,
}: MobileNavigationMenuProps) => {


  return (
    <StyledDrawer
      open={menuOpen}
      anchor="left"
      classes={{ paper: classes.paper }}
      ModalProps={{ onBackdropClick: toggleMenu }}
    >
      <MobileNavigationControls
        toggleMenu={toggleMenu}
        navigateToRoute={navigateToRoute}
      />
      <Container className={classes.linksList}>
        <List component="ul" disablePadding>
          {(navigation?.menus || []).map((menu: any) => (
            <SubLinkGroup
              key={menu?.label}
              section={menu}
              level={1}
              navigateToRoute={navigateToRoute}
              parentLevels={null}
              toggleMenu={toggleMenu}
            />
          ))}
        </List>
      </Container>
    </StyledDrawer>
  );
};

export default MobileNavigationMenu;
