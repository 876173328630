import React, { FunctionComponent, useMemo } from 'react';
import { styled , Theme } from '@mui/material/styles';
import colors from '../../themes/main-colors';
import Container from '@mui/material/Container';

const PREFIX = 'PullQuote';

const classes = {
  root: `${PREFIX}-root`,
  statement: `${PREFIX}-statement`,
  attribution: `${PREFIX}-attribution`,
  imageContainer: `${PREFIX}-imageContainer`,
  detail: `${PREFIX}-detail`,
  detailsWOImage: `${PREFIX}-detailsWOImage`,
  image: `${PREFIX}-image`
};

const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: (props: pullQuoteProps) => ({
    display: 'flex',
    backgroundColor: props.bgColor ? props.bgColor : colors.white,
    minHeight: 452,
    boxShadow: '0px 3px 6px #00002930',
    borderRadius: 5,
    opacity: theme.spacing(0.1),
    alignItems: 'center',
    padding: theme.spacing(5),
    '& .MuiContainer-root': {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
      padding: theme.spacing(2.5, 0),
      minHeight: 307,
    },
  }),

  [`& .${classes.statement}`]: {
    fontSize: theme.spacing(3),
    lineHeight: theme.typography.pxToRem(28),
    fontWeight: 100,
    color: colors.noirBlur,
    marginBottom: theme.spacing(3.75),
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2.25),
      marginBottom: theme.spacing(2.5),
      lineHeight: theme.typography.pxToRem(20),
    },
  },

  [`& .${classes.attribution}`]: {
    fontWeight: 100,
    fontSize: theme.spacing(2),
    color: colors.noirBlur,
  },

  [`& .${classes.imageContainer}`]: {
    float: 'left',
    marginRight: theme.spacing(7.5),
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2.5),
      marginRight: theme.spacing(0),
    },
  },

  [`& .${classes.detail}`]: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'block',
    },
  },

  [`& .${classes.detailsWOImage}`]: {
    width: '100%',
  },

  [`& .${classes.image}`]: {
    maxWidth: 330,
    borderRadius: 5,
    height: 300,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      objectFit: 'cover',
    },
  }
}));

interface pullQuoteProps {
  image?: string;
  statement?: string;
  attribution?: string;
  bgColor?: string;
}
const PullQuote: React.FC<pullQuoteProps> = (props) => {

  const { statement = '', attribution = '', image = '', bgColor = '' } = {
    ...props,
  };
  return (
    <Root className={classes.root}>
      <Container>
        {image && (
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src={image}
              height="300"
              width="300"
            />
          </div>
        )}
        <div
          className={
            image
              ? classes.detail
              : `${classes.detail} ${classes.detailsWOImage}`
          }
        >
          {statement && <div className={classes.statement}>{statement}</div>}
          {attribution && (
            <div className={classes.attribution}>{attribution}</div>
          )}
        </div>
      </Container>
    </Root>
  );
};
export default PullQuote
