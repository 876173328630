import React, { FC } from 'react';
import { styled, Theme } from '@mui/material/styles';
import colors from '../../themes/main-colors';
import PgeVideoPlayer from '../pge-video-player';
import Container from '@mui/material/Container';
import PgeButton from '../pge-button/_PgeButton';
import { PgeButtonProps } from '../pge-button/types.d';
import ImageLink from '../image-link';
import RichText from '../rich-text';
import { ColorName } from '../../constants';
import { MessagingProps as Props } from './types.d';

const PREFIX = 'MessageBlock';

const classes = {
  main: `${PREFIX}-main`,
  root: `${PREFIX}-root`,
  mediaDiv: `${PREFIX}-mediaDiv`,
  alignLeft: `${PREFIX}-alignLeft`,
  alignRight: `${PREFIX}-alignRight`,
  image: `${PREFIX}-image`,
  details: `${PREFIX}-details`,
  mainHeading: `${PREFIX}-mainHeading`,
  paragraphHeadline: `${PREFIX}-paragraphHeadline`,
  description: `${PREFIX}-description`
};

const Root = styled('section')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.main}`]: {
    padding: theme.spacing(5),
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 0),
    },
  },

  [`& .${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
    },
  },

  [`& .${classes.mediaDiv}`]: {
    width: '50%',
    float: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2.5),
      padding: 0,
    },
    '& > iframe': {
      width: '100%',
      height: 275,
      borderRadius: 5,
    },
  },

  [`& .${classes.alignLeft}`]: {
    flexDirection: 'row',
    '& .media': {
      paddingRight: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    },
  },

  [`& .${classes.alignRight}`]: {
    flexDirection: 'row-reverse',
    '& .media': {
      paddingLeft: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },
  },

  [`& .${classes.image}`]: {
    width: '100%',
    borderRadius: theme.spacing(0.625),
    boxShadow: '0px 3px 6px #00002930',
    objectFit: 'cover',
    height: 275,
    objectPosition: ({ imagePosition }: Props) => imagePosition,
  },

  [`& .${classes.details}`]: {
    width: '50%',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  [`& .${classes.mainHeading}`]: {
    fontSize: theme.spacing(3),
    lineHeight: theme.typography.pxToRem(28),
    fontWeight: 'bold',
    color: colors.noirBlur,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.typography.pxToRem(24),
    },
  },

  [`& .${classes.paragraphHeadline}`]: {
    fontWeight: 100,
    fontSize: theme.spacing(4.5),
    lineHeight: theme.typography.pxToRem(36),
    color: colors.noirBlur,
    margin: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(3),
      lineHeight: theme.typography.pxToRem(28),
    },
    '& > a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },

  [`& .${classes.description}`]: {
    fontSize: theme.spacing(2.5),
    lineHeight: theme.typography.pxToRem(24),
    color: colors.noirBlur,
    margin: theme.spacing(0),
    fontWeight: theme.spacing(50),
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2),
      lineHeight: theme.typography.pxToRem(20),
    },
  }
}));

type ColorNames = keyof typeof ColorName;
interface ImageFile {
  url: string;
}
interface Image {
  file: ImageFile;
}
interface Video {
  url: string;
  vimeoEmbedUrl: string;
}
export interface MessagingBlockButton {
  buttonText: string;
  buttonType: PgeButtonProps['theme'];
}
interface MessageBlockProps {
  largeHeadline: string;
  paragraphHeadline: string;
  paragraphCopy: Ctfl.RichTextNode;
  image: Image;
  video: Video;
  imageLink: string;
  button: MessagingBlockButton;
  buttonLink: string;
  colorBackground: ColorNames;
  imageAlignment: string;
  imagePosition: 'Top' | 'Center' | 'Bottom' | undefined;
}

const MessageBlock: FC<MessageBlockProps> = ({
  largeHeadline,
  paragraphHeadline,
  paragraphCopy,
  image,
  video,
  imageLink,
  button,
  buttonLink,
  colorBackground,
  imageAlignment,
  imagePosition,
}) => {



  let direction = '';

  if (
    (imageAlignment === 'Left' && image) ||
    (imageAlignment === 'Left' && video)
  ) {
    direction = `${classes.root} ${classes.alignLeft}`;
  }
  if (
    (imageAlignment === 'Right' && image) ||
    (imageAlignment === 'Right' && video)
  ) {
    direction = `${classes.root} ${classes.alignRight}`;
  }

  return (
    <Root
      className={classes.main}
      style={
        colorBackground
          ? { backgroundColor: ColorName[colorBackground] }
          : { backgroundColor: '#fff' }
      }
    >
      <Container>
        {largeHeadline && (
          <h1 className={classes.mainHeading}>{largeHeadline}</h1>
        )}
        <div className={direction}>
          <div
            className={
              (image || video) &&
              `media align-${imageAlignment} ${classes.mediaDiv}`
            }
          >
            {image?.file?.url && (
              <ImageLink link={imageLink} text={paragraphHeadline}>
                <img
                  className={classes.image}
                  src={image.file.url}
                  alt=""
                  height="275"
                  width="500"
                />
              </ImageLink>
            )}
            {video && (
              <PgeVideoPlayer
                video={{ url: video?.url ?? video?.vimeoEmbedUrl }}
              />
            )}
          </div>

          <div className={classes.details}>
            {paragraphHeadline && (
              <h3 className={classes.paragraphHeadline}>
                <PgeButton link={buttonLink} theme="Text" display="Inline">
                  {paragraphHeadline}
                </PgeButton>
              </h3>
            )}
            {paragraphCopy && (
              <RichText className={`${classes.description}`}>
                {paragraphCopy}
              </RichText>
            )}
            {button && (
              <PgeButton
                data-testid="button"
                link={buttonLink}
                theme={button?.buttonType}
              >
                {button?.buttonText}
              </PgeButton>
            )}
          </div>
        </div>
      </Container>
    </Root>
  );
};

export default MessageBlock;
