import {
  Context,
  FunctionComponent as FC,
  createContext,
  useState,
  useEffect,
} from 'react';

import { setInStorage, getFromStorage } from '../util/storage-utils';
import { PreferredLanguages } from '../util/languageUtil';

// NOTE: The supported languages data point has a dependency to c2m's PERSON.LANGUAGE_CD as well as to contentful's allowed 'locale' values.
// Ideally, we stick to the values that contentful offers since that is the system of record for translations.
// We offer a large language list (PreferredLanguages in languageUtil.ts) to the user which seems to be an overpromise. Values that will eventually tie in here.
export enum SupportedLanguages {
  English = 'en',
  Spanish = 'es',
}
export const DEFAULT_LANGUAGE = SupportedLanguages.English;
const LOCALE_KEY = 'locale';

/* acccess the language from outside of a functional component
  ex: useFormState > form-validations > useTranslations
  'hooks can only be called inside the body of a function component'
  prefer useContext when in functional components
*/
export const getLanguage = (): string => {
  return getFromStorage(LOCALE_KEY) || DEFAULT_LANGUAGE;
};

export interface LanguageContext {
  language: SupportedLanguages;
  handleLanguageChange: (s: string) => void;
}

const defaultContext: LanguageContext = {
  language: DEFAULT_LANGUAGE,
  handleLanguageChange: (_s: string): void => undefined,
};

// eslint-disable-next-line no-redeclare
export const LanguageContext: Context<LanguageContext> =
  createContext<LanguageContext>(defaultContext);

interface IProps {
  children: any;
}

/** Attempts to map any language to a supported langugage of type SupportedLanguages.
 * Defaults to SupportedLanguages.English if no match.
 *
 * If we add a new translation to the contentful entries, we can add that language value here and it should just work */
const mapLangToSupportedLang = (
  lang: PreferredLanguages | SupportedLanguages | string,
): SupportedLanguages => {
  if (Object.values(SupportedLanguages).includes(lang as SupportedLanguages)) {
    return lang as SupportedLanguages;
  }
  switch (lang) {
    case PreferredLanguages.ENGLISH:
      return SupportedLanguages.English;
    case PreferredLanguages.SPANISH:
      return SupportedLanguages.Spanish;
    default:
      return SupportedLanguages.English;
  }
};

export const LanguageProvider: FC<IProps> = ({ children }) => {
  const [language, setLanguage] =
    useState<SupportedLanguages>(DEFAULT_LANGUAGE);

  const handleLanguageChange = (preferredLanguage: string) => {
    const supportedLanguage = mapLangToSupportedLang(preferredLanguage);

    setLanguage(supportedLanguage);
    setInStorage(LOCALE_KEY, supportedLanguage);
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const queryStringParamLocale = new URLSearchParams(
      window.location.search,
    ).get(LOCALE_KEY);
    const localStorageLocale = getFromStorage(LOCALE_KEY);
    const preferredLanguage =
      queryStringParamLocale || localStorageLocale || DEFAULT_LANGUAGE;
    handleLanguageChange(preferredLanguage);
  }, []);

  return (
    <LanguageContext.Provider
      value={{ language, handleLanguageChange }}
      children={children}
    />
  );
};

export default LanguageProvider;
