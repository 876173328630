import { gql, useApolloClient, useMutation } from '@apollo/client';
import { AccountDetail, Mutation } from '../pge-types';
import { updateAccountDetails } from '../queries/account.query';
import useSelectedGroupId from './useSelectedGroupId';

export default function useUpdateAccountNickname() {
  const client = useApolloClient();
  const { selectedGroupId } = useSelectedGroupId();
  const [update, { loading: accountNicknameSaving }] = useMutation<Mutation>(
    updateAccountDetails,
  );

  async function updateAccountNickname(
    account: AccountDetail,
    nickname: string | null,
    groupId?: string,
  ) {
    await update({
      variables: {
        payload: {
          accountNumber: account.accountNumber,
          description: nickname || '',
          groupId: groupId || selectedGroupId,
        },
      },
    });
    return client.writeQuery({
      variables: {id: `AccountDetail:${account.accountNumber}`},
      data: {
        description: nickname,
      },
      query:gql`{
        query AccountDetailDescription($id: Stringclear!) {
              AccountDetail(id: $id){
                  description
              }
          }
      }`
    });
  }

  return {
    updateAccountNickname,
    accountNicknameSaving,
  };
}
