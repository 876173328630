import React, { FC, createContext, useContext } from 'react';

interface Props {
  context: Record<string, any>;
  children: React.ReactNode;
}

type Promotion = {
  promotion: boolean;
  noFooter: boolean;
  noLogo: boolean;
  noSignIn: boolean;
};

const init: Promotion = {
  promotion: false,
  noFooter: false,
  noLogo: false,
  noSignIn: false,
};

const PromotionContext = createContext<Promotion>(init);

const PromotionProvider: FC<Props> = ({ context, children }) => {
  const template = /promotion-template-tsx$/;
  const promotion = template.test(context.page?.componentChunkName || '');

  return (
    <PromotionContext.Provider
      value={{
        promotion,
        noFooter: !!context.disableFooter,
        noLogo: !!context.disableLogo,
        noSignIn: !!context.disableSignIn,
      }}
    >
      {children}
    </PromotionContext.Provider>
  );
};

const usePromotionContext = () => ({ ...useContext(PromotionContext) });

export default PromotionProvider;
export { usePromotionContext };
