import React from 'react';
import { styled , Theme } from '@mui/material/styles';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import { Box, Typography } from '@mui/material';
import ROUTES from '../../routes';
import TextLink from '../text-link';

const PREFIX = 'ReconnectNotice';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  errorIcon: `${PREFIX}-errorIcon`,
  warningText: `${PREFIX}-warningText`
};

const StyledBox = styled(Box)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.wrapper}`]: {
    backgroundColor: colors.errorRed,
    display: 'flex',
    borderRadius: 4,
    margin: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },

  [`& .${classes.errorIcon}`]: {
    width: 48,
    alignItems: 'flex-start',
    marginTop: '18px',
    '& path:first-of-type': {
      fill: '#520b02',
    },
    '& path:last-of-type': {
      fill: colors.white,
    },
  },

  [`& .${classes.warningText}`]: {
    padding: theme.spacing(2, 2, 2, 0),
    color: colors.white,
    flexGrow: 8,
    '& p': { margin: 0 },
    '& a': {
      '&:active': {
        color: colors.white,
        borderBottom: 'none',
      },
      color: colors.white,
    },
  }
}));

type ReconnectNoticeProps = {
  isEligibleForReconnect: boolean;
};

const ReconnectNotice: (reconnectNoticeProps: ReconnectNoticeProps)=>JSX.Element = ({ isEligibleForReconnect }: ReconnectNoticeProps) => {
  const { t, richT } = useTranslation();

  return (
    <StyledBox className={classes.wrapper}>
      <ErrorTwoToneIcon className={classes.errorIcon} />
      <div className={classes.warningText}>
        {isEligibleForReconnect ? (
          <Typography variant={'h6'}>
            {t('RECONNECT_ELIGIBLE_NOTICE')}
            <TextLink to={ROUTES.RECONNECT_SERVICE_START}>
              {t('SEE_YOUR_PAYMENT_OPTIONS')}
            </TextLink>
          </Typography>
        ) : (
          <Typography variant={'h6'}>
            {richT('RECONNECT_INELIGIBLE_NOTICE', {
              PGE_CUSTOMER_SERVICE_NUMBER: t('PGE_CUSTOMER_SERVICE_NUMBER'),
              CUSTOMER_SERVICE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
            })}
          </Typography>
        )}
      </div>
    </StyledBox>
  );
};

export default ReconnectNotice;
