import { ComponentPropsWithoutRef } from 'react';
import { Button } from '../../../constants';

export const Omits = [
  'aria-label',
  'style',
  'promoLink',
  'videowrappedImage',
  'overrideThumbnail',
  'promoLInk',
] as const;

interface Base extends Queries.ProgramCardFragment, ComponentPropsWithoutRef<'article'> {
  readonly programBadge?: Ctfl.Field['Badge'];
  readonly colorBackground?: Exclude<Ctfl.Field['Background'], 'Blue'>;
  readonly column?: Ctfl.Field['Column'];
  readonly ctaButton?: {
    readonly buttonType?: Button['Theme'];
    readonly buttonText?: string;
  };
}

export type ProgramCardProps = Partial<Omit<Base, typeof Omits[number]>>;
