import React from 'react';

import { styled , Theme } from '@mui/material/styles';

import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import ShortcutsBar from './Shortcuts';
import colors from '../../themes/main-colors';
import PGEButton from '../buttons/PGE-Button';
import ROUTES from '../../routes';
import { useTranslation } from '../../hooks/useTranslation';
import useAuth from '../../hooks/useAuth';
import { SearchBox, WithSearch } from '@elastic/react-search-ui';

import { navigate } from 'gatsby';
import SearchIcon from '@mui/icons-material/Search';

const PREFIX = 'MobileNavigationControls';

const classes = {
  controls: `${PREFIX}-controls`,
  closeButtonIcon: `${PREFIX}-closeButtonIcon`,
  buttonRoot: `${PREFIX}-buttonRoot`,
  elasticSearchBox: `${PREFIX}-elasticSearchBox`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.controls}`]: {
    display: 'inline-block',
    position: 'relative',
  },

  [`& .${classes.closeButtonIcon}`]: {
    color: colors.white,
  },

  [`& .${classes.buttonRoot}`]: {
    background: colors.navBarBackground,
    color: colors.white,
    borderRadius: 'none',
  },

  [`& .${classes.elasticSearchBox}`]: {
    boxSizing: 'border-box',
    margin: theme.spacing(0, 0, 1.25, 1.25),
    display: 'flex',
    '& .sui-search-box': {
      padding: theme.spacing(0, 1.25, 0, 0),
    },
    '& .sui-search-box__submit': {
      display: 'none',
    },
    '& .sui-search-box__text-input': {
      padding: theme.spacing(1),
    },
    '& > form': {
      width: '100%',
    },
    '& .MuiSvgIcon-root': {
      margin: theme.spacing(1.375, 0, 0, -5),
      zIndex: 1,
      fill: colors.noirBlur,
      height: theme.spacing(2),
    },
    '& .MuiButton-outlinedPrimary': {
      marginLeft: theme.spacing(2),
    },
    '& .sui-search-box__autocomplete-container': {
      top: '97%',
    },
  }
}));

type MobileNavigationControlsProps = {
  toggleMenu: () => void;
  navigateToRoute: (route: string) => (e: any) => Promise<any>;
};

const MobileNavigationControls = ({
  toggleMenu,
  navigateToRoute,
}: MobileNavigationControlsProps) => {

  const { t } = useTranslation();
  const { signOut, isAuthenticated } = useAuth();
  const isBrowser = !(typeof window === 'undefined');

  return (
    <Root>
      <div className={classes.controls}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <IconButton onClick={() => toggleMenu()} size="large">
            <Close className={classes.closeButtonIcon} />
          </IconButton>
          <PGEButton
            classes={{
              root: classes.buttonRoot,
            }}
            endIcon={<AccountCircleOutlined />}
            onClick={
              isAuthenticated
                ? () => signOut()
                : navigateToRoute(ROUTES.SIGN_IN)
            }
          >
            {isAuthenticated ? t('SIGN_OUT') : t('SIGN_IN')}
          </PGEButton>
        </Box>
      </div>
      <div className={classes.elasticSearchBox}>
        {isBrowser && (
          <WithSearch
            mapContextToProps={({ searchTerm, setSearchTerm }: any) => ({
              searchTerm,
              setSearchTerm,
            })}
          >
            {({ searchTerm, setSearchTerm }: any) => {
              return (
                <>
                  <SearchBox
                    autocompleteMinimumCharacters={3}
                    autocompleteResults={{
                      sectionTitle: 'Results',
                      titleField: 'title',
                      urlField: 'url',
                      shouldTrackClickThrough: true,
                      clickThroughTags: ['test'],
                    }}
                    autocompleteSuggestions={true}
                    debounceLength={0}
                    onSubmit={async (value: any) => {
                      toggleMenu();
                      await navigate('/search?q=' + value);
                      setSearchTerm(value);
                    }}
                  />
                  <SearchIcon
                    style={{ cursor: 'pointer' }}
                    onClick={async (e: any) => {
                      toggleMenu();
                      await navigate('/search?q=' + searchTerm);
                      setSearchTerm(searchTerm);
                    }}
                  />
                </>
              );
            }}
          </WithSearch>
        )}
      </div>
      <ShortcutsBar navigateToRoute={navigateToRoute} />
    </Root>
  );
};

export default MobileNavigationControls;
