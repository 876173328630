import authService from './authService';
import { navigate } from 'gatsby';
import ROUTES from '../routes';

type Message = {
  src: string;
  idToken: string;
  refToken: string;
};

function isWatMessage(data: any): data is Message {
  return data?.src === 'WAT' && data?.idToken;
}

function isMobileAppMessage(data: any): data is Message {
  return data?.src === 'MobileApp' && data?.idToken;
}

async function handleMessage(event: MessageEvent) {
  const data = event.data;
  const isWat = isWatMessage(data);
  const isMobileApp = isMobileAppMessage(data);

  if (isWat || isMobileApp) {
    window.removeEventListener('message', handleMessage, false);
    await authService.signInWithCustomToken(data.idToken);

    if (isWat) {
      await navigate(ROUTES.ACCOUNT);
    }

    if (isMobileApp) {
      await navigate(ROUTES.OUTAGES_MOBILE_APP);
    }
  }
}

export function startPostMessageService() {
  if (typeof window !== 'undefined') {
    window.addEventListener('message', handleMessage, false);
  }
}
