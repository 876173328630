import { Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';
import React, { forwardRef } from 'react';
import colors from '../../../src/themes/main-colors';
import { useIsMobile } from '../../util/style-utils';
import ROUTES from '../../routes';

import { NotificationsContext } from '../../providers/NotificationsProvider';

const PREFIX = 'Notifications';

const classes = {
  alert: `${PREFIX}-alert`,
  icon: `${PREFIX}-icon`,
  iconMobile: `${PREFIX}-iconMobile`,
  action: `${PREFIX}-action`,
  actionMobile: `${PREFIX}-actionMobile`,
  message: `${PREFIX}-message`,
  messageMobile: `${PREFIX}-messageMobile`,
  filledSuccess: `${PREFIX}-filledSuccess`,
  filledWarning: `${PREFIX}-filledWarning`,
  filledError: `${PREFIX}-filledError`,
  filledInfo: `${PREFIX}-filledInfo`,
  successIcon: `${PREFIX}-successIcon`,
  errorIcon: `${PREFIX}-errorIcon`,
  warningIcon: `${PREFIX}-warningIcon`,
  infoIcon: `${PREFIX}-infoIcon`,
};

const StyledNotificationsContextConsumer = styled(
  NotificationsContext.Consumer,
)(() => ({
  [`& .${classes.alert}`]: {
    margin: '1em 1em 1em 1em',
    '& > .MuiAlert-message > p': {
      margin: 0,
    },
  },

  [`& .${classes.icon}`]: { paddingTop: '13px' },
  [`& .${classes.iconMobile}`]: { paddingTop: '8px' },
  [`& .${classes.action}`]: { alignItems: 'start' },
  [`& .${classes.actionMobile}`]: { alignItems: 'start', paddingTop: '6px' },
  [`& .${classes.message}`]: { fontSize: '1.2rem', fontWeight: 700 },
  [`& .${classes.messageMobile}`]: { fontSize: '1rem', fontWeight: 700 },
  [`& .${classes.filledSuccess}`]: { backgroundColor: colors.shoreGreen },
  [`& .${classes.filledWarning}`]: { backgroundColor: colors.sunsetOrange },

  [`& .${classes.filledError}`]: {
    backgroundColor: colors.roseRed,
    '& a': {
      color: '#FFF',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
  },

  [`& .${classes.filledInfo}`]: {
    backgroundColor: colors.sparkBlue,
    '& a': {
      color: '#FFF',
      fontWeight: 'bold',
    },
  },

  [`& .${classes.successIcon}`]: {
    '& path': {
      transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
    },
    fill: colors.darkGreen,
    fontSize: 'inherit',
    background: colors.white,
    borderRadius: '50%',
    transform: 'scale(1.2)',
  },

  [`& .${classes.errorIcon}`]: {
    '& path': {
      transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
    },
    fill: colors.darkRed,
    fontSize: 'inherit',
    background: colors.white,
    borderRadius: '50%',
    transform: 'scale(1.2)',
  },

  [`& .${classes.warningIcon}`]: {
    '& path': {
      transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
    },
    fill: colors.darkOrange,
    fontSize: 'inherit',
    background: colors.white,
    clipPath: 'polygon(50% 6%, 100% 91%, 0 91%)',
    transform: 'scale(1.3)',
  },

  [`& .${classes.infoIcon}`]: {
    '& path': {
      transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
    },
    fill: colors.darkBlue,
    fontSize: 'inherit',
    background: colors.white,
    borderRadius: '50%',
    transform: 'scale(1.2)',
  },
}));

export type NotificationSeverity = 'error' | 'warning' | 'info' | 'success';

export type NotificationVariant = 'filled' | 'outlined' | 'standard';

interface NotificationsProps {
  severity?: NotificationSeverity;
  variant?: NotificationVariant;
  title?: string;
  message?: React.ReactNode;
  isOpen?: boolean;
  alertStyle?: string;
}

const Notifications = forwardRef<HTMLDivElement, NotificationsProps>(
  (props, ref) => {
    const isMobile = useIsMobile();
    return (
      <StyledNotificationsContextConsumer>
        {({ state, setState }) => {
          const s = props.isOpen ? props : state;

          const alertStyleOverride = s.alertStyle ? s.alertStyle : '';

          let alertClass;
          if (s.severity === 'success') {
            alertClass = `${classes.filledSuccess} ${classes.alert} ${alertStyleOverride} `;
          } else if (s.severity === 'error') {
            alertClass = `${classes.filledError} ${classes.alert} ${alertStyleOverride}`;
          } else if (s.severity === 'warning') {
            alertClass = `${classes.filledWarning} ${classes.alert} ${alertStyleOverride}`;
          } else {
            // its info!
            alertClass = `${classes.filledInfo} ${classes.alert} ${alertStyleOverride}`;
          }

          let messageClass;
          if (isMobile) {
            messageClass = classes.messageMobile;
          } else {
            messageClass = classes.message;
          }

          let iconClass;
          if (isMobile) {
            iconClass = classes.iconMobile;
          } else {
            iconClass = classes.icon;
          }

          let actionClass;
          if (isMobile) {
            actionClass = classes.actionMobile;
          } else {
            actionClass = classes.action;
          }

          return (
            <Collapse in={s.isOpen} style={{ zIndex: 0 }}>
              <MuiAlert
                className={alertClass}
                classes={{
                  icon: iconClass,
                  action: actionClass,
                  message: messageClass,
                }}
                ref={ref}
                severity={s.severity}
                variant={s.variant}
                iconMapping={{
                  success: <CheckCircleIcon className={classes.successIcon} />,
                  error: <ErrorIcon className={classes.errorIcon} />,
                  warning: <WarningIcon className={classes.warningIcon} />,
                  info: <InfoIcon className={classes.infoIcon} />,
                }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size={isMobile ? 'small' : 'medium'}
                    onClick={() => setState({ isOpen: false })}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {s.title && <MuiAlertTitle>{s.title}</MuiAlertTitle>}
                {s.message}
              </MuiAlert>
            </Collapse>
          );
        }}
      </StyledNotificationsContextConsumer>
    );
  },
);

export default Notifications;
