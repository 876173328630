import React from 'react';
import { styled } from '@mui/material/styles';
import { Theme, useTheme } from '@mui/material';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import colors from '../../themes/main-colors';
import Container from '@mui/material/Container';
import RichText from '../rich-text';

interface CtaProps {
  header?: string;
  description?: Queries.ModuleCallToActionFragment['shortDescription'];
  button?: IPGEButtonProps | null;
  bgMode?: string;
  image?: string;
}

const CTA: React.FC<CtaProps> = props => {

  const {
    bgMode = '',
    header,
    description,
    button,
  } = {
    ...props,
  };

  const notImageOrGradient = bgMode === 'Blue'
    || bgMode === 'Grey'
    || bgMode === 'None'

  const theme = useTheme();

  return (
    <div
      css={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(5),
        boxShadow: '0px 3px 6px #00002930',
        borderRadius: theme.spacing(0.625),
        backgroundColor: bgMode === 'Blue' ? colors.lightBlue1
          : bgMode === 'Grey' ? colors.lightGray2
          : colors.white,

        ...(bgMode === 'Gradient' ? {
          backgroundImage: 'linear-gradient(180deg, #0064A2, #0096A2)',
        } : bgMode === 'Image' ? {
          backgroundImage: `URL(${props.image})`,
          backgroundSize: 'cover',
        } : {}),
      }}>
      <Container>
        {header && <div css={{
          fontSize: theme.spacing(4.5),
          color: !notImageOrGradient ? theme.palette.common.white : colors.noirBlur,
          fontWeight: theme.spacing(12.5), // ???
          lineHeight: theme.typography.pxToRem(36),
          [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(3),
          },
        }}>{header}</div>}
        {description && (
          <RichText
            className={notImageOrGradient ? '' : 'bkg-Dark'}
            css={{
            ...(!notImageOrGradient ? {
              fontSize: theme.spacing(3),
              color: theme.palette.common.white,
              marginTop: theme.spacing(2.5),

              '& p': {
                fontSize: '1.1rem',
              },
              '& a, & button': {
                color: 'inherit',
                textDecoration: 'underline',
              },
            } : {
              color: colors.noirBlur,
              marginTop: theme.spacing(2.5),

              '& p': {
                fontSize: '1.1rem',
              },
            })
          }}>
            {description}
          </RichText>
        )}
        {button && (
          <div css={{
            marginTop: theme.spacing(2.5),
          }}>
            <PgeButton {...button} />
          </div>
        )}
      </Container>
    </div>
  );
};

export default CTA;
