import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { graphql, useStaticQuery } from 'gatsby';
import BroadcastCommunicationBanner from './BroadcastCommunicationBanner';
import { LanguageContext } from '../../providers/LanguageProvider';
import RichText from '../rich-text';
import colors from '../../themes/main-colors';
import { AlertColor } from '@mui/lab';

const PREFIX = 'BroadcastCommunication';

const classes = {
  globalBannerLink: `${PREFIX}-globalBannerLink`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() =>
  ({
    [`& .${classes.globalBannerLink}`]: {
      '& a, & .MuiButton-label': {
        color: colors.black,
      },
    }
  }));

// The ne filter in this query is part of a (hopefully temporary) hack to get around:
// https://www.npmjs.com/package/gatsby-source-contentful#notes-on-contentful-content-models
// In order to have a type included in the types/gatsby-types.d.ts schema, there need to
// be at least one instance of a content entry for the type. Entry 1yTL9ZuX2x3V8UorolfVQK
// is the place holder for BroadcastMessage and it should never be displayed.
const BroadcastCommunicationQuery = graphql`
  query AllContentfulBroadcastMessage {
    allContentfulBroadcastMessage(
      filter: { contentful_id: { ne: "1yTL9ZuX2x3V8UorolfVQK" } }
    ) {
      nodes {
        ...BroadcastMessage
      }
    }
  }
`;

const BroadcastCommunication = () => {

  const { allContentfulBroadcastMessage } = useStaticQuery<Queries.Query>(
    BroadcastCommunicationQuery,
  );
  const { language } = useContext(LanguageContext);

  const infoBannerStyle = {
    backgroundColor: colors.white,
    color: colors.black,
  };

  return (
    (<Root>
      {allContentfulBroadcastMessage.nodes
        .filter((node) => node.node_locale === language) //only show messages for current language
        .map((message) => (
          <BroadcastCommunicationBanner
            data-testid="broadcast-banner"
            key={message.id}
            style={
              message.messageType?.toLowerCase() === 'info'
                ? infoBannerStyle
                : {}
            } // inline style to avoid impact on other banners
            severity={message.messageType?.toLowerCase() as AlertColor}
            messageBody={
              message.messageBody && (
                <RichText
                  className={`${
                    message.messageType?.toLowerCase() === 'info'
                      ? classes.globalBannerLink
                      : ''
                  } RichText`}
                >
                  {message.messageBody}
                </RichText>
              )
            }
          />
        ))}
    </Root>)
  );
};

export default BroadcastCommunication;
