/**  These language slugs match the values coming from c2m as a string via getAccountInfo.prefLanguage as well as the keys in contentful */
export enum PreferredLanguages {
  ENGLISH = 'ENGLISH',
  ARABIC = 'ARABIC',
  BURMESE = 'BURMESE',
  CAMBODIAN = 'CAMBODIAN',
  CHINESE_CANTONESE = 'CHINESE_CANTONESE',
  CHINESE_MANDARIN = 'CHINESE_MANDARIN',
  FARSI = 'FARSI',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  LAOTIAN = 'LAOTIAN',
  ROHINGYA = 'ROHINGYA',
  ROMANIAN = 'ROMANIAN',
  RUSSIAN = 'RUSSIAN',
  SOMALI = 'SOMALI',
  SPANISH = 'SPANISH',
  SWAHILI = 'SWAHILI',
  VIETNAMESE = 'VIETNAMESE',
  OTHER = 'OTHER',
}
