import { createElement, Fragment, ExoticComponent, FC, ReactNode } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import block from './block';
import inline from './inline';
import { RichTextProps, Wrap } from './types.d';
import { useTheme } from '@mui/material';

type Element = Exclude<Wrap, 'fragment' | 'none'> | ExoticComponent;

const RichTextRaw = ({
  children,
  wrap = 'fragment',
  override = {},
  ...props
}: RichTextProps): ElementNode => {
  if (!children) {
    return null;
  }

  const baseWrap = wrap === 'fragment' && !!props.className
    ? 'div'
    : wrap;

  const element: Element =  baseWrap === 'fragment' || baseWrap === 'none'
    ? Fragment
    : baseWrap;

  const options: Options = {
    renderNode: {
      ...block(wrap !== 'none'),
      ...inline(),
    },
    ...override,
  };

  return createElement(
    element,
    //@ts-ignore
    { ...props },
    renderRichText(children, options),
  );
}

const RichText: FC<RichTextProps> = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <div css={{
      '& h3, & h4': {
        fontSize: '1.25rem',
        letterSpacing: '.01ch',
      },

      '& h3, & h4, & h5, & h6': {
        lineHeight: 1.2,
      },

      '& h4': {
        fontStyle: 'italic',
      },

      '& h5': {
        fontSize: '1.4rem',
        fontWeight: 400,
      },

      '& h6': {
        fontSize: '1rem',
      },

      '& p': {
        fontSize: '1rem',
        letterSpacing: '.02ch',
        lineHeight: 1.4,

        [theme.breakpoints.up('sm')]: {
          fontSize: '1.25rem',
        },
      },

      '& > *:not(hr)': {
        marginTop: '.8em',
      },

      '& hr': {
        border: 0,
        margin: theme.spacing(3, 0, 1),
      },

      '& ul': {
        listStyle: 'disc',
        paddingLeft: theme.spacing(3),
      },

      '& ol': {
        listStyle: 'decimal',
        paddingLeft: theme.spacing(3),
      },

      '& > ul, & > ol': {
        msOverflowStyle: '-ms-autohiding-scrollbar',
        overflowX: 'auto',
      },

      '& li': {
        display: 'list-item',
        paddingLeft: 0,
      },

      '& img': {
        width: '100%',
        marginTop: '1rem',
        border: '1px solid #eee',
      },

      '& code': {
        fontSize: '.8em',
      },
    }}>
      <RichTextRaw {...props}>{children}</RichTextRaw>
    </div>
  )
}

export default RichText;
