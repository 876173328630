import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';

import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';

import ROUTES from '../../routes';

const PREFIX = 'ShortcutsBar';

const classes = {
  shortcutsContainer: `${PREFIX}-shortcutsContainer`,
  shortcutButtonRoot: `${PREFIX}-shortcutButtonRoot`
};

const StyledContainer = styled(Container)(() =>
  ({
    [`&.${classes.shortcutsContainer}`]: {
      padding: 0,
      backgroundColor: colors.mobileNavQuickLinks,
      position: 'relative',
    },

    [`& .${classes.shortcutButtonRoot}`]: {
      position: 'relative',
      flex: 1,
      margin: 0,
      padding: '8px 0',
      border: '1px solid #ffffff33',
      borderRadius: 0,

      '& > span': {
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: 52,
        padding: 4,
      },

      '& > span > h3': {
        margin: '0 0 -10px',
        padding: 0,
        fontSize: '2.0em',
      },

      '& > span > h4': {
        textTransform: 'uppercase',
        fontWeight: 400,
        fontSize: '10px',
        margin: 0,
        padding: 0,
      },
    }
  }));

interface ShortcutsBarProps {
  navigateToRoute: (route: string) => (e: any) => Promise<void>;
}

const ShortcutsBar: FC<ShortcutsBarProps> = ({ navigateToRoute }) => {

  const { t } = useTranslation();

  return (
    <StyledContainer className={classes.shortcutsContainer}>
      <Button
        className={classes.shortcutButtonRoot}
        onClick={navigateToRoute(ROUTES.ACCOUNT)}
      >
        <h3>
          <AccountCircleOutlined fontSize="inherit" />
        </h3>
        <h4>{t('ACCOUNT')}</h4>
      </Button>
      <Button
        className={classes.shortcutButtonRoot}
        onClick={navigateToRoute(ROUTES.PAYMENT)}
      >
        <h3>
          <PaymentOutlinedIcon fontSize="inherit" />
        </h3>
        <h4>{t('BILLING')}</h4>
      </Button>
      <Button
        className={classes.shortcutButtonRoot}
        onClick={navigateToRoute(ROUTES.START_SERVICE)}
      >
        <h3>
          <PowerSettingsNewOutlinedIcon fontSize="inherit" />
        </h3>
        <h4>{t('MOVING')}</h4>
      </Button>
      <Button
        className={classes.shortcutButtonRoot}
        onClick={navigateToRoute(ROUTES.OUTAGES)}
      >
        <h3>
          <OfflineBoltOutlinedIcon fontSize="inherit" />
        </h3>
        <h4>{t('OUTAGE')}</h4>
      </Button>
    </StyledContainer>
  );
};

export default ShortcutsBar;
