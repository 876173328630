import React from 'react';
import { styled , Theme, useTheme } from '@mui/material/styles';
import Tabs, { TabProps } from '../tabs/Tabs';
import colors from '../../themes/main-colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import PGEAccordion from '../accordion/';

const PREFIX = 'Tabset';

const classes = {
  root: `${PREFIX}-root`,
  headerTitle: `${PREFIX}-headerTitle`,
  descText: `${PREFIX}-descText`,
  image: `${PREFIX}-image`
};

const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    borderRadius: 5,
  },

  [`& .${classes.headerTitle}`]: {
    fontSize: theme.spacing(4.5),
    fontWeight: theme.spacing(12.5),
    color: colors.noirBlur,
    paddingBottom: theme.spacing(1.25),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(3),
      paddingBottom: theme.spacing(1.25),
    },
  },

  [`& .${classes.descText}`]: {
    fontSize: theme.spacing(2.5),
    color: colors.noirBlur,
    paddingBottom: theme.spacing(3.75),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2),
      paddingBottom: theme.spacing(2.5),
    },
  },

  [`& .${classes.image}`]: {
    width: '100%',
    height: 275,
    objectFit: 'cover',
    borderRadius: theme.spacing(0.625, 0.625, 0, 0),
    display: 'block',
    objectPosition: ({ imagePosition }: TabsetProps) => imagePosition,
    [theme.breakpoints.down('md')]: {
      height: 135,
    },
  }
}));

interface TabsetProps {
  tabData: TabProps;
  header?: string;
  tabsetImage?: any;
  intro?: string;
  readonly imagePosition?: Ctfl.Field['Position'];
}

const Tabset:React.FC<TabsetProps> = props => {

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const length: number = props?.tabData?.tabs?.length ?? 0;

  let component: React.ReactNode = <></>;
  if (matches) {
    if (length < 3) {
      component = <Tabs {...props.tabData} />;
    } else {
      const accordionItems: any = props?.tabData?.tabs?.map((item: any) => {
        return {
          tabTitle: item?.label,
          tabBody: item?.data,
          tabTitleIcon: item?.icon,
        };
      });
      component = <PGEAccordion accordionItems={accordionItems} />;
    }
  } else {
    component = <Tabs {...props.tabData} />;
  }
  return (
    <Root className={classes.root}>
      {props.tabsetImage && (
        <img
          className={classes.image}
          src={props.tabsetImage?.file?.url}
          alt={props.header}
        />
      )}
      {props.header && (
        <div className={classes.headerTitle}>{props.header}</div>
      )}
      {props.intro && <div className={classes.descText}>{props.intro}</div>}
      {component}
    </Root>
  );
};
export default Tabset
