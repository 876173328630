export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  DateTimeCustom: any;
  DateTimeISO: any;
  JSON: any;
  Latitude: any;
  LocalDate: any;
  Longitude: any;
  PhoneNumber: any;
  PhoneNumberPgeFormat: any;
  _Any: any;
  federation__FieldSet: any;
  link__Import: any;
};

export type Account = {
  __typename?: 'Account';
  accountDetails?: Maybe<AccountDetails>;
  accountId?: Maybe<Scalars['String']>;
  autoPay?: Maybe<AutoPayEnrollStatus>;
  /** BillHistory start and end date must be an ISO date time string */
  billHistory?: Maybe<Array<BillingHistory>>;
  billingInfo?: Maybe<BillPayInfo>;
  chargeSummary?: Maybe<ChargeSummary>;
  collectionActivity?: Maybe<AccountCollectionActivity>;
  encryptedAccountId?: Maybe<Scalars['String']>;
  fieldActivity?: Maybe<AccountFieldActivity>;
  mailingAddress?: Maybe<Address>;
  nextBill?: Maybe<NextBillInfo>;
  payment?: Maybe<PaymentInfo>;
  paymentExtension?: Maybe<PaymentExtensionInfo>;
  /** PaymentHistory start and end date must be an ISO date time string */
  paymentHistory?: Maybe<Array<PaymentHistory>>;
  persons?: Maybe<Array<Maybe<Person>>>;
  premises?: Maybe<Array<Maybe<PremiseDetails>>>;
  reconnectQuote?: Maybe<ReconnectQuoteDetails>;
  /** Currently stub so that if the first number of the accountId is even the value will be true */
  servicesEligibility?: Maybe<ServicesEligibility>;
  tpa?: Maybe<Tpa>;
  viewBillDetails?: Maybe<ViewBillInfo>;
};


export type AccountBillHistoryArgs = {
  endDate: Scalars['DateTimeISO'];
  startDate: Scalars['DateTimeISO'];
};


export type AccountPaymentArgs = {
  encryptedPersonId: Scalars['String'];
};


export type AccountPaymentExtensionArgs = {
  encryptedPersonId: Scalars['String'];
};


export type AccountPaymentHistoryArgs = {
  endDate: Scalars['DateTimeISO'];
  startDate: Scalars['DateTimeISO'];
};

export type AccountAlert = {
  __typename?: 'AccountAlert';
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type AccountCollectionActivity = {
  __typename?: 'AccountCollectionActivity';
  fifteenDayNoticeServed: Scalars['Boolean'];
  noticeDueDate?: Maybe<Scalars['DateTimeISO']>;
};

/** This is Root Object for Customer  Info */
export type AccountCustomer = {
  __typename?: 'AccountCustomer';
  accountMeta?: Maybe<AccountMeta>;
  autoPayAccounts?: Maybe<Array<Maybe<AutoPayAccounts>>>;
  contactDetails?: Maybe<Array<Maybe<PersonContact>>>;
  email: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  groups?: Maybe<Array<Maybe<Group>>>;
  lastConfirmedDate?: Maybe<Scalars['LocalDate']>;
  personId: Scalars['String'];
  personName?: Maybe<Scalars['String']>;
  prefLanguage?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTimeCustom'];
  uid: Scalars['String'];
};


/** This is Root Object for Customer  Info */
export type AccountCustomerAutoPayAccountsArgs = {
  token?: InputMaybe<Scalars['String']>;
};


/** This is Root Object for Customer  Info */
export type AccountCustomerGroupsArgs = {
  groupInfoParams?: InputMaybe<GroupInfoParams>;
};

export type AccountDetail = {
  __typename?: 'AccountDetail';
  accountNumber: Scalars['String'];
  accountType: AccountType;
  autoPay?: Maybe<AutoPayInfo>;
  billInfo?: Maybe<BillInfo>;
  coCustomerNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  commPreferences?: Maybe<Array<Maybe<CommPreference>>>;
  currentCharges?: Maybe<CurrentCharges>;
  description?: Maybe<Scalars['String']>;
  encryptedAccountNumber: Scalars['String'];
  /** @deprecated Not required since personId will be same for all accounts */
  encryptedPersonId: Scalars['String'];
  equalpay?: Maybe<PaymentPlanTypeResponse>;
  isActive: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  /** @deprecated Not using anywhere on client side. use paymentExtensionInfo.isEnrolled */
  isEnrolled?: Maybe<Scalars['Boolean']>;
  /** @deprecated  Not required since we are removing indirect associations */
  isLoggedInUserOnAccount: Scalars['Boolean'];
  isPaperlessBillEnrolled?: Maybe<IsPaperlessBillEnrolledResponse>;
  isSummaryBillAccount?: Maybe<Scalars['Boolean']>;
  mailingAddress?: Maybe<Address>;
  mainCustomerName: Scalars['String'];
  nextBill?: Maybe<NextBillInfo>;
  paperLessBillInfo?: Maybe<PaperLessBillInfoResponse>;
  paymentAlerts?: Maybe<PaymentAlerts>;
  paymentEligibility?: Maybe<PaymentEligibility>;
  paymentExtensionInfo?: Maybe<PaymentExtensionResponse>;
  paymentHistory?: Maybe<ViewPaymentHistoryDetailResponse>;
  peakTimeRebateGiveBack?: Maybe<PeakTimeRebateGiveBackDetails>;
  peaktimeRebate?: Maybe<PeakTimeRebateProgramStatus>;
  pendingDisconnect?: Maybe<PendingDisconnectStatus>;
  pgeEnergyTracker?: Maybe<PgeEnergyTrackerData>;
  preferredDueDate?: Maybe<PreferredDueDateDetails>;
  preferredDueDateInfo?: Maybe<PreferredDueDateInfoResponse>;
  premiseInfo?: Maybe<Array<Maybe<PremiseInfo>>>;
  reconnectQuoteDetails?: Maybe<ReconnectQuoteDetails>;
  relationType: Scalars['String'];
  renewableEnrollment?: Maybe<RenewableEnrollment>;
  /** @deprecated use Premise Details, which has SP/SA details included */
  serviceAddresses?: Maybe<Array<Maybe<ServiceAddress>>>;
  serviceConnectivity?: Maybe<ServiceConnectivity>;
  smartThermostatEnrollment?: Maybe<SmartThermostatEnrollmentEnrollment>;
  timeOfDayInfo?: Maybe<TimeOfDayInfo>;
  tpa?: Maybe<Tpa_AccountDetail>;
  viewBillAverageTemperature?: Maybe<ViewBillAverageTemperature>;
  viewBillMonthUsage?: Maybe<ViewBillMonthlyUsage>;
};


export type AccountDetailCommPreferencesArgs = {
  commPreferencesParams: CommPreferencesParams;
};


export type AccountDetailPaymentHistoryArgs = {
  paymentHistoryParams?: InputMaybe<PaymentHistoryParams>;
};

export type AccountDetailList = {
  __typename?: 'AccountDetailList';
  accounts?: Maybe<Array<Maybe<AccountDetail>>>;
  hasInactiveAccounts?: Maybe<Scalars['Boolean']>;
  timestamp: Scalars['DateTimeCustom'];
  totalCount: Scalars['Int'];
};

export type AccountDetailListParams = {
  accountStatus?: InputMaybe<AccountStatusType>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterParams>;
  groupId?: InputMaybe<Scalars['ID']>;
  paging?: InputMaybe<PagingParams>;
  sort?: InputMaybe<SortParams>;
  userAccountRelFilter?: InputMaybe<Array<InputMaybe<AccountRelationship>>>;
};

export type AccountDetailParams = {
  accountNumberList: Array<AccountParams>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterParams>;
  paging?: InputMaybe<PagingParams>;
  sort?: InputMaybe<SortParams>;
};

export type AccountDetails = {
  __typename?: 'AccountDetails';
  accountStatus: SaStatus;
  accountType?: Maybe<AccountType>;
  statusDate?: Maybe<Scalars['DateTimeCustom']>;
};

export type AccountDetailsInput = {
  accountNumber: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  groupId: Scalars['String'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
};

export type AccountDetailsRequest = {
  description?: InputMaybe<Scalars['String']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};

export type AccountDetailsResponse = {
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  coCustomerNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mailingAddress?: InputMaybe<ServiceAddressResponse>;
  mainCustomerName?: InputMaybe<Scalars['String']>;
  relationType?: InputMaybe<Scalars['String']>;
  serviceAddresses?: InputMaybe<Array<InputMaybe<ServiceAddressResponse>>>;
};

/**
 * If person is returning customer and no active sa. Client will not pass account id.
 * If at least 1 of person (mutiple person) is flaged is bad debt / fraud, service will return bad debt / fraud = true
 */
export type AccountEligibilityParams = {
  acctId?: InputMaybe<Scalars['String']>;
  perId: Scalars['String'];
  premId?: InputMaybe<Scalars['String']>;
};

export type AccountEligibilityResponse = {
  __typename?: 'AccountEligibilityResponse';
  isBadDebt?: Maybe<Scalars['Boolean']>;
  isFraud?: Maybe<Scalars['Boolean']>;
};

export type AccountFieldActivity = {
  __typename?: 'AccountFieldActivity';
  disconnected?: Maybe<Scalars['Boolean']>;
  pendingReconnect?: Maybe<Scalars['Boolean']>;
  scheduledDisconnectDate?: Maybe<Scalars['DateTimeISO']>;
};

export type AccountGroup = {
  __typename?: 'AccountGroup';
  accountNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupCode?: Maybe<Scalars['String']>;
  groupId: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  groupType?: Maybe<GroupType>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  numberOfAccounts?: Maybe<Scalars['Int']>;
};

export type AccountGroupParams = {
  encryptedPersonId: Scalars['String'];
  encryptedaccountNumber: Scalars['String'];
};

export type AccountGroupResponse = ResponseBase & {
  __typename?: 'AccountGroupResponse';
  code?: Maybe<Scalars['Int']>;
  errorReason?: Maybe<ErrorReason>;
  group?: Maybe<Group>;
  groupStatus: GroupStatus;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export const enum AccountGroupVerificationType {
  DateOfBirth = 'DateOfBirth',
  EmployeeIdentificationNumber = 'EmployeeIdentificationNumber',
  PrimaryNotificationPhone = 'PrimaryNotificationPhone'
};

export type AccountInfoParams = {
  filter?: InputMaybe<FilterParams>;
  groupId?: InputMaybe<Scalars['String']>;
  paging?: InputMaybe<PagingParams>;
  sort?: InputMaybe<SortParams>;
};

export const enum AccountLookUpType {
  Account = 'ACCOUNT',
  Phone = 'PHONE'
};

export type AccountLookupDetails = {
  __typename?: 'AccountLookupDetails';
  accountID?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  customerClass?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  listOfPersonIDs?: Maybe<Array<Maybe<Person>>>;
  personCount?: Maybe<Scalars['String']>;
  premiseCount?: Maybe<Scalars['String']>;
  premiseDetails?: Maybe<Array<Maybe<PremiseDetails>>>;
  state?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AccountMeta = {
  __typename?: 'AccountMeta';
  hasInactiveAccounts?: Maybe<Scalars['Boolean']>;
  totalAccounts: Scalars['Int'];
};

export type AccountNumberParams = {
  accountNumber: Scalars['String'];
};

export type AccountParams = {
  accountNumber: Scalars['String'];
};

export type AccountPerson = {
  __typename?: 'AccountPerson';
  encryptedPersonId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  personRelationshipType?: Maybe<RelationshipType>;
};

export type AccountPersonInput = {
  changeReason?: InputMaybe<Scalars['String']>;
  employerName?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  personRelationshipType?: InputMaybe<RelationshipType>;
};

export const enum AccountRelationship {
  /** Administrative/Assistant */
  Administ = 'ADMINIST',
  /** SPO Assignee */
  Assignee = 'ASSIGNEE',
  /** Authorized User */
  Authusr = 'AUTHUSR',
  /** Auto Associated */
  Auto = 'AUTO',
  /** Co-Applicant */
  Coapp = 'COAPP',
  /** Web Self Service User */
  Cssuser = 'CSSUSER',
  /** Employee */
  Empl = 'EMPL',
  /** Sustainability/EE */
  Energy = 'ENERGY',
  /** Manager/Executive */
  Exec = 'EXEC',
  /** Facilities/Maintenance */
  Facility = 'FACILITY',
  /** Financial/Bookkeeper */
  Fin = 'FIN',
  /** Main Customer */
  Main = 'MAIN',
  /** Owner/CEO */
  Ownr = 'OWNR',
  /** Property Manager */
  Propmgr = 'PROPMGR',
  /** Responsible Party */
  Resp = 'RESP',
  /** Scheduling ESS */
  Schdess = 'SCHDESS'
};

export type AccountRemoveAccountsResponse = ResponseBase & {
  __typename?: 'AccountRemoveAccountsResponse';
  code?: Maybe<Scalars['Int']>;
  errorReason?: Maybe<ErrorReason>;
  groupStatus: GroupStatus;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AccountResponse = {
  accounts?: InputMaybe<Array<InputMaybe<AccountDetailsResponse>>>;
  personId?: InputMaybe<Scalars['String']>;
  personName?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

export const enum AccountSort {
  Accountnumber = 'ACCOUNTNUMBER',
  Default = 'DEFAULT',
  Duedate = 'DUEDATE',
  Nickname = 'NICKNAME',
  Serviceaddress = 'SERVICEADDRESS'
};

export const enum AccountStatusType {
  Active = 'ACTIVE',
  All = 'ALL',
  Inactive = 'INACTIVE'
};

export type AccountSummary = {
  __typename?: 'AccountSummary';
  accountID: Scalars['ID'];
  premises?: Maybe<Array<Maybe<PremiseSummary>>>;
};

export const enum AccountType {
  Com = 'COM',
  Eba = 'EBA',
  Non = 'NON',
  Res = 'RES',
  Ret = 'RET',
  Self = 'SELF',
  State = 'STATE',
  Statecom = 'STATECOM',
  Stateres = 'STATERES',
  Sum = 'SUM',
  Summary = 'SUMMARY'
};

export type AddAccountGroupInput = {
  createdDate?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  groupName?: InputMaybe<Scalars['String']>;
  groupType?: InputMaybe<GroupType>;
  id?: InputMaybe<Scalars['String']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  numberOfAccounts?: InputMaybe<Scalars['Int']>;
};

export type AddDeviceDetailInput = {
  accountNumber: Scalars['String'];
  deviceId: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};

export type AddDeviceDetailResponse = {
  __typename?: 'AddDeviceDetailResponse';
  encryptedContactId?: Maybe<Scalars['String']>;
  errorReason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type AddGroupRequest = {
  groupCode?: InputMaybe<Scalars['String']>;
  groupName?: InputMaybe<Scalars['String']>;
  groupType?: InputMaybe<GroupTypeRequest>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type AddGroupResponse = {
  group_id?: InputMaybe<Scalars['String']>;
};

export type AddRemoveAccountRequest = {
  accountNumber?: InputMaybe<Scalars['String']>;
  action?: InputMaybe<AddRemoveOperation>;
  businessPersonId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
};

export type AddRemoveAccountsInput = {
  accounts: Array<InputMaybe<Scalars['String']>>;
  action: AddRemoveOperation;
  groupId: Scalars['String'];
  groupName: Scalars['String'];
};

export type AddRemoveAccountsRequest = {
  accounts?: InputMaybe<Array<InputMaybe<AddRemoveAccountRequest>>>;
};

export type AddRemoveAccountsResponse = {
  result?: InputMaybe<Scalars['Boolean']>;
};

export const enum AddRemoveOperation {
  Add = 'ADD',
  Remove = 'REMOVE'
};

export type AdditionalInfo = {
  __typename?: 'AdditionalInfo';
  alternatePhoneInfo?: Maybe<AlternatePhone>;
  dateOfBirth?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  employmentInformation?: Maybe<EmploymentInfo>;
  federalInformation?: Maybe<FederalInfo>;
  hasExistingPrimaryIdentification?: Maybe<Scalars['Boolean']>;
  hasFederalInfo?: Maybe<Scalars['Boolean']>;
  hasSocialSecurityInfo?: Maybe<Scalars['Boolean']>;
  hasStateInfo?: Maybe<Scalars['Boolean']>;
  mailingAddress?: Maybe<Address>;
  mailingAndServiceAddressesSame?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  previousAddress?: Maybe<Address>;
  primaryPhone?: Maybe<Scalars['String']>;
  registerForOnlineAccess?: Maybe<Scalars['Boolean']>;
  socialSecurityNumber?: Maybe<Scalars['String']>;
  stateInformation?: Maybe<StateInfo>;
};

export type AdditionalInfoInput = {
  alternatePhoneInfo?: InputMaybe<AlternatePhoneInput>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  employmentInformation?: InputMaybe<EmploymentInfoInput>;
  federalInformation?: InputMaybe<FederalInfoInput>;
  hasExistingPrimaryIdentification?: InputMaybe<Scalars['Boolean']>;
  hasFederalInfo?: InputMaybe<Scalars['Boolean']>;
  hasSocialSecurityInfo?: InputMaybe<Scalars['Boolean']>;
  hasStateInfo?: InputMaybe<Scalars['Boolean']>;
  mailingAddress?: InputMaybe<AddressInput>;
  mailingAndServiceAddressesSame?: InputMaybe<Scalars['Boolean']>;
  oldEmailAddress?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  previousAddress?: InputMaybe<AddressInput>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  registerForOnlineAccess?: InputMaybe<Scalars['Boolean']>;
  socialSecurityNumber?: InputMaybe<Scalars['String']>;
  stateInformation?: InputMaybe<StateInfoInput>;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postal?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postal?: InputMaybe<Scalars['String']>;
  qasVerified?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
};

export type AlertDetailInfo = {
  description: Scalars['String'];
  emailContactId?: InputMaybe<Scalars['String']>;
  emailPrefId?: InputMaybe<Scalars['String']>;
  isEmail: Scalars['Boolean'];
  isText: Scalars['Boolean'];
  originalValue?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Float']>;
  textContactId?: InputMaybe<Scalars['String']>;
  textPrefId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type AlertReponse = {
  __typename?: 'AlertReponse';
  alerts: Array<Maybe<CommPreference>>;
  prefLanguage: Scalars['String'];
};

export const enum AlertType {
  Email = 'EMAIL',
  Sms = 'SMS'
};

export type AlternatePhone = {
  __typename?: 'AlternatePhone';
  alternatePhoneNumber?: Maybe<Scalars['String']>;
  alternatePhoneText?: Maybe<Scalars['String']>;
  alternatePhoneType?: Maybe<PhoneType>;
};

export type AlternatePhoneInfoInput = {
  alternatePhoneExt?: InputMaybe<Scalars['String']>;
  alternatePhoneNumber?: InputMaybe<Scalars['String']>;
  alternatePhoneType?: InputMaybe<PhoneType>;
};

export type AlternatePhoneInput = {
  alternatePhoneNumber?: InputMaybe<Scalars['String']>;
  alternatePhoneText?: InputMaybe<Scalars['String']>;
  alternatePhoneType?: InputMaybe<PhoneType>;
};

export type ApplicantIdInfo = {
  idType: PersonPrimaryIdType;
  idValue: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
};

export const enum ApplicantRelationship {
  Domesticpartner = 'DOMESTICPARTNER',
  None = 'NONE',
  Other = 'OTHER',
  Roommate = 'ROOMMATE',
  Spouse = 'SPOUSE'
};

export type ApplicantResponse = {
  __typename?: 'ApplicantResponse';
  perId?: Maybe<Scalars['String']>;
};

/** Applicant verification flag for adding Co-Customer */
export const enum ApplicantVerificationEligibility {
  ExperianCreditCheckError = 'EXPERIAN_CREDIT_CHECK_ERROR',
  ExperianCreditCheckFailed = 'EXPERIAN_CREDIT_CHECK_FAILED',
  ExperianCreditCheckPass = 'EXPERIAN_CREDIT_CHECK_PASS',
  ExperianCreditCheckPersonNotFound = 'EXPERIAN_CREDIT_CHECK_PERSON_NOT_FOUND',
  IdVerificationFailed = 'ID_VERIFICATION_FAILED',
  IdVerificationPass = 'ID_VERIFICATION_PASS',
  None = 'NONE'
};

export type ApplicantWithEligibilityResponse = {
  __typename?: 'ApplicantWithEligibilityResponse';
  perId?: Maybe<Scalars['String']>;
  verificationFlag: ApplicantVerificationEligibility;
};

/** Applicant Auth User request */
export type AuthUserApplicant = {
  acctId: Scalars['String'];
  additionalInfo: AuthUserApplicantAdditionalInfo;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mainPerId?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
};

export type AuthUserApplicantAdditionalInfo = {
  altPhone?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  idInfo?: InputMaybe<ApplicantIdInfo>;
  mobPhone?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  primaryPhoneIsMobile?: InputMaybe<Scalars['Boolean']>;
  relationshipType?: InputMaybe<ApplicantRelationship>;
};

export type AutoPay = {
  __typename?: 'AutoPay';
  isEnrolled?: Maybe<Scalars['Boolean']>;
};

export type AutoPayAccounts = {
  __typename?: 'AutoPayAccounts';
  accountNumber: Scalars['String'];
  description: Scalars['String'];
  premiseInfo?: Maybe<Array<Maybe<PremiseInfo>>>;
};

export type AutoPayCancelIvaInput = {
  accountNumber: Scalars['String'];
  personId: Scalars['String'];
  /** Paymentus referenceId / Conf number */
  referenceId: Scalars['String'];
  serviceAddressLine1?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type AutoPayCancelInput = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type AutoPayEnrollDetails = {
  __typename?: 'AutoPayEnrollDetails';
  referenceNumber: Scalars['String'];
  tokenId: Scalars['String'];
  type: Scalars['String'];
};

export type AutoPayEnrollInput = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  tokenId: Scalars['String'];
};

export type AutoPayEnrollStatus = {
  __typename?: 'AutoPayEnrollStatus';
  isEnrolled: Scalars['Boolean'];
  isSameDayEnrolled: Scalars['Boolean'];
};

export const enum AutoPayErrorReason {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  NotEnrolled = 'NotEnrolled',
  Unknown = 'Unknown'
};

export type AutoPayInfo = {
  __typename?: 'AutoPayInfo';
  availablePaymentMethods?: Maybe<Array<PaymentCategory>>;
  enrollDetails?: Maybe<AutoPayEnrollDetails>;
  isEnrolled: Scalars['Boolean'];
  isSameDayEnrolled: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTimeCustom']>;
};

export type AutoPaymentDownloadPdfRequest = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountRoutingNumber?: InputMaybe<Scalars['String']>;
  confirmationNumber?: InputMaybe<Scalars['String']>;
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  serviceAddress?: InputMaybe<Scalars['String']>;
};

export type AutoPaymentPdfResponse = {
  __typename?: 'AutoPaymentPdfResponse';
  pdf?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type BillInfo = {
  __typename?: 'BillInfo';
  amountDue?: Maybe<Scalars['Float']>;
  /** @deprecated balanceDueDate is not resolved and used */
  balanceDueDate?: Maybe<Scalars['String']>;
  billDetails?: Maybe<ViewBillDetails>;
  dueDate?: Maybe<Scalars['String']>;
  enrolledInTPA?: Maybe<Scalars['Boolean']>;
  futurePaymentDate?: Maybe<Scalars['String']>;
  isAccountPayable?: Maybe<Scalars['Boolean']>;
  isNewAccount?: Maybe<Scalars['Boolean']>;
  lastPaymentAmount?: Maybe<Scalars['Float']>;
  lastPaymentDate?: Maybe<Scalars['String']>;
  multipleFuturePaymentsScheduled?: Maybe<Scalars['Boolean']>;
  oneTimeFuturePaymentScheduled?: Maybe<Scalars['Boolean']>;
};

export type BillNotFoundDownloadPdf = {
  __typename?: 'BillNotFoundDownloadPdf';
  pdf?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type BillPayInfo = {
  __typename?: 'BillPayInfo';
  /** @deprecated amountDue name is confusing in this context, please use currentBillAmountDue instead */
  amountDue?: Maybe<Scalars['Float']>;
  currentTotalBalanceAmount?: Maybe<Scalars['Float']>;
  dueDate?: Maybe<Scalars['DateTimeISO']>;
  enrolledInTPA?: Maybe<Scalars['Boolean']>;
  futurePaymentDate?: Maybe<Scalars['DateTimeISO']>;
  isAccountPayable?: Maybe<Scalars['Boolean']>;
  isNewAccount?: Maybe<Scalars['Boolean']>;
  lastPaymentAmount?: Maybe<Scalars['Float']>;
  lastPaymentDate?: Maybe<Scalars['DateTimeISO']>;
  multipleFuturePaymentsScheduled?: Maybe<Scalars['Boolean']>;
  oneTimeFuturePaymentScheduled?: Maybe<Scalars['Boolean']>;
};

export type BillableAccountInfo = {
  __typename?: 'BillableAccountInfo';
  isAccountBillable?: Maybe<Scalars['Boolean']>;
  isAccountOnFlexPricePlan?: Maybe<Scalars['Boolean']>;
  isNonSpo?: Maybe<Scalars['Boolean']>;
  isServiceDesignationElectric?: Maybe<Scalars['Boolean']>;
};

export const enum BillingAndPaymentDelimiterType {
  Csv = 'Csv',
  Pipe = 'Pipe',
  Unknown = 'Unknown'
};

export type BillingAndPaymentHistoryDetailsDownloadResponse = {
  __typename?: 'BillingAndPaymentHistoryDetailsDownloadResponse';
  byteString?: Maybe<Scalars['String']>;
};

export type BillingAndPaymentHistoryDetailsInput = {
  delimeterType?: InputMaybe<BillingAndPaymentDelimiterType>;
  encryptedAccountNumbers: Array<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTimeCustom']>;
  filterByDueDate?: InputMaybe<Scalars['Boolean']>;
  reportType?: InputMaybe<BillingAndPaymentReportType>;
  sortedByDueDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTimeCustom']>;
};

export type BillingAndPaymentHistoryDetailsResponse = {
  __typename?: 'BillingAndPaymentHistoryDetailsResponse';
  accountNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingSummaries?: Maybe<Array<Maybe<BillingAndPaymentHistorySummary>>>;
  totalAccounts?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type BillingAndPaymentHistorySummary = {
  __typename?: 'BillingAndPaymentHistorySummary';
  accountNumber?: Maybe<Scalars['String']>;
  amountDue?: Maybe<Scalars['Float']>;
  billDate?: Maybe<Scalars['DateTimeCustom']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<BillingAndPaymentSummaryDetail>>>;
  dueDate?: Maybe<Scalars['DateTimeCustom']>;
  encryptedAccountNumber?: Maybe<Scalars['String']>;
  encryptedBillingId?: Maybe<Scalars['String']>;
  encryptedSummaryBillId?: Maybe<Scalars['String']>;
  isSummaryBillAccount?: Maybe<Scalars['Boolean']>;
  totalKwh?: Maybe<Scalars['Float']>;
};

export const enum BillingAndPaymentReportType {
  Accounting = 'Accounting',
  LongForm = 'LongForm',
  ShortForm = 'ShortForm',
  Unknown = 'Unknown'
};

export type BillingAndPaymentSummaryDetail = {
  __typename?: 'BillingAndPaymentSummaryDetail';
  amount?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  serviceAddress?: Maybe<Scalars['String']>;
};

export type BillingHistory = {
  __typename?: 'BillingHistory';
  billAmount: Scalars['Float'];
  billDate: Scalars['DateTimeISO'];
  billingPeriodEndDate: Scalars['DateTimeISO'];
  billingPeriodStartDate: Scalars['DateTimeISO'];
  usageInKwh: Scalars['Float'];
};

export type BillingPeriod = {
  __typename?: 'BillingPeriod';
  averageTemperature?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  totalCost?: Maybe<Scalars['Int']>;
  totalKwh?: Maybe<Scalars['Int']>;
};

export type BusinessInfo = {
  businessName?: InputMaybe<Scalars['String']>;
  perId?: InputMaybe<Scalars['String']>;
};

export type BusinessPersonRequest = {
  accountNumberList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  businessPersonId?: InputMaybe<Scalars['String']>;
};

export type CifDetails = {
  __typename?: 'CIFDetails';
  Segment: Scalars['String'];
  accountNumber: Scalars['String'];
  adChannelBlock?: Maybe<Scalars['Boolean']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  amountDue: Scalars['Float'];
  amountPastDue?: Maybe<Scalars['Float']>;
  authenticationToken1?: Maybe<Scalars['String']>;
  authenticationToken2?: Maybe<Scalars['String']>;
  authenticationToken3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dayExt?: Maybe<Scalars['String']>;
  dayPhone?: Maybe<Scalars['String']>;
  daysPastDue?: Maybe<Scalars['Int']>;
  delinquent?: Maybe<Scalars['Boolean']>;
  dueDate: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  eveningExt?: Maybe<Scalars['String']>;
  eveningPhone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  ivrChannelBlock?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['Float']>;
  maxPostingDate?: Maybe<Scalars['String']>;
  maxPostingDate2?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  minAmount?: Maybe<Scalars['Float']>;
  mobileChannelBlock?: Maybe<Scalars['Boolean']>;
  paperSuppressed?: Maybe<Scalars['Boolean']>;
  paymentType?: Maybe<Scalars['String']>;
  securityToken?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  webChannelBlock?: Maybe<Scalars['Boolean']>;
  zipcode: Scalars['String'];
};

export type CifDetailsParams = {
  accountNumber: Scalars['String'];
};

export type CancelAutoPay = {
  __typename?: 'CancelAutoPay';
  autoPay?: Maybe<AutoPayInfo>;
  errorReason?: Maybe<AutoPayErrorReason>;
  isSuccess: Scalars['Boolean'];
};

export const enum CardType {
  Amex = 'AMEX',
  Disc = 'DISC',
  Mc = 'MC',
  Visa = 'VISA'
};

export const enum ChangeEmailReason {
  PersonNotRegistered = 'PersonNotRegistered',
  Success = 'Success',
  WebUserNameExists = 'WebUserNameExists'
};

export type ChangeEmailRequest = {
  encryptedPersonId: Scalars['String'];
  newEmail: Scalars['String'];
};

export type ChangeEmailResponse = {
  __typename?: 'ChangeEmailResponse';
  isSuccess?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<ChangeEmailReason>;
};

export type ChangePasswordInfo = {
  __typename?: 'ChangePasswordInfo';
  hasPerson?: Maybe<Scalars['Boolean']>;
  sameAsOldPassword?: Maybe<Scalars['Boolean']>;
};

export type ChangePasswordInfoRequest = {
  password?: InputMaybe<Scalars['String']>;
};

export type ChangePasswordInfoResponse = {
  __typename?: 'ChangePasswordInfoResponse';
  hasPerson?: Maybe<HasPersonIdResponse>;
  sameAsOldPassword?: Maybe<SameAsOldPasswordResponse>;
};

export type ChangePasswordUpdateRequest = {
  encryptedPersonId: Scalars['String'];
  password: Scalars['String'];
};

export type ChangePasswordUpdateResponse = {
  __typename?: 'ChangePasswordUpdateResponse';
  signInToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ChangePinUpdateRequest = {
  encryptedPersonId: Scalars['String'];
  newPinCode: Scalars['String'];
};

export type ChangePinUpdateResponse = {
  __typename?: 'ChangePinUpdateResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export const enum Channel {
  Csweb = 'CSWEB',
  Iva = 'IVA',
  Ivr = 'IVR',
  Mobapp = 'MOBAPP'
};

export type Characteristic = {
  action: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type ChargeSummary = {
  __typename?: 'ChargeSummary';
  /** @deprecated Replaced by field with less ambiguous name */
  amountDue?: Maybe<Scalars['Float']>;
  balanceForward?: Maybe<Scalars['Float']>;
  billedAmountLessPayments?: Maybe<Scalars['Float']>;
  dueDate?: Maybe<Scalars['DateTimeISO']>;
  pastDueAmount?: Maybe<Scalars['Float']>;
  paymentAdjustments?: Maybe<Scalars['Float']>;
};

/** Applicant Co-Customer request */
export type CoApplicant = {
  acctId: Scalars['String'];
  additionalInfo: CoApplicantAdditionalInfo;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mainPerId?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  perId?: InputMaybe<Scalars['String']>;
};

export type CoApplicantAdditionalInfo = {
  altPhone?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  emailAddress: Scalars['String'];
  idInfo: ApplicantIdInfo;
  mobPhone?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  primaryPhoneIsMobile?: InputMaybe<Scalars['Boolean']>;
  relationshipType?: InputMaybe<ApplicantRelationship>;
};

export type CoCustomerForAccountRequest = {
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type CoCustomerInfo = {
  __typename?: 'CoCustomerInfo';
  encryptedPersonId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  personalIdentificationType?: Maybe<PersonalIdentificationType>;
};

export const enum CocustomerRelationshipType {
  DomesticPartner = 'DomesticPartner',
  None = 'None',
  Other = 'Other',
  Roommate = 'Roommate',
  Spouse = 'Spouse'
};

export type CollectionActivity = {
  __typename?: 'CollectionActivity';
  FifteenDayNoticeServed: Scalars['Boolean'];
  NoticeDueDate?: Maybe<Scalars['DateTimeCustom']>;
};

export type CommPreference = {
  __typename?: 'CommPreference';
  deliveryTypeDetails?: Maybe<Array<Maybe<DeliveryTypeDetail>>>;
  notificationOptions?: Maybe<NotificationOption>;
  notificationType: NotificationType;
  notificationTypeDescription: Scalars['String'];
};

export type CommPreferencesParams = {
  deviceId?: InputMaybe<Scalars['String']>;
  notificationFor: NotificationFor;
  notificationTypes?: InputMaybe<Array<NotificationType>>;
};

export type CommercialAccountValidation = {
  __typename?: 'CommercialAccountValidation';
  isValidAccountNumber: Scalars['Boolean'];
  isValidBusinessAccount: Scalars['Boolean'];
  shouldUseBookkeeperRegistration: Scalars['Boolean'];
};

export type CommercialContactInfoInput = {
  bookKeeperName?: InputMaybe<Scalars['String']>;
  bookKeeperPhone?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  bookKeeperPhoneExt?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  primaryPhone?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  primaryPhoneExt?: InputMaybe<Scalars['String']>;
};

export type CommercialRegistrationRequest = {
  AccountNumber?: InputMaybe<Scalars['String']>;
  EmailAddress?: InputMaybe<Scalars['String']>;
  FirstName?: InputMaybe<Scalars['String']>;
  IsCoApplicantOfCommercialAccount?: InputMaybe<Scalars['Boolean']>;
  LastName?: InputMaybe<Scalars['String']>;
  MiddleName?: InputMaybe<Scalars['String']>;
  Password?: InputMaybe<Scalars['String']>;
  VerificationType?: InputMaybe<VerificationType>;
  VerificationValue?: InputMaybe<Scalars['String']>;
};

export type ContactDetail = {
  __typename?: 'ContactDetail';
  contactId?: Maybe<Scalars['ID']>;
  encryptedContactId: Scalars['String'];
  encryptedPreferenceId?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isSelected: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
};

export type ContactInfo = {
  companyName?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export const enum ContactInfoConfirmationOption {
  InfoCorrect = 'INFO_CORRECT',
  UpdateInfo = 'UPDATE_INFO'
};

export type ContactInfoConfirmationRequest = {
  contactInfoConfirmationOption: ContactInfoConfirmationOption;
};

export type ContactInfoConfirmationResponse = {
  __typename?: 'ContactInfoConfirmationResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ContactInput = {
  encryptedContactId: Scalars['String'];
  encryptedPreferenceId?: InputMaybe<Scalars['String']>;
  isSelected: Scalars['Boolean'];
  value: Scalars['String'];
};

export type ContactlogPersonLeavingBehindRequest = {
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type CreateAccountGroupInput = {
  accountNumber: Scalars['String'];
  businessName: Scalars['String'];
  verificationType?: InputMaybe<AccountGroupVerificationType>;
  verificationValue: Scalars['String'];
};

export type CreateGroupAccountsInput = {
  accountNumber: Scalars['String'];
};

export type CreateGroupInput = {
  accounts: Array<CreateGroupAccountsInput>;
  groupName: Scalars['String'];
};

export type CurrentBillDetailsResponse = {
  accountNumber?: InputMaybe<Scalars['String']>;
  amountDue?: InputMaybe<Scalars['Float']>;
  dueDate?: InputMaybe<Scalars['String']>;
  enrolledInTPA?: InputMaybe<Scalars['Boolean']>;
  futurePaymentDate?: InputMaybe<Scalars['String']>;
  isAccountPayable?: InputMaybe<Scalars['Boolean']>;
  isNewAccount?: InputMaybe<Scalars['Boolean']>;
  lastPaymentAmount?: InputMaybe<Scalars['Float']>;
  lastPaymentDate?: InputMaybe<Scalars['String']>;
  multipleFuturePaymentsScheduled?: InputMaybe<Scalars['Boolean']>;
  oneTimeFuturePaymentScheduled?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentCharges = {
  __typename?: 'CurrentCharges';
  amountDue: Scalars['Float'];
  dueDate?: Maybe<Scalars['DateTimeCustom']>;
};

export type CustomGroupInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  checked?: InputMaybe<Scalars['Boolean']>;
  createdDate?: InputMaybe<Scalars['String']>;
  groupCode?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  groupName?: InputMaybe<Scalars['String']>;
  groupType?: InputMaybe<GroupType>;
  id?: InputMaybe<Scalars['String']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  nickname?: InputMaybe<Scalars['String']>;
  numberOfAccounts?: InputMaybe<Scalars['Int']>;
};

export type CustomerContactInput = {
  accountNumber: Scalars['String'];
  characteristics?: InputMaybe<Array<InputMaybe<Characteristic>>>;
  contactClass: Scalars['String'];
  contactType: Scalars['String'];
  cssUser?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  longDescription: Scalars['String'];
  shouldPrintLetter: Scalars['Boolean'];
};

/** This type is deprecated. */
export type CustomerContactResponse = {
  __typename?: 'CustomerContactResponse';
  success: Scalars['Boolean'];
};

export type CustomerInfoDetail = {
  additionalInfo: StopAdditionalInfo;
  emailAddress: Scalars['String'];
  perId: Scalars['String'];
};

export type CustomerInfoInput = {
  additionalInformation?: InputMaybe<AdditionalInfoInput>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  personalIdentificationType?: InputMaybe<PersonalIdentificationType>;
  spouseOrRegisteredDomesticPartnerType?: InputMaybe<CocustomerRelationshipType>;
};

export type CustomerLanguagePrefRequest = {
  email: Scalars['String'];
  prefLang: Scalars['String'];
};

export type CustomerLanguagePrefResponse = {
  __typename?: 'CustomerLanguagePrefResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type CustomerPreferencesAccountResponse = {
  accountNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerPreferencesResponse = {
  accounts?: InputMaybe<Array<InputMaybe<CustomerPreferencesAccountResponse>>>;
  contactInfoConfirmationOption?: InputMaybe<ContactInfoConfirmationOption>;
  hasGroups?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  lastConfirmedDate?: InputMaybe<Scalars['LocalDate']>;
  prefLanguage?: InputMaybe<Scalars['String']>;
};

export type CustomerServiceDisconnectedParams = {
  addressLine1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  postal?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type CustomerServiceDisconnectedResponse = {
  __typename?: 'CustomerServiceDisconnectedResponse';
  isServiceDisconnected: Scalars['Boolean'];
};

export type DefaultAccountInfo = {
  __typename?: 'DefaultAccountInfo';
  accountNumber?: Maybe<Scalars['String']>;
  encryptedAccountNumber?: Maybe<Scalars['String']>;
  encryptedPersonId?: Maybe<Scalars['String']>;
};

export type DeleteGroupInput = {
  groups: Array<DeleteGroupListInput>;
};

export type DeleteGroupListInput = {
  groupId: Scalars['String'];
};

export type DeleteGroupResponse = ResponseBase & {
  __typename?: 'DeleteGroupResponse';
  code?: Maybe<Scalars['Int']>;
  errorReason?: Maybe<ErrorReason>;
  groupStatus: GroupStatus;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePersonDetailsRequest = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mainPersonEmailId?: InputMaybe<Scalars['String']>;
  relationshipType: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type DeletePersonDetailsResponse = {
  __typename?: 'DeletePersonDetailsResponse';
  success: Scalars['Boolean'];
};

export type DeleteProfileError = {
  __typename?: 'DeleteProfileError';
  error?: Maybe<Array<Maybe<KeyValue>>>;
};

export type DeleteProfileRequest = {
  ownerId: Scalars['String'];
  token: Scalars['String'];
};

export type DeleteProfileResponse = {
  __typename?: 'DeleteProfileResponse';
  profileResponse?: Maybe<ProfileResponse>;
};

export const enum DeliveryType {
  Email = 'EMAIL',
  Push = 'PUSH',
  Sms = 'SMS'
};

export type DeliveryTypeDetail = {
  __typename?: 'DeliveryTypeDetail';
  contactDetails?: Maybe<Array<Maybe<ContactDetail>>>;
  deliveryType: DeliveryType;
  deliveryTypeDescription: Scalars['String'];
};

export type DeliveryTypeInput = {
  contacts: Array<ContactInput>;
  deliveryType: DeliveryType;
};

export type Description = {
  __typename?: 'Description';
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export const enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
};

export const enum ElectionWindow {
  Closed = 'CLOSED',
  Feb = 'FEB',
  Nov = 'NOV',
  Unknown = 'UNKNOWN'
};

export type ElectionWindowStatus = {
  __typename?: 'ElectionWindowStatus';
  electionWindow?: Maybe<ElectionWindow>;
  periodEffectiveDate?: Maybe<Scalars['String']>;
  periodEndDate?: Maybe<Scalars['String']>;
};

export type EmploymentInfo = {
  __typename?: 'EmploymentInfo';
  employerName?: Maybe<Scalars['String']>;
  employmentOption?: Maybe<EmploymentType>;
};

export type EmploymentInfoInput = {
  employerName?: InputMaybe<Scalars['String']>;
  employmentOption?: InputMaybe<EmploymentType>;
};

export const enum EmploymentType {
  Employed = 'Employed',
  None = 'None',
  Retired = 'Retired',
  SelfEmployed = 'SelfEmployed',
  Unemployed = 'Unemployed'
};

export type EnergyTrackerData = {
  __typename?: 'EnergyTrackerData';
  details?: Maybe<EnergyTrackerDetails>;
  detailsAvailable: Scalars['Boolean'];
  encryptedIdentifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasMoreThan15DaysOfData?: Maybe<Scalars['Boolean']>;
  serviceProvider?: Maybe<EnergyTrackerServiceProvider>;
  showEnergyTracker?: Maybe<Scalars['Boolean']>;
};

export type EnergyTrackerDataParams = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
};

export type EnergyTrackerDetails = {
  __typename?: 'EnergyTrackerDetails';
  billToDateAmount?: Maybe<Scalars['Float']>;
  billingCycleDay?: Maybe<Scalars['Int']>;
  billingProgress?: Maybe<Scalars['Int']>;
  lastReadDate?: Maybe<Scalars['String']>;
  maxProjectedAmount?: Maybe<Scalars['Float']>;
  minProjectedAmount?: Maybe<Scalars['Float']>;
  numberOfBillingDays?: Maybe<Scalars['Int']>;
  projectedAmount?: Maybe<Scalars['Float']>;
};

export type EnergyTrackerInfo = {
  __typename?: 'EnergyTrackerInfo';
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceProvider?: Maybe<EnergyTrackerServiceProvider>;
  showEnergyTracker?: Maybe<Scalars['Boolean']>;
};

export type EnergyTrackerLinkInput = {
  __typename?: 'EnergyTrackerLinkInput';
  LinkType?: Maybe<EnergyTrackerLinkInputLinkType>;
  ServiceProvider?: Maybe<EnergyTrackerLinkInputServiceProvider>;
};

export const enum EnergyTrackerLinkInputLinkType {
  CompareBills = 'CompareBills',
  MyUsage = 'MyUsage',
  None = 'None',
  WaysToSave = 'WaysToSave'
};

export const enum EnergyTrackerLinkInputServiceProvider {
  FirstFuel = 'FirstFuel',
  None = 'None',
  OPower = 'OPower'
};

export const enum EnergyTrackerServiceProvider {
  FirstFuel = 'FirstFuel',
  None = 'None',
  OPower = 'OPower'
};

export type EnergyTrackerUserInfo = {
  __typename?: 'EnergyTrackerUserInfo';
  mainAccountPersonId?: Maybe<Scalars['String']>;
  premiseIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  provider?: Maybe<EnergyTrackerServiceProvider>;
};

export type EnrollAutoPay = {
  __typename?: 'EnrollAutoPay';
  autoPay?: Maybe<AutoPayInfo>;
  confirmationId?: Maybe<Scalars['String']>;
  errorReason?: Maybe<AutoPayErrorReason>;
  isSuccess: Scalars['Boolean'];
};

export type EnrollMedicalCertProgramInput = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  loggedInUserName: Scalars['String'];
  premiseId?: InputMaybe<Scalars['String']>;
};

export type EnrollMedicalCertProgramResponse = {
  __typename?: 'EnrollMedicalCertProgramResponse';
  caseId?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
};

export type EnrollToPaymentExtensionInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  currentEnrolledInOption3?: InputMaybe<Scalars['Boolean']>;
  currentPayPlanId?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  payPlanType?: InputMaybe<PaymentExtensionOptionType>;
  paymentMode?: InputMaybe<Scalars['String']>;
  scheduleDate?: InputMaybe<Scalars['DateTimeISO']>;
  scheduledAmount?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
};

export type EnrolledInstallmentDetails = {
  __typename?: 'EnrolledInstallmentDetails';
  currentAccountBalance: Scalars['Float'];
  currentTpaMonthBalance?: Maybe<Scalars['Float']>;
  monthsMatrix: Array<Tpa_MonthsMatrix>;
  payOffAmount: Scalars['Float'];
  recurringChargeAmount: Scalars['Float'];
  totalMonths: Scalars['Int'];
};

export type EnrollmentAccounts = {
  __typename?: 'EnrollmentAccounts';
  eligibleForEnrollment?: Maybe<Scalars['Boolean']>;
  openEnrollmentAccounts?: Maybe<Array<Maybe<OpenEnrollmentAccount>>>;
  totalAccounts?: Maybe<Scalars['Int']>;
};

export type Enrollments = {
  __typename?: 'Enrollments';
  descriptions?: Maybe<Array<Maybe<Description>>>;
  enrolled?: Maybe<Scalars['Boolean']>;
};

export const enum ErrorReason {
  AccountAlreadyExistsInGroup = 'AccountAlreadyExistsInGroup',
  AccountGroupAccountNotFound = 'AccountGroupAccountNotFound',
  AccountGroupAlreadyExists = 'AccountGroupAlreadyExists',
  AccountGroupPersonNotFound = 'AccountGroupPersonNotFound',
  AccountGroupVerificationFailed = 'AccountGroupVerificationFailed',
  CannotRemoveDefaultAccount = 'CannotRemoveDefaultAccount',
  CustomGroupIsDefaultGroup = 'CustomGroupIsDefaultGroup',
  CustomerNotEligibleForManageAccountGroup = 'CustomerNotEligibleForManageAccountGroup',
  DefaultAccountNumber = 'DefaultAccountNumber',
  DefaultGroup = 'DefaultGroup',
  DelAutoAssociationTechnicalError = 'DelAutoAssociationTechnicalError',
  DelCustomGroupTechnicalError = 'DelCustomGroupTechnicalError',
  GroupCodeAlreadyExists = 'GroupCodeAlreadyExists',
  GroupNameAlreadyExists = 'GroupNameAlreadyExists',
  LoggedOnUserAutoGroup = 'LoggedOnUserAutoGroup',
  Unknown = 'Unknown'
};

export type ExperianCreditCheckCurrentAddress = {
  city: Scalars['String'];
  postalCode: Scalars['String'];
  stateOrProvince: Scalars['String'];
  streetAddress: Scalars['String'];
};

export type ExperianCreditCheckRequest = {
  currentAddress: ExperianCreditCheckCurrentAddress;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  ssn: Scalars['String'];
};

export type ExperianCreditCheckResponse = {
  __typename?: 'ExperianCreditCheckResponse';
  hasError: Scalars['Boolean'];
  isFraud: Scalars['Boolean'];
  notFound: Scalars['Boolean'];
};

export type ExpiryDate = {
  __typename?: 'ExpiryDate';
  month?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export const enum FederalIdentificationType {
  OregonTribalId = 'OregonTribalID',
  UsImmigration = 'USImmigration',
  UsMilitaryId = 'USMilitaryID',
  UsPassport = 'USPassport',
  UsStudentVisa = 'USStudentVisa',
  UsTemporaryVisa = 'USTemporaryVisa'
};

export type FederalInfo = {
  __typename?: 'FederalInfo';
  federalIDNumber?: Maybe<Scalars['String']>;
  federalIDType?: Maybe<FederalIdentificationType>;
};

export type FederalInfoInput = {
  federalIDNumber?: InputMaybe<Scalars['String']>;
  federalIDType?: InputMaybe<FederalIdentificationType>;
};

export type FieldActivity = {
  __typename?: 'FieldActivity';
  Disconnected: Scalars['Boolean'];
  PendingReconnect: Scalars['Boolean'];
  ScheduledDisconnectDate?: Maybe<Scalars['DateTimeISO']>;
};

export type FileKeyMap = {
  __typename?: 'FileKeyMap';
  pgeStoreKey?: Maybe<Scalars['String']>;
  tempKey?: Maybe<Scalars['String']>;
};

export type FileUploadGetAllUploadInput = {
  featureType: Scalars['String'];
  perId: Scalars['String'];
};

export type FileUploadGetAllUploadResponse = {
  __typename?: 'FileUploadGetAllUploadResponse';
  confirmationId?: Maybe<Scalars['String']>;
  createdDttm: Scalars['DateTimeISO'];
  description?: Maybe<Scalars['String']>;
  featureType?: Maybe<Scalars['String']>;
  fileReferenceName?: Maybe<Scalars['String']>;
  fileUploadStatus?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  hasOutage?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type FileUploadInfoResponse = {
  __typename?: 'FileUploadInfoResponse';
  channel?: Maybe<Scalars['String']>;
  createdDttm: Scalars['DateTimeISO'];
  description?: Maybe<Scalars['String']>;
  featureType?: Maybe<Scalars['String']>;
  fileReferenceName?: Maybe<Scalars['String']>;
  fileUploadStatus?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type FileUploadPgeStoreUrlInput = {
  confirmationId: Scalars['String'];
  perId: Scalars['String'];
};

export type FileUploadPgeStoreUrlResponse = {
  __typename?: 'FileUploadPgeStoreUrlResponse';
  downloadUrl: Scalars['String'];
  key: Scalars['String'];
};

export type FileUploadPreSignedUrlInput = {
  key: Scalars['String'];
};

export type FileUploadPreSignedUrlResponse = {
  __typename?: 'FileUploadPreSignedUrlResponse';
  key: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type FileUploadinfoInput = {
  keys: Array<InputMaybe<Scalars['String']>>;
};

export type FilterParams = {
  filterBy?: InputMaybe<Scalars['String']>;
  operator?: Operator;
};

export const enum ForgotPasswordErrorType {
  NoPersonFound = 'NoPersonFound',
  NoPhoneFound = 'NoPhoneFound',
  NoValidWebUser = 'NoValidWebUser',
  None = 'None',
  SpecialHandling = 'SpecialHandling'
};

export type ForgotPasswordRequest = {
  birthDate?: InputMaybe<Scalars['DateTimeCustom']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  identificationType?: InputMaybe<PersonalIdentificationType>;
  lastFourDigitEIN?: InputMaybe<Scalars['String']>;
  lastFourDigitITIN?: InputMaybe<Scalars['String']>;
  lastFourDigitSSN?: InputMaybe<Scalars['String']>;
  lastFourDigitStateOrDriverID?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pinCode?: InputMaybe<Scalars['String']>;
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  forgotPasswordError?: Maybe<ForgotPasswordErrorType>;
  signInToken?: Maybe<Scalars['String']>;
};

export const enum FromSidEnum {
  Cslongcode1 = 'CSLONGCODE1',
  Csshortcode1 = 'CSSHORTCODE1'
};

export type GetAccountInfoParams = {
  removeInactiveAccounts?: InputMaybe<Scalars['Boolean']>;
};

export type GetAlertsRequest = {
  acctId?: InputMaybe<Scalars['String']>;
  commPreferencesParams?: InputMaybe<CommPreferencesParams>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  accounts?: Maybe<Array<Maybe<AccountDetail>>>;
  defaultAccount?: Maybe<DefaultAccountInfo>;
  /** @deprecated Group code is used no more */
  groupCode?: Maybe<Scalars['String']>;
  groupId: Scalars['ID'];
  groupName: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isDefaultAccountExists: Scalars['Boolean'];
  /** @deprecated if true, it means that the user can't delete or change it. This isn't straight forward and we recommend to use group type instead. Group types ASSO (Auto Association) and CUSTOM are 'editable'. */
  isLoggedOnUserAutoGroup: Scalars['Boolean'];
  numberOfAccounts?: Maybe<Scalars['Int']>;
  type: GroupType;
};


export type GroupAccountsArgs = {
  accountInfoParams?: InputMaybe<AccountInfoParams>;
};

export type GroupDetailsRequest = {
  groupCode: Scalars['String'];
  groupName: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type GroupInfoParams = {
  filter?: InputMaybe<FilterParams>;
  paging?: InputMaybe<PagingParams>;
  sort?: InputMaybe<SortParams>;
};

export type GroupResponse = {
  accounts?: InputMaybe<Array<InputMaybe<CustomerPreferencesAccountResponse>>>;
  groupType?: InputMaybe<GroupTypeRequest>;
  groupcode?: InputMaybe<Scalars['String']>;
  groupid?: InputMaybe<Scalars['String']>;
  groupname?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
};

export const enum GroupSort {
  Default = 'DEFAULT',
  Grouptype = 'GROUPTYPE',
  Numberofaccounts = 'NUMBEROFACCOUNTS'
};

export const enum GroupStatus {
  Created = 'Created',
  Deleted = 'Deleted',
  NotCreated = 'NotCreated',
  Partial = 'Partial',
  Preserved = 'Preserved'
};

export const enum GroupType {
  Automatic = 'Automatic',
  Custom = 'Custom',
  Virtual = 'Virtual'
};

export const enum GroupTypeRequest {
  Asso = 'ASSO',
  Auto = 'AUTO',
  Custom = 'CUSTOM',
  Virt = 'VIRT'
};

export type GroupsResponse = {
  groups?: InputMaybe<Array<InputMaybe<GroupResponse>>>;
};

export type GuestAccountDetails = {
  __typename?: 'GuestAccountDetails';
  foundMultiple: Scalars['Boolean'];
  isFound: Scalars['Boolean'];
};

export type GuestPayInfo = {
  __typename?: 'GuestPayInfo';
  amountDue: Scalars['Float'];
  encryptedAccountNumber: Scalars['String'];
  houseNumber?: Maybe<Scalars['String']>;
  lastFourdDigitAccount: Scalars['String'];
};

export type GuestPayInput = {
  customerName: Scalars['String'];
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  guestEmailAddress?: InputMaybe<Scalars['String']>;
  guestTextPhone?: InputMaybe<Scalars['String']>;
  paymentAmount: Scalars['Float'];
  tokenId: Scalars['String'];
};

export type GuestPayInputForIva = {
  accountNumber?: InputMaybe<Scalars['String']>;
  customerName: Scalars['String'];
  guestEmailAddress?: InputMaybe<Scalars['String']>;
  guestTextPhone?: InputMaybe<Scalars['String']>;
  paymentAmount: Scalars['Float'];
  tokenId: Scalars['String'];
};

export type GuestPaymentAccountInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  identificationType: IdentificationType;
  phoneNumber?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type GuestPaymentConfirmation = {
  __typename?: 'GuestPaymentConfirmation';
  cardType?: Maybe<Scalars['String']>;
  confirmationId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<PaymentErrorMessage>;
  errorReason?: Maybe<PaymentErrorReason>;
  isSuccess: Scalars['Boolean'];
  lastFourDigitsCardNumber?: Maybe<Scalars['String']>;
  lastFourdDigitAccount: Scalars['String'];
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentMethod?: Maybe<Scalars['String']>;
};

export type GuestPaymentDetails = {
  __typename?: 'GuestPaymentDetails';
  eligibility?: Maybe<GuestPaymentEligibility>;
  guestAccountDetails: GuestAccountDetails;
  paymentInfo?: Maybe<GuestPayInfo>;
};

export type GuestPaymentDownloadPdfInput = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  confirmationNumber?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  paymentAmount?: InputMaybe<Scalars['String']>;
  paymentDate?: InputMaybe<Scalars['String']>;
};

export type GuestPaymentEligibility = {
  __typename?: 'GuestPaymentEligibility';
  isEligible?: Maybe<Scalars['Boolean']>;
};

export type GuestPaymentPdf = {
  __typename?: 'GuestPaymentPdf';
  pdf?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GuestPaymentVerify = {
  __typename?: 'GuestPaymentVerify';
  encryptedAccountNumber?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type HasPersonIdResponse = {
  __typename?: 'HasPersonIdResponse';
  hasPerson?: Maybe<Scalars['Boolean']>;
};

export type HistoricalUsageInput = {
  authorizationContactInfo: ContactInfo;
  businessNames: Array<InputMaybe<BusinessInfo>>;
  contactInformation: ContactInfo;
  isReleaseAuthorization: Scalars['Boolean'];
};

export const enum HowDidYouHear {
  Billinsrt = 'BILLINSRT',
  Event = 'EVENT',
  Inperson = 'INPERSON',
  Letter = 'LETTER',
  MagazineAd = 'MAGAZINE_AD',
  NewspaperAd = 'NEWSPAPER_AD',
  NewsArticle = 'NEWS_ARTICLE',
  Other = 'OTHER',
  PgeWebsite = 'PGE_WEBSITE',
  RadioAd = 'RADIO_AD',
  TelevisionAd = 'TELEVISION_AD'
};

export type IFrameInfoRequest = {
  additionalParameters?: InputMaybe<Scalars['String']>;
  callbackUrl?: InputMaybe<Scalars['String']>;
  cvv?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  entryPoint?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  ignoreCase?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['String']>;
  pmCategory: PaymentCategory;
  postMessagePmDetailsOrigin: Scalars['String'];
  primaryPM?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
};

export type IFrameInfoResponse = {
  __typename?: 'IFrameInfoResponse';
  src: Scalars['String'];
};

export const enum IdentificationType {
  AccountNumber = 'accountNumber',
  PhoneNumber = 'phoneNumber'
};

export type IdentityInput = {
  type: IdentityType;
  value: Scalars['String'];
};

export const enum IdentityType {
  Dl = 'DL',
  Dob = 'DOB',
  Ssn = 'SSN'
};

export type InstagramFeed = {
  __typename?: 'InstagramFeed';
  posts: Array<Maybe<InstagramPost>>;
};

export type InstagramParams = {
  limit: Scalars['Int'];
};

export type InstagramPost = {
  __typename?: 'InstagramPost';
  id: Scalars['String'];
  permalink: Scalars['String'];
};

export type IsEmailExistResponse = {
  __typename?: 'IsEmailExistResponse';
  exist?: Maybe<Scalars['Boolean']>;
};

export type IsPaperlessBillEnrolledRequest = {
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type IsPaperlessBillEnrolledResponse = {
  __typename?: 'IsPaperlessBillEnrolledResponse';
  result?: Maybe<Scalars['Boolean']>;
};

export type IsPersonalIdValidParams = {
  encryptedPersonId: Scalars['String'];
  idNumber: Scalars['String'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LastPaymentDetails = {
  __typename?: 'LastPaymentDetails';
  lastPaymentAmount?: Maybe<Scalars['Float']>;
  lastPaymentDate?: Maybe<Scalars['String']>;
};

export type LeadData = {
  __typename?: 'LeadData';
  acctId?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<LeadDetailsInfo>>>;
  rowCount?: Maybe<Scalars['Int']>;
  zone?: Maybe<Scalars['String']>;
};

export type LeadDetailsEmailUpdateParams = {
  payload: LeadDetailsEmailUpdateRequest;
};

export type LeadDetailsEmailUpdateRequest = {
  discardReasonCd?: InputMaybe<LeadDiscardReason>;
  encryptedString: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  outcome: Scalars['String'];
};

export type LeadDetailsInfo = {
  __typename?: 'LeadDetailsInfo';
  bo?: Maybe<Scalars['String']>;
  boStatus?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  creDttm?: Maybe<Scalars['String']>;
  descr?: Maybe<Scalars['String']>;
  detailedDescr?: Maybe<Scalars['String']>;
  initiativeCd?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
};

export type LeadDetailsUpdateParams = {
  payload: LeadDetailsUpdateRequest;
};

export type LeadDetailsUpdateRequest = {
  accountId: Scalars['String'];
  discardReasonCd?: InputMaybe<LeadDiscardReason>;
  leadId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  outcome: Scalars['String'];
};

export type LeadDetailsUpdateResponse = {
  __typename?: 'LeadDetailsUpdateResponse';
  discardReasonCd: Scalars['String'];
  leadId: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  outcome: Scalars['String'];
};

export const enum LeadDiscardReason {
  LeadDiscardNo = 'LEAD_DISCARD_NO'
};

export type LeadInfoParams = {
  acctId: Scalars['String'];
};

export type LinkedApplicantAdditionalInfo = {
  altPhone?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  idInfo?: InputMaybe<ApplicantIdInfo>;
  mobPhone?: InputMaybe<Scalars['String']>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  primaryPhoneIsMobile?: InputMaybe<Scalars['Boolean']>;
  relationshipType?: InputMaybe<ApplicantRelationship>;
};

export const enum LinkedApplicantError {
  InvalidCode = 'InvalidCode',
  None = 'None'
};

export type LinkedApplicantResponse = {
  __typename?: 'LinkedApplicantResponse';
  linkedApplicanError?: Maybe<LinkedApplicantError>;
  perId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type LinkedPerson = {
  __typename?: 'LinkedPerson';
  accountRelTypeCd?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  encryptedContactId?: Maybe<Scalars['String']>;
  encryptedEmailId?: Maybe<Scalars['String']>;
  encryptedPersonId?: Maybe<Scalars['String']>;
  inputData?: Maybe<Scalars['String']>;
  isCssUser?: Maybe<Scalars['Boolean']>;
  isFinanciallyResponsible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
  verificationStatus?: Maybe<Scalars['String']>;
};

export type LinkedPersonRequest = {
  accountId?: InputMaybe<Scalars['String']>;
};

export type ListProfilesRequest = {
  loginId: Scalars['String'];
};

export type ListProfilesResponse = {
  __typename?: 'ListProfilesResponse';
  listProfilesResponse: PaymentProfileList;
};

export type MailingAddress = {
  addressLine1: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  postal: Scalars['String'];
  state: Scalars['String'];
};

export const enum MedicalCertEnrollmentStatus {
  Enrolled = 'ENROLLED',
  None = 'NONE',
  Open = 'OPEN'
};

export type MedicalCertStatusParams = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

/**
 * `enrollmentStatus` Logic:
 * 	if (isEnrolled = TRUE and caseStatus = COMPLETE)
 * 		*** do nothing just return everything including exp date
 * 		enrollmentStatus = ENROLLED
 * 	if (isEnrolled = TRUE and caseStatus = OPEN)
 * 		*** this will show PDF link in Client
 * 		enrollmentStatus = OPEN
 * 	else
 * 		*** un-enrolled flow
 * 		enrollmentStatus = NONE
 */
export type MedicalCertStatusResponse = {
  __typename?: 'MedicalCertStatusResponse';
  enrollmentStatus?: Maybe<MedicalCertEnrollmentStatus>;
  expirationDate?: Maybe<Scalars['String']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isEnrolled?: Maybe<Scalars['Boolean']>;
};

export type MeterAccessInput = {
  hasDog?: InputMaybe<Scalars['Boolean']>;
  hasSecurityGate?: InputMaybe<Scalars['Boolean']>;
  meterAccessMessage?: InputMaybe<Scalars['String']>;
};

export type MeterDetails = {
  __typename?: 'MeterDetails';
  meterId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type MonthlyUsage = {
  __typename?: 'MonthlyUsage';
  monthName?: Maybe<Scalars['String']>;
  totalKwh?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** deprecated: CSAPI implementation. DO NOT USE. */
export type MoveServiceEligibilityRequest = {
  encryptedAccountNumber: Scalars['String'];
  serviceAddress: AddressInput;
};

/** deprecated: CSAPI implementation. DO NOT USE. */
export type MoveServiceEligibilityResponse = {
  __typename?: 'MoveServiceEligibilityResponse';
  eligibleForGreenResource: Scalars['Boolean'];
  eligibleForMoveService: Scalars['Boolean'];
  isCurrentlyPaperlessBilling: Scalars['Boolean'];
};

export type MoveServiceRequest = {
  anyoneRemainingAtProperty?: InputMaybe<Scalars['Boolean']>;
  coCustomersInformation?: InputMaybe<Array<InputMaybe<CustomerInfoInput>>>;
  currentServiceAddress?: InputMaybe<AddressInput>;
  customerInformation?: InputMaybe<CustomerInfoInput>;
  eligibleForGreenResource?: InputMaybe<Scalars['Boolean']>;
  eligibleForMoveService?: InputMaybe<Scalars['Boolean']>;
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  heatSourceType?: InputMaybe<Scalars['String']>;
  isAddressExactMatch?: InputMaybe<Scalars['Boolean']>;
  isCurrentlyPaperlessBilling?: InputMaybe<Scalars['Boolean']>;
  isElectricVehicleSelected?: InputMaybe<Scalars['Boolean']>;
  isPaperlessBillingSelected?: InputMaybe<Scalars['Boolean']>;
  isRenewablesSelected?: InputMaybe<Scalars['Boolean']>;
  isSmartThermostatSelected?: InputMaybe<Scalars['Boolean']>;
  livesAtPremise?: InputMaybe<Scalars['Boolean']>;
  moveToServiceAddress?: InputMaybe<AddressInput>;
  preferredDueDate?: InputMaybe<Scalars['String']>;
  premiseId?: InputMaybe<Scalars['String']>;
  selectedPremiseType?: InputMaybe<PremiseType>;
  startDate?: InputMaybe<Scalars['String']>;
  stopDate?: InputMaybe<Scalars['String']>;
  waterHeaterType?: InputMaybe<Scalars['String']>;
};

export type MoveServiceSubmitResponse = {
  __typename?: 'MoveServiceSubmitResponse';
  isSuccessful: Scalars['Boolean'];
};

export type MoveSvcEligibilityRequest = {
  acctId: Scalars['String'];
  encryptedPremId: Scalars['String'];
  perId: Scalars['String'];
};

export type MoveSvcEligibilityResponse = {
  __typename?: 'MoveSvcEligibilityResponse';
  hasActiveDepositAndTPAAgreement: Scalars['Boolean'];
  hasActiveServiceAgreement: Scalars['Boolean'];
  hasBadDebt: Scalars['Boolean'];
  hasDisconnect: Scalars['Boolean'];
  hasEnrolledInEqualpay: Scalars['Boolean'];
  hasFraud: Scalars['Boolean'];
  hasPendingDisconnect: Scalars['Boolean'];
};

export type MoveSvcRequest = {
  acctId: Scalars['String'];
  authPartyPerIds: Array<InputMaybe<Scalars['String']>>;
  coCustomerPerId?: InputMaybe<Scalars['String']>;
  customerInfo: CustomerInfoDetail;
  enrollInPaperless: Scalars['Boolean'];
  greenSource: Scalars['Boolean'];
  mailingAndServiceAddressesSame: Scalars['Boolean'];
  moveFromAddress?: InputMaybe<AddressInput>;
  moveToAddress?: InputMaybe<AddressInput>;
  moveTopremises: MoveTopremiseDetails;
  notificationPhone?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  premId: Scalars['String'];
  propertyUsage: Scalars['String'];
  receiveNotifications: Scalars['Boolean'];
  startDate: Scalars['Date'];
  stayingBehind?: InputMaybe<Scalars['Boolean']>;
  stopDate: Scalars['Date'];
};

export type MoveSvcSubmitResponse = {
  __typename?: 'MoveSvcSubmitResponse';
  isSuccessfull: Scalars['Boolean'];
};

export type MoveToServiceAddressEligibilityRequest = {
  encryptedAccountNumber: Scalars['String'];
  selectedPremiseType: PremiseType;
  serviceAddress: AddressInput;
};

export type MoveToServiceAddressEligibilityResponse = {
  __typename?: 'MoveToServiceAddressEligibilityResponse';
  isAddressExactMatch: Scalars['Boolean'];
  isEligible: Scalars['Boolean'];
  isPeakTimeRebateEligible: Scalars['Boolean'];
  premiseId: Scalars['String'];
  serviceAddressEligibilityType?: Maybe<ServiceAddressEligibilityType>;
};

export type MoveTopremiseDetails = {
  premId: Scalars['String'];
  servicePointList?: InputMaybe<Array<InputMaybe<ServicePointInfo>>>;
};

export type MultiPaymentDetail = {
  __typename?: 'MultiPaymentDetail';
  confirmationNumber?: Maybe<Scalars['String']>;
  encryptedAccountNumber?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAuthUserApplicant?: Maybe<ApplicantResponse>;
  /**
   * If main person exist (perId != null), then perform validate person identification in C2M
   * If idType is not supplied, C2M validation will compare to all ID attached from the person,
   * If idType is provided, C2m will compare it to ALL idType
   */
  addCoApplicant?: Maybe<ApplicantWithEligibilityResponse>;
  addDeviceDetails: AddDeviceDetailResponse;
  addPreferredDueDate?: Maybe<PreferredDueDateAddResponse>;
  addRemoveAccountsToGroup: AccountRemoveAccountsResponse;
  cancelAutoPay: CancelAutoPay;
  cancelAutoPayIVA: Scalars['Boolean'];
  cancelPeakTimeRebate?: Maybe<PeaktimeRebateSuccessResponse>;
  createAccountGroup: AccountGroupResponse;
  createCustomGroup: AccountGroupResponse;
  createCustomerContact?: Maybe<Scalars['Boolean']>;
  createDirectAccessHistoricalUsage: Scalars['Boolean'];
  createOpenEnrollment?: Maybe<OpenEnrollmentResponse>;
  createPersonLeavingBehindContactLog?: Maybe<Scalars['Boolean']>;
  createRenewablesEnrollmentTodo?: Maybe<Scalars['Boolean']>;
  createWebCustomerContact?: Maybe<Scalars['Boolean']>;
  deleteGroup: DeleteGroupResponse;
  deletePersonDetails?: Maybe<DeletePersonDetailsResponse>;
  deleteProfile?: Maybe<DeleteProfileResponse>;
  enrollAutoPay: EnrollAutoPay;
  enrollMedicalCertificateProgram?: Maybe<EnrollMedicalCertProgramResponse>;
  enrollPaymentExtension?: Maybe<PaymentExtensionEnrollResponse>;
  enrollPeakTimeRebate?: Maybe<PeaktimeRebateSuccessResponse>;
  enrollToPaymentExtension?: Maybe<PaymentExtensionEnrollResponse>;
  enrollTpa: Tpa_EnrollmentConfirmation;
  enrollTpaForIVA: Tpa_EnrollmentConfirmation;
  linkedAccountApplicant?: Maybe<LinkedApplicantResponse>;
  makePayment: PaymentConfirmationList;
  moveServiceSubmit?: Maybe<MoveServiceSubmitResponse>;
  moveSvcSubmit?: Maybe<MoveSvcSubmitResponse>;
  outage?: Maybe<Scalars['Boolean']>;
  postBackAutoPayEnroll: Scalars['Boolean'];
  postBackPayment: PostBackPaymentConfirmation;
  postBackPaymentForIVA: PostBackPaymentConfirmationForIva;
  registerCommercial?: Maybe<RegistrationResponse>;
  registerResidential?: Maybe<RegistrationResponse>;
  reportOutageBySpList?: Maybe<ReportOutageResponse>;
  reportOutageBySpListGuest?: Maybe<ReportOutageResponse>;
  root?: Maybe<Scalars['String']>;
  sendActivationCode?: Maybe<SendActivationCodeResponse>;
  sendEmailActivationCode?: Maybe<SendActivationCodeResponse>;
  sendSmsText: Scalars['Boolean'];
  sendVerificationForAddPerson?: Maybe<LinkedApplicantResponse>;
  startServiceCreateIneligibilityLog?: Maybe<Scalars['Boolean']>;
  startServiceSubmit?: Maybe<StartServiceResponse>;
  /** This service is responsible for creating/updating person info and start service on single or multiple SPs. */
  startSubmit?: Maybe<StartSubmitResponse>;
  stopServiceCreate?: Maybe<Scalars['Boolean']>;
  /** This mutation is deprecated. */
  stopServiceSubmit?: Maybe<Scalars['String']>;
  stopServiceSubmitRequest?: Maybe<StopSubmitRequestResponse>;
  submitCommPreferences: SubmitCommPreferencesResponse;
  submitGuestPayment: GuestPaymentConfirmation;
  submitGuestPaymentForIVA: GuestPaymentConfirmation;
  submitOnecheckPayment?: Maybe<OnecheckPaymentSubmitResponse>;
  submitPeakTimeRebateGiveBack?: Maybe<PeakTimeRebateGiveBack>;
  submitQuickPayment: QuickPayConfirmation;
  submitTimeOfDayEnrollment: TimeOfDayEnrollmentConfirmation;
  submitTimeOfDayUnenrollment: TimeOfDayUnenrollmentConfirmation;
  unregister?: Maybe<UnregisterResponse>;
  updateAccountDetails: UpdateAccountDetailsResponse;
  /** @deprecated This mutation has been deprecated. */
  updateAccountInfo?: Maybe<Scalars['Boolean']>;
  updateAccountInformation?: Maybe<Scalars['Boolean']>;
  updateAlertDetails?: Maybe<ReportOutageResponse>;
  updateAutoPay: UpdateAutoPayList;
  updateContactInfoConfirmation: ContactInfoConfirmationResponse;
  updateEmail?: Maybe<ChangeEmailResponse>;
  updateGroup: UpdateGroupResponse;
  updateLanguagePreference: CustomerLanguagePrefResponse;
  updateLeadDetails: LeadDetailsUpdateResponse;
  updateLeadEmailDetails: LeadDetailsUpdateResponse;
  updateMailingAddress: Scalars['Boolean'];
  updatePaperlessBill?: Maybe<UpdatePaperlessBillResponse>;
  updatePassword?: Maybe<ChangePasswordUpdateResponse>;
  updatePeakTimeRebate?: Maybe<PeaktimeRebateSuccessResponse>;
  updatePendingUserCommPreference?: Maybe<PendingUserCommPreferenseResponse>;
  updatePersonContactDetails?: Maybe<UpdatePersonContactDetailsResponse>;
  updatePin?: Maybe<ChangePinUpdateResponse>;
  updateProfilePhone: Scalars['Boolean'];
  updateRenewables?: Maybe<Scalars['Boolean']>;
  updateServiceAgreementNickname: UpdateServiceAgreementResponse;
  updateTextPhone: UpdateTextPhoneResponse;
  uploadFilePgeStore?: Maybe<UploadFilePgeStoreResponse>;
  uploadStore?: Maybe<UploadStoreResponse>;
  validateAndUpdateTempPassword?: Maybe<ForgotPasswordResponse>;
  verifyAddPersonAccessToken?: Maybe<VerifyAddPersonAccessTokenResponse>;
  verifyEqualsEncryptedValue: VerifyEqualsEncryptedValueResponse;
};


export type MutationAddAuthUserApplicantArgs = {
  payload: AuthUserApplicant;
};


export type MutationAddCoApplicantArgs = {
  payload: CoApplicant;
};


export type MutationAddDeviceDetailsArgs = {
  payload: AddDeviceDetailInput;
};


export type MutationAddPreferredDueDateArgs = {
  payload?: InputMaybe<PreferredDueDateAddRequest>;
};


export type MutationAddRemoveAccountsToGroupArgs = {
  payload: AddRemoveAccountsInput;
};


export type MutationCancelAutoPayArgs = {
  payload: AutoPayCancelInput;
};


export type MutationCancelAutoPayIvaArgs = {
  payload: AutoPayCancelIvaInput;
};


export type MutationCancelPeakTimeRebateArgs = {
  payload: PeakTimeRebateParams;
};


export type MutationCreateAccountGroupArgs = {
  payload: CreateAccountGroupInput;
};


export type MutationCreateCustomGroupArgs = {
  payload: CreateGroupInput;
};


export type MutationCreateCustomerContactArgs = {
  params: CustomerContactInput;
};


export type MutationCreateDirectAccessHistoricalUsageArgs = {
  payload: HistoricalUsageInput;
};


export type MutationCreateOpenEnrollmentArgs = {
  payload: OpenEnrollmentInput;
};


export type MutationCreatePersonLeavingBehindContactLogArgs = {
  payload?: InputMaybe<ContactlogPersonLeavingBehindRequest>;
};


export type MutationCreateRenewablesEnrollmentTodoArgs = {
  payload?: InputMaybe<RenewablesEnrollmentToDoRequest>;
};


export type MutationCreateWebCustomerContactArgs = {
  params: CustomerContactInput;
};


export type MutationDeleteGroupArgs = {
  payload: DeleteGroupInput;
};


export type MutationDeletePersonDetailsArgs = {
  payload?: InputMaybe<DeletePersonDetailsRequest>;
};


export type MutationDeleteProfileArgs = {
  payload?: InputMaybe<DeleteProfileRequest>;
};


export type MutationEnrollAutoPayArgs = {
  payload: AutoPayEnrollInput;
};


export type MutationEnrollMedicalCertificateProgramArgs = {
  payload: EnrollMedicalCertProgramInput;
};


export type MutationEnrollPaymentExtensionArgs = {
  payload?: InputMaybe<PaymentExtensionEnrollInput>;
};


export type MutationEnrollPeakTimeRebateArgs = {
  payload: PeaktimeRebateUpsertInput;
};


export type MutationEnrollToPaymentExtensionArgs = {
  payload?: InputMaybe<EnrollToPaymentExtensionInput>;
};


export type MutationEnrollTpaArgs = {
  payload: Tpa_EnrollInput;
};


export type MutationEnrollTpaForIvaArgs = {
  payload: Tpa_EnrollInput;
};


export type MutationLinkedAccountApplicantArgs = {
  payload: LinkedPersonApplicant;
};


export type MutationMakePaymentArgs = {
  payload: PaymentInputList;
};


export type MutationMoveServiceSubmitArgs = {
  payload?: InputMaybe<MoveServiceRequest>;
};


export type MutationMoveSvcSubmitArgs = {
  payload?: InputMaybe<MoveSvcRequest>;
};


export type MutationOutageArgs = {
  payload?: InputMaybe<OutageReport>;
};


export type MutationPostBackAutoPayEnrollArgs = {
  payload: PostAutoPayEnrollInput;
};


export type MutationPostBackPaymentArgs = {
  payload: PostBackPaymentInput;
};


export type MutationPostBackPaymentForIvaArgs = {
  payload: PostBackPaymentForIvaInput;
};


export type MutationRegisterCommercialArgs = {
  payload: CommercialRegistrationRequest;
};


export type MutationRegisterResidentialArgs = {
  payload: ResidentialRegistrationRequest;
};


export type MutationReportOutageBySpListArgs = {
  payload: ReportOutageBySpListRequest;
};


export type MutationReportOutageBySpListGuestArgs = {
  payload: ReportOutageBySpListGuestRequest;
};


export type MutationSendActivationCodeArgs = {
  payload?: InputMaybe<SendActivationCodeRequest>;
};


export type MutationSendEmailActivationCodeArgs = {
  payload?: InputMaybe<SendEmailActivationCodeRequest>;
};


export type MutationSendSmsTextArgs = {
  input: SendSmsTextInput;
};


export type MutationSendVerificationForAddPersonArgs = {
  payload: LinkedPersonApplicant;
};


export type MutationStartServiceCreateIneligibilityLogArgs = {
  payload?: InputMaybe<StartServiceIneligibilityLogRequest>;
};


export type MutationStartServiceSubmitArgs = {
  payload?: InputMaybe<StartServiceRequest>;
};


export type MutationStartSubmitArgs = {
  payload?: InputMaybe<StartSubmitRequest>;
};


export type MutationStopServiceCreateArgs = {
  payload?: InputMaybe<StopServiceCreateInput>;
};


export type MutationStopServiceSubmitArgs = {
  payload?: InputMaybe<StopServiceSubmitRequest>;
};


export type MutationStopServiceSubmitRequestArgs = {
  payload?: InputMaybe<StopSubmitRequestInput>;
};


export type MutationSubmitCommPreferencesArgs = {
  payload: SubmitCommPreferencesRequest;
};


export type MutationSubmitGuestPaymentArgs = {
  payload: GuestPayInput;
};


export type MutationSubmitGuestPaymentForIvaArgs = {
  payload: GuestPayInputForIva;
};


export type MutationSubmitOnecheckPaymentArgs = {
  payload?: InputMaybe<OnecheckPaymentSubmitRequest>;
};


export type MutationSubmitPeakTimeRebateGiveBackArgs = {
  payload: PeakTimeRebateGiveBackDetailsInput;
};


export type MutationSubmitQuickPaymentArgs = {
  payload: QuickPayInput;
};


export type MutationSubmitTimeOfDayEnrollmentArgs = {
  payload: TimeOfDayEnrollmentInput;
};


export type MutationSubmitTimeOfDayUnenrollmentArgs = {
  payload: TimeOfDayUnenrollmentInput;
};


export type MutationUpdateAccountDetailsArgs = {
  payload: AccountDetailsInput;
};


export type MutationUpdateAccountInfoArgs = {
  payload?: InputMaybe<SubmitUpdateInfoInput>;
};


export type MutationUpdateAccountInformationArgs = {
  payload?: InputMaybe<SubmitUpdateInformationInput>;
};


export type MutationUpdateAlertDetailsArgs = {
  payload?: InputMaybe<UpdateAlrtDetailsRequest>;
};


export type MutationUpdateAutoPayArgs = {
  payload: UpdateAutoPayInput;
};


export type MutationUpdateContactInfoConfirmationArgs = {
  payload: ContactInfoConfirmationRequest;
};


export type MutationUpdateEmailArgs = {
  payload?: InputMaybe<ChangeEmailRequest>;
};


export type MutationUpdateGroupArgs = {
  payload: UpdateGroupInput;
};


export type MutationUpdateLanguagePreferenceArgs = {
  payload: CustomerLanguagePrefRequest;
};


export type MutationUpdateLeadDetailsArgs = {
  payload: LeadDetailsUpdateRequest;
};


export type MutationUpdateLeadEmailDetailsArgs = {
  payload: LeadDetailsEmailUpdateRequest;
};


export type MutationUpdateMailingAddressArgs = {
  payload: UpdateMailingAddressInput;
};


export type MutationUpdatePaperlessBillArgs = {
  payload?: InputMaybe<UpdatePaperlessBillRequest>;
};


export type MutationUpdatePasswordArgs = {
  payload?: InputMaybe<ChangePasswordUpdateRequest>;
};


export type MutationUpdatePeakTimeRebateArgs = {
  payload: PeaktimeRebateUpsertInput;
};


export type MutationUpdatePendingUserCommPreferenceArgs = {
  payload: PendingUserCommPreference;
};


export type MutationUpdatePersonContactDetailsArgs = {
  payload?: InputMaybe<UpdatePersonContactDetailsRequest>;
};


export type MutationUpdatePinArgs = {
  payload?: InputMaybe<ChangePinUpdateRequest>;
};


export type MutationUpdateProfilePhoneArgs = {
  payload: UpdateProfilePhoneInput;
};


export type MutationUpdateRenewablesArgs = {
  payload?: InputMaybe<RenewablePowerInput>;
};


export type MutationUpdateServiceAgreementNicknameArgs = {
  payload: ServiceAgreementNicknameInput;
};


export type MutationUpdateTextPhoneArgs = {
  payload: UpdateTextPhoneInput;
};


export type MutationUploadFilePgeStoreArgs = {
  payload?: InputMaybe<UploadFilePgeStoreRequest>;
};


export type MutationUploadStoreArgs = {
  payload?: InputMaybe<UploadStoreRequest>;
};


export type MutationValidateAndUpdateTempPasswordArgs = {
  payload?: InputMaybe<ForgotPasswordRequest>;
};


export type MutationVerifyAddPersonAccessTokenArgs = {
  payload?: InputMaybe<VerifyAddPersonAccessTokenRequest>;
};


export type MutationVerifyEqualsEncryptedValueArgs = {
  payload?: InputMaybe<VerifyEqualsEncryptedValueRequest>;
};

export type MutiPaymentSubmitResponse = {
  __typename?: 'MutiPaymentSubmitResponse';
  confirmationNumbers?: Maybe<Array<Maybe<MultiPaymentDetail>>>;
};

export type NextBillInfo = {
  __typename?: 'NextBillInfo';
  billDate?: Maybe<Scalars['DateTimeISO']>;
};

export const enum NotificationFor {
  Alert = 'ALERT',
  Outage = 'OUTAGE',
  Push = 'PUSH'
};

export type NotificationOption = {
  __typename?: 'NotificationOption';
  sequence?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type NotificationOptionInput = {
  sequence?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export const enum NotificationType {
  Out = 'OUT',
  Paperless = 'PAPERLESS',
  Rest = 'REST',
  Unknown = 'Unknown',
  Webdisc = 'WEBDISC',
  Webexc = 'WEBEXC',
  Webpda = 'WEBPDA',
  Webpyrcv = 'WEBPYRCV',
  Webpyrem = 'WEBPYREM',
  Webuse = 'WEBUSE'
};

export type NotificationTypeInput = {
  deliveryTypes: Array<DeliveryTypeInput>;
  notificationOptions?: InputMaybe<Array<InputMaybe<NotificationOptionInput>>>;
  notificationType: NotificationType;
};

export const enum OnecheckDisplayOption {
  AmountDue = 'AmountDue',
  LastBilledAmount = 'LastBilledAmount'
};

export type OnecheckPaymentAccount = {
  __typename?: 'OnecheckPaymentAccount';
  accountNumber?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountDue?: Maybe<Scalars['Float']>;
  billDueDate?: Maybe<Scalars['String']>;
  downloadBillUrl?: Maybe<Scalars['String']>;
  lastBilledAmount?: Maybe<Scalars['Float']>;
  lastBilledDate?: Maybe<Scalars['String']>;
};

export type OnecheckPaymentAccountRequest = {
  accountNumber: Scalars['String'];
  addresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  amountDue: Scalars['Float'];
  billDueDate?: InputMaybe<Scalars['String']>;
  lastBilledAmount?: InputMaybe<Scalars['Float']>;
  lastBilledDate?: InputMaybe<Scalars['String']>;
  payment: Scalars['Float'];
  paymentOriginal: Scalars['Float'];
};

export type OnecheckPaymentDownloadRemittanceFormResponse = {
  __typename?: 'OnecheckPaymentDownloadRemittanceFormResponse';
  pdf?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OnecheckPaymentGroupInfoRequest = {
  customGroupName?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
};

export type OnecheckPaymentGroupInfoResponse = {
  __typename?: 'OnecheckPaymentGroupInfoResponse';
  accounts?: Maybe<Array<Maybe<OnecheckPaymentAccount>>>;
  numberOfAccounts?: Maybe<Scalars['Int']>;
  totalAmountDue?: Maybe<Scalars['Float']>;
};

export type OnecheckPaymentInfoResponse = {
  __typename?: 'OnecheckPaymentInfoResponse';
  amountDueDetails?: Maybe<OnecheckPaymentGroupInfoResponse>;
  lastBilledAmountDetails?: Maybe<OnecheckPaymentGroupInfoResponse>;
};

export type OnecheckPaymentSubmitRequest = {
  accountGroup?: InputMaybe<Scalars['String']>;
  accounts: Array<OnecheckPaymentAccountRequest>;
  displayOption?: InputMaybe<OnecheckDisplayOption>;
  encryptedPersonId: Scalars['String'];
  isCustomGroup?: InputMaybe<Scalars['Boolean']>;
  numberOfAccounts?: InputMaybe<Scalars['Int']>;
  oneCheckTotal: Scalars['Float'];
  remittanceId?: InputMaybe<Scalars['String']>;
  todaysDate: Scalars['String'];
  totalAmountDue: Scalars['Float'];
};

export type OnecheckPaymentSubmitResponse = {
  __typename?: 'OnecheckPaymentSubmitResponse';
  pdf?: Maybe<Scalars['String']>;
  remittanceId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OnetimePaymentDownloadPdfRequest = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountRoutingNumber?: InputMaybe<Scalars['String']>;
  confirmationNumber?: InputMaybe<Scalars['String']>;
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  isFutureDatedPayment?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  paymentAmount?: InputMaybe<Scalars['String']>;
  paymentDate?: InputMaybe<Scalars['String']>;
  serviceAddress?: InputMaybe<Scalars['String']>;
};

export type OnetimePaymentPdfResponse = {
  __typename?: 'OnetimePaymentPdfResponse';
  pdf?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export const enum OnlineAccountType {
  PgeAgencyAcct = 'PGEAgencyAcct',
  PgeCommercialAcct = 'PGECommercialAcct',
  PgeEssAcct = 'PGEEssAcct',
  PgeResidentialAcct = 'PGEResidentialAcct',
  Unknown = 'Unknown'
};

export type OpenEnrollmentAccount = {
  __typename?: 'OpenEnrollmentAccount';
  acctId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  currentPlan?: Maybe<Scalars['String']>;
  effectiveRateSched?: Maybe<Scalars['String']>;
  perId?: Maybe<Scalars['String']>;
  spId?: Maybe<Scalars['String']>;
};

export type OpenEnrollmentAccountInput = {
  acctId: Scalars['String'];
  address: Scalars['String'];
  effectiveRateSched: Scalars['String'];
  perId?: InputMaybe<Scalars['String']>;
  planChangedTo: Scalars['String'];
  spId: Scalars['String'];
};

export type OpenEnrollmentAccountsRequest = {
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  isCustomGroup?: InputMaybe<Scalars['Boolean']>;
  selectedGroup?: InputMaybe<Scalars['String']>;
};

export type OpenEnrollmentInput = {
  authorization: ContactInfo;
  contactInformation: ContactInfo;
  electionWindow: ElectionWindow;
  openEnrollmentAccounts: Array<InputMaybe<OpenEnrollmentAccountInput>>;
  periodEffectiveDate: Scalars['String'];
};

export type OpenEnrollmentResponse = {
  __typename?: 'OpenEnrollmentResponse';
  isEnrollmentSuccessful?: Maybe<Scalars['Boolean']>;
};

export const enum Operator {
  Startswith = 'STARTSWITH'
};

export const enum Organization {
  CentralCityConcern = 'CentralCityConcern',
  CommunityEnergyProject = 'CommunityEnergyProject',
  None = 'None',
  WorkingTheoryFarm = 'WorkingTheoryFarm'
};

export const enum OtherPeopleRelType {
  Authusr = 'AUTHUSR',
  Coapp = 'COAPP'
};

export type OtherPeopleRequest = {
  acctId: Scalars['String'];
};

export type OtherPeopleResponse = {
  __typename?: 'OtherPeopleResponse';
  acctRelType?: Maybe<OtherPeopleRelType>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  perId?: Maybe<Scalars['String']>;
};

export type OutageByCountyOrZipCode = OutageDetailsByCountyList | OutageDetailsByZipCode;

export type OutageByCountyParams = {
  county?: InputMaybe<Scalars['String']>;
};

export type OutageDetailByGuestParams = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  lookUpType: OutageLookUpType;
  value?: InputMaybe<Scalars['String']>;
};

export type OutageDetailParams = {
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type OutageDetailsByCounty = {
  __typename?: 'OutageDetailsByCounty';
  county: Scalars['String'];
  outages: Scalars['Int'];
  totalCustomersImpacted: Scalars['Int'];
  typeName?: Maybe<Scalars['String']>;
  zipCodeInfo: Array<ZipCodeInfo>;
};

export type OutageDetailsByCountyList = {
  __typename?: 'OutageDetailsByCountyList';
  outageByCountyList?: Maybe<Array<Maybe<OutageDetailsByCounty>>>;
  typeName?: Maybe<Scalars['String']>;
};

export type OutageDetailsByZipCode = {
  __typename?: 'OutageDetailsByZipCode';
  cause: Scalars['String'];
  estimatedRestorationOn?: Maybe<Scalars['DateTimeISO']>;
  estimatedRestorationOut?: Maybe<Scalars['DateTimeISO']>;
  lat?: Maybe<Scalars['Latitude']>;
  long?: Maybe<Scalars['Longitude']>;
  totalCustomersImpacted: Scalars['Int'];
  typeName?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type OutageDetailsByZipCodeParams = {
  zipCode: Scalars['String'];
};

export type OutageEvent = {
  __typename?: 'OutageEvent';
  eventId: Scalars['ID'];
  lat: Scalars['Latitude'];
  long: Scalars['Longitude'];
  polygon?: Maybe<Array<Maybe<OutagePolygon>>>;
  totalCustomersImpacted: Scalars['Int'];
};

export type OutageEventDetails = {
  __typename?: 'OutageEventDetails';
  cause?: Maybe<Scalars['String']>;
  crewDispatchedStatus?: Maybe<Scalars['String']>;
  estimatedTimeOn?: Maybe<Scalars['DateTimeISO']>;
  estimatedTimeOut?: Maybe<Scalars['DateTimeISO']>;
  eventStatus?: Maybe<Scalars['String']>;
  isStorm?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Latitude']>;
  long?: Maybe<Scalars['Longitude']>;
  noOfReports?: Maybe<Scalars['Int']>;
  totalCustomersImpacted: Scalars['Int'];
  typeName?: Maybe<Scalars['String']>;
};

export type OutageEventParams = {
  outageEventId: Scalars['ID'];
};

export type OutageEvents = {
  __typename?: 'OutageEvents';
  events?: Maybe<Array<Maybe<OutageEvent>>>;
  lastUpdateTime?: Maybe<Scalars['DateTimeISO']>;
  typeName?: Maybe<Scalars['String']>;
};

export type OutageInfo = {
  __typename?: 'OutageInfo';
  callbackPhone?: Maybe<Scalars['String']>;
  callbackRequested?: Maybe<Scalars['Boolean']>;
  cause?: Maybe<Scalars['String']>;
  crewDispatchedStatus?: Maybe<Scalars['String']>;
  estimatedTimeOn?: Maybe<Scalars['DateTimeISO']>;
  estimatedTimeOut?: Maybe<Scalars['DateTimeISO']>;
  hasKnownOutage?: Maybe<Scalars['Boolean']>;
  numberOfCustomersAffected?: Maybe<Scalars['Int']>;
  numberOfReports?: Maybe<Scalars['Int']>;
  outageAlreadyReported?: Maybe<Scalars['Boolean']>;
  outageDueToStorm?: Maybe<Scalars['Boolean']>;
  registeredPhone?: Maybe<Scalars['String']>;
};

export const enum OutageLookUpType {
  Account = 'ACCOUNT',
  Phone = 'PHONE',
  Premise = 'PREMISE'
};

export type OutageLookupMultipleOption = {
  __typename?: 'OutageLookupMultipleOption';
  encryptedValue: Scalars['String'];
  houseNumber: Scalars['String'];
  last4CharAddress?: Maybe<Scalars['String']>;
};

export type OutageLookupParams = {
  encryptedPremiseId?: InputMaybe<Scalars['String']>;
  encryptedValue?: InputMaybe<Scalars['String']>;
  geoLocationToken?: InputMaybe<Scalars['String']>;
  lookupType: OutageLookUpType;
  value?: InputMaybe<Scalars['String']>;
};

export type OutageLookupResponse = {
  __typename?: 'OutageLookupResponse';
  encryptedAccountNumber?: Maybe<Scalars['String']>;
  encryptedPremiseId?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  isAccountExists: Scalars['Boolean'];
  isEligibleToReport: Scalars['Boolean'];
  multiplePremiseList?: Maybe<Array<OutageLookupMultipleOption>>;
  outages?: Maybe<Array<ServicePointOutageDetails>>;
};

export type OutagePolygon = {
  __typename?: 'OutagePolygon';
  lat: Scalars['Latitude'];
  long: Scalars['Longitude'];
};

export type OutageReport = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  encryptedServicePointId: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  source: OutageSource;
};

export type OutageRestoredAlert = {
  contactValue: Scalars['String'];
  encryptedContactId: Scalars['String'];
  encryptedNotificationId?: InputMaybe<Scalars['String']>;
  isSelected: Scalars['Boolean'];
  type: AlertType;
};

export const enum OutageSource {
  Ivr = 'IVR',
  Web = 'WEB'
};

export type OutageSpecificInput = {
  type: OutageSpecificInputType;
  value?: InputMaybe<Scalars['String']>;
};

export const enum OutageSpecificInputType {
  AllOutageEvents = 'ALL_OUTAGE_EVENTS',
  OutageByCounty = 'OUTAGE_BY_COUNTY',
  OutageByZipcode = 'OUTAGE_BY_ZIPCODE',
  OutageEventDetail = 'OUTAGE_EVENT_DETAIL'
};

export type Outages = OutageEventDetails | OutageEvents;

export type PagingParams = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type PaperLessBillInfoResponse = {
  __typename?: 'PaperLessBillInfoResponse';
  paperLessEmail?: Maybe<Scalars['String']>;
};

export type PaymentAlerts = {
  __typename?: 'PaymentAlerts';
  /** @deprecated use totalFutureDatedPaymentAmount, which is sum of all future dated payments */
  futureDatedPaymentAmount?: Maybe<Scalars['Float']>;
  isFutureDated?: Maybe<Scalars['Boolean']>;
  isMultipleSameDayPayment?: Maybe<Scalars['Boolean']>;
  isSingleSameDayPayment?: Maybe<Scalars['Boolean']>;
  lastPaidAmount?: Maybe<Scalars['Float']>;
  totalFutureDatedPaymentAmount?: Maybe<Scalars['Float']>;
};

export type PaymentBankInfoResponse = {
  __typename?: 'PaymentBankInfoResponse';
  amountDue?: Maybe<Scalars['Float']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  encryptedAccountNumber?: Maybe<Scalars['String']>;
  isPaymentDueInNextThreeDays?: Maybe<Scalars['Boolean']>;
  routingNumber?: Maybe<Scalars['String']>;
};

export const enum PaymentCategory {
  AmazonPay = 'AMAZON_PAY',
  Ap = 'AP',
  Cc = 'CC',
  Dc = 'DC',
  Dd = 'DD',
  Gp = 'GP',
  PaypalAccount = 'PAYPAL_ACCOUNT',
  PaypalCredit = 'PAYPAL_CREDIT'
};

export type PaymentConfirmation = {
  __typename?: 'PaymentConfirmation';
  accountNumber: Scalars['String'];
  cardType: Scalars['String'];
  confirmationId?: Maybe<Scalars['String']>;
  digitalWalletEmail?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<PaymentErrorMessage>;
  errorReason?: Maybe<PaymentErrorReason>;
  isSuccess: Scalars['Boolean'];
  lastFourDigitsCardNumber: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentMethod: Scalars['String'];
  pendingDisconnect?: Maybe<PendingDisconnectStatus>;
};

export type PaymentConfirmationList = {
  __typename?: 'PaymentConfirmationList';
  isPartialSucceed: Scalars['Boolean'];
  paymentConfirmation: Array<PaymentConfirmation>;
};

export type PaymentEligibility = {
  __typename?: 'PaymentEligibility';
  isCashOnly: Scalars['Boolean'];
  isNonBillableNoBalance: Scalars['Boolean'];
};

export type PaymentEligibilityParams = {
  accountNumberList: Array<AccountNumberParams>;
};

export const enum PaymentErrorMessage {
  AddressVerificationCardSecurityCodeFailed = 'ADDRESS_VERIFICATION_CARD_SECURITY_CODE_FAILED',
  AddressVerificationFailed = 'ADDRESS_VERIFICATION_FAILED',
  AlreadyReversed = 'ALREADY_REVERSED',
  AuthorizationExpired = 'AUTHORIZATION_EXPIRED',
  AuthorizationNotFound = 'AUTHORIZATION_NOT_FOUND',
  Callvoicecenter = 'CALLVOICECENTER',
  CardholdersActivityLimitExceeded = 'CARDHOLDERS_ACTIVITY_LIMIT_EXCEEDED',
  CardholderAuthenticationRequired = 'CARDHOLDER_AUTHENTICATION_REQUIRED',
  CardholderStoppedAllBilling = 'CARDHOLDER_STOPPED_ALL_BILLING',
  CardholderStoppedBilling = 'CARDHOLDER_STOPPED_BILLING',
  Cardisexpired = 'CARDISEXPIRED',
  CardAccountLengthError = 'CARD_ACCOUNT_LENGTH_ERROR',
  CardIssuerDeclinedCvv = 'CARD_ISSUER_DECLINED_CVV',
  CardLostStolen = 'CARD_LOST_STOLEN',
  CardTypeNotEnabled = 'CARD_TYPE_NOT_ENABLED',
  Connectiontimeout = 'CONNECTIONTIMEOUT',
  CreditCardNumberNotMatch = 'CREDIT_CARD_NUMBER_NOT_MATCH',
  CurrencyMustMatchPaypalAccount = 'CURRENCY_MUST_MATCH_PAYPAL_ACCOUNT',
  CurrencyNotEnabledPaypalSeller = 'CURRENCY_NOT_ENABLED_PAYPAL_SELLER',
  Decline = 'DECLINE',
  DeclinedCallForApproval = 'DECLINED_CALL_FOR_APPROVAL',
  DeclinedCallIssuer = 'DECLINED_CALL_ISSUER',
  DeclinedUpdatedCardholderAvailable = 'DECLINED_UPDATED_CARDHOLDER_AVAILABLE',
  Defaultcall = 'DEFAULTCALL',
  Donothonor = 'DONOTHONOR',
  Duplicatepayment = 'DUPLICATEPAYMENT',
  DuplicateTransaction = 'DUPLICATE_TRANSACTION',
  EncryptionError = 'ENCRYPTION_ERROR',
  ErrorDoNotRetryCallIssuer = 'ERROR_DO_NOT_RETRY_CALL_ISSUER',
  Expirydatepast = 'EXPIRYDATEPAST',
  FraudSecurityViolation = 'FRAUD_SECURITY_VIOLATION',
  Greaterthanmaxamount = 'GREATERTHANMAXAMOUNT',
  InconsistentData = 'INCONSISTENT_DATA',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  Invalidamount = 'INVALIDAMOUNT',
  Invalidccnumber = 'INVALIDCCNUMBER',
  Invalideffective = 'INVALIDEFFECTIVE',
  Invalidexpiration = 'INVALIDEXPIRATION',
  Invalidtransaction = 'INVALIDTRANSACTION',
  InvalidAuthorizationCode = 'INVALID_AUTHORIZATION_CODE',
  InvalidCc = 'INVALID_CC',
  InvalidClientId = 'INVALID_CLIENT_ID',
  InvalidCreditPlan = 'INVALID_CREDIT_PLAN',
  InvalidCurrencyCode = 'INVALID_CURRENCY_CODE',
  InvalidMerchant = 'INVALID_MERCHANT',
  InvalidMerchantNumber = 'INVALID_MERCHANT_NUMBER',
  InvalidSecurePaymentData = 'INVALID_SECURE_PAYMENT_DATA',
  InvalidStore = 'INVALID_STORE',
  InvalidTaxAmount = 'INVALID_TAX_AMOUNT',
  InvalidTransactionData = 'INVALID_TRANSACTION_DATA',
  InvalidTransactionDivisionNumber = 'INVALID_TRANSACTION_DIVISION_NUMBER',
  InvalidUserCredentials = 'INVALID_USER_CREDENTIALS',
  Issuerunavailable = 'ISSUERUNAVAILABLE',
  IssuerCardholderPutRestriction = 'ISSUER_CARDHOLDER_PUT_RESTRICTION',
  Lessthanminamount = 'LESSTHANMINAMOUNT',
  LimitExceeded = 'LIMIT_EXCEEDED',
  Lost = 'LOST',
  Loststolen = 'LOSTSTOLEN',
  Noanswer = 'NOANSWER',
  Notonfile = 'NOTONFILE',
  NoAccount = 'NO_ACCOUNT',
  NoActionTaken = 'NO_ACTION_TAKEN',
  NoDataFound = 'NO_DATA_FOUND',
  OfflineIssuerDeclined = 'OFFLINE_ISSUER_DECLINED',
  PartialApprovalGroupIiiVersion = 'PARTIAL_APPROVAL_GROUP_III_VERSION',
  PayerCannotPayWithPaypal = 'PAYER_CANNOT_PAY_WITH_PAYPAL',
  PaypalAccountFundingInstrumentDeclined = 'PAYPAL_ACCOUNT_FUNDING_INSTRUMENT_DECLINED',
  PaypalAccountLockedClosed = 'PAYPAL_ACCOUNT_LOCKED_CLOSED',
  PaypalAuthorizationAmountLimitExceeded = 'PAYPAL_AUTHORIZATION_AMOUNT_LIMIT_EXCEEDED',
  PaypalAuthorizationCountLimitExceeded = 'PAYPAL_AUTHORIZATION_COUNT_LIMIT_EXCEEDED',
  PaypalBlockingDuplicateOrderIds = 'PAYPAL_BLOCKING_DUPLICATE_ORDER_IDS',
  PaypalBusinessAccountLockedClosed = 'PAYPAL_BUSINESS_ACCOUNT_LOCKED_CLOSED',
  PaypalBusinessAccountPreferenceTransactionFailing = 'PAYPAL_BUSINESS_ACCOUNT_PREFERENCE_TRANSACTION_FAILING',
  PaypalBusinessAccountRestricted = 'PAYPAL_BUSINESS_ACCOUNT_RESTRICTED',
  PaypalBuyerRevokedAuthorization = 'PAYPAL_BUYER_REVOKED_AUTHORIZATION',
  PaypalChannelInitiatedBillingNotEnabled = 'PAYPAL_CHANNEL_INITIATED_BILLING_NOT_ENABLED',
  PaypalDomesticTransactionRequired = 'PAYPAL_DOMESTIC_TRANSACTION_REQUIRED',
  PaypalMerchantAccountConfigurationError = 'PAYPAL_MERCHANT_ACCOUNT_CONFIGURATION_ERROR',
  PaypalPayeeAccountInvalidNoConfirmedEmail = 'PAYPAL_PAYEE_ACCOUNT_INVALID_NO_CONFIRMED_EMAIL',
  PaypalPayeeBlockedTransaction = 'PAYPAL_PAYEE_BLOCKED_TRANSACTION',
  PaypalPayeeEmailIncorrectlyFormatted = 'PAYPAL_PAYEE_EMAIL_INCORRECTLY_FORMATTED',
  PaypalPayeeEmailPermissionDenied = 'PAYPAL_PAYEE_EMAIL_PERMISSION_DENIED',
  PaypalPaymentAlreadyCompleted = 'PAYPAL_PAYMENT_ALREADY_COMPLETED',
  PaypalPaymentMethodInvalid = 'PAYPAL_PAYMENT_METHOD_INVALID',
  PaypalPendingPaymentsNotSupported = 'PAYPAL_PENDING_PAYMENTS_NOT_SUPPORTED',
  PaypalPhoneNumberRequired = 'PAYPAL_PHONE_NUMBER_REQUIRED',
  PaypalReferenceTransactionsNotEnabled = 'PAYPAL_REFERENCE_TRANSACTIONS_NOT_ENABLED',
  PaypalTaxInfoRequired = 'PAYPAL_TAX_INFO_REQUIRED',
  PaypalTransactionLimitExceeded = 'PAYPAL_TRANSACTION_LIMIT_EXCEEDED',
  PaypalValidationError = 'PAYPAL_VALIDATION_ERROR',
  Pickup = 'PICKUP',
  Processordecline = 'PROCESSORDECLINE',
  ProcessorDeclined = 'PROCESSOR_DECLINED',
  ProcessorDoesNotSupportFeature = 'PROCESSOR_DOES_NOT_SUPPORT_FEATURE',
  RefusedDueToPaypalRiskModel = 'REFUSED_DUE_TO_PAYPAL_RISK_MODEL',
  SetupErrorAmount = 'SETUP_ERROR_AMOUNT',
  SetupErrorCard = 'SETUP_ERROR_CARD',
  SetupErrorHierarchy = 'SETUP_ERROR_HIERARCHY',
  SetupErrorMerchant = 'SETUP_ERROR_MERCHANT',
  SetupErrorTerminal = 'SETUP_ERROR_TERMINAL',
  Stolen = 'STOLEN',
  SurchargeNotPermitted = 'SURCHARGE_NOT_PERMITTED',
  TransactionAmountExceedsDivisionLimit = 'TRANSACTION_AMOUNT_EXCEEDS_DIVISION_LIMIT',
  TransactionNotAllowed = 'TRANSACTION_NOT_ALLOWED',
  Unknown = 'UNKNOWN',
  Violation = 'VIOLATION',
  VoiceAuthorizationRequired = 'VOICE_AUTHORIZATION_REQUIRED'
};

export const enum PaymentErrorReason {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Failed = 'FAILED',
  Scheduled = 'SCHEDULED',
  Unknown = 'UNKNOWN'
};

export const enum PaymentExtensionApeEligibilityCheck {
  AccountBalanceIneligibility = 'AccountBalanceIneligibility',
  AutopayEnrolledIneligibility = 'AutopayEnrolledIneligibility',
  CanceledPaymentIneligibility = 'CanceledPaymentIneligibility',
  DisconnectIneligibility = 'DisconnectIneligibility',
  Eligible = 'Eligible',
  EmployeeIneligibility = 'EmployeeIneligibility',
  EqualPayEnrolledIneligibility = 'EqualPayEnrolledIneligibility',
  IncativeAccountIneligibility = 'IncativeAccountIneligibility',
  MedicalCertificateIneligibility = 'MedicalCertificateIneligibility',
  NonResIneligibility = 'NonResIneligibility',
  PaymentExistsIneligibility = 'PaymentExistsIneligibility',
  UnknownIneligibility = 'UnknownIneligibility',
  UnsatisfiedDepositIneligibility = 'UnsatisfiedDepositIneligibility'
};

export type PaymentExtensionCurrentPlan = {
  __typename?: 'PaymentExtensionCurrentPlan';
  amountDue?: Maybe<Scalars['Float']>;
  dueDate?: Maybe<Scalars['DateTimeCustom']>;
  enrolledInOption3?: Maybe<Scalars['Boolean']>;
  planId?: Maybe<Scalars['String']>;
};

export type PaymentExtensionCurrentPlanInfo = {
  __typename?: 'PaymentExtensionCurrentPlanInfo';
  amountDue?: Maybe<Scalars['Float']>;
  dueDate?: Maybe<Scalars['DateTimeISO']>;
  enrolledInOption3?: Maybe<Scalars['Boolean']>;
  planId?: Maybe<Scalars['String']>;
};

export type PaymentExtensionDetailInput = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
};

export type PaymentExtensionDetailResponse = {
  __typename?: 'PaymentExtensionDetailResponse';
  currentPlan?: Maybe<PaymentExtensionCurrentPlan>;
  eligibility?: Maybe<PaymentExtensionEligibility>;
  paymentExtensionOptions?: Maybe<Array<Maybe<PaymentExtensionOption>>>;
};

export const enum PaymentExtensionEligibility {
  EligibleWithCurrentPlan = 'EligibleWithCurrentPlan',
  EligibleWithPastDuePlan = 'EligibleWithPastDuePlan',
  EligibleWithoutCurrentPlan = 'EligibleWithoutCurrentPlan',
  Ineligible = 'Ineligible',
  Unknown = 'Unknown'
};

export type PaymentExtensionEligibilityResponse = {
  __typename?: 'PaymentExtensionEligibilityResponse';
  ape?: Maybe<PaymentExtensionApeEligibilityCheck>;
  tpa?: Maybe<PaymentExtensionTpaEligibilityCheck>;
};

export type PaymentExtensionEnrollInput = {
  currentPlandId?: InputMaybe<Scalars['String']>;
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  enrolledInOption3?: InputMaybe<Scalars['Boolean']>;
  hasPastDueAmount?: InputMaybe<Scalars['Boolean']>;
  selectedPaymentExtensionOption?: InputMaybe<SelectedPaymentExtensionOptions>;
  serviceAddress?: InputMaybe<PaymentExtensionServiceAddress>;
};

export type PaymentExtensionEnrollResponse = {
  __typename?: 'PaymentExtensionEnrollResponse';
  confirmationNumber?: Maybe<Scalars['String']>;
};

export type PaymentExtensionInfo = {
  __typename?: 'PaymentExtensionInfo';
  currentPlan?: Maybe<PaymentExtensionCurrentPlanInfo>;
  eligibility?: Maybe<PaymentExtensionEligibilityResponse>;
  paymentExtensionOptions?: Maybe<Array<Maybe<PaymentExtensionOptionInfo>>>;
};

export type PaymentExtensionInfoResponse = {
  __typename?: 'PaymentExtensionInfoResponse';
  details?: Maybe<PaymentExtensionDetailResponse>;
  isEnrolled?: Maybe<Scalars['Boolean']>;
};

export type PaymentExtensionInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
};

export type PaymentExtensionOption = {
  __typename?: 'PaymentExtensionOption';
  amount?: Maybe<Scalars['Float']>;
  paymentExtensionOptionType?: Maybe<PaymentExtensionOptionType>;
  planDate?: Maybe<Scalars['DateTimeCustom']>;
};

export type PaymentExtensionOptionInfo = {
  __typename?: 'PaymentExtensionOptionInfo';
  amount?: Maybe<Scalars['Float']>;
  paymentExtensionOptionType?: Maybe<PaymentExtensionOptionType>;
  planDate?: Maybe<Scalars['DateTimeISO']>;
};

export const enum PaymentExtensionOptionType {
  Option1 = 'Option1',
  Option2 = 'Option2',
  Option3 = 'Option3'
};

export type PaymentExtensionResponse = {
  __typename?: 'PaymentExtensionResponse';
  currentPlan?: Maybe<PaymentExtensionCurrentPlan>;
  eligibility?: Maybe<PaymentExtensionEligibility>;
  isEnrolled?: Maybe<Scalars['Boolean']>;
  paymentExtensionOptions?: Maybe<Array<Maybe<PaymentExtensionOption>>>;
};

export type PaymentExtensionServiceAddress = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  houseType?: InputMaybe<Scalars['String']>;
  inCareOf?: InputMaybe<Scalars['String']>;
  pmbMailstop?: InputMaybe<Scalars['String']>;
  postal?: InputMaybe<Scalars['String']>;
  qasVerified?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
};

export const enum PaymentExtensionTpaEligibilityCheck {
  Eligible = 'Eligible',
  Ineligible = 'Ineligible'
};

export type PaymentExtensionTimePaymentArrangementResponse = {
  __typename?: 'PaymentExtensionTimePaymentArrangementResponse';
  isEnrolled?: Maybe<Scalars['Boolean']>;
};

export type PaymentHistory = {
  __typename?: 'PaymentHistory';
  amountPaid: Scalars['Float'];
  paymentDate: Scalars['DateTimeISO'];
  paymentType: PaymentHistoryStatus;
};

export type PaymentHistoryFilterParams = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  types?: InputMaybe<Array<InputMaybe<ViewPaymentHistoryBillingAndPaymentType>>>;
};

export type PaymentHistoryParams = {
  filterParams?: InputMaybe<PaymentHistoryFilterParams>;
  pagingParams?: InputMaybe<PagingParams>;
  sortDirection?: InputMaybe<Direction>;
};

export const enum PaymentHistoryStatus {
  Agency = 'Agency',
  Payment = 'Payment',
  PendingAgencyPay = 'PendingAgencyPay',
  PendingPayment = 'PendingPayment'
};

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  /** @deprecated No longer supported */
  amountDue?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  autoPay?: Maybe<AutoPay>;
  isCardOnly?: Maybe<Scalars['Boolean']>;
  isNonBillableNoBalance?: Maybe<Scalars['Boolean']>;
  isPendingDisconnect?: Maybe<Scalars['Boolean']>;
};

export type PaymentInput = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentDate: Scalars['DateTimeCustom'];
  tokenId: Scalars['String'];
};

export type PaymentInputList = {
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  payment: Array<PaymentInput>;
  tpaConfirmationNumber?: InputMaybe<Scalars['String']>;
};

export const enum PaymentPlanType {
  EqualPay = 'EqualPay',
  RegularPay = 'RegularPay',
  Unknown = 'Unknown'
};

export type PaymentPlanTypeResponse = {
  __typename?: 'PaymentPlanTypeResponse';
  paymentPlanType?: Maybe<PaymentPlanType>;
};

export type PaymentProfile = {
  __typename?: 'PaymentProfile';
  accountNumber?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  creditCardExpiryDate?: Maybe<ExpiryDate>;
  default?: Maybe<Scalars['Boolean']>;
  hasDeferredPayments?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  usedForAutopay?: Maybe<Scalars['Boolean']>;
};

export type PaymentProfileList = {
  __typename?: 'PaymentProfileList';
  profile: Array<PaymentProfile>;
};

export type PaymentTokenResponse = {
  __typename?: 'PaymentTokenResponse';
  token: Scalars['String'];
};

export const enum PaymentType {
  Cc = 'CC',
  Chq = 'CHQ',
  Sav = 'SAV'
};

export const enum PeakTimeEnrollmentStatus {
  Enrolled = 'Enrolled',
  Unenrolled = 'Unenrolled',
  YetToEnroll = 'YetToEnroll'
};

export type PeakTimeRebaseWidgetResponse = {
  __typename?: 'PeakTimeRebaseWidgetResponse';
  url?: Maybe<Scalars['String']>;
};

export type PeakTimeRebateContact = {
  __typename?: 'PeakTimeRebateContact';
  email?: Maybe<Scalars['String']>;
  mobilePhoneNumber?: Maybe<Scalars['String']>;
};

/** Submit give back details response */
export type PeakTimeRebateGiveBack = {
  __typename?: 'PeakTimeRebateGiveBack';
  success?: Maybe<Scalars['Boolean']>;
};

/** Get give back details response */
export type PeakTimeRebateGiveBackDetails = {
  __typename?: 'PeakTimeRebateGiveBackDetails';
  isEligible?: Maybe<Scalars['Boolean']>;
  organizationName?: Maybe<Organization>;
  peakTimeSeason?: Maybe<PeakTimeSeason>;
};

/** Submit give back payload */
export type PeakTimeRebateGiveBackDetailsInput = {
  encryptedAccountNumber: Scalars['String'];
  organizationName: Organization;
  peakTimeSeason: PeakTimeSeason;
};

/** Get give back details payload */
export type PeakTimeRebateGiveBackInput = {
  accountNumber: Scalars['String'];
};

export type PeakTimeRebateParams = {
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type PeakTimeRebateProgramInfoResponse = {
  __typename?: 'PeakTimeRebateProgramInfoResponse';
  isEligible?: Maybe<Scalars['Boolean']>;
  isEnrolled?: Maybe<Scalars['Boolean']>;
  peakTimeRebateContact?: Maybe<PeakTimeRebateContact>;
};

export type PeakTimeRebateProgramStatus = {
  __typename?: 'PeakTimeRebateProgramStatus';
  hasActiveSA: Scalars['Boolean'];
  hasRebates: Scalars['Boolean'];
  peakTimeRebateEnrollmentStatus: PeakTimeEnrollmentStatus;
};

export type PeakTimeRebateProgramStatusResponse = {
  hasActiveSa?: InputMaybe<Scalars['Boolean']>;
  hasRebates?: InputMaybe<Scalars['Boolean']>;
  peakTimeRebateEnrollmentStatus?: InputMaybe<PeakTimeEnrollmentStatus>;
};

export const enum PeakTimeSeason {
  NextPeakEvent = 'NextPeakEvent',
  NotToDonate = 'NotToDonate',
  Winter = 'Winter'
};

export type PeaktimeRebateCancelInput = {
  encryptedAccountNumber: Scalars['String'];
};

export type PeaktimeRebateSuccessResponse = {
  __typename?: 'PeaktimeRebateSuccessResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type PeaktimeRebateUpsertInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  mobilePhoneNumber?: InputMaybe<Scalars['String']>;
};

export type PendingDisconnect = {
  __typename?: 'PendingDisconnect';
  isPendingDisconnect?: Maybe<Scalars['Boolean']>;
};

export type PendingDisconnectStatus = {
  __typename?: 'PendingDisconnectStatus';
  isPendingDisconnect: Scalars['Boolean'];
};

export type PendingFinancialTransactions = {
  __typename?: 'PendingFinancialTransactions';
  billId?: Maybe<Scalars['String']>;
  cisDivision?: Maybe<Scalars['String']>;
  creDttm?: Maybe<Scalars['DateTimeCustom']>;
  curAmt?: Maybe<Scalars['Int']>;
  ftId?: Maybe<Scalars['String']>;
  ftTypeFlg?: Maybe<Scalars['String']>;
  glDivision?: Maybe<Scalars['String']>;
  saId?: Maybe<Scalars['String']>;
  saStatusFlg?: Maybe<Scalars['String']>;
  saTypeCd?: Maybe<Scalars['String']>;
};

export type PendingFinancialTransactionsParams = {
  accountNumbers: Array<InputMaybe<Scalars['String']>>;
  saStatusFlag?: InputMaybe<Scalars['String']>;
};

export type PendingUserCommPreferenseResponse = {
  __typename?: 'PendingUserCommPreferenseResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Person = {
  __typename?: 'Person';
  availableIdentityTypes: Array<IdentityType>;
  encryptedPersonId?: Maybe<Scalars['String']>;
  mainCustomer?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['String']>;
  personName?: Maybe<Scalars['String']>;
};

export type PersonAccountRequest = {
  accountNumberList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  businessPersonAccountList?: InputMaybe<Array<InputMaybe<BusinessPersonRequest>>>;
  username?: InputMaybe<Scalars['String']>;
};

export type PersonContact = {
  __typename?: 'PersonContact';
  contactType: Scalars['String'];
  contactValue: Scalars['String'];
  encryptedContactId: Scalars['String'];
  isPrimary: Scalars['Boolean'];
};

export type PersonDetail = {
  __typename?: 'PersonDetail';
  contactDetails?: Maybe<Array<Maybe<PersonContact>>>;
  encryptedPersonId: Scalars['String'];
  profileUpdateEligibility?: Maybe<ProfileUpdateEligibility>;
};

export type PersonIdentificationCheckInput = {
  idNumber: Scalars['String'];
  idType: PersonPrimaryIdType;
  perId: Scalars['String'];
};

export type PersonIdentificationCheckInputResponse = {
  __typename?: 'PersonIdentificationCheckInputResponse';
  isValid?: Maybe<Scalars['Boolean']>;
};

/**
 * DL = Drivers License (State-Number)
 * DUNS = DUNS Number
 * EIN = Employer Identification Number
 * ESSID = ESS ID
 * FRGN = Foreign ID
 * ITIN = Individual Tax Identification Number
 * MATR = Matricula ID
 * MILITARY = Military ID
 * ORTRIB = OR Tribal ID
 * PSSPRT = Passport
 */
export const enum PersonPrimaryIdType {
  Dl = 'DL',
  Duns = 'DUNS',
  Ein = 'EIN',
  Essid = 'ESSID',
  Frgn = 'FRGN',
  Itin = 'ITIN',
  Matr = 'MATR',
  Military = 'MILITARY',
  None = 'NONE',
  Ortrib = 'ORTRIB',
  Pssprt = 'PSSPRT',
  Resalien = 'RESALIEN',
  Ssn = 'SSN',
  Visa = 'VISA',
  Wrksch = 'WRKSCH'
};

/**
 * PNP - Primary Notification Phone
 * MOB - Mobile
 * ALT - Alternate Phone
 */
export const enum PersonVerificationType {
  Alt = 'ALT',
  Mob = 'MOB',
  Pnp = 'PNP'
};

export const enum PersonalIdentificationType {
  Dl = 'DL',
  Dob = 'DOB',
  Duns = 'DUNS',
  Ein = 'EIN',
  Essid = 'ESSID',
  Frgn = 'FRGN',
  Itin = 'ITIN',
  Matr = 'MATR',
  Military = 'MILITARY',
  None = 'NONE',
  Ortrib = 'ORTRIB',
  Pincode = 'PINCODE',
  Pssprt = 'PSSPRT',
  Resalien = 'RESALIEN',
  Ssn = 'SSN',
  Visa = 'VISA',
  Wrksch = 'WRKSCH'
};

export type PersonalIdentificationTypeParams = {
  encryptedPersonId: Scalars['String'];
};

export type PgeEnergyTrackerData = {
  __typename?: 'PgeEnergyTrackerData';
  billableAccountDetails?: Maybe<BillableAccountInfo>;
  energyTrackerInfo?: Maybe<EnergyTrackerInfo>;
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceProvider?: Maybe<EnergyTrackerServiceProvider>;
  showEnergyTracker?: Maybe<Scalars['Boolean']>;
};

export type PgeEnergyTrackerDataParams = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
};

export type PgeStoreFileUrlRequest = {
  keys: Array<InputMaybe<Scalars['String']>>;
};

export type PgeStoreFileUrlResponse = {
  __typename?: 'PgeStoreFileUrlResponse';
  downloadUrl: Scalars['String'];
  key: Scalars['String'];
};

export const enum PhoneType {
  Alternate = 'Alternate',
  Home = 'Home',
  Mobile = 'Mobile',
  None = 'None',
  PrimaryNotificationPhone = 'PrimaryNotificationPhone',
  Work = 'Work'
};

export type PostAutoPayEnrollInput = {
  accountNumber: Scalars['String'];
  amountDue?: InputMaybe<Scalars['Float']>;
  cardType?: InputMaybe<CardType>;
  customerName: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  /** must be 4 digits */
  lastFourNumber: Scalars['String'];
  paymentType: PaymentType;
  referenceId: Scalars['String'];
  source: Source;
  tokenId: Scalars['String'];
};

export type PostBackPaymentConfirmation = {
  __typename?: 'PostBackPaymentConfirmation';
  success: Scalars['Boolean'];
  successs: Scalars['Boolean'];
};

export type PostBackPaymentConfirmationForIva = {
  __typename?: 'PostBackPaymentConfirmationForIVA';
  success: Scalars['Boolean'];
};

export type PostBackPaymentForIvaInput = {
  accountNumber: Scalars['String'];
  cardType: Scalars['String'];
  customerFirstName: Scalars['String'];
  customerLastName: Scalars['String'];
  last4DigitCardNumber: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentDate: Scalars['LocalDate'];
  paymentType: Scalars['String'];
  personId: Scalars['String'];
  referenceId: Scalars['String'];
  source: Scalars['String'];
  tokenId: Scalars['String'];
};

export type PostBackPaymentInput = {
  accountNumber: Scalars['String'];
  cardType: Scalars['String'];
  customerFirstName: Scalars['String'];
  customerLastName: Scalars['String'];
  last4DigitCardNumber: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentDate: Scalars['LocalDate'];
  paymentType: Scalars['String'];
  referenceId: Scalars['String'];
  source: Scalars['String'];
};

export type PotentialAddress = {
  __typename?: 'PotentialAddress';
  address?: Maybe<Scalars['String']>;
  isFullAddress?: Maybe<Scalars['Boolean']>;
  isMultipleAddress?: Maybe<Scalars['Boolean']>;
  moniker?: Maybe<Scalars['String']>;
  partialAddress?: Maybe<Scalars['String']>;
};

export type PowerPortfolio = {
  __typename?: 'PowerPortfolio';
  lastUpdateTime: Scalars['DateTime'];
  load?: Maybe<Scalars['Float']>;
  supplyHistory?: Maybe<Array<Maybe<SupplyHistory>>>;
  supplyTypeDetails?: Maybe<Array<Maybe<SupplyTypeDetail>>>;
  temp?: Maybe<Scalars['Float']>;
};


export type PowerPortfolioSupplyHistoryArgs = {
  supplyHistoryParams: SupplyHistoryParams;
};

export type PreferredDueDate = {
  __typename?: 'PreferredDueDate';
  effectiveDate?: Maybe<Scalars['String']>;
  preferredDueDate?: Maybe<Scalars['Int']>;
  status?: Maybe<PreferredDueDateStatus>;
};

export type PreferredDueDateAddRequest = {
  encryptedAccountNumber: Scalars['String'];
  preferredDueDate?: InputMaybe<Scalars['Int']>;
};

export type PreferredDueDateAddResponse = {
  __typename?: 'PreferredDueDateAddResponse';
  /** @deprecated effectiveDate is not used */
  effectiveDate?: Maybe<Scalars['String']>;
  preferredDueDate?: Maybe<Scalars['Int']>;
  status?: Maybe<PreferredDueDateStatus>;
};

export type PreferredDueDateDetails = {
  __typename?: 'PreferredDueDateDetails';
  dueDate?: Maybe<PreferredDueDate>;
  eligibility?: Maybe<PreferredDueDateEligibility>;
};

export type PreferredDueDateEligibility = {
  __typename?: 'PreferredDueDateEligibility';
  isEligible?: Maybe<Scalars['Boolean']>;
  reasonCode?: Maybe<Scalars['String']>;
};

export type PreferredDueDateEligibilityResponse = {
  __typename?: 'PreferredDueDateEligibilityResponse';
  isEligible?: Maybe<Scalars['Boolean']>;
  reasonCode?: Maybe<Scalars['String']>;
};

export type PreferredDueDateInfoResponse = {
  __typename?: 'PreferredDueDateInfoResponse';
  effectiveDate?: Maybe<Scalars['String']>;
  preferredDueDate?: Maybe<Scalars['Int']>;
  status?: Maybe<PreferredDueDateStatus>;
};

export type PreferredDueDateRequest = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
};

export type PreferredDueDateResponse = {
  __typename?: 'PreferredDueDateResponse';
  dueDateInfo?: Maybe<PreferredDueDateInfoResponse>;
  eligibility?: Maybe<PreferredDueDateEligibilityResponse>;
};

export const enum PreferredDueDateStatus {
  Added = 'Added',
  ChangedToday = 'ChangedToday',
  Error = 'Error',
  Found = 'Found',
  NotAdded = 'NotAdded',
  NotChanged = 'NotChanged',
  NotEligible = 'NotEligible',
  NotFound = 'NotFound'
};

export type PremiseDetail = {
  premId: Scalars['String'];
  spList: Array<InputMaybe<Scalars['String']>>;
};

export type PremiseDetailForEligiblePremise = {
  __typename?: 'PremiseDetailForEligiblePremise';
  premiseId?: Maybe<Scalars['String']>;
  premiseInfo?: Maybe<Scalars['String']>;
  spId?: Maybe<Scalars['String']>;
  spType?: Maybe<Scalars['String']>;
};

export type PremiseDetails = {
  __typename?: 'PremiseDetails';
  city?: Maybe<Scalars['String']>;
  encryptedPremiseId?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  servicePoints?: Maybe<Array<Maybe<ServicePoint>>>;
  state?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};


export type PremiseDetailsServicePointsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PremiseEligibility = {
  __typename?: 'PremiseEligibility';
  isAddressExactMatch?: Maybe<Scalars['Boolean']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isPeakTimeRebateEligible?: Maybe<Scalars['Boolean']>;
  premiseDetailList?: Maybe<Array<Maybe<PremiseDetailForEligiblePremise>>>;
  premiseId?: Maybe<Scalars['String']>;
  serviceAddressEligibilityType?: Maybe<StartServiceAddressEligibilityType>;
};

export type PremiseInfo = {
  __typename?: 'PremiseInfo';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  encryptedPremiseId: Scalars['String'];
  houseType?: Maybe<Scalars['String']>;
  isEligibleForOutage?: Maybe<Scalars['Boolean']>;
  postal: Scalars['String'];
  saDetails?: Maybe<Array<Maybe<SaDetail>>>;
  servicePointDetails?: Maybe<Array<Maybe<ServicePointDetail>>>;
  state: Scalars['String'];
};

export type PremiseSearchRequest = {
  premList: Array<InputMaybe<Scalars['String']>>;
};

export type PremiseSearchResponse = {
  __typename?: 'PremiseSearchResponse';
  premId?: Maybe<Scalars['String']>;
  spList?: Maybe<Array<Maybe<PremiseSpDetails>>>;
};

export type PremiseSpDetails = {
  __typename?: 'PremiseSpDetails';
  geolocation?: Maybe<Scalars['String']>;
  isResAreaLight?: Maybe<Scalars['Boolean']>;
  locationCode?: Maybe<Scalars['String']>;
  locationDesc?: Maybe<Scalars['String']>;
  meterDesc?: Maybe<Scalars['String']>;
  meterNumber?: Maybe<Scalars['String']>;
  meterStatus?: Maybe<Scalars['String']>;
  meterType?: Maybe<Scalars['String']>;
  spDesc?: Maybe<Scalars['String']>;
  spId?: Maybe<Scalars['String']>;
  spStatus?: Maybe<Scalars['String']>;
  spType?: Maybe<Scalars['String']>;
};

export type PremiseSummary = {
  __typename?: 'PremiseSummary';
  city?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export const enum PremiseType {
  None = 'None',
  Own = 'Own',
  Rent = 'Rent'
};

export type PrimaryEmailDetails = {
  __typename?: 'PrimaryEmailDetails';
  isPrimaryEmailExists: Scalars['Boolean'];
};

export type ProfilePreferencesRequest = {
  language?: InputMaybe<Scalars['String']>;
};

export type ProfileResponse = {
  __typename?: 'ProfileResponse';
  errors?: Maybe<DeleteProfileError>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type ProfileUpdateEligibility = {
  __typename?: 'ProfileUpdateEligibility';
  isEligible: Scalars['Boolean'];
};

export type PromotionalOpportunity = {
  __typename?: 'PromotionalOpportunity';
  companyName?: Maybe<Scalars['String']>;
  isUsingCompanyName?: Maybe<Scalars['Boolean']>;
};

export type PromotionalOpportunityInput = {
  companyName?: InputMaybe<Scalars['String']>;
  isIncludeInPromotion?: InputMaybe<Scalars['Boolean']>;
};

export const enum PropertyUsage {
  FriendFamily = 'FRIEND_FAMILY',
  Landlord = 'LANDLORD',
  Owner = 'OWNER',
  Tenant = 'TENANT'
};

export type QasSearchResult = {
  __typename?: 'QasSearchResult';
  isAddressTruncated?: Maybe<Scalars['Boolean']>;
  potentialAddresses?: Maybe<Array<Maybe<PotentialAddress>>>;
  suggestedAddress?: Maybe<SuggestedAddress>;
  verifyLevel?: Maybe<VerifyLevel>;
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  account?: Maybe<Account>;
  accountExists: Scalars['Boolean'];
  accounts?: Maybe<Array<Maybe<AccountSummary>>>;
  billNotFoundPdfBizLegacy?: Maybe<BillNotFoundDownloadPdf>;
  billNotFoundPdfCsLegacy?: Maybe<BillNotFoundDownloadPdf>;
  experianCreditCheck?: Maybe<ExperianCreditCheckResponse>;
  getAccountDetailList?: Maybe<AccountDetailList>;
  getAccountDetails?: Maybe<Array<Maybe<AccountDetail>>>;
  /** Description: Validate the instance of an `account`, and whether it belongs to person’s details provided. Then determines whether account is in good standing to allow for additional service to be added. This is used in Signed scenario. */
  getAccountEligibilty?: Maybe<AccountEligibilityResponse>;
  getAccountGroups?: Maybe<Array<Maybe<Group>>>;
  getAccountInfo?: Maybe<AccountCustomer>;
  getAlerts?: Maybe<AlertReponse>;
  getAllFileUpload?: Maybe<Array<Maybe<FileUploadGetAllUploadResponse>>>;
  getAllOutageEvents?: Maybe<Scalars['JSON']>;
  getBillInfo?: Maybe<BillInfo>;
  getBillingAndPaymentHistoryDetails?: Maybe<BillingAndPaymentHistoryDetailsResponse>;
  getCIFDetails: CifDetails;
  getCoCustomerPersonDetails?: Maybe<AdditionalInfo>;
  getCoCustomersForAccount?: Maybe<Array<Maybe<CoCustomerInfo>>>;
  getCustomToken?: Maybe<UserTokenResponse>;
  getDownloadAutoPaymentPdf?: Maybe<AutoPaymentPdfResponse>;
  getDownloadGuestPaymentPdf?: Maybe<GuestPaymentPdf>;
  getDownloadOnecheckRemittanceFormPdf?: Maybe<OnecheckPaymentDownloadRemittanceFormResponse>;
  getDownloadOnetimePaymentPdf?: Maybe<OnetimePaymentPdfResponse>;
  getElectionWindowStatus?: Maybe<ElectionWindowStatus>;
  getEnergyTrackerData?: Maybe<EnergyTrackerData>;
  getEnergyTrackerUserInfo?: Maybe<EnergyTrackerUserInfo>;
  getFileUploadInfo?: Maybe<Array<Maybe<FileUploadInfoResponse>>>;
  getFileUploadPgeStoreUrl?: Maybe<Array<Maybe<FileUploadPgeStoreUrlResponse>>>;
  getFileUploadPreSignedUrl?: Maybe<FileUploadPreSignedUrlResponse>;
  getGroupAmountDetails?: Maybe<OnecheckPaymentInfoResponse>;
  getGuestPaymentInfo: GuestPaymentDetails;
  getIFrameInfo?: Maybe<IFrameInfoResponse>;
  getInstagramPosts?: Maybe<InstagramFeed>;
  getLeadData?: Maybe<LeadData>;
  getLinkedPersons?: Maybe<Array<Maybe<LinkedPerson>>>;
  getMailingAddressForAccount?: Maybe<Address>;
  getMainCustomerPersonDetails?: Maybe<AdditionalInfo>;
  getMedicalCertStatus?: Maybe<MedicalCertStatusResponse>;
  getOpenEnrollmentAccounts?: Maybe<EnrollmentAccounts>;
  getOutageDetailsByZipCode?: Maybe<OutageDetailsByZipCode>;
  getOutageEventDetails?: Maybe<OutageEventDetails>;
  getOutageEvents?: Maybe<Outages>;
  getOutageLookup: OutageLookupResponse;
  getOutagesByCounty?: Maybe<Array<Scalars['JSON']>>;
  getOutagesByCountyOrZipCode?: Maybe<OutageByCountyOrZipCode>;
  getPaymentExtensionInfo?: Maybe<PaymentExtensionInfoResponse>;
  getPaymentToken?: Maybe<PaymentTokenResponse>;
  getPeakTimeRebateProgramInfo?: Maybe<PeakTimeRebateProgramInfoResponse>;
  getPeakTimeRebateStatus?: Maybe<PeakTimeRebateProgramStatus>;
  getPersonPrimaryIdentificationType: PersonalIdentificationType;
  getPersonsForAccount?: Maybe<Array<Maybe<AccountPerson>>>;
  getPgeEnergyTrackerData?: Maybe<PgeEnergyTrackerData>;
  getPgeStoreFileUrl?: Maybe<Array<Maybe<PgeStoreFileUrlResponse>>>;
  getPnpPhone?: Maybe<Scalars['String']>;
  getPowerPortfolio?: Maybe<PowerPortfolio>;
  getPreSignedUrl?: Maybe<FileUploadPreSignedUrlResponse>;
  getPreferredDueDateInfo?: Maybe<PreferredDueDateResponse>;
  getQuickPaymentDetails: QuickPayDetails;
  getQuickViewBillInfo?: Maybe<QuickViewBillInfo>;
  getRenewableEnrollmentStatus?: Maybe<RenewableEnrollment>;
  getRenewables?: Maybe<RenewablePower>;
  getSalesforceDecryptedAccountNumber: Scalars['String'];
  getSalesforceEncryptedAccountNumber: Scalars['String'];
  /** @deprecated This query has been deprecated. Use fetching response from getAccountDetails.pgeEnergyTracker */
  getServiceAgreementNicknames?: Maybe<Array<Maybe<ServiceAgreementNickname>>>;
  getSmartThermostatEnrollmentStatus?: Maybe<SmartThermostatEnrollmentEnrollment>;
  getUserAccountPremiseInfo?: Maybe<UserAccountPremiseInfoResponse>;
  getViewBillInfo?: Maybe<ViewBillInfoResponse>;
  getViewPaymentHistoryChargeSummary?: Maybe<ViewPaymentHistoryResponse>;
  hasPerson?: Maybe<HasPersonIdResponse>;
  isCustomerServiceDisconnected?: Maybe<CustomerServiceDisconnectedResponse>;
  isEmailExist?: Maybe<IsEmailExistResponse>;
  isPaperlessBillEnrolled?: Maybe<IsPaperlessBillEnrolledResponse>;
  isPersonalIdentificationValid?: Maybe<Scalars['Boolean']>;
  listProfiles?: Maybe<ListProfilesResponse>;
  /** deprecated: CSAPI implementation. DO NOT USE. */
  moveServiceEligibility?: Maybe<MoveServiceEligibilityResponse>;
  moveSvcEligibility?: Maybe<MoveSvcEligibilityResponse>;
  moveToServiceAddressEligibility?: Maybe<MoveToServiceAddressEligibilityResponse>;
  /**
   * Get all person related to MAIN Customer for Move and Stop Service.
   * This will pull ONLY COAPP and AUTHUSR Type.
   */
  otherPeople?: Maybe<Array<Maybe<OtherPeopleResponse>>>;
  person?: Maybe<PersonDetail>;
  /**
   * Validate existing person's Identification for WEB only.
   *
   * Client send ID value and Person ID.
   *
   * If idType = NONE - Backend compare last 4 digit for ALL defined identification IDs in Person Details if there is a match.
   *
   * If idType NOT EQUAL to NONE - Last 4 digit will compare to specific ID type.
   */
  personIdentificationCheck?: Maybe<PersonIdentificationCheckInputResponse>;
  premiseSearch?: Maybe<Array<Maybe<PremiseSearchResponse>>>;
  qasRefineAddressSearch?: Maybe<QasSearchResult>;
  qasSearchAddress?: Maybe<QasSearchResult>;
  qasSearchAddressById?: Maybe<QasSearchResult>;
  renewablesAccountEligibility?: Maybe<RenewablesAccountEligibleResponse>;
  root?: Maybe<Scalars['String']>;
  sameAsOldPassword?: Maybe<SameAsOldPasswordResponse>;
  searchServiceAddress?: Maybe<SearchServiceAddressResponse>;
  sendOTL?: Maybe<SendOtlResponse>;
  startServiceAddressEligibility?: Maybe<ServiceAddressEligibility>;
  startServiceEligibility?: Maybe<StartServiceEligibilityResponse>;
  startServicePremiseEligibility?: Maybe<PremiseEligibility>;
  startServiceSignOutPersonEligibility?: Maybe<StartServiceSignOutPersonEligibility>;
  stopServiceEligibility?: Maybe<Scalars['Boolean']>;
  stopSrvceEligibility?: Maybe<Array<Maybe<StopServiceEligibilityResponse>>>;
  validateAccountExists?: Maybe<ValidateAccountExistsResponse>;
  validateCommercialAccountNumber?: Maybe<CommercialAccountValidation>;
  validateOTLToken?: Maybe<ValidateOtlResponse>;
  /**
   * TODO: personValidate need to merge to person resolver as sub-resolver
   * This Operation validate person.
   *
   * Note:
   * * Phone number support format e.g. - All input will convert to "(326) 612-0262" :
   * - (326) 612-0262
   * - 3266120262
   * - 326.612.0262
   * - 326 612 0262
   */
  validatePerson?: Maybe<ValidatePersonResponse>;
  validatePremise?: Maybe<Array<Maybe<ValidatePremiseResponse>>>;
  verifyIdentity: Array<VerifiedIdentity>;
  verifyUserEmail?: Maybe<VerifyEmailResponse>;
};


export type QueryAccountArgs = {
  accountId: Scalars['String'];
};


export type QueryAccountExistsArgs = {
  accountId: Scalars['String'];
};


export type QueryAccountsArgs = {
  phoneNumber: Scalars['String'];
};


export type QueryExperianCreditCheckArgs = {
  param: ExperianCreditCheckRequest;
};


export type QueryGetAccountDetailListArgs = {
  params: AccountDetailListParams;
};


export type QueryGetAccountDetailsArgs = {
  params: AccountDetailParams;
};


export type QueryGetAccountEligibiltyArgs = {
  params?: InputMaybe<AccountEligibilityParams>;
};


export type QueryGetAccountGroupsArgs = {
  params?: InputMaybe<AccountGroupParams>;
};


export type QueryGetAccountInfoArgs = {
  params?: InputMaybe<GetAccountInfoParams>;
};


export type QueryGetAlertsArgs = {
  params?: InputMaybe<GetAlertsRequest>;
};


export type QueryGetAllFileUploadArgs = {
  param: FileUploadGetAllUploadInput;
};


export type QueryGetBillInfoArgs = {
  params?: InputMaybe<BillInfoParams>;
};


export type QueryGetBillingAndPaymentHistoryDetailsArgs = {
  payload: BillingAndPaymentHistoryDetailsInput;
};


export type QueryGetCifDetailsArgs = {
  payload: CifDetailsParams;
};


export type QueryGetCoCustomerPersonDetailsArgs = {
  encryptedPersonId?: InputMaybe<Scalars['String']>;
};


export type QueryGetCoCustomersForAccountArgs = {
  params: CoCustomerForAccountRequest;
};


export type QueryGetCustomTokenArgs = {
  payload?: InputMaybe<UserTokenRequest>;
};


export type QueryGetDownloadAutoPaymentPdfArgs = {
  payload?: InputMaybe<AutoPaymentDownloadPdfRequest>;
};


export type QueryGetDownloadGuestPaymentPdfArgs = {
  payload?: InputMaybe<GuestPaymentDownloadPdfInput>;
};


export type QueryGetDownloadOnecheckRemittanceFormPdfArgs = {
  payload?: InputMaybe<OnecheckPaymentSubmitRequest>;
};


export type QueryGetDownloadOnetimePaymentPdfArgs = {
  payload?: InputMaybe<OnetimePaymentDownloadPdfRequest>;
};


export type QueryGetEnergyTrackerDataArgs = {
  params?: InputMaybe<EnergyTrackerDataParams>;
};


export type QueryGetEnergyTrackerUserInfoArgs = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetFileUploadInfoArgs = {
  param: FileUploadinfoInput;
};


export type QueryGetFileUploadPgeStoreUrlArgs = {
  param: FileUploadPgeStoreUrlInput;
};


export type QueryGetFileUploadPreSignedUrlArgs = {
  param: FileUploadPreSignedUrlInput;
};


export type QueryGetGroupAmountDetailsArgs = {
  payload?: InputMaybe<OnecheckPaymentGroupInfoRequest>;
};


export type QueryGetGuestPaymentInfoArgs = {
  params: GuestPaymentAccountInput;
};


export type QueryGetIFrameInfoArgs = {
  payload?: InputMaybe<IFrameInfoRequest>;
};


export type QueryGetInstagramPostsArgs = {
  params?: InputMaybe<InstagramParams>;
};


export type QueryGetLeadDataArgs = {
  params: LeadInfoParams;
};


export type QueryGetLinkedPersonsArgs = {
  params?: InputMaybe<LinkedPersonRequest>;
};


export type QueryGetMailingAddressForAccountArgs = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetMainCustomerPersonDetailsArgs = {
  encryptedPersonId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMedicalCertStatusArgs = {
  params: MedicalCertStatusParams;
};


export type QueryGetOpenEnrollmentAccountsArgs = {
  payload: OpenEnrollmentAccountsRequest;
};


export type QueryGetOutageDetailsByZipCodeArgs = {
  params: OutageDetailsByZipCodeParams;
};


export type QueryGetOutageEventDetailsArgs = {
  params: OutageEventParams;
};


export type QueryGetOutageEventsArgs = {
  params?: InputMaybe<OutageSpecificInput>;
};


export type QueryGetOutageLookupArgs = {
  params: OutageLookupParams;
};


export type QueryGetOutagesByCountyArgs = {
  params?: InputMaybe<OutageByCountyParams>;
};


export type QueryGetOutagesByCountyOrZipCodeArgs = {
  params?: InputMaybe<OutageSpecificInput>;
};


export type QueryGetPaymentExtensionInfoArgs = {
  payload?: InputMaybe<PaymentExtensionDetailInput>;
};


export type QueryGetPeakTimeRebateProgramInfoArgs = {
  payload: PeakTimeRebateParams;
};


export type QueryGetPeakTimeRebateStatusArgs = {
  params: PeakTimeRebateParams;
};


export type QueryGetPersonPrimaryIdentificationTypeArgs = {
  params: PersonalIdentificationTypeParams;
};


export type QueryGetPersonsForAccountArgs = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetPgeEnergyTrackerDataArgs = {
  params?: InputMaybe<PgeEnergyTrackerDataParams>;
};


export type QueryGetPgeStoreFileUrlArgs = {
  param: PgeStoreFileUrlRequest;
};


export type QueryGetPnpPhoneArgs = {
  encryptedPersonId?: InputMaybe<Scalars['String']>;
};


export type QueryGetPreSignedUrlArgs = {
  param: FileUploadPreSignedUrlInput;
};


export type QueryGetPreferredDueDateInfoArgs = {
  payload?: InputMaybe<PreferredDueDateRequest>;
};


export type QueryGetQuickPaymentDetailsArgs = {
  encryptedAccountInfo: Scalars['String'];
};


export type QueryGetQuickViewBillInfoArgs = {
  token: Scalars['String'];
};


export type QueryGetRenewableEnrollmentStatusArgs = {
  payload?: InputMaybe<RenewableEnrollmentStatusParams>;
};


export type QueryGetRenewablesArgs = {
  payload?: InputMaybe<RenewableInput>;
};


export type QueryGetSalesforceDecryptedAccountNumberArgs = {
  payload: SalesforceDecryptedAccountNumberInput;
};


export type QueryGetSalesforceEncryptedAccountNumberArgs = {
  payload: SalesforceEncryptedAccountNumberInput;
};


export type QueryGetServiceAgreementNicknamesArgs = {
  params: ServiceAgreementNicknamesParams;
};


export type QueryGetSmartThermostatEnrollmentStatusArgs = {
  payload?: InputMaybe<SmartThermostatEnrollmentStatusParams>;
};


export type QueryGetUserAccountPremiseInfoArgs = {
  payload: UserAccountPremiseInfo;
};


export type QueryGetViewBillInfoArgs = {
  payload: ViewBillDetailsRequest;
};


export type QueryGetViewPaymentHistoryChargeSummaryArgs = {
  payload?: InputMaybe<ViewPaymentHistoryInput>;
};


export type QueryIsCustomerServiceDisconnectedArgs = {
  payload?: InputMaybe<CustomerServiceDisconnectedParams>;
};


export type QueryIsEmailExistArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryIsPaperlessBillEnrolledArgs = {
  payload?: InputMaybe<IsPaperlessBillEnrolledRequest>;
};


export type QueryIsPersonalIdentificationValidArgs = {
  params?: InputMaybe<IsPersonalIdValidParams>;
};


export type QueryListProfilesArgs = {
  payload?: InputMaybe<ListProfilesRequest>;
};


export type QueryMoveServiceEligibilityArgs = {
  payload?: InputMaybe<MoveServiceEligibilityRequest>;
};


export type QueryMoveSvcEligibilityArgs = {
  param?: InputMaybe<MoveSvcEligibilityRequest>;
};


export type QueryMoveToServiceAddressEligibilityArgs = {
  payload?: InputMaybe<MoveToServiceAddressEligibilityRequest>;
};


export type QueryOtherPeopleArgs = {
  param: OtherPeopleRequest;
};


export type QueryPersonArgs = {
  encryptedPersonId: Scalars['String'];
};


export type QueryPersonIdentificationCheckArgs = {
  payload: PersonIdentificationCheckInput;
};


export type QueryPremiseSearchArgs = {
  payload?: InputMaybe<PremiseSearchRequest>;
};


export type QueryQasRefineAddressSearchArgs = {
  params?: InputMaybe<QuickAddressSearchParams>;
};


export type QueryQasSearchAddressArgs = {
  params?: InputMaybe<QuickAddressSearchParams>;
};


export type QueryQasSearchAddressByIdArgs = {
  params?: InputMaybe<QuickAddressSearchParams>;
};


export type QueryRenewablesAccountEligibilityArgs = {
  payload?: InputMaybe<RenewablesAccountEligibleRequest>;
};


export type QuerySameAsOldPasswordArgs = {
  payload?: InputMaybe<SameAsOldPasswordRequest>;
};


export type QuerySearchServiceAddressArgs = {
  payload?: InputMaybe<SearchServiceAddressRequest>;
};


export type QuerySendOtlArgs = {
  params: SendOtlRequest;
};


export type QueryStartServiceAddressEligibilityArgs = {
  payload?: InputMaybe<StartServiceAddressEligibilityRequest>;
};


export type QueryStartServiceEligibilityArgs = {
  payload?: InputMaybe<StartServiceEligibilityRequest>;
};


export type QueryStartServicePremiseEligibilityArgs = {
  payload?: InputMaybe<StartServicePremiseEligibilityRequest>;
};


export type QueryStartServiceSignOutPersonEligibilityArgs = {
  payload?: InputMaybe<StartServiceSignOutPersonEligibilityRequest>;
};


export type QueryStopServiceEligibilityArgs = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
};


export type QueryStopSrvceEligibilityArgs = {
  params?: InputMaybe<StopServiceEligibilityRequest>;
};


export type QueryValidateAccountExistsArgs = {
  payload?: InputMaybe<ValidateAccountExistsRequest>;
};


export type QueryValidateCommercialAccountNumberArgs = {
  accountNumber: Scalars['String'];
};


export type QueryValidateOtlTokenArgs = {
  params: ValidateOtlRequest;
};


export type QueryValidatePersonArgs = {
  payload: ValidatePersonInput;
};


export type QueryValidatePremiseArgs = {
  payload?: InputMaybe<ValidatePremiseRequest>;
};


export type QueryVerifyIdentityArgs = {
  input: Array<VerifyIdentityInput>;
};

export type QuickAddressSearchParams = {
  addressLine1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Scalars['String']>;
  isMailingAddress?: InputMaybe<Scalars['Boolean']>;
  moniker?: InputMaybe<Scalars['String']>;
  postal?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type QuickPayConfirmation = {
  __typename?: 'QuickPayConfirmation';
  cardType?: Maybe<Scalars['String']>;
  confirmationId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<PaymentErrorMessage>;
  errorReason?: Maybe<PaymentErrorReason>;
  isSuccess: Scalars['Boolean'];
  lastFourDigitsCardNumber?: Maybe<Scalars['String']>;
  lastFourdDigitAccount: Scalars['String'];
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentMethod?: Maybe<Scalars['String']>;
};

export type QuickPayDetails = {
  __typename?: 'QuickPayDetails';
  autoPay: AutoPayInfo;
  currentCharges: CurrentCharges;
  emailId: Scalars['String'];
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  paymentDate: Scalars['DateTimeCustom'];
  paymentEligibility: PaymentEligibility;
  paymentProfile: PaymentProfile;
  serviceAddress?: Maybe<Scalars['String']>;
};

export type QuickPayInput = {
  emailId: Scalars['String'];
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  paymentAmount: Scalars['Float'];
  tokenId: Scalars['String'];
};

export type QuickViewBillDetails = {
  __typename?: 'QuickViewBillDetails';
  amountDue?: Maybe<Scalars['Float']>;
  billDate?: Maybe<Scalars['DateTimeCustom']>;
  billStatus?: Maybe<Scalars['String']>;
  billingPeriodEndDate?: Maybe<Scalars['DateTimeCustom']>;
  billingPeriodStartDate?: Maybe<Scalars['DateTimeCustom']>;
  downloadBillUrl?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTimeCustom']>;
  encryptedAccountNumber?: Maybe<Scalars['String']>;
  encryptedBillId?: Maybe<Scalars['String']>;
  hasBills?: Maybe<Scalars['Boolean']>;
  kwh?: Maybe<Scalars['Float']>;
  previousBalance?: Maybe<Scalars['Float']>;
  totalAdjustments?: Maybe<Scalars['Float']>;
  totalBalanceAfterBill?: Maybe<Scalars['Float']>;
  totalCurrentCharges?: Maybe<Scalars['Float']>;
};

export type QuickViewBillInfo = {
  __typename?: 'QuickViewBillInfo';
  accountNumber?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  autoPay?: Maybe<AutoPayEnrollStatus>;
  billInfo?: Maybe<BillInfo>;
  customerName?: Maybe<Scalars['String']>;
  encryptedAccountNumber?: Maybe<Scalars['String']>;
  encryptedPersonId?: Maybe<Scalars['String']>;
  equalpay?: Maybe<PaymentPlanTypeResponse>;
  isAccountExists?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isSummaryBillAccount?: Maybe<Scalars['Boolean']>;
  isTokenExpired?: Maybe<Scalars['Boolean']>;
  lastPaymentDetails?: Maybe<LastPaymentDetails>;
  paymentHistoryDetails?: Maybe<Array<Maybe<PaymentHistory>>>;
  pendingDisconnect?: Maybe<PendingDisconnectStatus>;
  pgeEnergyTracker?: Maybe<PgeEnergyTrackerData>;
  tpa?: Maybe<Tpa_AccountDetail>;
  viewBillAverageTemperature?: Maybe<ViewBillAverageTemperature>;
  viewBillDetails?: Maybe<ViewBillDetails>;
  viewBillMonthUsage?: Maybe<ViewBillMonthlyUsage>;
};

export type ReconnectPaymentOptionDetails = {
  __typename?: 'ReconnectPaymentOptionDetails';
  balance?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  reconnectFee?: Maybe<Scalars['Float']>;
  totalDue?: Maybe<Scalars['Float']>;
};

export type ReconnectQuoteDetails = {
  __typename?: 'ReconnectQuoteDetails';
  fullAmountOption?: Maybe<ReconnectPaymentOptionDetails>;
  minimumDueOption?: Maybe<ReconnectPaymentOptionDetails>;
  optionsValidThrough?: Maybe<Scalars['DateTimeISO']>;
  quoteStatus: ReconnectQuoteStatus;
};

export const enum ReconnectQuoteStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  Quoted = 'QUOTED'
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  signinToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Scalars['String']>;
};

export const enum RelationshipType {
  AdministrativeOrAssistant = 'AdministrativeOrAssistant',
  AuthorizedUser = 'AuthorizedUser',
  AutoAssociated = 'AutoAssociated',
  CoApplicant = 'CoApplicant',
  Employee = 'Employee',
  FacilitiesOrMaintenance = 'FacilitiesOrMaintenance',
  FinancialOrBookkeeper = 'FinancialOrBookkeeper',
  MainCustomer = 'MainCustomer',
  ManagerOrExecutive = 'ManagerOrExecutive',
  OwnerOrCeo = 'OwnerOrCEO',
  PropertyManager = 'PropertyManager',
  ResponsibleParty = 'ResponsibleParty',
  SpoAssignee = 'SPOAssignee',
  SchedulingEss = 'SchedulingESS',
  SchedulingOrEss = 'SchedulingOrESS',
  SustainabilityOrEe = 'SustainabilityOrEE',
  Unknown = 'Unknown',
  WebSelfServiceUser = 'WebSelfServiceUser'
};

export const enum RenewableEligibility {
  Eligible = 'Eligible',
  IneligibleHasDisconnect = 'IneligibleHasDisconnect',
  IneligibleHasMultipleServiceAgreement = 'IneligibleHasMultipleServiceAgreement',
  IneligibleNoActiveServiceAgreement = 'IneligibleNoActiveServiceAgreement',
  Unknown = 'Unknown'
};

export type RenewableEnrollment = {
  __typename?: 'RenewableEnrollment';
  renewableEnrollmentStatus?: Maybe<RenewableEnrollmentStatus>;
};

export const enum RenewableEnrollmentStatus {
  Enrolled = 'Enrolled',
  NotEnrolled = 'NotEnrolled'
};

export type RenewableEnrollmentStatusParams = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
};

export type RenewableInput = {
  encryptedServiceAgreementId: Scalars['String'];
};

export type RenewableOption = {
  __typename?: 'RenewableOption';
  cleanWindChargeBlock?: Maybe<Scalars['Int']>;
  cleanWindEndDate?: Maybe<Scalars['String']>;
  cleanWindEnrolled?: Maybe<Scalars['Boolean']>;
  cleanWindQuantityEffectiveDate?: Maybe<Scalars['String']>;
  cleanWindStartDate?: Maybe<Scalars['String']>;
  greenFutureSolarBlock?: Maybe<Scalars['Int']>;
  greenFutureSolarEnrolled?: Maybe<Scalars['Boolean']>;
  greenSourceEndDate?: Maybe<Scalars['String']>;
  greenSourceEnrolled?: Maybe<Scalars['Boolean']>;
  greenSourceStartDate?: Maybe<Scalars['String']>;
  supportHabitatEndDate?: Maybe<Scalars['String']>;
  supportHabitatStartDate?: Maybe<Scalars['String']>;
  supportingHabitatEnrolled?: Maybe<Scalars['Boolean']>;
};

export type RenewablePower = {
  __typename?: 'RenewablePower';
  cleanWindBlocks: Scalars['Int'];
  greenFutureSolarBlocks: Scalars['Int'];
  isCleanWindEnrolled: Scalars['Boolean'];
  isGreenFutureSolarEnrolled: Scalars['Boolean'];
  isGreenSourceEnrolled: Scalars['Boolean'];
  isHabitatSupportEnrolled: Scalars['Boolean'];
};

export type RenewablePowerInput = {
  cleanWindBlocks?: InputMaybe<Scalars['Int']>;
  cssUser?: InputMaybe<Scalars['String']>;
  encryptedSaId?: InputMaybe<Scalars['String']>;
  greenFutureSolarBlocks?: InputMaybe<Scalars['Int']>;
  howDidYouHear?: InputMaybe<HowDidYouHear>;
  isCleanWindSelected?: InputMaybe<Scalars['Boolean']>;
  isGreenFutureSolarSelected?: InputMaybe<Scalars['Boolean']>;
  isGreenSourceSelected?: InputMaybe<Scalars['Boolean']>;
  isHabitatSupportSelected?: InputMaybe<Scalars['Boolean']>;
  promoCode?: InputMaybe<Scalars['String']>;
  promotionalOpportunity?: InputMaybe<PromotionalOpportunityInput>;
};

export type RenewablesAccountEligibleRequest = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
};

export type RenewablesAccountEligibleResponse = {
  __typename?: 'RenewablesAccountEligibleResponse';
  eligibility?: Maybe<RenewableEligibility>;
  encryptedServiceAgreementId?: Maybe<Scalars['String']>;
  isIndustrial?: Maybe<Scalars['Boolean']>;
  isSmallBusiness?: Maybe<Scalars['Boolean']>;
};

export type RenewablesEnrollmentToDoRequest = {
  contactName?: InputMaybe<Scalars['String']>;
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  encryptedServiceAgreementId?: InputMaybe<Scalars['String']>;
  primaryPhoneNumber?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export const enum RenewablesTransactionType {
  Cancel = 'CANCEL',
  Change = 'CHANGE',
  Enroll = 'ENROLL'
};

export type ReportOutageBySpListGuestRequest = {
  callbackPhone?: InputMaybe<Scalars['String']>;
  encryptedAccountNumber: Scalars['String'];
  encryptedServicePointList: Array<Scalars['String']>;
  source: ReportOutageSource;
};

export type ReportOutageBySpListRequest = {
  accountNumber: Scalars['String'];
  callbackPhone?: InputMaybe<Scalars['String']>;
  encryptedPersonId: Scalars['String'];
  encryptedPremiseId: Scalars['String'];
  encryptedServicePointList: Array<Scalars['String']>;
  outageRestoredAlertList?: InputMaybe<Array<OutageRestoredAlert>>;
  source: ReportOutageSource;
};

export type ReportOutageResponse = {
  __typename?: 'ReportOutageResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export const enum ReportOutageSource {
  Ivr = 'IVR',
  Mob = 'MOB',
  Web = 'WEB'
};

export type ResidentialRegistrationRequest = {
  AccountNumber?: InputMaybe<Scalars['String']>;
  EmailAddress: Scalars['String'];
  IsPaperlessBillingSelected?: InputMaybe<Scalars['Boolean']>;
  Last4DigitsOfDriversLicenseOrStateId?: InputMaybe<Scalars['String']>;
  Last4DigitsOfSSN?: InputMaybe<Scalars['String']>;
  Password: Scalars['String'];
  PhoneNumber?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  PinCode?: InputMaybe<Scalars['String']>;
};

export type ResponseBase = {
  code?: Maybe<Scalars['Int']>;
  errorReason?: Maybe<ErrorReason>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaDetail = {
  __typename?: 'SADetail';
  encryptedSAId: Scalars['String'];
  endDate?: Maybe<Scalars['DateTimeISO']>;
  nickName?: Maybe<Scalars['String']>;
  saStatus: SaStatus;
  startDate: Scalars['DateTimeISO'];
};

export const enum SaStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Incomplete = 'INCOMPLETE',
  Multiple = 'MULTIPLE',
  Pendingstart = 'PENDINGSTART',
  Pendingstop = 'PENDINGSTOP',
  Reactivated = 'REACTIVATED',
  Stopped = 'STOPPED'
};

export const enum SaType {
  Rartpa = 'RARTPA'
};

export type SalesforceDecryptedAccountNumberInput = {
  encryptedAccountNumber: Scalars['String'];
};

export type SalesforceEncryptedAccountNumberInput = {
  encryptedAccountNumber: Scalars['String'];
};

export type SameAsOldPasswordRequest = {
  password?: InputMaybe<Scalars['String']>;
};

export type SameAsOldPasswordResponse = {
  __typename?: 'SameAsOldPasswordResponse';
  sameAsOldPassword?: Maybe<Scalars['Boolean']>;
};

export const enum SartServicePremiseTypeParam {
  None = 'None',
  Own = 'Own',
  Rent = 'Rent'
};

export type SearchServiceAddress = {
  __typename?: 'SearchServiceAddress';
  addressLine1: Scalars['String'];
  city: Scalars['String'];
  geoLocationToken?: Maybe<Scalars['String']>;
  postal: Scalars['String'];
  premiseIds?: Maybe<Array<Scalars['String']>>;
};

export type SearchServiceAddressRequest = {
  match?: InputMaybe<Scalars['String']>;
};

export type SearchServiceAddressResponse = {
  __typename?: 'SearchServiceAddressResponse';
  addresses?: Maybe<Array<Maybe<SearchServiceAddress>>>;
};

export type SelectedPaymentExtensionOptions = {
  amount?: InputMaybe<Scalars['Float']>;
  paymentExtensionOptionType?: InputMaybe<PaymentExtensionOptionType>;
  planDate?: InputMaybe<Scalars['DateTimeCustom']>;
};

export type SendActivationCodeRequest = {
  phoneNumber: Scalars['String'];
};

export type SendActivationCodeResponse = {
  __typename?: 'SendActivationCodeResponse';
  encryptedActivationCode: Scalars['String'];
  hasError?: Maybe<Scalars['Boolean']>;
};

export type SendEmailActivationCodeRequest = {
  email: Scalars['String'];
};

export type SendOtlRequest = {
  email: Scalars['String'];
};

export type SendOtlResponse = {
  __typename?: 'SendOTLResponse';
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export type SendSmsTextInput = {
  accountId?: InputMaybe<Scalars['String']>;
  fromSid: FromSidEnum;
  /** Max length is 1600 char length */
  messageBody: Scalars['String'];
  notificationType: Scalars['String'];
  personId?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
  /** PhoneNumber is a E.164 spec, basically this is +17895551234 */
  toNumber: Scalars['PhoneNumber'];
};

export type ServiceAddress = {
  __typename?: 'ServiceAddress';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  fullServiceAddress?: Maybe<Scalars['String']>;
  fullStreetAddress1?: Maybe<Scalars['String']>;
  fullStreetAddress2?: Maybe<Scalars['String']>;
  houseType?: Maybe<Scalars['String']>;
  inCareOf?: Maybe<Scalars['String']>;
  pMBMailstop?: Maybe<Scalars['String']>;
  postal?: Maybe<Scalars['String']>;
  qasVerified?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
};

export type ServiceAddressEligibility = {
  __typename?: 'ServiceAddressEligibility';
  isAddressExactMatch?: Maybe<Scalars['Boolean']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isPeakTimeRebateEligible?: Maybe<Scalars['Boolean']>;
  premiseDetailList?: Maybe<Array<Maybe<PremiseDetailForEligiblePremise>>>;
  premiseId?: Maybe<Scalars['String']>;
  serviceAddressEligibilityType?: Maybe<StartServiceAddressEligibilityType>;
};

export const enum ServiceAddressEligibilityType {
  AddressNotFound = 'AddressNotFound',
  CommercialBuilding = 'CommercialBuilding',
  None = 'None'
};

export type ServiceAddressResponse = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  postal?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type ServiceAgreementNickname = {
  __typename?: 'ServiceAgreementNickname';
  encryptedId: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
};

export type ServiceAgreementNicknameInput = {
  encryptedId: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
};

export type ServiceAgreementNicknameResponse = {
  __typename?: 'ServiceAgreementNicknameResponse';
  serviceAgreements?: Maybe<Array<Maybe<ServiceAgreementNickname>>>;
};

export type ServiceAgreementNicknamesParams = {
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type ServiceConnectivity = {
  __typename?: 'ServiceConnectivity';
  disconnectionDate?: Maybe<Scalars['DateTimeCustom']>;
  isDisconnected?: Maybe<Scalars['Boolean']>;
  isEligibleForReconnect?: Maybe<Scalars['Boolean']>;
  isReconnectInProgress?: Maybe<Scalars['Boolean']>;
};

export const enum ServiceEligibilityReason {
  Eligible = 'ELIGIBLE',
  NeedsManualStopIneligible = 'NEEDS_MANUAL_STOP_INELIGIBLE',
  NonElectricIneligible = 'NON_ELECTRIC_INELIGIBLE',
  ProtectedAddressIneligible = 'PROTECTED_ADDRESS_INELIGIBLE',
  ServiceIntegrationIneligible = 'SERVICE_INTEGRATION_INELIGIBLE'
};

export type ServicePoint = {
  __typename?: 'ServicePoint';
  encryptedServicePointID?: Maybe<Scalars['String']>;
  meter?: Maybe<MeterDetails>;
  outage?: Maybe<OutageInfo>;
  servicePointID?: Maybe<Scalars['String']>;
};

export type ServicePointDetail = {
  __typename?: 'ServicePointDetail';
  encryptedServicePointId: Scalars['String'];
  lat?: Maybe<Scalars['Latitude']>;
  long?: Maybe<Scalars['Longitude']>;
  meter?: Maybe<MeterDetails>;
  outageInfo?: Maybe<ServicePointOutageDetails>;
};

export type ServicePointInfo = {
  servicePointId: Scalars['String'];
};

export type ServicePointOutageDetails = {
  __typename?: 'ServicePointOutageDetails';
  callbackPhone?: Maybe<Scalars['String']>;
  callbackRequested?: Maybe<Scalars['Boolean']>;
  cause?: Maybe<Scalars['String']>;
  crewDispatchStatus?: Maybe<Scalars['String']>;
  customersAffected?: Maybe<Scalars['Int']>;
  encryptedServicePointId?: Maybe<Scalars['String']>;
  estimatedTimeOn?: Maybe<Scalars['DateTime']>;
  estimatedTimeOut?: Maybe<Scalars['DateTime']>;
  eventStatus?: Maybe<Scalars['String']>;
  hasKnownOutage?: Maybe<Scalars['Boolean']>;
  isDueToStorm?: Maybe<Scalars['Boolean']>;
  isReported?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Latitude']>;
  long?: Maybe<Scalars['Longitude']>;
  registeredPhone?: Maybe<Scalars['String']>;
  totalReports?: Maybe<Scalars['Int']>;
};

export type ServicesEligibility = {
  __typename?: 'ServicesEligibility';
  moveService?: Maybe<ServiceEligibilityReason>;
  startService?: Maybe<ServiceEligibilityReason>;
  stopService?: Maybe<ServiceEligibilityReason>;
};

export type SmartThermostatEnrollmentEnrollment = {
  __typename?: 'SmartThermostatEnrollmentEnrollment';
  smartThermostatEnrollmentStatus?: Maybe<SmartThermostatEnrollmentStatus>;
};

export const enum SmartThermostatEnrollmentStatus {
  Enrolled = 'Enrolled',
  NotEnrolled = 'NotEnrolled'
};

export type SmartThermostatEnrollmentStatusParams = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
};

export type SortParams = {
  direction?: Direction;
  sort: AccountSort;
};

export const enum Source {
  Iva = 'IVA'
};

export type SsmCustomerInfo = {
  additionalInfo?: InputMaybe<StartSubmitAdditionalInfo>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  perId?: InputMaybe<Scalars['String']>;
};

export const enum StartMoveEligibility {
  Eligible = 'Eligible',
  InEligible = 'InEligible'
};

export type StartServiceAddressEligibilityRequest = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  selectedPremiseType: SartServicePremiseTypeParam;
  serviceAddress: StartServiceAddressParam;
};

export const enum StartServiceAddressEligibilityType {
  AddressNotFound = 'AddressNotFound',
  CommercialBuilding = 'CommercialBuilding',
  None = 'None'
};

export type StartServiceAddressParam = {
  addressLine1: Scalars['String'];
  city: Scalars['String'];
  postal: Scalars['String'];
  state: Scalars['String'];
};

export type StartServiceEligibilityRequest = {
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type StartServiceEligibilityResponse = {
  __typename?: 'StartServiceEligibilityResponse';
  isEligible?: Maybe<Scalars['Boolean']>;
};

export type StartServiceIneligibilityLogRequest = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type StartServicePremiseEligibilityRequest = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  premiseId: Scalars['String'];
  selectedPremiseType: SartServicePremiseTypeParam;
};

export type StartServiceRequest = {
  channel?: InputMaybe<Scalars['String']>;
  coCustomerInformation?: InputMaybe<CustomerInfoInput>;
  currentServiceAddress?: InputMaybe<AddressInput>;
  customerInformation?: InputMaybe<CustomerInfoInput>;
  eligibleForGreenResource?: InputMaybe<Scalars['Boolean']>;
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  heatSourceType?: InputMaybe<Scalars['String']>;
  isAddressExactMatch?: InputMaybe<Scalars['Boolean']>;
  isCoCustomerSelected?: InputMaybe<Scalars['Boolean']>;
  isCurrentlyPaperlessBilling?: InputMaybe<Scalars['Boolean']>;
  isElectricVehicleSelected?: InputMaybe<Scalars['Boolean']>;
  isPaperlessBillingSelected?: InputMaybe<Scalars['Boolean']>;
  isRenewablesSelected?: InputMaybe<Scalars['Boolean']>;
  isSmartThermostatSelected?: InputMaybe<Scalars['Boolean']>;
  isUserLoggedIn?: InputMaybe<Scalars['Boolean']>;
  livesAtPremise?: InputMaybe<Scalars['Boolean']>;
  peakTimeRebateEmail?: InputMaybe<Scalars['String']>;
  peakTimeRebateMobilePhone?: InputMaybe<Scalars['String']>;
  preferredDueDateSelected?: InputMaybe<Scalars['Int']>;
  premiseId?: InputMaybe<Scalars['String']>;
  selectedPremiseType?: InputMaybe<PremiseType>;
  serviceAddress?: InputMaybe<AddressInput>;
  startDate?: InputMaybe<Scalars['String']>;
  submitDate?: InputMaybe<Scalars['String']>;
  waterHeaterType?: InputMaybe<Scalars['String']>;
};

export type StartServiceResponse = {
  __typename?: 'StartServiceResponse';
  isPersonUpdated?: Maybe<Scalars['Boolean']>;
  isPreferredDueDateSuccessful?: Maybe<Scalars['Boolean']>;
  isStartServiceSuccessful?: Maybe<Scalars['Boolean']>;
};

export type StartServiceSignOutPersonEligibility = {
  __typename?: 'StartServiceSignOutPersonEligibility';
  cssEmail?: Maybe<Scalars['String']>;
  hasCSSUser: Scalars['Boolean'];
  isEligible: Scalars['Boolean'];
  mainHasMultipleAccounts: Scalars['Boolean'];
};

export type StartServiceSignOutPersonEligibilityRequest = {
  firstName: Scalars['String'];
  idType: Scalars['String'];
  idValue: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
};

export type StartSubmitAdditionalInfo = {
  alternatePhone?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  emailAddress: Scalars['String'];
  idInfo?: InputMaybe<StartSubmitIdInfo>;
  isPrimaryPhoneMobile: Scalars['Boolean'];
  mailingAddress?: InputMaybe<StartSubmitMailingAddress>;
  mobPhone?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  password?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['PhoneNumberPgeFormat'];
};

export type StartSubmitIdInfo = {
  idType: PersonPrimaryIdType;
  idValue: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
};

export type StartSubmitMailingAddress = {
  addressLine1: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  inCareOf?: InputMaybe<Scalars['String']>;
  postal: Scalars['String'];
  qasVerified: Scalars['Boolean'];
  state: Scalars['String'];
};

export type StartSubmitPremise = {
  premId: Scalars['String'];
  servicePointList: Array<StartSubmitSp>;
};

export type StartSubmitRequest = {
  acctId?: InputMaybe<Scalars['String']>;
  customerInfo?: InputMaybe<SsmCustomerInfo>;
  enrollInPaperless: Scalars['Boolean'];
  greenSource: Scalars['Boolean'];
  isGuest: Scalars['Boolean'];
  isUserLoggedIn: Scalars['Boolean'];
  mailingAndServiceAddressesSame: Scalars['Boolean'];
  notificationPhone?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  premises: Array<StartSubmitPremise>;
  propertyUsage: PropertyUsage;
  receiveNotifications: Scalars['Boolean'];
  registerToPortal: Scalars['Boolean'];
  registeredCssUser?: InputMaybe<Scalars['String']>;
  serviceAddress?: InputMaybe<AddressInput>;
  startDate: Scalars['Date'];
  submitDate: Scalars['Date'];
};

/** Return acctId and perId if Start Service is successful */
export type StartSubmitResponse = {
  __typename?: 'StartSubmitResponse';
  acctId?: Maybe<Scalars['String']>;
  encryptedAccountNumber?: Maybe<Scalars['String']>;
  isRegistrationSuccess?: Maybe<Scalars['Boolean']>;
  isStartSuccess?: Maybe<Scalars['Boolean']>;
  perId?: Maybe<Scalars['String']>;
  signinToken?: Maybe<Scalars['String']>;
};

export type StartSubmitSp = {
  spId: Scalars['String'];
};

export type State = {
  __typename?: 'State';
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export const enum StateIdType {
  DriverLicense = 'DriverLicense',
  StateIdCard = 'StateIDCard'
};

export type StateInfo = {
  __typename?: 'StateInfo';
  stateIDNumber?: Maybe<Scalars['String']>;
  stateIDState?: Maybe<State>;
  stateIdentificationType?: Maybe<StateIdType>;
};

export type StateInfoInput = {
  stateIDNumber?: InputMaybe<Scalars['String']>;
  stateIDState?: InputMaybe<StateInput>;
  stateIdentificationType?: InputMaybe<StateIdType>;
};

export type StateInput = {
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type StopAdditionalInfo = {
  mailingAddress: StopSubmitMailingAddress;
};

export type StopServiceAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  inCareOf?: InputMaybe<Scalars['String']>;
  postal?: InputMaybe<Scalars['String']>;
  qasVerified?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
};

export type StopServiceCreateInput = {
  channel?: InputMaybe<Channel>;
  encryptedAccountNumber: Scalars['String'];
  encryptedPremiseId: Scalars['String'];
  stopDate: Scalars['LocalDate'];
};

export type StopServiceEligibilityRequest = {
  acctId: Scalars['String'];
  encryptedpremId?: InputMaybe<Scalars['String']>;
  perId?: InputMaybe<Scalars['String']>;
};

export type StopServiceEligibilityResponse = {
  __typename?: 'StopServiceEligibilityResponse';
  acctId?: Maybe<Scalars['String']>;
  hasMultiplePremises?: Maybe<Scalars['Boolean']>;
  hasNoActiveElectricSA?: Maybe<Scalars['Boolean']>;
  hasPendingStop?: Maybe<Scalars['Boolean']>;
  hasProtectedAddress?: Maybe<Scalars['Boolean']>;
  isDisconnected?: Maybe<Scalars['Boolean']>;
  isPendingDisconnect?: Maybe<Scalars['Boolean']>;
};

/** This object type is deprecated. */
export type StopServiceSubmitRequest = {
  anyoneRemainingAtProperty?: InputMaybe<Scalars['Boolean']>;
  channel?: InputMaybe<Channel>;
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  finalBillAddress?: InputMaybe<StopServiceAddressInput>;
  phoneExt?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  serviceAddress?: InputMaybe<AddressInput>;
  stopDate?: InputMaybe<Scalars['String']>;
};

export type StopSubmitMailingAddress = {
  addressLine1: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  inCareOf?: InputMaybe<Scalars['String']>;
  postal: Scalars['String'];
  qasVerified: Scalars['Boolean'];
  state: Scalars['String'];
};

export type StopSubmitRequestInput = {
  acctId: Scalars['String'];
  channel?: InputMaybe<Channel>;
  finalBillAddress?: InputMaybe<StopServiceAddressInput>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  premId: Scalars['String'];
  stayingBehind?: InputMaybe<Scalars['String']>;
  stopDate: Scalars['Date'];
};

export type StopSubmitRequestResponse = {
  __typename?: 'StopSubmitRequestResponse';
  isSucessfull?: Maybe<Scalars['Boolean']>;
};

export type SubmitCommPreferencesRequest = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  notificationTypes: Array<NotificationTypeInput>;
};

export type SubmitCommPreferencesResponse = {
  __typename?: 'SubmitCommPreferencesResponse';
  success: Scalars['Boolean'];
};

export type SubmitUpdateInfoInput = {
  accountType?: InputMaybe<OnlineAccountType>;
  channel?: InputMaybe<Channel>;
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  originalUpdateAccountInfo?: InputMaybe<UpdateAccountInfoInput>;
  serviceAddress?: InputMaybe<AddressInput>;
  updateInfo?: InputMaybe<UpdateAccountInfoInput>;
};

export type SubmitUpdateInformationInput = {
  accountType?: InputMaybe<OnlineAccountType>;
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  encryptedPersonId?: InputMaybe<Scalars['String']>;
  originalUpdateAccountInfo?: InputMaybe<UpdateAccountInformationInput>;
  serviceAddress?: InputMaybe<AddressInput>;
  updateInfo?: InputMaybe<UpdateAccountInformationInput>;
};

export type SuggestedAddress = {
  __typename?: 'SuggestedAddress';
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  isGeneralAddress?: Maybe<Scalars['Boolean']>;
  isHighwayContractRoute?: Maybe<Scalars['Boolean']>;
  postal?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type SupplyHistory = {
  __typename?: 'SupplyHistory';
  intervalDateTime: Scalars['DateTime'];
  supplyTypeDetails?: Maybe<Array<Maybe<SupplyTypeDetail>>>;
};

export type SupplyHistoryParams = {
  interval: Scalars['Int'];
  supplyDate: Scalars['DateTime'];
};

export const enum SupplyType {
  Baoload = 'BAOLOAD',
  Hydro = 'HYDRO',
  Solar = 'SOLAR',
  Thermal = 'THERMAL',
  Wind = 'WIND'
};

export type SupplyTypeDetail = {
  __typename?: 'SupplyTypeDetail';
  supply: Scalars['Float'];
  type: SupplyType;
};

export type Tpa = {
  __typename?: 'TPA';
  installmentAmount?: Maybe<Scalars['Float']>;
  installmentFullBillAmount?: Maybe<Scalars['Float']>;
  payAgreementAmount?: Maybe<Scalars['Float']>;
  payAgreementDownPayment?: Maybe<Scalars['Float']>;
  payAgreementMonths?: Maybe<Scalars['Int']>;
};

export type Tpa_AccountDetail = {
  __typename?: 'TPA_AccountDetail';
  accountBalance?: Maybe<Scalars['Float']>;
  enrolledInstallmentDetails?: Maybe<EnrolledInstallmentDetails>;
  isEligible: Scalars['Boolean'];
  isEnrolled: Scalars['Boolean'];
  recommendedPaymentMonths?: Maybe<Scalars['Int']>;
};

export type Tpa_EnrollInput = {
  accountId: Scalars['String'];
  allowSaTypes: Scalars['Boolean'];
  arrangementAmount: Scalars['Float'];
  channel: Channel;
  downPaymentAmount: Scalars['Float'];
  installments: Scalars['Int'];
  personId: Scalars['String'];
  saType: SaType;
};

export type Tpa_EnrollmentConfirmation = {
  __typename?: 'TPA_EnrollmentConfirmation';
  confirmationNumber: Scalars['String'];
  enrolledInstallmentDetails?: Maybe<EnrolledInstallmentDetails>;
};

export type Tpa_MonthsMatrix = {
  __typename?: 'TPA_MonthsMatrix';
  doesBillExist: Scalars['Boolean'];
  isPaymentCompleted: Scalars['Boolean'];
  monthNumber: Scalars['Int'];
  monthlyAmount: Scalars['Float'];
  paymentDate: Scalars['DateTime'];
};

export type TimeOfDayEligibility = {
  __typename?: 'TimeOfDayEligibility';
  ineligibilityType?: Maybe<Array<TimeOfDayEligibilityType>>;
  isEligible: Scalars['Boolean'];
};

export const enum TimeOfDayEligibilityType {
  CustomerOrCoCustomer = 'CUSTOMER_OR_CO_CUSTOMER',
  EqualPay = 'EQUAL_PAY',
  PendingFinancialTransactions = 'PENDING_FINANCIAL_TRANSACTIONS',
  Residential_07 = 'RESIDENTIAL_07',
  SaCharNetSpo = 'SA_CHAR_NET_SPO',
  SaStatusMultiple = 'SA_STATUS_MULTIPLE',
  SaStatusNone = 'SA_STATUS_NONE',
  UnenrolledLast_12Months = 'UNENROLLED_LAST_12_MONTHS'
};

export type TimeOfDayEnrollmentConfirmation = {
  __typename?: 'TimeOfDayEnrollmentConfirmation';
  exceptions?: Maybe<Array<Maybe<TimeOfDayEnrollmentException>>>;
  isSuccess: Scalars['Boolean'];
  referenceId: Scalars['String'];
};

export type TimeOfDayEnrollmentException = {
  __typename?: 'TimeOfDayEnrollmentException';
  type: TimeOfDayEnrollmentExceptionType;
  value: Scalars['Boolean'];
};

export const enum TimeOfDayEnrollmentExceptionType {
  Meter = 'METER'
};

export type TimeOfDayEnrollmentInput = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export const enum TimeOfDayEnrollmentStatus {
  Enrolled = 'Enrolled',
  PendingEnroll = 'PendingEnroll',
  PendingEnrolled = 'PendingEnrolled',
  PendingUnenroll = 'PendingUnenroll',
  PendingUnenrolled = 'PendingUnenrolled',
  Unenrolled = 'Unenrolled',
  CancelledEnrollement = 'cancelledEnrollement',
  CancelledUnenrollement = 'cancelledUnenrollement'
};

export type TimeOfDayInfo = {
  __typename?: 'TimeOfDayInfo';
  eligibility?: Maybe<TimeOfDayEligibility>;
  enrollmentStatus: TimeOfDayEnrollmentStatus;
  timeOfDaySummaryInfo?: Maybe<TimeOfDaySummaryInfo>;
};

export type TimeOfDaySummaryInfo = {
  __typename?: 'TimeOfDaySummaryInfo';
  accountId?: Maybe<Scalars['String']>;
  basicBillAmount?: Maybe<Scalars['Float']>;
  deltaBasicToTodAmount?: Maybe<Scalars['Float']>;
  numberOfCalculatedBills?: Maybe<Scalars['Int']>;
  timeofDaySummaryDetails?: Maybe<Array<Maybe<TimeofDaySummaryDetails>>>;
  todBillAmount?: Maybe<Scalars['Float']>;
  totalNumberOfBills?: Maybe<Scalars['Int']>;
};

export type TimeOfDayUnenrollmentConfirmation = {
  __typename?: 'TimeOfDayUnenrollmentConfirmation';
  isSuccess: Scalars['Boolean'];
  referenceId: Scalars['String'];
};

export type TimeOfDayUnenrollmentInput = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  unEnrollmentReasonCd?: InputMaybe<Scalars['String']>;
};

export type TimeofDaySummaryDetails = {
  __typename?: 'TimeofDaySummaryDetails';
  basicBillAmount?: Maybe<Scalars['Float']>;
  billDate?: Maybe<Scalars['String']>;
  todBillAmount?: Maybe<Scalars['Float']>;
};

export type UnregisterResponse = {
  __typename?: 'UnregisterResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateAccountDetailsResponse = ResponseBase & {
  __typename?: 'UpdateAccountDetailsResponse';
  AccountDetail?: Maybe<AccountDetail>;
  code?: Maybe<Scalars['Int']>;
  errorReason?: Maybe<ErrorReason>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateAccountInfoInput = {
  accountType?: InputMaybe<OnlineAccountType>;
  altPhoneInfo?: InputMaybe<AlternatePhoneInfoInput>;
  commContactInfo?: InputMaybe<CommercialContactInfoInput>;
  mailingAddress?: InputMaybe<AddressInput>;
  persons?: InputMaybe<Array<InputMaybe<AccountPersonInput>>>;
  primaryPhone?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountInformationInput = {
  accountType?: InputMaybe<OnlineAccountType>;
  alternatePhone?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  commContactInfo?: InputMaybe<CommercialContactInfoInput>;
  mailingAddress?: InputMaybe<AddressInput>;
  mobilePhone?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
  persons?: InputMaybe<Array<InputMaybe<AccountPersonInput>>>;
  prefLanguage?: InputMaybe<Scalars['String']>;
  primaryPhone?: InputMaybe<Scalars['PhoneNumberPgeFormat']>;
};

export type UpdateAlertDetailsRequest = {
  acctId?: InputMaybe<Scalars['String']>;
  alerts?: InputMaybe<Array<InputMaybe<AlertDetailInfo>>>;
  newPhoneNumber?: InputMaybe<Scalars['String']>;
  originalMobPhone?: InputMaybe<Scalars['String']>;
  originalMobPhoneSequence?: InputMaybe<Scalars['Float']>;
  originallyNotEnrolled?: InputMaybe<Scalars['Boolean']>;
  perId?: InputMaybe<Scalars['String']>;
  prefLanguage?: InputMaybe<Scalars['String']>;
};

export type UpdateAlertDetailsResponse = {
  __typename?: 'UpdateAlertDetailsResponse';
  updateAlertError: Scalars['String'];
};

export const enum UpdateAlertsError {
  ContactExists = 'ContactExists',
  None = 'None',
  Other = 'Other',
  PhoneAttachedToActiveAccount = 'PhoneAttachedToActiveAccount'
};

export type UpdateAlrtDetailsRequest = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  notificationTypes: Array<NotificationTypeInput>;
  prefLanguage?: InputMaybe<Scalars['String']>;
};

export type UpdateAutoPay = {
  __typename?: 'UpdateAutoPay';
  accountNumber: Scalars['String'];
  enrollAutoPayInfo?: Maybe<EnrollAutoPay>;
};

export type UpdateAutoPayInput = {
  accountNumberList: Array<AccountParams>;
  newTokenId: Scalars['String'];
  oldTokenId: Scalars['String'];
};

export type UpdateAutoPayList = {
  __typename?: 'UpdateAutoPayList';
  isPartialSucceed: Scalars['Boolean'];
  updateAutoPayList: Array<Maybe<UpdateAutoPay>>;
};

export type UpdateGroupInput = {
  groupCode?: InputMaybe<Scalars['String']>;
  groupId: Scalars['String'];
  groupName?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateGroupResponse = ResponseBase & {
  __typename?: 'UpdateGroupResponse';
  code?: Maybe<Scalars['Int']>;
  errorReason?: Maybe<ErrorReason>;
  group?: Maybe<Group>;
  groupStatus: GroupStatus;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdatePaperlessBillRequest = {
  email: Scalars['String'];
  encryptedAccountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  isPaperless: Scalars['Boolean'];
};

export type UpdatePaperlessBillResponse = {
  __typename?: 'UpdatePaperlessBillResponse';
  success: Scalars['Boolean'];
};

export type UpdatePersonContactDetailsRequest = {
  activationCodeInput?: InputMaybe<Scalars['String']>;
  emailDetails?: InputMaybe<EmailDetails>;
  encryptedActivationCode?: InputMaybe<Scalars['String']>;
  encryptedPersonId: Scalars['String'];
  phoneDetails?: InputMaybe<PhoneDetails>;
};

export type UpdatePersonContactDetailsResponse = {
  __typename?: 'UpdatePersonContactDetailsResponse';
  emailContactId?: Maybe<Scalars['String']>;
  encryptedEmailId?: Maybe<Scalars['String']>;
  encryptedPhoneId?: Maybe<Scalars['String']>;
  isSuccess?: Maybe<Scalars['Boolean']>;
  phoneContactId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export const enum UpdatePersonContactError {
  ContactExists = 'ContactExists',
  EmailExist = 'EmailExist',
  InvalidCode = 'InvalidCode',
  None = 'None',
  Other = 'Other',
  PhoneAttachedToActiveAccount = 'PhoneAttachedToActiveAccount'
};

export type UpdateServiceAgreementResponse = ResponseBase & {
  __typename?: 'UpdateServiceAgreementResponse';
  code?: Maybe<Scalars['Int']>;
  errorReason?: Maybe<ErrorReason>;
  message?: Maybe<Scalars['String']>;
  serviceAgreementNickname?: Maybe<ServiceAgreementNickname>;
  success?: Maybe<Scalars['Boolean']>;
};

export const enum UpdateValidatePhoneError {
  ContactExists = 'ContactExists',
  InvalidCode = 'InvalidCode',
  None = 'None',
  Other = 'Other',
  PhoneAttachedToActiveAccount = 'PhoneAttachedToActiveAccount'
};

export type UploadFilePgeStoreRequest = {
  description: Scalars['String'];
  featureType: Scalars['String'];
  hasOutage?: InputMaybe<Scalars['Boolean']>;
  isEmergency?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  nearestAddress?: InputMaybe<Scalars['String']>;
  personId: Scalars['String'];
  source: Scalars['String'];
};

export type UploadFilePgeStoreResponse = {
  __typename?: 'UploadFilePgeStoreResponse';
  confirmationId?: Maybe<Scalars['String']>;
  fileUploadStatus?: Maybe<Scalars['Boolean']>;
};

export type UploadStoreRequest = {
  addKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  delKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  featureType: Scalars['String'];
};

export type UploadStoreResponse = {
  __typename?: 'UploadStoreResponse';
  pgeStorekeys?: Maybe<Array<Maybe<FileKeyMap>>>;
  success: Scalars['Boolean'];
};

export const enum UserAccountPremiseAccountType {
  Com = 'COM',
  Eba = 'EBA',
  Res = 'RES',
  Ret = 'RET',
  Self = 'SELF',
  State = 'STATE',
  Summary = 'SUMMARY',
  Unknown = 'UNKNOWN'
};

export type UserAccountPremiseInfo = {
  email?: InputMaybe<Scalars['String']>;
};

export type UserAccountPremiseInfoDetail = {
  __typename?: 'UserAccountPremiseInfoDetail';
  accountNumber?: Maybe<Scalars['String']>;
  mainPersonId?: Maybe<Scalars['String']>;
  premiseId?: Maybe<Scalars['String']>;
};

export type UserAccountPremiseInfoResponse = {
  __typename?: 'UserAccountPremiseInfoResponse';
  userAccountPremiseList?: Maybe<Array<Maybe<UserAccountPremiseInfoDetail>>>;
};

export type UserTokenRequest = {
  application?: InputMaybe<Scalars['String']>;
};

export type UserTokenResponse = {
  __typename?: 'UserTokenResponse';
  application?: Maybe<Scalars['String']>;
  customToken?: Maybe<Scalars['String']>;
};

export type ValidateAccountExistsRequest = {
  lookUpType: AccountLookUpType;
  value: Scalars['String'];
};

export type ValidateAccountExistsResponse = {
  __typename?: 'ValidateAccountExistsResponse';
  result: Scalars['Boolean'];
};

export type ValidateOtlRequest = {
  token: Scalars['String'];
};

export type ValidateOtlResponse = {
  __typename?: 'ValidateOTLResponse';
  customToken?: Maybe<Scalars['String']>;
  isExpired: Scalars['Boolean'];
};

export type ValidatePersonContactValueInput = {
  type: PersonVerificationType;
  value: Scalars['PhoneNumberPgeFormat'];
};

export type ValidatePersonInput = {
  contactValues: Array<InputMaybe<ValidatePersonContactValueInput>>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
};

export type ValidatePersonResponse = {
  __typename?: 'ValidatePersonResponse';
  encryptedPersonId?: Maybe<Scalars['String']>;
  hasOnlineAccount?: Maybe<Scalars['Boolean']>;
  hasPersonalId?: Maybe<Scalars['Boolean']>;
  isBadDebt?: Maybe<Scalars['Boolean']>;
  isExistingPerson?: Maybe<Scalars['Boolean']>;
  isFraud?: Maybe<Scalars['Boolean']>;
  mainHasMultipleAccounts?: Maybe<Scalars['Boolean']>;
  perId?: Maybe<Scalars['String']>;
  primaryIdType?: Maybe<PersonPrimaryIdType>;
  username?: Maybe<Scalars['String']>;
};

export type ValidatePremiseRequest = {
  acctId?: InputMaybe<Scalars['String']>;
  isStopService?: InputMaybe<Scalars['Boolean']>;
  perId?: InputMaybe<Scalars['String']>;
  premiseDetails: Array<InputMaybe<PremiseDetail>>;
  serviceAddress?: InputMaybe<Scalars['String']>;
  validateDate?: InputMaybe<Scalars['String']>;
};

export type ValidatePremiseResponse = {
  __typename?: 'ValidatePremiseResponse';
  eligibility?: Maybe<StartMoveEligibility>;
  premId?: Maybe<Scalars['String']>;
  spList?: Maybe<Array<Maybe<ValidatePremiseSpDetails>>>;
};

export type ValidatePremiseSpDetails = {
  __typename?: 'ValidatePremiseSpDetails';
  isEligible?: Maybe<Scalars['Boolean']>;
  meterStatusInactive?: Maybe<Scalars['Boolean']>;
  pendingDisconnect?: Maybe<Scalars['Boolean']>;
  spId?: Maybe<Scalars['String']>;
  spTypeNotResidential?: Maybe<Scalars['Boolean']>;
};

export const enum VerificationType {
  Dob = 'DOB',
  Ein = 'EIN',
  Phone = 'PHONE'
};

export type VerifiedIdentity = {
  __typename?: 'VerifiedIdentity';
  personId: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type VerifyAccountGroupDetailsReponse = {
  __typename?: 'VerifyAccountGroupDetailsReponse';
  personId?: Maybe<Scalars['String']>;
  reason?: Maybe<VerifyAccountGroupReason>;
};

export type VerifyAccountGroupDetailsRequest = {
  accountNumber: Scalars['String'];
  businessName: Scalars['String'];
  verificationType: AccountGroupVerificationType;
  verificationValue: Scalars['String'];
};

export const enum VerifyAccountGroupReason {
  AccountNotFound = 'AccountNotFound',
  PersonFound = 'PersonFound',
  PersonNotFound = 'PersonNotFound',
  VerificationFailed = 'VerificationFailed'
};

export type VerifyAddPersonAccessTokenRequest = {
  accessToken: Scalars['String'];
  emailAddress: Scalars['String'];
  shouldVerify?: InputMaybe<Scalars['Boolean']>;
};

export type VerifyAddPersonAccessTokenResponse = {
  __typename?: 'VerifyAddPersonAccessTokenResponse';
  errorReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type VerifyEmailResponse = {
  __typename?: 'VerifyEmailResponse';
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export type VerifyEqualsEncryptedValueRequest = {
  clearTextValue: Scalars['String'];
  encryptedValue: Scalars['String'];
};

export type VerifyEqualsEncryptedValueResponse = {
  __typename?: 'VerifyEqualsEncryptedValueResponse';
  result: Scalars['Boolean'];
};

export type VerifyIdentityInput = {
  identity: IdentityInput;
  personId: Scalars['String'];
};

export const enum VerifyLevel {
  InteractionRequired = 'InteractionRequired',
  Multiple = 'Multiple',
  None = 'None',
  PremisesPartial = 'PremisesPartial',
  StreetPartial = 'StreetPartial',
  Verified = 'Verified'
};

export type ViewBillAverageTemperature = {
  __typename?: 'ViewBillAverageTemperature';
  currentBillingPeriod?: Maybe<ViewBillAverageTemperatureBillingPeriod>;
  previousBillingPeriod?: Maybe<ViewBillAverageTemperatureBillingPeriod>;
  temperatureSource?: Maybe<Scalars['String']>;
};

export type ViewBillAverageTemperatureBillingPeriod = {
  __typename?: 'ViewBillAverageTemperatureBillingPeriod';
  averageTemperature?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTimeCustom']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalKwh?: Maybe<Scalars['Float']>;
};

export type ViewBillDetails = {
  __typename?: 'ViewBillDetails';
  amountDue?: Maybe<Scalars['Float']>;
  billDate?: Maybe<Scalars['DateTimeCustom']>;
  billStatus?: Maybe<Scalars['String']>;
  billingPeriodEndDate?: Maybe<Scalars['DateTimeCustom']>;
  billingPeriodStartDate?: Maybe<Scalars['DateTimeCustom']>;
  downloadBillUrl?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTimeCustom']>;
  encryptedAccountNumber?: Maybe<Scalars['String']>;
  encryptedBillId?: Maybe<Scalars['String']>;
  encryptedSummaryBillId?: Maybe<Scalars['String']>;
  hasBills?: Maybe<Scalars['Boolean']>;
  isTokenExpired?: Maybe<Scalars['Boolean']>;
  kwh?: Maybe<Scalars['Float']>;
  previousBalance?: Maybe<Scalars['Float']>;
  totalAdjustments?: Maybe<Scalars['Float']>;
  totalBalanceAfterBill?: Maybe<Scalars['Float']>;
  totalCurrentCharges?: Maybe<Scalars['Float']>;
};

export type ViewBillDetailsRequest = {
  encryptedAccountNumber: Scalars['String'];
};

export type ViewBillInfo = {
  __typename?: 'ViewBillInfo';
  /** @deprecated Not required since amountDue field in here not used by IVA */
  amountDue?: Maybe<Scalars['Float']>;
  amountDueOnBill?: Maybe<Scalars['Float']>;
  billDate?: Maybe<Scalars['DateTimeISO']>;
  billStatus?: Maybe<Scalars['String']>;
  /** @deprecated Not required since billingPeriodEndDate field in here not used by IVA */
  billingPeriodEndDate?: Maybe<Scalars['DateTimeISO']>;
  /** @deprecated Not required since billingPeriodStartDate field in here not used by IVA */
  billingPeriodStartDate?: Maybe<Scalars['DateTimeISO']>;
  dueDate?: Maybe<Scalars['DateTimeISO']>;
  /** @deprecated Not required since kwh field in here not used by IVA */
  kwh?: Maybe<Scalars['Float']>;
  previousBalance?: Maybe<Scalars['Float']>;
  totalAdjustments?: Maybe<Scalars['Float']>;
  totalBalanceAfterBill?: Maybe<Scalars['Float']>;
  totalCurrentCharges?: Maybe<Scalars['Float']>;
};

export type ViewBillInfoResponse = {
  __typename?: 'ViewBillInfoResponse';
  viewBillAverageTemperature?: Maybe<ViewBillAverageTemperature>;
  viewBillDetails?: Maybe<ViewBillDetails>;
  viewBillMonthUsage?: Maybe<ViewBillMonthlyUsage>;
};

export type ViewBillMonthlyUsage = {
  __typename?: 'ViewBillMonthlyUsage';
  usages?: Maybe<Array<Maybe<ViewBillMonthlyUsageDetails>>>;
};

export type ViewBillMonthlyUsageDetails = {
  __typename?: 'ViewBillMonthlyUsageDetails';
  monthName?: Maybe<Scalars['String']>;
  totalKwh?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['String']>;
};

export const enum ViewPaymentHistoryBillingAndPaymentType {
  Agency = 'Agency',
  Bill = 'Bill',
  Payment = 'Payment',
  PendingAgencyPay = 'PendingAgencyPay',
  PendingPayment = 'PendingPayment',
  Unknown = 'Unknown'
};

export type ViewPaymentHistoryBillingUsageResponse = {
  __typename?: 'ViewPaymentHistoryBillingUsageResponse';
  billingUsages?: Maybe<Array<Maybe<ViewPaymentHistoryDetail>>>;
  totalDetailsRecords?: Maybe<Scalars['Int']>;
};

export type ViewPaymentHistoryDetail = {
  __typename?: 'ViewPaymentHistoryDetail';
  amountDue?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  billingPeriodEndDate?: Maybe<Scalars['DateTimeCustom']>;
  billingPeriodStartDate?: Maybe<Scalars['DateTimeCustom']>;
  date?: Maybe<Scalars['DateTimeCustom']>;
  encryptedBillId?: Maybe<Scalars['String']>;
  encryptedSummaryBillId?: Maybe<Scalars['String']>;
  kwh?: Maybe<Scalars['Float']>;
  type?: Maybe<ViewPaymentHistoryBillingAndPaymentType>;
};

export type ViewPaymentHistoryDetailResponse = {
  __typename?: 'ViewPaymentHistoryDetailResponse';
  paymentHistoryDetails?: Maybe<Array<Maybe<ViewPaymentHistoryDetail>>>;
  totalDetailsRecords?: Maybe<Scalars['Int']>;
};

export type ViewPaymentHistoryInput = {
  encryptedAccountNumber?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTimeCustom']>;
  startDate?: InputMaybe<Scalars['DateTimeCustom']>;
};

export type ViewPaymentHistoryResponse = {
  __typename?: 'ViewPaymentHistoryResponse';
  amountDue?: Maybe<Scalars['Float']>;
  balanceForward?: Maybe<Scalars['Float']>;
  billingAndPaymentDetails?: Maybe<Array<Maybe<ViewPaymentHistoryDetail>>>;
  dueDate?: Maybe<Scalars['DateTimeCustom']>;
  paymentAdjustments?: Maybe<Scalars['Float']>;
  totalDetailsRecords?: Maybe<Scalars['Float']>;
};

export type ZipCodeInfo = {
  __typename?: 'ZipCodeInfo';
  lat?: Maybe<Scalars['Latitude']>;
  long?: Maybe<Scalars['Longitude']>;
  totalCustomersEffected: Scalars['Int'];
  zipCode: Scalars['String'];
};

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type AniEligibility = {
  __typename?: 'aniEligibility';
  isEligible?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type BillInfoParams = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
};

export type EmailDetails = {
  email: Scalars['String'];
  emailType: Scalars['String'];
  isEmailActive: Scalars['String'];
  isPrimaryEmail: Scalars['String'];
};

export const enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
};

export type LinkedPersonApplicant = {
  acctId: Scalars['String'];
  additionalInfo: LinkedApplicantAdditionalInfo;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  loggedInUserName?: InputMaybe<Scalars['String']>;
  mainPerId?: InputMaybe<Scalars['String']>;
  mainPersonEmailId?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  updatedBy: Scalars['String'];
};

export type PendingUserCommPreference = {
  inputData?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type PhoneDetails = {
  isPhoneActive: Scalars['String'];
  isPrimaryPhone: Scalars['String'];
  phone: Scalars['String'];
  phoneType: Scalars['String'];
};

export type UpdateMailingAddressInput = {
  accountNumber: Scalars['String'];
  encryptedPersonId: Scalars['String'];
  mailingAddress: MailingAddress;
};

export type UpdateProfilePhoneInput = {
  encryptedPersonId: Scalars['String'];
  /**
   * - Must not be false if phoneType is PrimaryNotificationPhone
   * - Must not be true if phoneType is Alternate
   */
  isPrimary: Scalars['Boolean'];
  phoneNumber: Scalars['PhoneNumber'];
  /** Only types allowed for this Mutation: Mobile, Alternate or PrimaryNotificationPhone */
  phoneType: PhoneType;
};

export type UpdateTextPhoneInput = {
  activationCodeInput?: InputMaybe<Scalars['String']>;
  encryptedActivationCode?: InputMaybe<Scalars['String']>;
  encryptedPersonId: Scalars['String'];
  isActive?: InputMaybe<Scalars['String']>;
  /**
   * - Must not be false if phoneType is PrimaryNotificationPhone
   * - Must not be true if phoneType is Alternate
   */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  phoneNumber: Scalars['PhoneNumber'];
  /** Only types allowed for this Mutation: Mobile, Alternate or PrimaryNotificationPhone */
  phoneType?: InputMaybe<PhoneType>;
};

export type UpdateTextPhoneResponse = {
  __typename?: 'updateTextPhoneResponse';
  encryptedContactId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  updatePhoneError?: Maybe<UpdateValidatePhoneError>;
};
