import { styled } from '@mui/material/styles';
import TextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from '@mui/material/TextField';
import React, { useState } from 'react';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import DelayedTextMask from '../delayed-text-mask';
import { InputAdornment, Typography } from '@mui/material';

export interface CommonInputProps {
  icon?: object;
  showTooltip?: boolean;
  adornment?: any;

  // TODO: fix these types
  delayedTextMask?: any;
  endAdornmentDataTestId?: any;
  numbersOnly?: any;
}

export interface CustomStandardTextFieldProps extends CommonInputProps, StandardTextFieldProps {}
export interface CustomFilledTextFieldProps extends CommonInputProps, FilledTextFieldProps {}
export interface CustomOutlinedTextFieldProps extends CommonInputProps, OutlinedTextFieldProps {}

export type TextFieldProps =
  | CustomStandardTextFieldProps
  | CustomFilledTextFieldProps
  | CustomOutlinedTextFieldProps;

const TextFieldWrapped = React.forwardRef((
  {
    InputProps,
    inputProps,
    delayedTextMask,
    endAdornmentDataTestId,
    numbersOnly,
    onPaste,
    onChange,
    style,
    classes,
    variant,
    onFocus,
    onBlur,
    value,
    ...props
  }: TextFieldProps,
  ref?: React.ForwardedRef<HTMLDivElement>
) => {
    const [textIsVisible, toggleTextVisibility] = useState<boolean>(false);
    const [shrink, setShrink] = useState<boolean>(false);

    const handleFocus = (e: any) => {
      setShrink(true);
      onFocus && onFocus(e);
    };

    const handleBlur = (e: any) => {
      onBlur && onBlur(e);
      if (e.target.value) {
        setShrink(true);
      }
      else {
        setShrink(false);
      }
    };

    const isDelayedTextMask = Boolean(delayedTextMask)

    const maskedInputProps = {
      ...InputProps,
      ...(delayedTextMask && {
        inputComponent: DelayedTextMask,
        endAdornment: (
          <InputAdornment
            data-testid={
              endAdornmentDataTestId
                ? endAdornmentDataTestId
                : 'text-visibility'
            }
            onClick={() => toggleTextVisibility(!textIsVisible)}
            style={{ cursor: 'pointer' }}
            position={'end'}
          >
            <Typography variant="body2" color="primary">
              {textIsVisible ? (
                <Visibility data-testid="visibility-on" />
              ) : (
                <VisibilityOff data-testid="visibility-off" />
              )}
            </Typography>
          </InputAdornment>
        ),
      }),
    };

    const maskedinputProps = {
      ...inputProps,
      ...(delayedTextMask && {
        show: textIsVisible,
        numbersOnly: numbersOnly,
      }),
    };

    // should we add these regardless of delayedtextmask???
    const delayedTextMaskProps = {
      InputLabelProps: { shrink: Boolean(value) || shrink },
      onFocus: handleFocus,
      onBlur: handleBlur,
    }

    return (
      <TextField
        onPaste={onPaste}
        onChange={onChange}
        style={style || { width: '100%' }}
        css={{
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.67)',
          },
          background: '#fff',
        }}
        classes={classes}
        InputProps={isDelayedTextMask ? maskedInputProps : InputProps}
        inputProps={isDelayedTextMask ? maskedinputProps : inputProps}
        ref={ref}
        variant={variant ? variant : 'outlined'}
        { ...(isDelayedTextMask ? delayedTextMaskProps : {}) }
        { ...props }
      />
    )
  },
);

export default TextFieldWrapped;
