import { ClickAwayListener } from '@mui/material';
import { Link } from '@gatsbyjs/reach-router';
import React, { useContext, useMemo, useState } from 'react';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import { useNavigationTranslations } from '../../queries/translations/navigationTranslations.query';
import NavigationRegistration from './NavigationRegistration';

import TEST_IDS from '../../constants/test_ids';
import { navigate } from 'gatsby';
import { css } from '@emotion/react'

const EXIT_DELAY = 180;
const ENTRY_DELAY = 550;
let exitTimerRef: any = null;
let entryTimerRef: any = null;

const MenuLink = ({ link, navigateToRoute }: any) => {
  /*
    for all <a> tags, override the href and use onClick to navigate
    <a
      href={route} // leave the route in tact for right-clicking and opening in a new tab/window
      onClick={(e: any) => { e.preventDefault(); navigateToRoute(route);}}
    >

    as this will preserve the single-page-application feel when navigating
    between pages
  */
  if (link?.slug) {
    // check if this seems to be an external link
    // if not, make sure that the slug starts with a slash
    let slug = link.slug;
    let newTab = false;
    const relText = 'noreferrer noopener';
    if (slug.includes('http')) {
      newTab = true;
    } else {
      slug = slug.charAt(0) === '/' ? slug : '/' + slug;
    }

    return (
      <li>
        <a
          aria-label={link.label}
          href={slug}
          target={newTab ? '_blank' : '_self'}
          rel={newTab ? relText : undefined}
          onClick={(e: any) => {
            if (newTab) {
              return true;
            }
            e.preventDefault();
            return navigateToRoute(slug);
          }}
        >
          {link.label}
        </a>
      </li>
    );
  }

  if (link?.link) {
    return (
      <li>
        <a
          aria-label={link.label}
          href={link.link}
          onClick={(e: any) => {
            e.preventDefault();
            return navigateToRoute(link.link);
          }}
        >
          {link.label}
        </a>
      </li>
    );
  }

  return (
    <li>
      <a
        aria-label={link.title || link.label}
        href={link.url}
        onClick={(e: any) => {
          e.preventDefault();
          return navigateToRoute(link.url);
        }}
      >
        {link.title || link.label}
      </a>
    </li>
  );
};

export const MegaMenuSection = ({
  components,
  items,
  navigateToRoute,
}: any) => {
  /*
    for all <a> tags, override the href and use onClick to navigate
    <a
      href={route} // leave the route in tact for right-clicking and opening in a new tab/window
      onClick={(e: any) => { e.preventDefault(); navigateToRoute(route);}}
    >

    as this will preserve the single-page-application feel when navigating
    between pages
  */
  return (
    <ul>
      {items?.map((menuItem: any) => {
        if (menuItem.mainNavMobileOnly) {
          return null;
        }

        return (
          <li key={menuItem.label}>
            <a
              aria-label={menuItem.label}
              href={menuItem.link}
              onClick={(e: any) => {
                e.preventDefault();
                return navigateToRoute(menuItem.link);
              }}
            >
              {menuItem.label}
            </a>
            <ul>
              {menuItem.links &&
                menuItem.links.map((link: any, subLinkIndex: number) => (
                  <MenuLink
                    link={link}
                    key={`${menuItem.label}-${subLinkIndex}`}
                    navigateToRoute={navigateToRoute}
                  />
                ))}
            </ul>
          </li>
        );
      })}
      {components?.map((component: any) => {
        const {
          component: Component,
          label,
          style,
          props: formProps,
        } = component;
        return (
          <li key={label} style={style}>
            <Component {...formProps} />
          </li>
        );
      })}
      {items?.length > 0 && <li style={{ flexGrow: 1 }} />}
    </ul>
  );
};

interface NavigationMegaMenuProps {
  isAuthenticated: boolean;
}

export const NavigationMegaMenu = ({
  isAuthenticated,
}: NavigationMegaMenuProps) => {
  const { navigation } = useNavigationTranslations();
  const { isPlannedDownTime } = useContext(featureFlagsContext);

  const [displayedMenu, setDisplayedMenu] = useState<any>(undefined);
  const [displayedIndex, setDisplayedIndex] = useState<number>(-1);

  const handleClose = () => {
    setDisplayedIndex(-1);
    setDisplayedMenu(undefined);
  };
  const navigateToRoute = (route: string) => {
    handleClose();
    return navigate(route);
  };

  const menus = useMemo(() => {
    if (isAuthenticated) {
      return navigation?.menus;
    }

    return (navigation?.menus || []).reduce(
      (acc: any, menuItem) => {
        if ((menuItem?.label || '') === 'My Account') {
          const newMenuItem = {
            ...menuItem,
            components: isPlannedDownTime
              ? []
              : [
                  {
                    label: 'RegisterForm',
                    component: NavigationRegistration,
                    style: {
                      paddingRight: 100,
                      paddingBottom: 50,
                    },
                    props: {
                      navigateToRoute,
                    },
                  },
                ],
          };
          acc.push(newMenuItem);
        } else {
          acc.push(menuItem);
        }
        return acc;
      },
      [isPlannedDownTime],
    );
  }, [isAuthenticated, navigation]);

  const handleOpen = (index: number) => {
    clearTimeout(exitTimerRef);
    const showMenu = () => {
      setDisplayedIndex(index);
      setDisplayedMenu(menus ? menus[index] : undefined);
    };
    entryTimerRef = setTimeout(showMenu, displayedMenu ? 0 : ENTRY_DELAY);
  };

  const skipOverMenu = () => {
    if (entryTimerRef && !displayedMenu) {
      clearTimeout(entryTimerRef);
    }
  };

  const handleExit = () => {
    exitTimerRef = setTimeout(handleClose, EXIT_DELAY);
  };

  const renderMenuHeader = (
    menuItem: any,
    index: number,
  ): JSX.Element | null => {
    // we are going to check to see if we are in a page or sub page of the
    // path so we can tag the first level of menu as active.
    let active = false;
    if (typeof window !== 'undefined') {
      const fullPath = window.location.pathname;
      const firstPart = fullPath.split('/')[1];
      const linkUrl = menuItem.url?.replace(/\//g, '');
      active = firstPart === linkUrl;
    }
    const displayed = displayedIndex === index ? 'displayed' : '';
    const activeClass = active ? 'active' : '';
    const classes = `${displayed} ${activeClass}`;
    return menuItem.label ? (
      <li key={menuItem.label || index}>
        {menuItem.url ? (
          <Link
            // this looses the tab. keyboarders have to hit enter.
            // onFocus={() => handleOpen(index)}
            to={menuItem.url || '/'}
            onMouseEnter={() => handleOpen(index)}
            onMouseLeave={skipOverMenu}
            onClick={() => {
              handleClose();
              skipOverMenu();
            }}
            aria-label={menuItem.label}
            className={classes}
          >
            {menuItem.label}
          </Link>
        ) : (
          <a
            href="#"
            className={displayedIndex === index ? 'displayed' : ''}
            onMouseEnter={() => handleOpen(index)}
            onMouseLeave={skipOverMenu}
            onClick={(e: any) => {
              e.preventDefault();
            }}
          >
            {menuItem.label}
          </a>
        )}
      </li>
    ) : null;
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div data-testid={TEST_IDS.NAV_ANCHOR} css={css`
        position: relative;
      `}>
        <nav role="navigation" aria-label="main" css={css`
          width: 100%;
          height: 45px;
          background-color: #203240;

          & ul {
            margin: 0 auto;
            padding: 0;
            max-width: 1180px;
            display: flex;
            height: 45px;
            align-items: center;
            list-style: none;
            justify-content: space-between;
          }

          & ul li {
            flex-basis: auto;
          }

          & ul li a {
            padding: 0 20px;
            display: flex;
            color: #fff;
            font-size: 16px;
            text-decoration: none;
            text-align: center;
            height: 45px;
            align-items: center;
          }

          & ul li a:hover,
          & ul li a.displayed,
          & ul li a.active {
            background-color: #0075bb;
          }

          & > ul li a:hover {
            text-decoration-line: underline;
            text-decoration-color: #e7b000
          }
        `}>
          <ul>{(menus || []).map(renderMenuHeader)}</ul>
        </nav>
        {displayedMenu && (
          <div onMouseLeave={handleExit} css={css`
            & > ul {
              margin: 0 auto;
              padding: 0;
              max-width: 1180px;
              display: flex;
              height: 45px;
              align-items: center;
              list-style: none;
            }


            & > ul li {
              flex:1;
              width: 250px;
            }

            & {
              z-index: 99;
              background-color: #0075bb;
              color: #fff;
              position: absolute;
              width: 100%;
              height: 345px;
            }
            & > ul {
              height: auto;
              align-items: flex-start;
            }

            & > ul li {
              margin-right: 45px;
              padding-right: 10px;
              margin-top: 15px;
              border-right: 1px solid #318bc2;
            }

            & > ul li ul {
              list-style: none;
              display: flex;
              flex-direction: column;
              padding: 0;
            }

            & > ul li ul li {
              border: none;
              margin: 0;
            }
            & > ul li:last-child {
              border: none;
            }
            & > ul li a {
              font-weight: bold;
              height: auto;
              text-align: left;
              height: auto;
            }

            & > ul li ul li a {
              font-size: 14px;
              line-height: 29px;
              font-weight: normal;
              border-bottom: 1px solid #0075bb;
              display: inline;
            }

            & > ul li a {
              display: flex;
              color: #fff;
              font-size: 16px;
              text-decoration: none;
              text-align: center;
              height: 45px;
              align-items: center;
            }

            & > ul li ul li a:hover,
            & > ul li a:hover {
              text-decoration-line: underline;
              text-decoration-color: #e7b000
            }
          `}>
            <MegaMenuSection
              items={displayedMenu?.groups}
              components={displayedMenu?.components}
              navigateToRoute={navigateToRoute}
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
