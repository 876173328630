import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import components from './components';
import palette from './palette';
import typography from './typography';

const theme: any = createTheme({
  palette,
  typography,
  components,
  breakpoints: { // Maintain v4 defaults
       values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
  },
});

export default responsiveFontSizes(theme, {
  breakpoints: ['sm'],
  disableAlign: true,
  factor: 1.5,
});
