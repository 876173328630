import createTypography from '@mui/material/styles/createTypography';
import palette from './palette';

export default createTypography(palette, {
  fontFamily: [
    'Proxima-Nova',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
  ].join(','),
  h1: {
    fontSize: '2.25rem',
    fontWeight: 300,
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 300,
  },
  h3: {
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  h4: {
    fontSize: '1.125rem',
    fontWeight: 400,
  },
  h5: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  body1: {
    fontSize: '1.125rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'none',
  },
});
