/**
 * TODO:
 * Adopt as the only PgeButton. This is a part of a larger effort to combine
 * all buttons and links into a single component.
 */

import {
  useState,
  forwardRef,
  ForwardRefExoticComponent as FREC,
  Fragment,
} from 'react';
import { Button, ButtonProps, useTheme } from '@mui/material';
import SvgMap from '../svg-map';
import Overlay from '../pge-overlay/_Overlay';
import { usePathContext } from '../../providers/PathProvider';
import useLink, { Link } from '../../hooks/useLink';
import { isOverlay } from '../../util/type-checking';
import {
  FileType,
  LinkType,
  Icon,
  Size,
  Shadow,
  Button as ButtonConstants,
} from '../../constants';

interface Base extends ButtonProps {
  readonly link?: Ctfl.Content['Link'] | string | Queries.ListItemFragment['titleLink'];
  readonly theme?: ButtonConstants['Theme'];
  readonly display?: ButtonConstants['Display'];
  readonly startIcon?: Icon['Type'];
  readonly endIcon?: Icon['Type'];
  readonly disableRippleEffect?: boolean;
}

export type PgeButtonProps = Partial<
  Omit<Base, 'color' | 'variant' | 'classes' | 'target' | 'rel'>
>;

const PgeButton: FREC<PgeButtonProps> = forwardRef(
  (
    {
      link,
      children,
      startIcon,
      endIcon,
      className = '',
      theme = 'Primary',
      display = 'Block',
      disableRippleEffect,
      ...props
    },
    ref,
  ) => {
    // Vars
    const href = useLink(link as Link);
    const overlay = isOverlay(link) ? link : null;
    const isButton = link === undefined || !!overlay;
    const isAnchor = !isButton && !!href;

    if ((isButton || overlay) && !children) {
      return null;
    }

    if (!isButton && !isAnchor) {
      return <Fragment>{children}</Fragment>;
    }

    const externalLink = href.match(LinkType.HTTP);
    const documentLink = href.match(FileType.DOC);
    const imageLink = href.match(FileType.IMG);

    let inlineIcon: Icon['Type'];
    if (documentLink?.[1] === 'xls' || documentLink?.[1] === 'xlsm') {
      inlineIcon = 'xlsx';
    } else {
      inlineIcon = (documentLink?.[1] as Icon['Type']) || externalLink?.[0];
    }

    const isBlank = !!externalLink || !!documentLink || !!imageLink;
    const iconEnd = theme === 'Text' ? inlineIcon : endIcon;

    const { resolve } = usePathContext();

    const muiTheme = useTheme();
    const white = muiTheme.palette.common.white;
    const text = muiTheme.palette.primary.contrastText;
    const primary = muiTheme.palette.primary.main;
    const emphasis = muiTheme.palette.warning.main;
    const back = muiTheme.palette.grey[800];
    const disabled = muiTheme.palette.grey[600];
    const darkText = muiTheme.palette.text.primary;

    const [open, setOpen] = useState(false);

    const variant = theme === 'Outline' ? 'outlined'
      : theme === 'Secondary' ? 'outlined'
      : theme === 'Pill' ? 'outlined'
      : theme === 'Text' ? 'text'
      : 'contained'

    return (
      <Fragment>
        <Button
          className={`PgeButton btn-${theme} btn-${display} ${className}`}
          component={isAnchor ? 'a' : 'button'}
          href={isAnchor ? resolve(href) : undefined}
          target={isBlank ? '_blank' : undefined}
          rel={isBlank ? 'noopener noreferrer' : undefined}
          startIcon={startIcon ? <SvgMap use={startIcon} /> : undefined}
          endIcon={iconEnd ? <SvgMap use={iconEnd} /> : undefined}
          onClick={overlay ? () => setOpen(true) : undefined}
          ref={ref}
          disableRipple={disableRippleEffect ? true : false}
          variant={variant}
          color={'primary'}
          css={{
            boxSizing: 'border-box',

            '&.btn-Block': {
              minHeight: Size.TOUCH,
              fontWeight: 500,
              lineHeight: 0.9,
            },

            '&:not(.btn-Text)': {
              padding: '2ex 2ch 1.8ex',
              fontSize: '1.125rem',
            },

            '& svg': {
              width: 14,
              height: 14,
            },

            '.bkg-Dark &.btn-Text': {
              position: 'relative',

              '& span': {
                zIndex: 2,
              },

              '&::before': {
                boxSizing: 'content-box',
                content: '""',
                display: 'block',
                position: 'absolute',
                width: '100%',
                height: '100%',
                padding: '0 4px',
                background: 'transparent',
                zIndex: 1,
              },

              '&:hover, &:focus': {
                color: darkText,
                textDecoration: 'none',

                '&::before': {
                  background: white,
                },
              },
            },

            '&.MuiButton-contained': {
              boxShadow: 'none',

              '&:hover, &:focus': {
                boxShadow: Shadow.TWO,
              },
            },

            '&.MuiButton-disabled': {
              background: disabled,
              opacity: 1,
              pointerEvents: 'none',
            },

            '&.MuiButton-outlined': {
              borderWidth: 2,

              '&:hover, &:focus': {
                borderWidth: 2,
                boxShadow: Shadow.TWO,
              },
            },

            '&.MuiButton-endIcon': {
              margin: '0 1px 1px 4px',
            },

            '&.MuiButton-startIcon': {
              margin: '0 4px 1px 1px',
            },

            '&.MuiButton-containedPrimary': theme === 'Back/Cancel'
              ? {
                background: back,
                color: text,
              } : theme === 'Primary' ? {
                '&:hover, &:focus': {
                  background: primary,
                },
              } : theme === 'Emphasis' ? {
                background: emphasis,
                color: text,

                '&:hover, &:focus': {
                  background: emphasis,
                },
              } : {},

            '.MuiButton-outlinedPrimary': theme === 'Outline'
              ? {
                background: 'none',
                color: text,
                borderColor: text,

                '&:hover, &:focus': {
                  background: 'none',
                  borderColor: text,
                },
              } : theme === 'Secondary' ? {
                borderColor: primary,

                '&:hover, &:focus': {
                  background: 'none',
                },
              } : theme === 'Pill' ? {
                background: text,
                borderRadius: '60px',
                boxShadow: Shadow.ONE,

                '&:hover, &:focus': {
                  background: text,
                  boxShadow: Shadow.THREE,
                },
              } : {},

            '&.MuiButton-textPrimary': {
              padding: 0,
              textAlign: 'inherit',

              '&.btn-Block': {
                fontSize: '1rem',
              },

              '&.btn-Inline': {
                minWidth: 'unset',
                font: 'inherit',
                verticalAlign: 'unset',
                display: 'inline-block',
              },

              '&:hover, &:focus': {
                background: 'none',
                textDecoration: 'underline',
              },
            },
          }}
          {...props}
        >
          <span>
            {children || href}
          </span>
        </Button>
        {overlay && (
          <Overlay
            content={overlay.content}
            open={open}
            setOpen={setOpen}
            entryName={overlay.entryName}
            statusAlert={overlay.statusAlert}
          />
        )}
      </Fragment>
    );
  },
);

export default PgeButton;
