import React from 'react';
import { styled , Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import RelatedList from '../related/RelatedList';

const PREFIX = 'Toc';

const classes = {
  root: `${PREFIX}-root`,
  data: `${PREFIX}-data`,
  image: `${PREFIX}-image`,
  pbLg: `${PREFIX}-pbLg`,
  pbSm: `${PREFIX}-pbSm`,
  pbMd: `${PREFIX}-pbMd`
};

const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.root}`]: (props: TocProps) => ({
    backgroundColor: props?.colorBackground
      ? props?.colorBackground
      : 'transparent',
    borderRadius: theme.typography.pxToRem(5),
    boxShadow: '0px 3px 6px #00002930',
    '& a': {
      textDecoration: 'none',
    },
  }),

  [`&.${classes.data}`]: {
    padding: theme.spacing(3.75, 5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5),
    },
  },

  [`& .${classes.image}`]: {
    width: '100%',
    height: theme.spacing(34.375),
    borderRadius: theme.spacing(0.625, 0.625, 0, 0),
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(16.875),
    },
  },

  [`& .${classes.pbLg}`]: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.typography.pxToRem(40),
    },
    paddingTop: theme.typography.pxToRem(10),
    paddingBottom: theme.typography.pxToRem(30),
  },

  [`& .${classes.pbSm}`]: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.typography.pxToRem(25),
    },
    paddingBottom: theme.typography.pxToRem(16),
  },

  [`& .${classes.pbMd}`]: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.typography.pxToRem(30),
    },
    paddingBottom: theme.typography.pxToRem(20),
    borderRadius: theme.spacing(0.625),
  }
}));

export interface TocProps {
  colorBackground?: string;
  distributeAcrossColumns?: string;
  tocHeader?: string;
  tocImage?: any;
  tocIntroCopy?: JSX.Element | null;
  addTocItems?: any;
}

const Toc: React.FC<TocProps> = props => {

  const {
    tocImage,
    colorBackground,
    distributeAcrossColumns,
    tocHeader,
    tocIntroCopy,
    addTocItems,
  } = props;

  let toc;

  const columnCount = distributeAcrossColumns
    ? parseInt(distributeAcrossColumns)
    : 2;
  if (addTocItems) {
    const columns: Array<JSX.Element> = [];
    for (const property of addTocItems) {
      columns.push(<RelatedList list={property} componentType="toc" />);
    }
    const grids = (
      <React.Fragment>
        <Grid item xs={12} md={12}>
          <Grid container>
            {columns.map((item, index) => (
              <Grid
                item
                xs={12}
                md={columnCount === 3 ? 4 : columnCount === 2 ? 6 : 12}
                key={index}
              >
                {item}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </React.Fragment>
    );
    toc = (
      <>
        {tocImage && (
          <img
            className={classes.image}
            src={tocImage?.file?.url}
            alt={tocHeader}
            height="275"
            width="1200"
          />
        )}
        <Root className={classes.data}>
          {tocHeader && (
            <Typography variant="h1" style={{ paddingBottom: '10px' }}>
              {tocHeader}
            </Typography>
          )}
          {tocIntroCopy && <>{tocIntroCopy}</>}
          <Grid container className={classes.pbLg}>
            {grids}
          </Grid>
        </Root>
      </>
    );
  }

  return <div className={classes.root}>{toc}</div>;
};

export default Toc;
