import { local } from './storage';
import AuthService, { TokenStorage } from './AuthenticationService';
import {
  ApigeeAuthAdapter,
  FirebaseAuthAdapter,
  HybridAuthAdapter,
} from './AuthenticationService/authAdapters';
import config from '../config';

const apigeeAdapter = new ApigeeAuthAdapter({
  baseUrl: config.apigee.baseUrl,
  clientId: config.apigee.auth.clientId,
});

const firebaseAdapter = new FirebaseAuthAdapter();

const hybridAdapter = new HybridAuthAdapter(firebaseAdapter, apigeeAdapter);
const tokensStorage = new TokenStorage(local, 'pge-token');

export default new AuthService(hybridAdapter, tokensStorage);
