import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons/PGE-Button';
import useAccountCustomer from '../../hooks/useAccountCustomer';

import { usePromotionContext } from '../../providers/PromotionProvider';

export const NavigationUser = ({
  signOut,
  language,
  handleLanguageChange,
}: any) => {
  const { t } = useTranslation();
  const { customer: user } = useAccountCustomer();

  const { promotion } = usePromotionContext();

  return (
    <div className="user-logged-in">
      {user !== undefined && (
        <div className={`item${promotion ? ' promotion' : ''}`}>
          {user.email}
        </div>
      )}
      <div className="item">
        <PGEButton
          data-testid={'sign-in-route-button'}
          variant={'outlined'}
          color={promotion ? 'secondary' : 'primary'}
          onClick={() => signOut()}
          style={{ whiteSpace: 'nowrap' }}
        >
          {t('SIGN_OUT')}
        </PGEButton>
      </div>
      {/*
      // TODO POST MVP
      <LanguageSelect
        currentLanguage={language}
        languages={[
          { label: 'English', value: 'en' },
          { label: 'Español', value: 'es' },
        ]}
        onChange={(lang: any) => handleLanguageChange(lang.value)}
      />
      */}
    </div>
  );
};
