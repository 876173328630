import React from 'react';
import { styled , Theme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ProgramCard from '../../program-card';
import RichText from '../../rich-text';
import useAttrs from '../../../hooks/useAttrs';
import { CardLayoutProps as Props, Omits } from './types.d';
const PREFIX = 'CardLayout';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  outline: `${PREFIX}-outline`
};

const Root = styled('section')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.root}`]: {
    contain: 'layout',
    width: '100%',
    marginTop: theme.spacing(7),

    '.CardLayout + & ': {
      marginTop: theme.spacing(14),
    },

    '& > .RichText': {
      marginBottom: theme.spacing(3),
    },
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',

    [theme.breakpoints.up('sm')]: {
      '& > *:not(:first-child)': {
        marginLeft: 'auto',
      },

      '& > *:not(:last-child)': {
        marginRight: 'auto',
      },

      '&.col-2 > *:nth-child(2n+3), &.col-3 > *:nth-child(3n+4)': {
        marginLeft: 0,
      },

      '&.col-2 > *:nth-child(2n+2), &.col-3 > *:nth-child(3n+3)': {
        marginRight: 0,
      },
    },
  },

  [`& .${classes.outline}`]: {
    width: 1,
    height: 1,
    position: 'absolute',
    overflow: 'hidden',
  }
}));




const CardLayout = ({
  promoHeader,
  promoIntroCopy,
  addItems,
  columns,
  ...props
}: Props): ElementNode => {
  // Default props
  const _columns = columns || '1';

  // Vars

  const { className = '', ...attrs } = useAttrs<Props>(props, Omits);


  return (
    <Root
      className={`CardLayout ${classes.root} ${className}`}
      aria-label={promoHeader || undefined}
      {...attrs}
    >
      {promoHeader && <Typography variant="h1">{promoHeader}</Typography>}
      <RichText className={'RichText'}>
        {promoIntroCopy}
      </RichText>
      <div className={`${classes.container} col-${_columns}`}>
        {addItems?.map((item, index) => (
          <ProgramCard
            column={_columns}
            key={`${item?.contentful_id}${index}`}
            {...item}
          />
        ))}
      </div>
    </Root>
  );
}

export default CardLayout;
