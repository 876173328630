import { Button, Typography, useTheme } from '@mui/material';
import scrollTo from 'gatsby-plugin-smoothscroll';
import RichText from '../../rich-text';
import { ColorName } from '../../../constants';

interface JumpLinkProps {
  jumpLinkHeading?: string;
  outlineEntries?: any;
  jumpLinkIntroCopy?: any;
}
function JumpLink({
  outlineEntries,
  jumpLinkHeading,
  jumpLinkIntroCopy,
}: JumpLinkProps): ElementNode {
  const theme = useTheme();

  return (
    <article css={{
      width: '100%',
      display: 'flex',
      boxSizing: 'border-box',
      contain: 'content',
      position: 'relative',
      flexDirection: 'column',
    }}>
      <div>
        {jumpLinkHeading && (
          <Typography variant="h1">{jumpLinkHeading}</Typography>
        )}

        <RichText>{jumpLinkIntroCopy}</RichText>
      </div>
      <div css={{
        display: 'flex',
        width: '100%',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'start',
      }}>
        <ul>
          {outlineEntries?.map((entry: any, index: number) => {
            const anchorId = `#_${entry?.contentful_id}`;
            return (
              <li key={index}>
                <Button
                  data-testid={index}
                  css={{
                    padding: 0,
                    textAlign: 'inherit',
                    color: ColorName.LightBlue,
                    fontSize: theme.typography.pxToRem(20),

                    '&:hover, &:focus': {
                      background: 'none',
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => scrollTo(anchorId)}
                >
                  {entry?.anchorLabel}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </article>
  );
}

export default JumpLink;
