import jwtDecode from 'jwt-decode';
import { Tokens, AuthAdapter } from '../../types';
import {
  signInWithEmailAndPassword,
  signInWithCustomToken as signInWithCustomFirebaseToken,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  MultiFactorError,
  ApplicationVerifier,
  MultiFactorResolver,
  User,
} from 'firebase/auth';

import { auth } from '../../../../firebase';
import { FirebaseError } from 'firebase/app';

class FirebaseAuthAdapter implements AuthAdapter {
  async signInWithPassword(email: string, password: string): Promise<Tokens> {
    try {
      const resp = await signInWithEmailAndPassword(auth, email, password);
      return await this.buildToken(resp.user);
    } catch (err) {
      throw this.getError(err);
    }
  }

  async signInWithCustomToken(token: string): Promise<Tokens> {
    try {
      const resp = await signInWithCustomFirebaseToken(auth, token);
      return await this.buildToken(resp.user);
    } catch (err) {
      throw this.getError(err);
    }
  }

  async refreshTokens(): Promise<Tokens> {
    try {
      await auth.currentUser?.getIdToken(true); //Force Refresh
      return await this.buildToken(auth.currentUser!);
    } catch (err) {
      throw this.getError(err);
    }
  }

  async signInWithMultiFactorAuth(
    verificationId: string,
    resolver: MultiFactorResolver,
    verificationCode: string,
  ) {
    const credentials = PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
      credentials,
    );

    try {
      const resp = await resolver.resolveSignIn(multiFactorAssertion);
      return await this.buildToken(resp.user);
    } catch (err) {
      throw this.getError(err);
    }
  }

  protected async buildToken(user: User): Promise<Tokens> {
    const accessToken = await user.getIdToken();
    const idToken = await user.getIdToken(true);
    const refreshToken = user.refreshToken;

    const decodedToken: any = jwtDecode(idToken);

    return {
      accessToken: accessToken
        ? {
            token: accessToken,
            payload: decodedToken,
          }
        : null,
      idToken: {
        token: idToken,
        payload: decodedToken,
      },
      expiresAt: decodedToken.exp,
      refreshToken: refreshToken,
    };
  }

  protected getError(err: unknown): Error {
    const error = new Error('Http error');

    if (err instanceof FirebaseError) {
      //Firebase error contains customData for handling MFA, hence returning same error
      //error.message = err.code;
      err.message = err.code;
      return err;
    }
    return error;
  }
}
export default FirebaseAuthAdapter;
