import { FC, useEffect, useRef } from 'react';
import { Typography, Theme, useTheme } from '@mui/material';
import RichText from '../../rich-text';
import { SlideProps as Props } from './types.d';
import { useSliderContext } from './GallerySlider';
import { cell } from './lib/grid';


const Caption: FC<Props> = ({
  imageHeader,
  imageCaption,
  downloadFile,
  num,
}) => {
  const { context } = useSliderContext();
  const refCaption = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const { current } = refCaption;

    if (current) {
      if (current.dataset.id === `${context.position}`) {
        current.style.opacity = '1';
        current.style.transition = '.4s .6s';
        current.style.pointerEvents = 'auto';
      } else {
        current.style.opacity = '0';
        current.style.transition = '.1s 0s';
        current.style.pointerEvents = 'none';
      }
    }
  }, [context]);

  return (
    <div
      css={{
        ...cell('Row', 2, 3),
        ...cell('Column', 1, 3),
        width: '100%',
        opacity: 0,

        [theme.breakpoints.up('sm')]: {
          ...cell('Row', 1, 3),
          ...cell('Column', 1, 2),
        },

        '& h2': {
          fontWeight: 700,
        },

        '& h2 + p': {
          marginTop: '.5em',
        },
      }}
      data-id={num}
      ref={refCaption}
    >
      {imageHeader && <Typography variant="h2">{imageHeader}</Typography>}
      <RichText>{imageCaption}</RichText>
      {downloadFile && (
        <a href={downloadFile.file?.url || undefined} download>
          Download
        </a>
      )}
    </div>
  );
};

export default Caption;
