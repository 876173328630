import React, { ReactElement } from 'react';
import { styled , Theme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import { Typography } from '@mui/material';
import colors from '../../themes/main-colors';
import PGEButton from '../pge-button/_PgeButton';

const PREFIX = 'CallOut';

const classes = {
  root: `${PREFIX}-root`,
  media: `${PREFIX}-media`,
  imgMedia: `${PREFIX}-imgMedia`,
  textColor: `${PREFIX}-textColor`,
  a: `${PREFIX}-a`,
  button: `${PREFIX}-button`
};

const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    borderRadius: 5,
    '& .MuiPaper-elevation1': {
      boxShadow: 'none ',
    },
    '& .MuiTypography-gutterBottom': {
      fontWeight: 'normal',
      color: colors.noirBlur,
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(40),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
      },
    },
    '& .MuiTypography-colorTextSecondary': {
      fontSize: theme.spacing(2.5),
      color: colors.noirBlur,
    },
    '& .MuiCardContent-root': {
      padding: theme.spacing(0, 3.75, 0, 1.25),
    },
    '& .MuiPaper-root': {
      backgroundColor: 'inherit',
    },
    '& .MuiCardActionArea-focusHighlight': {
      backgroundColor: 'inherit',
    },
    '& .MuiButtonBase-root': {
      cursor: 'auto',
    },
  },

  [`& .${classes.media}`]: {
    height: 70,
    width: 70,
    margin: theme.spacing(0, 1.25, 2.5, 1.25),
  },

  [`& .${classes.imgMedia}`]: {
    height: 275,
    width: '100%',
    borderRadius: theme.spacing(0.625, 0.625, 0, 0),
    margin: theme.spacing(1.25),
    backgroundPosition: ({ imagePosition }: Props) => imagePosition,
  },

  [`& .${classes.textColor}`]: {
    '& > a': {
      textDecoration: 'none',
      cursor: 'pointer !important',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },

  [`& .${classes.a}`]: {
    underLine: 'none',
  },

  [`& .${classes.button}`]: {
    padding: theme.spacing(1.25, 0, 0, 1.25),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2.5, 0),
    },
  }
}));

interface Props {
  heading?: string;
  shortDescription?: string | ReactElement | null;
  photo?: any;
  button?: IPGEButtonProps | null;
  link?: Ctfl.Content['Link'] | string;
  readonly imagePosition?: Ctfl.Field['Position'];
}

const CallOut: React.FC<Props> = (props) => {


  const getHeading = (
    heading: any,
    buttonProps: IPGEButtonProps | null | undefined,
    link: Ctfl.Content['Link'] | string | undefined,
  ) => {
    if (
      buttonProps &&
      (buttonProps.buttonMode === null || buttonProps.buttonMode === '')
    ) {
      return (
        <PGEButton link={link} theme="Text" display="Inline">
          {props.heading}
        </PGEButton>
      );
    } else {
      return heading;
    }
  };
  return (
    <Root className={classes.root}>
      <Card>
        {props.photo && props.photo.includes('.svg') === true ? (
          <CardMedia className={classes.media} image={props.photo} />
        ) : (
          props.photo &&
          props.photo !== 'null' && (
            <CardMedia className={classes.imgMedia} image={props.photo} />
          )
        )}

        <CardContent>
          {props.heading && (
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.textColor}
            >
              {getHeading(props.heading, props?.button, props?.link)}
            </Typography>
          )}

          {props.shortDescription && <div>{props.shortDescription}</div>}
        </CardContent>

        {props.button?.buttonMode && (
          <div className={classes.button}>
            <PgeButton {...props.button} />
          </div>
        )}
      </Card>
    </Root>
  );
};
export default CallOut;
