import Backdrop from '@mui/material/Backdrop';
import { styled, Theme, useTheme } from '@mui/material/styles';
import React from 'react';

import { TransitionContext } from '../../providers/TransitionProvider';
import ColorBlendSpinner from '../spinner/ColorBlendSpinner';

export default (props: { forceOpen?: boolean; message?: string }) => {
  const theme = useTheme();

  return (
    <TransitionContext.Consumer>
      {(value: { state: { open: boolean; message: string } }) => {
        const { open, message } = value.state || {};
        return (
          <Backdrop
            css={{
              zIndex: theme.zIndex.drawer + 9999,
              color: '#fff',
              fontSize: '1.5em',
              fontWeight: 'bold',
              textAlign: 'center',
              padding: theme.spacing(2)
            }}
            open={!!props.forceOpen || !!open}
          >
            <ColorBlendSpinner>{props.message || message}</ColorBlendSpinner>
          </Backdrop>
        );
      }}
    </TransitionContext.Consumer>
  );
};
