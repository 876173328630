import { ComponentPropsWithoutRef } from 'react';

export const ItemOmits = ['aria-label', 'style', 'tabTeaser'] as const;

export const ListOmits = ['aria-label', 'style'] as const;

interface ItemBase
  extends Queries.ListItemFragment,
    ComponentPropsWithoutRef<'section'> {
  readonly length: number;
  readonly pos: number;
}

interface ListBase
  extends Queries.StaticListFragment,
    ComponentPropsWithoutRef<'article'> {
  readonly colorBackground?: Ctfl.Field['Background'];
  readonly imagePosition?: Ctfl.Field['Position'];
}

export type ListItemProps = Partial<Omit<ItemBase, typeof ItemOmits[number]>>;

export type StaticListProps = Partial<Omit<ListBase, typeof ListOmits[number]>>;
