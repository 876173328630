import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import InterceptModal from './InterceptModal';
import {
  removeFromStorage,
  getFromStorage,
  setInStorage,
} from '../../../util/storage-utils';

const GlobalInterceptModalQuery = graphql`
  {
    allContentfulInterceptModal(
      filter: {
        entryName: {
          regex: "/^((?!placeholder|demo-|test-comp|untitled-|sample-).)*$/i"
        }
        node_locale: { eq: "en" }
      }
    ) {
      nodes {
        ...interceptModal
      }
    }
  }
`;

const GLOBAL_INTERCEPT_KEY = 'globalInterceptModalDismissed';

const setModalDismissal = (modalId: string) => {
  setInStorage(GLOBAL_INTERCEPT_KEY, modalId);
};

const GlobalInterceptModal = () => {
  const { allContentfulInterceptModal } = useStaticQuery<Queries.Query>(
    GlobalInterceptModalQuery,
  );

  const [modal] = allContentfulInterceptModal.nodes;

  if (!modal) {
    removeFromStorage(GLOBAL_INTERCEPT_KEY);
    return null;
  }

  const modalId = modal.contentful_id;
  const dismissedModalId = getFromStorage(GLOBAL_INTERCEPT_KEY);

  if (dismissedModalId === modalId) {
    return null;
  }

  return (
    <InterceptModal
      modal={modal}
      onCloseCallback={() => setModalDismissal(modalId)}
    />
  );
};

export default GlobalInterceptModal;
