import React from 'react';
import { INLINES } from '@contentful/rich-text-types';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import PgeButton from '../../pge-button/_PgeButton';
import { isRefLink } from '../../../util/type-checking';

function inline(): RenderNode {
  return {
    [INLINES.HYPERLINK]: (node, txt) => (
      <PgeButton
        link={node.data.uri}
        theme="Text"
        display="Inline"
        disableRippleEffect={true}
      >
        {txt}
      </PgeButton>
    ),

    [INLINES.ASSET_HYPERLINK]: (node, txt) => (
      <PgeButton
        link={node.data.target?.file?.url}
        theme="Text"
        display="Inline"
        disableRippleEffect={true}
      >
        {txt}
      </PgeButton>
    ),

    [INLINES.ENTRY_HYPERLINK]: (node, txt) => {
      if (isRefLink(node.data.target)) {
        return (
          <PgeButton
            link={node.data.target}
            theme="Text"
            display="Inline"
            disableRippleEffect={true}
          >
            {txt}
          </PgeButton>
        );
      } else {
        return null;
      }
    },
  };
}

export default inline;
