import React, { FunctionComponent, CSSProperties } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import PGELogoProps from './PGELogo.types';

const logoPathStyle: CSSProperties = {
  fill: '#1473c1',
  fillOpacity: 1,
  fillRule: 'nonzero',
  stroke: 'none',
};

const PGELogo: FunctionComponent<PGELogoProps> = ({
  className,
  color,
  viewBox,
}) => {
  const style = { ...logoPathStyle, fill: color || '#1473c1' };
  return (
    <SvgIcon viewBox={viewBox} className={className}>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,288)" id="g10">
        <g transform="scale(0.1)" id="g12">
          <path
            id="path14"
            style={style}
            className="letterG"
            d="m 872.402,1082.07 c 0,138.64 105.957,225.63 236.098,225.63 97.46,0 156.31,-51.67 186.39,-105.96 l -94.18,-49.04 c -16.34,28.78 -49.69,55.59 -92.21,55.59 -71.28,0 -121.645,-54.94 -121.645,-126.22 0,-71.29 50.365,-126.23 121.645,-126.23 32.05,0 64.75,13.082 80.45,26.16 v 34.01 h -98.11 v 98.76 h 210.6 V 941.461 c -47.09,-51.672 -110.53,-85.031 -192.94,-85.031 -130.141,0 -236.098,86.98 -236.098,225.64"
          />
          <path
            id="path16"
            style={style}
            className="letterE"
            d="m 1376.64,860.641 v 436.239 h 319.82 v -98.11 h -207.33 v -68.02 h 202.76 v -98.1 h -202.76 v -73.9 h 207.33 v -98.109 h -319.82"
          />
          <path
            id="path18"
            className="letterP"
            style={style}
            d="m 721.516,1151.68 c 0,-29.43 -22.246,-46.43 -52.325,-46.43 h -90.91 v 93.52 h 90.91 c 30.079,0 52.325,-17 52.325,-47.09 z m -255.723,145.2 V 860.641 H 578.281 V 1007.15 H 684.23 c 98.106,0 151.739,66.05 151.739,144.53 0,79.14 -53.633,145.2 -151.739,145.2 H 465.793"
          />
          <path
            id="path20"
            style={style}
            d="m 1192.68,2097.26 c -140.6,140.43 -247.309,1.26 -247.309,1.26 L 1985.33,1315.79 c 0,0 -652.06,641.04 -792.65,781.47"
          />
          <path
            id="path22"
            style={style}
            d="M 62.8125,1210.61 C -77.7813,1070.19 61.5547,963.609 61.5547,963.609 L 845.227,2002.32 c 0,0 -641.809,-651.28 -782.4145,-791.71"
          />
          <path
            id="path24"
            style={style}
            d="M 963.398,62.7383 C 1103.99,-77.6914 1210.69,61.4805 1210.69,61.4805 L 170.742,844.199 c 0,0 652.063,-641.027 792.656,-781.4607"
          />
          <path
            id="path26"
            style={style}
            d="M 2098.45,1207.28 1314.79,168.57 c 0,0 641.79,651.282 782.4,791.711 140.59,140.429 1.26,246.999 1.26,246.999"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PGELogo;
