import React, { useEffect } from 'react';
import { styled , Theme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from '../../hooks/useTranslation';
import Box from '@mui/material/Box';
import PGEButton from '../buttons';
import Modal from '../modal';
import useFormState, { convertValidationRules } from '../../hooks/useFormState';
import TextField from '../text-field';
import { ALPHA_NUMERIC_REGEX } from '../../util/form-validation';
import Typography from '@mui/material/Typography';

const PREFIX = 'NicknameChangeModal';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  modal: `${PREFIX}-modal`,
  card: `${PREFIX}-card`,
  closeIcon: `${PREFIX}-closeIcon`,
  cancelButton: `${PREFIX}-cancelButton`,
  infoText: `${PREFIX}-infoText`,
  saErrorText: `${PREFIX}-saErrorText`
};

const StyledModal = styled(Modal)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    '& .MuiPaper-root': {
      minWidth: '45%',
    },
    '& .MuiFormControl-root': {
      minHeight: '75px',
    },
  },

  [`& .${classes.input}`]: {
    width: '100%',
  },

  [`& .${classes.modal}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.card}`]: {
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },

  [`& .${classes.closeIcon}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
  },

  [`& .${classes.cancelButton}`]: {
    border: 'none !important',
    marginRight: theme.spacing(1),
  },

  [`& .${classes.infoText}`]: {
    color: `${theme.palette.error.main} !important`,
  },

  [`& .${classes.saErrorText}`]: {
    margin: '20px 0 20px 0',
    color: `${theme.palette.error.main} !important`,
  }
}));

type Model = {
  nickname: string;
  removeNickname: boolean;
};

type AdditionalContext = {
  t: (translation: string) => string;
};

type NicknameType = 'ACCOUNT' | 'ADDRESS';

type Props = {
  onClose: () => void;
  open?: boolean;
  onSubmit: (value: Model) => void;
  defaultNickname?: string;
  nicknameType: NicknameType;
  loading: boolean;
  saving: boolean;
  accountSaStatus?: string;
};

export default function NicknameChangeModal({
  onClose,
  open = true,
  onSubmit,
  defaultNickname,
  nicknameType = 'ADDRESS',
  loading = false,
  saving = false,
  accountSaStatus = '',
}: Props) {
  const { t } = useTranslation();
  const form = useFormState(
    {
      nickname: defaultNickname || '',
      removeNickname: false,
    },
    {
      validate: convertValidationRules<Model, AdditionalContext>(() => ({
        nickname: (value, { values }) => {
          if (!value && !values.removeNickname) {
            return nicknameType === 'ADDRESS'
              ? t('ENTER_NICKNAME_SERVICE_AGREEMENT')
              : t('ENTER_NICKNAME');
          }

          const invalidCharacters = new RegExp(
            /^.*?(?=[(^<>_={}[\];|\)]).*$/gi,
          );
          if (value.match(invalidCharacters)) {
            return t('NICKNAME_INVALID_CHARACTERS');
          }
          const isAlphaNumeric = new RegExp(ALPHA_NUMERIC_REGEX);
          if (!isAlphaNumeric.test(value)) {
            return t('ENTER_NICKNAME_WITHOUT_SPACE');
          }
          return null;
        },
      })),
      validationContext: { t },
    },
  );

  useEffect(() => {
    if (!form.values.nickname && defaultNickname) {
      void (async () => form.setValue('nickname', defaultNickname))();
    }
  }, [defaultNickname]);



  return (
    <StyledModal
      className={classes.root}
      onClose={onClose}
      open={open}
      title={
        nicknameType === 'ACCOUNT'
          ? t('NICKNAME_ACCOUNT')
          : t('NICKNAME_ADDRESS')
      }
      data-testid="nickname-change-modal"
    >
      {accountSaStatus === 'PENDINGSTART' ? (
        <>
          <Typography variant={'body1'} className={classes.saErrorText}>
            {t('SERVICE_ADDRESS_NICKNAME_ERROR')}
          </Typography>
          <Box justifyContent="flex-end" display="flex">
            <PGEButton
              className={classes.cancelButton}
              color={'primary'}
              variant={'contained'}
              onClick={onClose}
            >
              {t('OK')}
            </PGEButton>
          </Box>
        </>
      ) : (
        <>
          {loading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField
                key={String(form.values.removeNickname)}
                name={'nickname'}
                label={t('NICKNAME')}
                margin="normal"
                className={classes.input}
                inputProps={{
                  maxLength: 256,
                }}
                {...form.props('nickname')}
                defaultValue={
                  form.values.removeNickname ? '' : form.values.nickname
                }
                disabled={form.values.removeNickname}
                error={
                  !form.values.removeNickname && Boolean(form.errors.nickname)
                }
                helperText={!form.values.removeNickname && form.errors.nickname}
              />
              <Typography variant={'subtitle1'} className={classes.infoText}>
                {t('NICKNAME_INFO_TEXT')}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'removeNickname'}
                    color={'primary'}
                    disabled={
                      nicknameType === 'ADDRESS'
                        ? true
                        : !Boolean(defaultNickname) ||
                          !Boolean(form.values.nickname)
                    }
                    {...form.checkProps('removeNickname')}
                  />
                }
                label={t('REMOVE_NICKNAME')}
              />
              <Box justifyContent="flex-end" display="flex">
                <PGEButton
                  className={classes.cancelButton}
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={onClose}
                >
                  {t('CANCEL')}
                </PGEButton>
                <PGEButton
                  color={'primary'}
                  variant={'contained'}
                  onClick={form.submit(onSubmit)}
                  disabled={
                    (form.values.nickname === defaultNickname &&
                      !form.values.removeNickname) ||
                    saving ||
                    (nicknameType === 'ADDRESS' && !form.values.nickname)
                  }
                >
                  {saving ? <CircularProgress /> : t('UPDATE')}
                </PGEButton>
              </Box>
            </>
          )}
        </>
      )}
    </StyledModal>
  );
}
