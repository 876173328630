import template from 'lodash/template';
import _truncate from 'lodash/truncate';

const interpolateTemplate = /{{([\s\S]+?)}}/g;

export const replaceVariables = (
    value: string,
    variables: Record<string, string | undefined>,
  ): string => {
    return template(value, {
      interpolate: interpolateTemplate,
    })(variables);
};

export const truncate = (str = '', length = 15) => {
  return _truncate(str, {
    length
  })
}
