import {
  QueryHookOptions,
  OperationVariables,
  DocumentNode,
} from '@apollo/client';
import usePgeQuery from './usePgeQuery';
import useAuth from './useAuth';

/**
 * Used instead of `useQuery` to make requests that require authentication.
 *
 * Handles waiting until the token has finished being validated/refreshed before
 * making the request.
 */
export default function useAuthQuery<
  TData = any,
>(query: DocumentNode, options: QueryHookOptions<TData> = {}) {
  const { isAuthenticated } = useAuth();
  return usePgeQuery<TData>(query, {
    ...options,
    skip: !isAuthenticated || options.skip,
    clientErrors: (e: any) => console.log('e', e),
  });
}
