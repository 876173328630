import React, { FunctionComponent, useState } from 'react';
import { styled , Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { navigate } from 'gatsby';
import Grid from '@mui/material/Grid';

import { useTranslation } from '../../hooks/useTranslation';
import useAuth from '../../hooks/useAuth';
import ROUTES from '../../routes';
import { usePromotionContext } from '../../providers/PromotionProvider';
import colors from '../../themes/main-colors';
import MobileNavigationMenu from './MobileNavMenu';
import { useNavigationTranslations } from '../../queries/translations/navigationTranslations.query';
import PGELogo from '../logos/PGELogo';
import { NavigationUser } from '../navigation/NavigationUser';
import { ColorName } from '../../constants';
import PGEButton from '../buttons/PGE-Button';
import { useIsMobile } from '../../util/style-utils';

const PREFIX = 'MobileHeader';

const classes = {
  root: `${PREFIX}-root`,
  toolBar: `${PREFIX}-toolBar`,
  promotionToolBar: `${PREFIX}-promotionToolBar`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  paper: `${PREFIX}-paper`,
  logoButton: `${PREFIX}-logoButton`,
  logo: `${PREFIX}-logo`,
  alignItem: `${PREFIX}-alignItem`
};

const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.toolBar}`]: (props: { isOutagesWebPage: Boolean }) => ({
    minHeight: props.isOutagesWebPage ? 64 : 88,
    background:
      'transparent linear-gradient(188deg, #0064A2 0%, #0096A2 100%) 0% 0% no-repeat padding-box',
    opacity: 0.9,
    display: 'flex',
  }),

  [`& .${classes.promotionToolBar}`]: (props: { isOutagesWebPage: Boolean }) => ({
    minHeight: props.isOutagesWebPage ? 64 : 88,
    background: ColorName.Grey,
    opacity: 0.9,
    display: 'flex',
    '& .text-right': {
      textAlign: 'right',
    },
  }),

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
    fontWeight: 700,
    fontSize: '1.125rem',
  },

  [`& .${classes.paper}`]: {
    background: colors.navBarBackground,
    color: colors.white,
  },

  [`& .${classes.logoButton}`]: {
    padding: '5px 21px 5px 2px',
  },

  [`& .${classes.logo}`]: (props: { isOutagesWebPage: Boolean }) => ({
    height: props.isOutagesWebPage ? 54 : 60,
    width: props.isOutagesWebPage ? 54 : 60,
    '& .angles': {
      fill: 'white',
    },
    '& .letters': {
      fill: 'white',
    },
  }),

  [`& .${classes.alignItem}`]: {
    display: 'flex',
    alignItems: 'center',
  }
}));

interface MobileHeaderProps {
  title: string;
}

const MobileHeader: FunctionComponent<MobileHeaderProps> = props => {
  const { navigation } = useNavigationTranslations();
  const isBrowser = !(typeof window === 'undefined');
  const isMobile = useIsMobile();
  const isOutagesWebPage =
    !isMobile &&
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES;

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { signOut, isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { promotion, noLogo, noSignIn } = usePromotionContext();

  const toggleMenu = () => {
    setMenuOpen(false);
  };

  const navigateToRoute = (route: string) => (e: any) => {
    e.preventDefault();
    setMenuOpen(false);
    return navigate(route);
  };

  return (
    <Root className={classes.root} data-swiftype-index="false">
      {!promotion && (
        <>
          <AppBar position={'static'} elevation={0}>
            <Toolbar className={classes.toolBar}>
              <IconButton
                id="hamburger-menu-icon"
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setMenuOpen(true);
                }}
                size="large">
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {props.title}
              </Typography>
              <IconButton
                edge="end"
                aria-label="home"
                className={isOutagesWebPage ? classes.logoButton : ''}
                onClick={() => navigate('/')}
                size="large">
                <PGELogo
                  viewBox="0 0 288 288"
                  color="#fff"
                  className={classes.logo}
                />
              </IconButton>
            </Toolbar>
          </AppBar>
          <MobileNavigationMenu
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            navigateToRoute={navigateToRoute}
            navigation={navigation}
          />
        </>
      )}
      {promotion && (
        <AppBar position="static" elevation={0} color="transparent">
          <Toolbar className={classes.promotionToolBar}>
            <Grid item xs={6}>
              {!isAuthenticated && !noSignIn && (
                <PGEButton
                  data-testid={'sign-in-route-button'}
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={() => navigate(ROUTES.SIGN_IN)}
                >
                  {t('SIGN_IN')}
                </PGEButton>
              )}
              {isAuthenticated && !noSignIn && (
                <NavigationUser signOut={signOut} />
              )}
            </Grid>
            <Grid item xs={6} className="text-right">
              {!noLogo && (
                <IconButton
                  edge="end"
                  aria-label="home"
                  className={isOutagesWebPage ? classes.logoButton : ''}
                  onClick={() => navigate('/')}
                  size="large">
                  <PGELogo
                    viewBox="0 0 288 288"
                    color="#006DBA"
                    className={classes.logo}
                  />
                </IconButton>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </Root>
  );
};

export default MobileHeader;
